import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarCheck,
  faCalendarLines,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserDataSelector } from 'hooks';
import React, { FC } from 'react';
import CommonContainer from 'ui-components/CommonContainer';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { getUserTimezoneConvertedNow } from 'pages/launcher/details/helper';
import { CustomLauncherTask, ILauncherTaskStatus } from '../types';

interface IProps {
  task: CustomLauncherTask;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  setSelectedTaskData: (
    value: React.SetStateAction<CustomLauncherTask | undefined>
  ) => void;
}

const ListItem: FC<IProps> = ({ task, setIsOpen, setSelectedTaskData }) => {
  const loggedInUserEid = useUserDataSelector((state) => state.eid);
  const entityUserData = useReactiveVar(usersEntityObj);
  const taskTimeDetails = (task: CustomLauncherTask) => {
    switch (task?.taskStatus) {
      case 'overdue':
        return (
          <Flex align='center' gap='5px'>
            <Flex align='center' gap='5px'>
              <FontAwesomeIcon
                icon={faCalendarLines as IconProp}
                color='#FF6A55'
              />
              <Box as='span' color='#FF6A55'>
                {moment(task?.formattedDueDate)?.format('MMM D')}
              </Box>
            </Flex>
            <Box color='#D1D8E0' as='span'>
              &bull;
            </Box>
            <Box color='#FF6A55' as='span'>
              {task?.formattedDuration}
            </Box>
          </Flex>
        );
      case 'inProgress':
        return (
          <Flex align='center' gap='5px'>
            <Flex align='center' gap='5px'>
              <FontAwesomeIcon
                icon={faCalendarLines as IconProp}
                color='#2A85FF'
              />
              <Box as='span' color='#2A85FF'>
                {moment(task?.formattedDueDate)?.format('MMM D')}
              </Box>
            </Flex>
            <Box as='span' color='#D1D8E0'>
              &bull;
            </Box>
            <Box as='span' color='#2A85FF'>
              {' '}
              Due in {task?.formattedDuration}
            </Box>
          </Flex>
        );
      case 'complete':
        return (
          <Flex align='center' gap='5px'>
            <Flex align='center' gap='5px'>
              <FontAwesomeIcon
                icon={faCalendarCheck as IconProp}
                color='#83BF6E'
              />
              <Box as='span' color='#83BF6E'>
                Marked completed on{' '}
                {moment(task?.formattedCompleteDate)?.format('MMM D')}
              </Box>
              <Box as='span' color='#83BF6E'>
                by {getUserName(task?.completedBy!)}
              </Box>
            </Flex>
          </Flex>
        );
      default:
        return null;
    }
  };
  const getUserName = (userId: string) => {
    if (userId === loggedInUserEid) {
      return 'You';
    }
    return (
      entityUserData?.find((userData: any) => userData?.eid === userId)?.name ||
      ''
    );
  };
  const taskStatus = (status: ILauncherTaskStatus) => {
    if (status === 'inProgress' || status === 'overdue') {
      return status;
    } else if (status === 'complete') {
      return 'completed';
    }
  };

  return (
    <CommonContainer
      bg='white'
      cursor='pointer'
      borderRadius='8px'
      p='12px 16px'
      onClick={() => {
        if (task?.taskStatus !== 'locked') {
          setIsOpen(true);
          setSelectedTaskData(task);
        }
      }}
    >
      <Flex gap='10px' align='flex-start' w='full'>
        <Box>
          {task?.taskStatus === 'locked' ? (
            <FontAwesomeIcon icon={faLockKeyhole as IconProp} />
          ) : (
            <TaskStatusIcon
              status={taskStatus(task?.taskStatus)}
              iconStyles={{ marginTop: '4px' }}
            />
          )}
        </Box>
        <Flex
          flexDir='column'
          // onClick={onOpen}
          cursor={task?.taskStatus !== 'locked' ? 'pointer' : 'no-drop'}
        >
          <Box gap='5px'>
            <Box as={task?.taskStatus === 'complete' ? 's' : 'span'}>
              {task?.title}
            </Box>
            {task?.description && (
              <Box
                as={task?.taskStatus === 'complete' ? 's' : 'span'}
                color='#6F767E'
              >
                : {task?.description}
              </Box>
            )}
          </Box>
          {task?.taskStatus === 'locked' ? (
            <Box>
              <Box
                as='span'
                color='#6F767E'
                fontSize='12px'
                fontWeight={400}
                mr='5px'
              >
                Unlocks when the above task is completed
              </Box>
            </Box>
          ) : (
            <Flex align='center' gap='5px'>
              <Flex>{taskTimeDetails(task)}</Flex>
              <Flex align='center' gap='5px'>
                <Box as='span' color='#D1D8E0'>
                  &bull;
                </Box>
                <Box as='span' color='rgba(111, 118, 126, 1)'>
                  {task?.category}
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </CommonContainer>
  );
};

export default ListItem;
