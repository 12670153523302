import React, { useEffect, useState } from 'react';
import { Checkbox, Flex, Text, Box, Image } from '@chakra-ui/react';
import { Row } from 'antd';
import { ReactComponent as BackButton } from '../../../assets/images/back.svg';
import { PopoverCloseButton } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import EmptyState from './EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { useReactiveVar } from '@apollo/client';
import { IEntityUser } from 'shared/global-types';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

import './TasksFilter.scss';
import { getImage } from 'utils';

type MemberFilterComponentProps = {
  extraFilterData: any;
  sortedMemberArray: any;
  setSortedMemberArray: any;
  setCheckedOptionsData: any;
  checkedOptionsData: any;
};

const MemberFilterComponent = ({
  extraFilterData,
  sortedMemberArray,
  setSortedMemberArray,
  setCheckedOptionsData,
  checkedOptionsData,
}: MemberFilterComponentProps) => {
  const usersEntityData: IEntityUser[] = useReactiveVar(usersEntityObj);
  const membersData = usersEntityData.filter((item) => item?.type === 'user');
  const [memberList, setMemberList] = useState(membersData);
  const [searchMemberQuery, setSearchMemberQuery] = useState('');

  const { setPageName, loading } = extraFilterData;

  const { checkedMemberList } = checkedOptionsData;

  const searchMemberQueryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMemberQuery(e?.target?.value);
  };

  const handleMemberSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newCheckedMemberList = [...checkedMemberList];
      newCheckedMemberList.push(e.target.value);
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkedMemberList: newCheckedMemberList,
      });
    } else {
      const newCheckedMemberList = checkedMemberList.filter(
        (item: any) => item !== e.target.value
      );
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkedMemberList: newCheckedMemberList,
      });
    }
  };

  let finalMemberList =
    sortedMemberArray?.length > 0 ? sortedMemberArray : memberList;

  const filteredMembers = finalMemberList?.filter((member: any) =>
    member?.name?.toLowerCase().includes(searchMemberQuery?.toLowerCase())
  );

  useEffect(() => {
    const tempArray: string[] = [];
    if (checkedMemberList?.length > 0) {
      memberList.map((member: any) => {
        if (checkedMemberList.includes(member?.eid)) {
          const obj = { ...member, isChecked: true };
          tempArray.push(obj);
        }
      });

      memberList.map((member: any) => {
        if (!checkedMemberList.includes(member?.eid)) {
          const obj = { ...member, isChecked: false };
          tempArray.push(obj);
        }
      });
    }

    setSortedMemberArray(tempArray);
  }, [checkedMemberList]);

  return (
    <>
      <Flex>
        <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
          <Row className='task-filter-tag-container' />
          <Row className='task-filter-title-container'>Filters</Row>
        </Row>
        <Flex gap={'20px'} height={'fit-content'}>
          <Text
            style={{ position: 'relative', top: 5 }}
            fontSize={'15px'}
            color={checkedMemberList?.length > 0 ? '#2A85FF' : '#DDDDDD'}
            cursor={'pointer'}
            onClick={() => {
              deployEvent(CommonAmplitudeEvents.CLEAR_MEMBERS_FILTER);
              setCheckedOptionsData({
                ...checkedOptionsData,
                checkedMemberList: [],
              });
            }}
          >
            Clear all
          </Text>
        </Flex>
        {/* <PopoverCloseButton
          style={{
            backgroundColor: '#EFEFEF',
            borderRadius: '50%',
            position: 'relative',
          }}
        /> */}
      </Flex>
      <Flex gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {`Assigned to Members (${memberList.length} members)`}
        </Text>
      </Flex>
      <SearchInput
        className='member-search-field'
        value={searchMemberQuery}
        onChange={searchMemberQueryHandler}
        placeholder='Search by name'
        hideShortcuts
      />
      {filteredMembers?.length > 0 ? (
        <Box maxHeight={'300px'} overflow={'scroll'}>
          {filteredMembers.map((member: any) => {
            return (
              <Flex
                key={member?.eid}
                gap={4}
                alignItems={'center'}
                padding={'0.5rem 0px'}
              >
                <Checkbox
                  // disabled={loading ? true : false}
                  value={member?.eid}
                  onChange={handleMemberSelection}
                  isChecked={
                    checkedMemberList.includes(member?.eid) ? true : false
                  }
                />

                <Flex gap={2} alignItems={'center'}>
                  <Box>
                    <Image
                      src={getImage(member.profilePic, member.name)}
                      alt={member?.name}
                      width={'40px'}
                      height='40px'
                      borderRadius={'50%'}
                      backgroundColor={'gray.200'}
                    />
                  </Box>
                  <Flex direction={'column'}>
                    <Text fontWeight={600}>{`${member?.name}`}</Text>
                    <Text
                      color={'#92929D'}
                      fontSize={'0.7rem'}
                      fontWeight={600}
                    >{`${member?.role} . ${member?.locations[0]?.name}`}</Text>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Box>
      ) : (
        <EmptyState title={'member'} />
      )}
    </>
  );
};

export default MemberFilterComponent;
