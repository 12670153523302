import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICropImgFilesData } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/DocumentsUploadComponent';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileToBase64 } from 'utils';

interface IProps {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setCropImgFilesData: React.Dispatch<
    React.SetStateAction<ICropImgFilesData[]>
  >;
}

const ImageResizeSkeleton: FC<IProps> = ({ setFiles, setCropImgFilesData }) => {
  const onFileDrop = async (fileLists: File[]) => {
    setFiles(fileLists);
    let _cropImgFilesData: ICropImgFilesData[] = [];
    for (let i = 0; i < fileLists?.length; i++) {
      const base64String = await fileToBase64(fileLists[i]);
      _cropImgFilesData?.push({
        crop: { x: 0, y: 0 },
        croppedImageFile: fileLists?.[i],
        imageBase64Url: base64String,
        zoom: 1,
        croppedAreaPixels: {
          height: 0,
          width: 0,
          x: 0,
          y: 0,
        },
      });
    }
    setCropImgFilesData(_cropImgFilesData);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/webp': ['.webp'],
    },
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });
  return (
    <Flex w='full' flexDir='column' gap='10px' align='center'>
      <Center
        border='1px dashed #3F3B5780'
        flexDir='column'
        borderRadius='12px'
        p={8}
        gap='10px'
        w='80%'
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <FontAwesomeIcon
          icon={faArrowUpFromBracket as IconProp}
          fontSize='24px'
        />
        <Flex>
          <Text fontWeight={400} fontSize='15px' color='#212121'>
            Drag and drop documents here
          </Text>
          &nbsp;
          <Text
            onClick={open}
            fontSize='15px'
            textDecor='underline'
            cursor='pointer'
          >
            or choose file
          </Text>
        </Flex>
        <Text fontWeight={400} fontSize='14px' color='#212121B2'>
          JPG, PNG, WEBP &bull; Max size 5 Mb
        </Text>
      </Center>
      <Text fontSize='12px' color='rgba(33, 33, 33, 0.6)'>
        Recommended size is 256x256px
      </Text>
    </Flex>
  );
};

export default ImageResizeSkeleton;
