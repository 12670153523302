import React, { FC, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from '../chaptor.types';
import { ISaveChapterData } from '../hooks/types';
import ShareChapterDrawer from 'pages/Chapters/ChapterView/ShareChapterDrawer';
import { IChapterInterface } from '../chapter.types';
import { SopDetailData } from 'pages/Chapters/ChapterView/chapter.graphql';
import HeadingName from './components/HeadingName';
import ActionButtons from './components/ActionButtons';

interface IProps {
  chapterId?: string;
  showShareDrawer: boolean;
  editorData: IChapterInterface;
  chapterStatus: string | undefined;
  chapterSaveData: ISaveChapterData;
  chapterSaveLoading: boolean;
  chapterUpdateLoading: boolean;
  initialLoad: boolean;
  isEditMode: boolean;
  showTrainingPathFlowModal: boolean;
  isAnyChangeMade: boolean;
  refetchChapterDetailsHandler: () => void;
  publishHandler: () => void;
  previewHandler: () => void;
  saveTemporaryHandler: () => void;
  setShowShareDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const Heading: FC<IProps> = ({
  showShareDrawer,
  chapterId,
  editorData,
  chapterStatus,
  chapterSaveData,
  chapterSaveLoading,
  chapterUpdateLoading,
  isEditMode,
  showTrainingPathFlowModal,
  initialLoad,
  isAnyChangeMade,
  refetchChapterDetailsHandler,
  publishHandler,
  previewHandler,
  saveTemporaryHandler,
  setShowShareDrawer,
}) => {
  const windowRef = useRef<any>();

  const { watch, getValues } = useFormContext<IFormInput>();

  useEffect(() => {
    function listener(event: MessageEvent<any>) {
      const message = event?.data?.message;
      switch (message) {
        case 'chapterPreviewLoaded':
          event.source?.postMessage({
            message: 'initChapterPreview',
            data: getValues(),
          });
          break;
        default:
          break;
      }
    }

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (windowRef.current && !windowRef.current?.closed) {
        windowRef.current.postMessage(
          {
            message: 'updateChapterPreview',
            data: value,
          },
          '*'
        );
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <Flex alignItems='baseline' flexDir='row' justifyContent='space-between'>
        <HeadingName />
        <ActionButtons
          chapterId={chapterId!}
          chapterSaveData={chapterSaveData}
          chapterSaveLoading={chapterSaveLoading}
          chapterStatus={chapterStatus}
          chapterUpdateLoading={chapterUpdateLoading}
          editorData={editorData}
          initialLoad={initialLoad}
          isAnyChangeMade={isAnyChangeMade}
          isEditMode={isEditMode}
          showTrainingPathFlowModal={showTrainingPathFlowModal}
          getChapterDetails={refetchChapterDetailsHandler}
          previewHandler={previewHandler}
          publishHandler={publishHandler}
          saveTemporaryHandler={saveTemporaryHandler}
          setShowShareDrawer={setShowShareDrawer}
        />
      </Flex>
      {showShareDrawer && (
        <ShareChapterDrawer
          chapterId={chapterId!}
          isOpen={showShareDrawer}
          onClose={() => setShowShareDrawer(false)}
          chapterData={editorData?.SopById! as unknown as SopDetailData}
        />
      )}
    </>
  );
};

export default Heading;
