import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  ATTACH_LOCATION_CREDENTIALS,
  LOCATION_MEMBERS,
  magicLinkQuery,
  MAKE_PRE_LAUNCH_LOCATION_LIVE,
  SEND_LOCATION_DETAILS_TO_LOCATION_OWNERS_QUERY,
} from '../location-login-details.graphql';
import {
  LocationLiveResp,
  MAKE_LOCATION_LIVE,
} from '../../LiveModal/live-modal.graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { FormType } from '../LocationLoginDetails';
import { LauncherLocation } from 'pages/launcher/dashboard/dashboard.graphql';
import { ServiceLayer } from '../types';
import { UPDATE_LOCATION_QUERY } from 'pages/Locations/EditLocation/update-location.graphql';
import { formatData } from '../formatData';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';

const useServiceLayer = (
  loggedInUserEid: string,
  locationData: LauncherLocation | undefined,
  locationOwnersArray: string[],
  type: 'preToOpen' | 'developmentToOpen',
  shouldUpdateLocation?: boolean,
  afterError?: () => void,
  afterSuccess?: () => void,
  moveToLocationLiveModal?: () => void
): ServiceLayer => {
  const [saveShareLoading, setSaveShareLoading] = useState(false);
  const [saveGoLoading, setSaveGoLoading] = useState(false);
  const [attachLoginDetailsLoading, setAttachLoginDetailsLoading] =
    useState(false);
  const client = useApolloClient();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  useEffect(() => {
    if (locationData?.locationId) {
      getLocationOwners({
        variables: {
          locationIds: [locationData?.locationId],
          authRoles: ['locationOwner'],
        },
      });
    }
  }, [locationData?.locationId]);

  const [
    getLocationOwners,
    { data: _locationOwners, loading: gettingLocationOwners },
  ] = useLazyQuery(LOCATION_MEMBERS, {
    fetchPolicy: 'network-only',
  });

  const locationOwners: string[] = useMemo(() => {
    if (locationOwnersArray?.length > 0) {
      return locationOwnersArray;
    }
    return (
      _locationOwners?.locationMembers?.[0]?.members?.map(
        (member: any) => member?.eid
      ) || []
    );
  }, [_locationOwners, locationOwnersArray]);

  const [attachLocationCredentials] = useMutation(ATTACH_LOCATION_CREDENTIALS);

  const [makeLive] = useMutation<LocationLiveResp>(MAKE_LOCATION_LIVE);

  const [makePreLaunchLive] = useMutation(MAKE_PRE_LAUNCH_LOCATION_LIVE);

  const [updateLocationDetails] = useMutation(UPDATE_LOCATION_QUERY);

  const onGetUserSuccess = () => {
    afterSuccess?.();
  };

  const onGetUserError = () => {
    afterError?.();
  };

  const { execute } = getLoggedInUserDataHandler(
    onGetUserSuccess,
    onGetUserError
  );

  const [shareLinkWithOwners] = useMutation(
    SEND_LOCATION_DETAILS_TO_LOCATION_OWNERS_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Location made live',
        });
        setSaveShareLoading(false);
        setSaveGoLoading(false);
        execute?.();
      },
      onError: (error) => {
        setSaveShareLoading(false);
        setSaveGoLoading(false);
        toast({
          status: 'error',
          title:
            'Location was made live, but could not be shared with location owners.',
        });
        afterError?.();
      },
    }
  );

  const updateLocationDetailsHandler = (clickType: 'saveShare' | 'saveGo') => {
    let inputDetails = formatData(locationData?.locationDetails);
    updateLocationDetails({
      variables: {
        input: inputDetails,
      },
    })
      .then((res) => {
        handleMakeLiveHandler(clickType);
      })
      .catch((error) => {
        setSaveShareLoading(false);
        setSaveGoLoading(false);
        if (error instanceof Error) {
          handleError(
            {
              ...error,
              message:
                'Location could not be made live. Please try again later.',
            },
            'Error'
          );
        } else {
          console.error("Caught an exception that's not an Error:", error);
        }
      });
  };

  const shareMagicLinkWithLocationOwners = (magicLink: string) => {
    shareLinkWithOwners({
      variables: {
        senderId: loggedInUserEid,
        receiverIds: locationOwners,
        message: `Please use the link to login : ${magicLink}`,
      },
    });
  };
  const handleMakeLive = async (locationId: string) => {
    const mutation =
      type === 'developmentToOpen' ? makeLive : makePreLaunchLive;
    const response = await mutation({
      variables: { locationId },
    });

    if (response.errors) {
      throw new Error(response.errors[0].message);
    }

    return response;
  };
  const handleError = (error: Error, title: string) => {
    toast({
      status: 'error',
      title: title,
      description: error.message || 'Something went wrong',
    });
  };

  const getAndShareMagicLink = () => {
    client
      .query({
        query: magicLinkQuery,
        variables: { eid: locationData?.locationId! },
      })
      .then((response) => {
        shareMagicLinkWithLocationOwners(response.data.getMagicLink);
      })
      .catch((error) => {
        setSaveShareLoading(false);
        setSaveGoLoading(false);
        if (error instanceof Error) {
          handleError(
            {
              ...error,
              message:
                'Location was made live, but could not be shared with location owners.',
            },
            'Error'
          );
          afterError?.();
        } else {
          console.error("Caught an exception that's not an Error:", error);
        }
      });
  };

  const handleMakeLiveHandler = (clickType: 'saveShare' | 'saveGo') => {
    handleMakeLive(locationData?.locationId!)
      .then((response) => {
        if (
          response.data &&
          (response.data.MakeLauncherLocationLive?.succeed ||
            response.data.MakePreLauncherLocationLive?.succeed)
        ) {
          if (clickType === 'saveGo') {
            toast({
              status: 'success',
              title: 'Success',
              description: 'Location made live',
            });
            execute?.();
          } else {
            getAndShareMagicLink();
          }
        }
      })
      .catch((error) => {
        setSaveShareLoading(false);
        setSaveGoLoading(false);
        if (error instanceof Error) {
          handleError(
            {
              ...error,
              message:
                'Location could not be made live. Please try again later.',
            },
            'Error'
          );
        } else {
          console.error("Caught an exception that's not an Error:", error);
        }
      });
  };

  const attachLocationCredentialsHandler = (
    data: FormType,
    clickType: 'saveShare' | 'saveGo'
  ) => {
    attachLocationCredentials({
      variables: {
        locationId: locationData?.locationId,
        username: data.userName,
        password: data.password,
      },
    })
      .then(() => {
        if (shouldUpdateLocation) {
          updateLocationDetailsHandler(clickType);
        } else {
          handleMakeLiveHandler(clickType);
        }
      })
      .catch((error) => {
        setSaveShareLoading(false);
        setSaveGoLoading(false);
        if (error instanceof Error) {
          handleError(error, 'Error');
        } else {
          console.error("Caught an exception that's not an Error:", error);
        }
      });
  };

  const attachLoginCredentials = async (data: FormType) => {
    try {
      const response = await attachLocationCredentials({
        variables: {
          locationId: locationData?.locationId,
          username: data.userName,
          password: data.password,
        },
      });

      if (!response.errors) {
        return response; // return the response for handling in attachLoginDetails
      } else {
        throw new Error(response.errors[0].message);
      }
    } catch (error) {
      throw error; // propagate the error for handling outside
    }
  };

  const saveAndShareHandler = async (data: FormType) => {
    setSaveShareLoading(true);
    attachLocationCredentialsHandler(data, 'saveShare');
  };

  const saveAndGo = async (data: FormType) => {
    setSaveGoLoading(true);
    attachLocationCredentialsHandler(data, 'saveGo');
  };

  const attachLoginDetails = async (data: FormType) => {
    console.log('ATTACHING LOGIN DETAILS : : : : : ', data);
    console.log('LOCATION DATA : ', locationData);
    setAttachLoginDetailsLoading(true);
    try {
      const response = await attachLoginCredentials(data);
      toast({
        status: 'success',
        title: 'Login details attached successfully',
      });
      console.log('Login credentials attached successfully:', response);
      setAttachLoginDetailsLoading(false);
      moveToLocationLiveModal?.();
    } catch (error) {
      console.error('Error attaching login credentials:', error);
      if (error instanceof Error) {
        handleError(error, 'Error');
      } else {
        console.error("Caught an exception that's not an Error:", error);
      }
      setAttachLoginDetailsLoading(false);
    }
  };

  return {
    btnState: {
      attachLoginDetailsLoading,
      saveGoLoading,
      saveShareLoading,
    },
    gettingLocationOwners,
    getLocationOwners,
    saveAndGo,
    saveAndShareHandler,
    attachLoginDetails,
  };
};

export default useServiceLayer;
