import { useLazyQuery, useMutation } from '@apollo/client';
import { ASSET_PAGINATION, CREATE_ASSET } from '../training-path-icon.graphql';
import {
  AssetPaginationResponse,
  AssetPaginationVariables,
  CreateAssetResponse,
  CreateAssetVariables,
  ServiceLayer,
} from '../types';

export const useServiceLayer = (): ServiceLayer => {
  const [getAssets, assetResponse] = useLazyQuery<
    AssetPaginationResponse,
    AssetPaginationVariables
  >(ASSET_PAGINATION, {
    fetchPolicy: 'network-only',
  });

  const [createAsset, createAssetResponse] = useMutation<
    CreateAssetResponse,
    CreateAssetVariables
  >(CREATE_ASSET);

  return {
    assetsListing: {
      getAssets,
      assetResponse,
    },
    createAsset: {
      createAsset,
      createAssetResponse,
    },
  };
};
