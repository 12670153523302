import { useReactiveVar } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { SopDetailData } from 'pages/Chapters/ChapterView/chapter.graphql';
import FolderVisibility from 'pages/Chapters/FolderVisibility';
import {
  IConditionType,
  IType,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { getAllFolderSubFolderList } from 'pages/Handbook/setVisibilityModal/SetSeeModal/utility';
import useCombinedStore from 'zustandStore/store';

export const useUpdateChapterVisibility = (refetchHandler: () => void) => {
  const { hierarchyData, updateHierarchyData } = useCombinedStore();
  const foldersList = useReactiveVar(foldersListVar);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);

  const setVisibilityModalCloseEvent = useCombinedStore(
    (state) =>
      state.events['knowledgeBaseVisibilityModal']['onSetVisibilityModalClose']
  );
  const clearEvent = useCombinedStore((state) => state.clearEvent);

  const allFoldersList = useMemo(() => {
    return getAllFolderSubFolderList(foldersList);
  }, [foldersList]);

  useEffect(() => {
    if (setVisibilityModalCloseEvent) {
      clearEvent('knowledgeBaseVisibilityModal', 'onSetVisibilityModalClose');
      setShowVisibilityModal(false);
    }
  }, [setVisibilityModalCloseEvent]);

  const updateVisibility = (_response: SopDetailData) => {
    setShowVisibilityModal(true);
    categoryOperation(_response);
  };

  const categoryOperation = (_response: SopDetailData) => {
    let foundCategory = allFoldersList?.find(
      (list) => list?.eid === _response?.categoryId
    );

    if (foundCategory) {
      let parentFolder: CustomCategoryEntity | undefined = undefined;
      let subFolder: CustomCategoryEntity | undefined = undefined;

      if (!foundCategory?.parentFolderId) {
        parentFolder = foundCategory;
        subFolder = undefined;
      } else {
        subFolder = foundCategory;
        parentFolder = allFoldersList?.find(
          (list) => list?.eid === subFolder?.parentFolderId
        );
      }

      if (!parentFolder) return;

      if (parentFolder && subFolder) {
        updateHierarchyData({
          ...hierarchyData,
          parentFolder: {
            details: { ...parentFolder, type: 'folder' },
            visibilityData: {
              locations: parentFolder?.visibleTo?.locations,
              roles: parentFolder?.visibleTo?.roles,
              users: parentFolder?.visibleTo?.users,
              ...(parentFolder?.visibleTo?.condition
                ? {
                    condition: parentFolder?.visibleTo
                      ?.condition as IConditionType,
                  }
                : {}),
              visibility: parentFolder?.visibility,
            },
          },
          subFolder: {
            details: { ...subFolder, type: 'subFolder' },
            visibilityData: {
              locations: subFolder?.visibleTo?.locations,
              roles: subFolder?.visibleTo?.roles,
              users: subFolder?.visibleTo?.users,
              ...(subFolder?.visibleTo?.condition
                ? {
                    condition: subFolder?.visibleTo
                      ?.condition as IConditionType,
                  }
                : {}),
              visibility: subFolder?.visibility,
            },
          },
          chapter: {
            details: _response as any,
            visibilityData: {
              locations: _response?.visibleTo?.locations,
              roles: _response?.visibleTo?.roles,
              users: _response?.visibleTo?.users,
              condition: _response?.visibleTo?.condition,
              visibility: _response?.visibility as IType,
            },
          },
        });
      } else {
        updateHierarchyData({
          ...hierarchyData,
          parentFolder: {
            details: { ...parentFolder, type: 'folder' },
            visibilityData: {
              locations: parentFolder?.visibleTo?.locations,
              roles: parentFolder?.visibleTo?.roles,
              users: parentFolder?.visibleTo?.users,
              ...(parentFolder?.visibleTo?.condition
                ? {
                    condition: parentFolder?.visibleTo
                      ?.condition as IConditionType,
                  }
                : {}),
              visibility: parentFolder?.visibility,
            },
          },
          subFolder: undefined,
          chapter: {
            details: _response as any,
            visibilityData: {
              locations: _response?.visibleTo?.locations,
              roles: _response?.visibleTo?.roles,
              users: _response?.visibleTo?.users,
              condition: _response?.visibleTo?.condition,
              visibility: _response?.visibility as IType,
            },
          },
        });
      }
    }
  };

  const renderVisibilityModal = () => {
    return (
      showVisibilityModal && (
        <FolderVisibility
          getChaptersList={() => {
            setShowVisibilityModal(false);
            refetchHandler();
          }}
        />
      )
    );
  };

  return { updateVisibility, renderVisibilityModal };
};
