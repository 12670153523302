import { gql } from '@apollo/client';

export const CHECK_USERNAME_EXISTS = gql`
  query Query($usernames: [String]) {
    CheckUsernameExists(usernames: $usernames)
  }
`;

export const UPLOAD_BULK_EXISTING_LOCATIONS = gql`
  mutation BulkAddOpenLocation($input: [BulkOpenLocationAddInput]) {
    BulkAddOpenLocation(input: $input) {
      eid
    }
  }
`;

export const UPLOAD_BULK_PRE_LAUNCH_LOCATIONS = gql`
  mutation BulkAddPreLaunchLocation($input: [BulkPreLaunchLocationAddInput]) {
    BulkAddPreLaunchLocation(input: $input) {
      eid
    }
  }
`;
