import { Flex, Text, Box } from '@chakra-ui/react';
import { Row } from 'antd';
import { ReactComponent as ChevronForward } from '../../../assets/images/chevronForward.svg';
import { ReactComponent as MembersIcon } from '../../../assets/images/sidebar/member.svg';
import { ReactComponent as LocationIcon } from '../../../assets/images/sidebar/location.svg';
import { ReactComponent as JobIcon } from '../../../assets/images/sidebar/job.svg';
import { PopoverCloseButton } from '@chakra-ui/react';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

import './TasksFilter.scss';

type BaseFilterComponentProps = {
  extraFilterData: any;
  setCheckedOptionsData: any;
  checkedOptionsData: any;
};

const BaseFilterComponent = ({
  extraFilterData,
  setCheckedOptionsData,
  checkedOptionsData,
}: BaseFilterComponentProps) => {
  const {
    triggerPopupCloseOnClearFilter,
    totalFilters,
    isPopoverOpen,
    setPageName,
  } = extraFilterData;

  const {
    checkedJobList,
    checkedLocationList,
    checkedMemberList,
    checkCreatedByList,
  } = checkedOptionsData;

  const handleClearAllFilter = () => {
    setCheckedOptionsData({
      ...checkedOptionsData,
      checkedJobList: [],
      checkedLocationList: [],
      checkedMemberList: [],
      checkCreatedByList: [],
    });

    // isPopoverOpen && totalFilters && triggerPopupCloseOnClearFilter();

    deployEvent(CommonAmplitudeEvents.CLEAR_ALL_FILTERS);
  };

  return (
    <>
      <Flex>
        <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
          <Row className='task-filter-tag-container' />
          <Row className='task-filter-title-container'>Filters</Row>
        </Row>
        <Flex gap={'20px'} height={'fit-content'}>
          <Text
            style={{ position: 'relative', top: 5 }}
            fontSize={'15px'}
            color={
              checkedJobList?.length > 0 ||
              checkedLocationList?.length > 0 ||
              checkedMemberList?.length > 0 ||
              checkCreatedByList?.length > 0
                ? '#2A85FF'
                : '#DDDDDD'
            }
            cursor={'pointer'}
            onClick={handleClearAllFilter}
          >
            Clear all
          </Text>
          {/* <Box
            style={{
              width: '1px',
              backgroundColor: '#DDDDDD',
              position: 'relative',
              right: '4px',
            }}
          ></Box>
          <PopoverCloseButton
            style={{
              backgroundColor: '#EFEFEF',
              borderRadius: '50%',
              position: 'relative',
              top: '0px',
              width: '36px',
              height: '36px',
            }}
          /> */}
        </Flex>
      </Flex>
      <Text
        style={{
          fontWeight: 600,
          fontSize: 14,
          marginBottom: '1rem',
        }}
      >
        Assigned to
      </Text>
      <Flex
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem 0px'}
        onClick={() => {
          deployEvent(CommonAmplitudeEvents.JOBS_FILTER);

          setPageName('job');
        }}
      >
        <Flex alignItems={'center'} gap={2}>
          <JobIcon />
          <Text fontSize={14} fontWeight={checkedJobList?.length && 600}>
            Jobs
          </Text>
          <Text fontSize={'0.7rem'} color={'#333B4F'}>
            {checkedJobList?.length > 0 &&
              `(${checkedJobList.length} selected)`}
          </Text>
        </Flex>
        <ChevronForward />
      </Flex>
      <hr />
      <Flex
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem 0px'}
        onClick={() => {
          deployEvent(CommonAmplitudeEvents.LOCATIONS_FILTER);

          setPageName('location');
        }}
      >
        <Flex alignItems={'center'} gap={2}>
          <LocationIcon />
          <Text fontSize={14} fontWeight={checkedLocationList?.length && 600}>
            Locations
          </Text>
          <Text fontSize={'0.7rem'} color={'#333B4F'}>
            {checkedLocationList.length > 0 &&
              `(${checkedLocationList?.length} selected)`}
          </Text>
        </Flex>
        <ChevronForward />
      </Flex>
      <hr />
      <Flex
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'1rem 0px'}
        onClick={() => {
          deployEvent(CommonAmplitudeEvents.MEMBERS_FILTER);

          setPageName('member');
        }}
      >
        <Flex alignItems={'center'} gap={2}>
          <MembersIcon />
          <Text fontSize={14} fontWeight={checkedMemberList?.length && 600}>
            Members
          </Text>
          <Text fontSize={'0.7rem'} color={'#333B4F'}>
            {checkedMemberList.length > 0 &&
              `(${checkedMemberList?.length} selected)`}
          </Text>
        </Flex>
        <ChevronForward />
      </Flex>
      <Flex
        cursor={'pointer'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginTop={'1rem'}
        onClick={() => {
          deployEvent(CommonAmplitudeEvents.CREATED_BY_FILTER);

          setPageName('createdBy');
        }}
      >
        <Flex gap={2} alignItems={'center'}>
          <Text fontSize={14} fontWeight={600}>
            Created by
          </Text>
          <Text fontSize={'0.7rem'} color={'#333B4F'}>
            {checkCreatedByList.length > 0 &&
              `(${checkCreatedByList?.length} selected)`}
          </Text>
        </Flex>
        <ChevronForward />
      </Flex>
    </>
  );
};

export default BaseFilterComponent;
