import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Collapse, Flex, Text } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { ErrorsObj, IBulkOperations } from './types';

interface IProps {
  bulkOperations: IBulkOperations;
}

const FileErrors: FC<IProps> = ({ bulkOperations }) => {
  const { errors, readyToProcessJson } = bulkOperations;

  const [showAll, setShowAll] = useState(false);
  const [showAllHeaderFileErrors, setShowAllHeaderFileErros] = useState(false);

  const groupedErrors = useMemo(() => {
    const errorMap = new Map();

    errors?.cellErrors?.forEach(({ errors }) => {
      errors.forEach(({ errorMsg, processData }) => {
        if (!errorMap.has(errorMsg)) {
          errorMap.set(errorMsg, []);
        }
        errorMap.get(errorMsg).push(processData?.cellRef);
      });
    });

    return Array.from(errorMap, ([errorMsg, cellRefs]) => ({
      errorMsg,
      cellRefs: cellRefs.join(', '),
    }));
  }, [errors?.cellErrors]);

  const toggleShowAll = () => setShowAll(!showAll);
  const toggleShowAllHeaderFileErrors = () =>
    setShowAllHeaderFileErros(!showAllHeaderFileErrors);

  if (
    errors?.fileUploadErrors?.length === 0 &&
    errors?.headerErrors?.length === 0
  ) {
    if (errors?.cellErrors?.length > 0) {
      return (
        <Flex flexDir='column' gap={2}>
          <Box
            bg='rgba(181, 228, 202, 0.26)'
            border='1px solid rgba(181, 228, 202, 0.26)'
            borderRadius='10px'
            p='10px 12px'
            color='rgba(41, 144, 5, 1)'
            fontWeight={500}
          >
            {readyToProcessJson?.length} location
            {bulkOperations?.readyToProcessJson?.length === 1 ? '' : 's'} have
            been imported successfully!
          </Box>
          <Flex gap={2} flexDir='column'>
            <Box
              bg='rgba(255, 106, 85, 0.1)'
              border='1px solid rgba(255, 106, 85, 0.1)'
              borderRadius='10px'
              p='10px 12px'
              color='rgba(255, 106, 85, 1)'
              fontWeight={500}
            >
              {errors?.cellErrors?.length} locations cannot be imported.
            </Box>
            {/* {errors?.cellErrors?.map((error, index) => {
              return error?.errors?.map((_error) => (
                <Box
                  key={index}
                  bg='rgba(255, 106, 85, 0.1)'
                  border='1px solid rgba(255, 106, 85, 0.1)'
                  borderRadius='10px'
                  p='10px 12px'
                  color='rgba(255, 106, 85, 1)'
                  fontWeight={500}
                >
                  {_error?.errorMsg} at {_error?.processData?.cellRef}
                </Box>
              ));
            })} */}
          </Flex>
          <Flex gap={2} flexDir='column'>
            {groupedErrors.slice(0, 3).map((error, index) => (
              <Box
                key={index}
                bg='rgba(255, 106, 85, 0.1)'
                border='1px solid rgba(255, 106, 85, 0.1)'
                borderRadius='10px'
                p='10px 12px'
                color='rgba(255, 106, 85, 1)'
                fontWeight={500}
              >
                {error.errorMsg} at {error.cellRefs}
              </Box>
            ))}
            {groupedErrors.length > 3 && (
              <Flex flexDir='column' gap={2}>
                {showAll && (
                  <Flex flexDir='column' gap={2}>
                    {groupedErrors.slice(3).map((error, index) => (
                      <Box
                        key={index}
                        bg='rgba(255, 106, 85, 0.1)'
                        border='1px solid rgba(255, 106, 85, 0.1)'
                        borderRadius='10px'
                        p='10px 12px'
                        color='rgba(255, 106, 85, 1)'
                        fontWeight={500}
                      >
                        {error.errorMsg} at {error.cellRefs}
                      </Box>
                    ))}
                  </Flex>
                )}
                <Flex
                  bg='rgba(255, 106, 85, 0.1)'
                  border='1px solid rgba(255, 106, 85, 0.1)'
                  borderRadius='10px'
                  p='10px 12px'
                  color='rgba(255, 106, 85, 1)'
                  fontWeight={500}
                  align='center'
                  justify='space-between'
                  onClick={toggleShowAll}
                  cursor='pointer'
                >
                  View {showAll ? 'less' : 'all'} (
                  {groupedErrors.slice(3)?.length})
                  {showAll ? (
                    <ChevronUpIcon fontSize='20px' />
                  ) : (
                    <ChevronDownIcon fontSize='20px' />
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      );
    }
    return (
      <Box
        bg='rgba(181, 228, 202, 0.26)'
        border='1px solid rgba(181, 228, 202, 0.26)'
        borderRadius='10px'
        p='10px 12px'
        color='rgba(41, 144, 5, 1)'
        fontWeight={500}
      >
        {readyToProcessJson?.length} locations have been imported successfully!
      </Box>
    );
  }
  return (
    <Flex gap={2} flexDir='column'>
      {[...errors?.fileUploadErrors, ...errors?.headerErrors]
        ?.slice(0, 3)
        .map((error, index) => (
          <Box
            key={index}
            bg='rgba(255, 106, 85, 0.1)'
            border='1px solid rgba(255, 106, 85, 0.1)'
            borderRadius='10px'
            p='10px 12px'
            color='rgba(255, 106, 85, 1)'
            fontWeight={500}
          >
            {error}
          </Box>
        ))}
      {[...errors?.fileUploadErrors, ...errors?.headerErrors]?.length > 3 && (
        <Flex flexDir='column' gap={2}>
          {showAllHeaderFileErrors && (
            <Flex flexDir='column' gap={2}>
              {[...errors?.fileUploadErrors, ...errors?.headerErrors]
                ?.slice(3)
                ?.map((error, index) => (
                  <Box
                    key={index}
                    bg='rgba(255, 106, 85, 0.1)'
                    border='1px solid rgba(255, 106, 85, 0.1)'
                    borderRadius='10px'
                    p='10px 12px'
                    color='rgba(255, 106, 85, 1)'
                    fontWeight={500}
                  >
                    {error}
                  </Box>
                ))}
            </Flex>
          )}
          <Flex
            bg='rgba(255, 106, 85, 0.1)'
            border='1px solid rgba(255, 106, 85, 0.1)'
            borderRadius='10px'
            p='10px 12px'
            color='rgba(255, 106, 85, 1)'
            fontWeight={500}
            align='center'
            justify='space-between'
            onClick={toggleShowAllHeaderFileErrors}
            cursor='pointer'
          >
            View {showAllHeaderFileErrors ? 'less' : 'all'} (
            {
              [...errors?.fileUploadErrors, ...errors?.headerErrors]?.slice(3)
                ?.length
            }
            )
            {showAllHeaderFileErrors ? (
              <ChevronUpIcon fontSize='20px' />
            ) : (
              <ChevronDownIcon fontSize='20px' />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default FileErrors;
