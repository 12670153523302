import React, { useEffect, useState } from 'react';
import { Checkbox, Flex, Text, Box } from '@chakra-ui/react';
import { Row } from 'antd';
import { ReactComponent as BackButton } from '../../../assets/images/back.svg';
import { PopoverCloseButton } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import EmptyState from './EmptyState';
import { userObj } from 'sop-commons/src/client';
import { useReactiveVar } from '@apollo/client';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

import './TasksFilter.scss';

type JobFilterComponentProps = {
  extraFilterData: any;
  sortedJobArray: any;
  setSortedJobArray: any;
  setCheckedOptionsData: any;
  checkedOptionsData: any;
};

const JobFilterComponent = ({
  extraFilterData,
  sortedJobArray,
  setSortedJobArray,
  setCheckedOptionsData,
  checkedOptionsData,
}: JobFilterComponentProps) => {
  const { entity } = useReactiveVar(userObj);
  const [jobList, setJobList] = useState(entity?.roles);
  const [searchJobQuery, setSearchJobQuery] = useState('');

  const { setPageName, loading } = extraFilterData;

  const { checkedJobList } = checkedOptionsData;

  const searchJobQueryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchJobQuery(e?.target?.value);
  };

  const handleJobSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newCheckedJobList = [...checkedJobList];
      newCheckedJobList.push(e.target.value);
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkedJobList: newCheckedJobList,
      });
    } else {
      const newCheckedJobList = checkedJobList.filter(
        (item: any) => item !== e.target.value
      );
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkedJobList: newCheckedJobList,
      });
    }
  };

  let finalJobList = sortedJobArray?.length > 0 ? sortedJobArray : jobList;

  const filteredJobs = finalJobList.filter((job) =>
    job?.name?.toLowerCase().includes(searchJobQuery.toLowerCase())
  );

  useEffect(() => {
    const tempArray: any[] = [];
    if (checkedJobList?.length > 0) {
      jobList.map((job: any) => {
        if (checkedJobList.includes(job?.name)) {
          const obj = { ...job, isChecked: true };
          tempArray.push(obj);
        }
      });

      jobList.map((job: any) => {
        if (!checkedJobList.includes(job?.name)) {
          const obj = { ...job, isChecked: false };
          tempArray.push(obj);
        }
      });
    }
    setSortedJobArray(tempArray);
  }, [checkedJobList]);

  return (
    <>
      <Flex>
        <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
          <Row className='task-filter-tag-container' />
          <Row className='task-filter-title-container'>Filters</Row>
        </Row>
        <Flex gap={'20px'} height={'fit-content'}>
          <Text
            style={{ position: 'relative', top: 5 }}
            fontSize={'15px'}
            color={checkedJobList?.length > 0 ? '#2A85FF' : '#DDDDDD'}
            cursor={'pointer'}
            onClick={() => {
              deployEvent(CommonAmplitudeEvents.CLEAR_JOBS_FILTER);
              setCheckedOptionsData({
                ...checkedOptionsData,
                checkedJobList: [],
              });
            }}
          >
            Clear all
          </Text>
        </Flex>
        {/* <PopoverCloseButton
          style={{
            backgroundColor: '#EFEFEF',
            borderRadius: '50%',
            position: 'relative',
          }}
        /> */}
      </Flex>
      <Flex gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {`Assigned to Jobs (${jobList?.length} jobs)`}
        </Text>
      </Flex>
      <SearchInput
        className='job-search-field'
        value={searchJobQuery}
        onChange={searchJobQueryHandler}
        placeholder='Search by job'
        hideShortcuts
      />
      {filteredJobs?.length > 0 ? (
        <Box maxHeight={'300px'} overflow={'scroll'}>
          {filteredJobs.map((job: any) => {
            return (
              <Flex
                key={job?.eid}
                gap={3}
                alignItems={'center'}
                padding={'0.5rem 0px'}
              >
                <Checkbox
                  // disabled={loading ? true : false}
                  value={job?.name}
                  onChange={handleJobSelection}
                  isChecked={checkedJobList?.includes(job?.name) ? true : false}
                >
                  <Text>{`${job?.name}`}</Text>
                </Checkbox>
              </Flex>
            );
          })}
        </Box>
      ) : (
        <EmptyState title={'job'} />
      )}
    </>
  );
};

export default JobFilterComponent;
