import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { IMakeLocLive } from '../../MakeLocLive';

type IStatusSwitchText = Pick<IMakeLocLive, 'isLive'>;

const StatusSwitchText: FC<IStatusSwitchText> = ({ isLive }) => {
  return (
    <Text fontWeight={800} fontSize='32px'>
      {isLive ? 'Live now!' : 'Launching'}
    </Text>
  );
};

export default StatusSwitchText;
