import { ApolloClient, ApolloProvider, useApolloClient } from '@apollo/client';
import { Flex, Box, AlertDialogCloseButton } from '@chakra-ui/react';
import { LauncherLocation } from 'pages/launcher/dashboard/dashboard.graphql';
import React, { useCallback, useRef } from 'react';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import LocationLoginDetails from './LocationLoginDetails';

interface IProps {
  locationData?: LauncherLocation;
  locationName?: string;
  shouldUpdateLocation?: boolean;
  type: 'preToOpen' | 'developmentToOpen';
  locationOwnersArray?: string[];
  onClose: () => void;
  afterSuccess?: () => void;
  afterError?: () => void;
  moveToLocationLiveModal?: () => void;
}

type LocationLoginDetailModal = (props: IProps) => void;

export const useLocationLoginDetailModal = (): LocationLoginDetailModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();
  const confirm = useConfirm();

  return useCallback(
    ({
      locationData,
      locationName,
      locationOwnersArray,
      shouldUpdateLocation,
      type,
      afterError,
      afterSuccess,
      onClose,
      moveToLocationLiveModal,
    }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      const afterSuccessHandler = () => {
        if (confirmRef.current) {
          confirmRef.current.destroy();
        }
        afterSuccess?.();
      };

      const afterErrorHandler = () => {
        if (confirmRef.current) {
          confirmRef.current.destroy();
        }
        afterError?.();
      };

      const moveToLocationLiveModalHandler = () => {
        if (confirmRef?.current) {
          confirmRef?.current?.destroy();
        }
        moveToLocationLiveModal?.();
      };

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader
                title={
                  type === 'preToOpen'
                    ? `Setup ${locationName || ''}`
                    : 'Before you go live, setup your account!'
                }
                color='#CABDFF'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={onClose}
            />
          </Flex>
        ),
        content: (
          <LocationLoginDetails
            locationData={locationData}
            locationOwnersArray={locationOwnersArray}
            shouldUpdateLocation={shouldUpdateLocation}
            type={type}
            onClose={onClose}
            afterError={afterErrorHandler}
            afterSuccess={afterSuccessHandler}
            moveToLocationLiveModalHandler={moveToLocationLiveModalHandler}
          />
        ),
        size: '3xl',
        isCentered: true,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '16px',
        },
        footer: null,
      });
    },
    []
  );
};
