import { Flex, useToast } from '@chakra-ui/react';
import ImageResizer from './ImageResizer';
import React, { FC, useContext, useState } from 'react';
import { ICropImgFilesData } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/DocumentsUploadComponent';
import { IIconList, ServiceLayer } from '../types';
import { useUploadImage } from 'hooks';

interface IProps {
  service: ServiceLayer;
  onClose: () => void;
  selectedIcon: (imgObj: IIconList) => void;
}

const ImageUpload: FC<IProps> = ({ service, onClose, selectedIcon }) => {
  const uploadImage = useUploadImage();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const [cropImgFilesData, setCropImgFilesData] = useState<ICropImgFilesData[]>(
    []
  );
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const createAssetService = service?.createAsset;

  const reset = () => {
    setFiles([]);
    setUploading(false);
    setCropImgFilesData([]);
  };

  const uploadImageHandler = async () => {
    try {
      setUploading(true);
      let url = await uploadImage(cropImgFilesData?.[0]?.croppedImageFile);
      console.log({ files, cropImgFilesData });
      console.log('url : ', url);
      createAssetService
        ?.createAsset({
          variables: {
            input: {
              assetType: 'image',
              fileSize: files?.[0]?.size,
              mimetype: files?.[0]?.type,
              resource: 'training',
              title: files?.[0]?.name,
              url: url,
              resourceSubtype: 'thumbnail',
            },
          },
        })
        ?.then((res) => {
          if (res?.data?.CreateEntityAsset?.eid) {
            selectedIcon({
              backgroundColor: '',
              createdAt: res?.data?.CreateEntityAsset?.createdAt,
              description: '',
              eid: res?.data?.CreateEntityAsset?.eid,
              label: res?.data?.CreateEntityAsset?.title,
              tags: res?.data?.CreateEntityAsset?.tags,
              type: '',
              updatedAt: res?.data?.CreateEntityAsset?.updatedAt,
              url: res?.data?.CreateEntityAsset?.url,
            });
            toast({
              status: 'success',
              title: 'Thumbnail added successfully',
            });
            setTimeout(() => {
              reset();
              setUploading(false);
              onClose?.();
            }, 1000);
          }
        })
        ?.catch((err) => {
          toast({
            status: 'error',
            title: 'Thumbnail could not be added',
          });
          setUploading(false);
        });
    } catch (err) {
      setUploading(false);
    }
  };

  return (
    <Flex id='training-path-icon-flex' h='430px' w='full'>
      <ImageResizer
        cropImgFilesData={cropImgFilesData}
        files={files}
        onClose={onClose}
        setCropImgFilesData={setCropImgFilesData}
        setFiles={setFiles}
        uploadImageHandler={uploadImageHandler}
        loading={uploading || createAssetService?.createAssetResponse?.loading}
      />
    </Flex>
  );
};

export default ImageUpload;
