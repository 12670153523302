import React, { FC, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { PopoverTrigger } from '../location-helper-functions';

import { ReactComponent as CopyIcon } from '../../../../assets/images/copy.svg';

const EmailItem: FC<{ email: string; onCopied: () => void }> = ({
  email,
  onCopied,
}) => {
  const { onCopy } = useClipboard(email);

  const onCopyClipboard = () => {
    onCopy();
    onCopied();
  };

  return (
    <ListItem
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      gap='8px'
    >
      <Box fontWeight={500} color='#1A1D1F' fontSize='14px' isTruncated>
        {email}
      </Box>
      <Image
        as={CopyIcon}
        boxSize='20px'
        minW='20px'
        cursor='pointer'
        onClick={onCopyClipboard}
      />
    </ListItem>
  );
};

interface IProps {
  values: string[];
  message: string;
}

const LocationEmailPhone: FC<IProps> = ({ values: _values = [], message }) => {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [oneValue, others] = useMemo(() => {
    return [_values.slice(0, 1), _values.slice(1).length];
  }, [_values]);

  const onCopied = () => {
    toast({
      status: 'success',
      title: message || 'Copied to clipboard',
    });
  };

  if (!_values?.length) {
    return <Box>-</Box>;
  }

  return (
    <Flex gap={2} pr={3} align='center'>
      <Flex overflow='hidden' align='center'>
        <Box
          fontSize='14px'
          fontWeight='500'
          color='#2A85FF'
          maxW='285px'
          lineHeight={1.2}
          isTruncated
        >
          {oneValue[0] || '-'}
        </Box>
      </Flex>

      {!!others && (
        <Popover placement='bottom-end'>
          <PopoverTrigger>
            <Button
              variant='outline'
              size='xs'
              px='10px'
              minW='36px'
              borderRadius='7px'
              gap='3px'
              fontWeight='500'
            >
              <span>+</span>
              <span>{others}</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            width='unset'
            minW='200px'
            maxW='300px'
            borderRadius='12px'
          >
            <PopoverArrow />
            <PopoverBody px={4} py={4}>
              <List spacing={4} styleType='none' maxH='300px' overflow='scroll'>
                {_values?.map((email, index) => {
                  return (
                    <EmailItem key={index} email={email} onCopied={onCopied} />
                  );
                })}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default LocationEmailPhone;
