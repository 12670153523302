import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormInput } from 'atoms';
import { LauncherLocation } from 'pages/launcher/dashboard/dashboard.graphql';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { useUserDataSelector } from 'hooks';
import Loader from 'ui-components/Loader';
import useServiceLayer from './layers/useServiceLayer';
import { ServiceLayer } from './types';

export interface FormType {
  userName: string;
  password: string;
  confirmPassword: string;
}

interface IProps {
  locationData?: LauncherLocation;
  shouldUpdateLocation?: boolean;
  type: 'preToOpen' | 'developmentToOpen';
  locationOwnersArray?: string[];
  onClose: () => void;
  afterSuccess?: () => void;
  afterError?: () => void;
  moveToLocationLiveModalHandler?: () => void;
}

const LocationLoginDetails: FC<IProps> = ({
  locationData,
  locationOwnersArray = [],
  shouldUpdateLocation,
  type,
  onClose,
  afterError,
  afterSuccess,
  moveToLocationLiveModalHandler,
}) => {
  console.log('Location Login Details : ', locationData);
  const loggedInUserEid = useUserDataSelector((state) => state.eid);

  const moveToLocationLiveModal = () => {
    moveToLocationLiveModalHandler?.();
  };

  const serviceLayer: ServiceLayer = useServiceLayer(
    loggedInUserEid,
    locationData,
    locationOwnersArray,
    type,
    shouldUpdateLocation,
    afterError,
    afterSuccess,
    moveToLocationLiveModal
  );
  const [showPassword, setShowPassword] = useState(false);
  const formMethods = useForm<FormType>();

  const handleSaveAndShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    formMethods.handleSubmit((data) => {
      serviceLayer.saveAndShareHandler(data);
    })();
  };

  const handleSaveAndGo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    formMethods.handleSubmit((data) => {
      serviceLayer.saveAndGo(data);
    })();
  };

  const attachLoginDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    formMethods.handleSubmit((data) => {
      serviceLayer.attachLoginDetails(data);
    })();
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return serviceLayer.gettingLocationOwners ? (
    <Center h='40vh'>
      <Loader />
    </Center>
  ) : (
    <Flex w='full'>
      <form style={{ width: '100%' }}>
        <Flex w='full' flexDir='column' gap={4}>
          <Controller
            control={formMethods.control}
            name='userName'
            rules={{
              required: 'Location username is required',
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error}>
                  <TitleHeader title='Location username' isRequired />
                  <FormInput {...field} />
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />

          <Flex align='center' gap={4}>
            <Flex gap='20px' w='full'>
              <FormControl
                isInvalid={Boolean(formMethods?.formState?.errors.password)}
              >
                <TitleHeader title='Password' isRequired />
                <InputGroup>
                  <Input
                    {...formMethods.register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must have at least 6 characters',
                      },
                      validate: (value) => {
                        if (value.replace(/\s/g, '').length <= 0) {
                          return 'Password cannot be all spaces';
                        }
                      },
                    })}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={
                        showPassword ? 'Hide password' : 'Show password'
                      }
                      variant='outline'
                      border='none'
                      icon={
                        showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash as IconProp} />
                        ) : (
                          <FontAwesomeIcon icon={faEye as IconProp} />
                        )
                      }
                      onClick={handleClickShowPassword}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {formMethods?.formState?.errors.password?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(
                  formMethods?.formState?.errors.confirmPassword
                )}
              >
                <TitleHeader title='Confirm Password' isRequired />
                <InputGroup>
                  <Input
                    {...formMethods?.register('confirmPassword', {
                      required: 'Confirm password is required',
                      validate: (value) =>
                        value === formMethods?.getValues().password ||
                        'The passwords do not match',
                    })}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Confirm Password'
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={
                        showPassword ? 'Hide password' : 'Show password'
                      }
                      variant='outline'
                      border='none'
                      icon={
                        showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash as IconProp} />
                        ) : (
                          <FontAwesomeIcon icon={faEye as IconProp} />
                        )
                      }
                      onClick={handleClickShowPassword}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {formMethods?.formState?.errors.confirmPassword?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify='space-between' gap={4} mt={4}>
          {type === 'developmentToOpen' && (
            <Button
              size='lg'
              variant='solid'
              colorScheme='blue'
              w='full'
              onClick={attachLoginDetails}
              disabled={serviceLayer.btnState.attachLoginDetailsLoading}
              isLoading={serviceLayer.btnState.attachLoginDetailsLoading}
            >
              Continue
            </Button>
          )}
          {type === 'preToOpen' && (
            <>
              <Button
                size='lg'
                w='50%'
                disabled={
                  serviceLayer.btnState.saveShareLoading ||
                  serviceLayer.btnState.saveGoLoading
                }
                isLoading={serviceLayer.btnState.saveShareLoading}
                onClick={handleSaveAndShare}
              >
                Save & share with owners
              </Button>
              <Button
                size='lg'
                w='50%'
                variant='solid'
                colorScheme='blue'
                disabled={
                  serviceLayer.btnState.saveGoLoading ||
                  serviceLayer.btnState.saveShareLoading
                }
                isLoading={serviceLayer.btnState.saveGoLoading}
                onClick={handleSaveAndGo}
              >
                Save & go to location
              </Button>
            </>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default LocationLoginDetails;
