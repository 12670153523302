import React, { FC, useState } from 'react';
import { Box, Checkbox, Flex, IconButton, Spacer } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IconImage } from 'ui-components';

import { IFormInput } from '../task.types';
import EditSteps from './EditSteps';

interface IProps {
  data: IFormInput['contents'][number];
  onDelete?: (value: IFormInput['contents'][number], index: number) => void;
  index: number;
}

const TaskItem: FC<IProps> = ({ data, onDelete, index }) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return <EditSteps data={data} close={() => setEditing(false)} />;
  }

  return (
    <Flex
      pos='relative'
      gap={4}
      _hover={{
        '.step-action': {
          display: 'flex',
          opacity: 1,
        },
      }}
    >
      <Checkbox isReadOnly />

      <Flex flexDir='column' justify='center' gap='2px' overflow='hidden'>
        {/*<Box fontSize='12px' color='#6F767E'>*/}
        {/*  Added on {data.dateAdded}*/}
        {/*</Box>*/}
        <Box fontSize='15px' fontWeight='500' color='#000000' isTruncated>
          {data.title}
        </Box>
      </Flex>
      <Spacer />

      {data?.type === 'todo' ? (
        <Box boxSize='46px' />
      ) : (
        <IconImage
          icon={data.sopData?.icon}
          thumbnail={data.thumbnail}
          name={data.title}
          files={data.sopData?.files}
          boxSize={46}
          borderRadius='6px'
        />
      )}

      <Flex
        display='none'
        opacity='0'
        className='step-action'
        gap={4}
        pos='absolute'
        right='0'
        boxSize='full'
        justify='flex-end'
        bg='linear-gradient(270deg, #FFFFFF 16%, rgba(255, 255, 255, 0) 86%)'
        transition='opacity 200ms, display 200ms'
      >
        {data?.type === 'todo' && (
          <IconButton
            aria-label='edit'
            borderRadius='full'
            bg='#BBBBBB'
            size='xs'
            alignSelf='center'
            icon={<FontAwesomeIcon icon={faPen as IconProp} color='white' />}
            onClick={() => setEditing(true)}
          />
        )}

        <IconButton
          aria-label='action'
          borderRadius='full'
          bg='#BBBBBB'
          size='xs'
          alignSelf='center'
          icon={
            <FontAwesomeIcon
              size='lg'
              icon={faClose as IconProp}
              color='white'
            />
          }
          onClick={() => onDelete?.(data, index)}
        />
      </Flex>
    </Flex>
  );
};

export default TaskItem;
