import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, Center, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { match } from 'ts-pattern';
import { ActionType } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { TSortBy } from 'types';
import { toArray } from 'utils';
import { ActionMenu } from 'ui-components';
import { NullShorting, SortByNumber } from 'utils/sorting';
import { AmplitudeEvent, deployEvent } from 'shared';

import { SortingTable } from 'sub-components/ChakraTable/SortingTable';
import { FORMS_RESPONSE_PAGINATION } from './form-internal.graphql';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';
import FormName from '../FormName';
import FormType from './FormType';

import EmptyState from 'sub-components/EmptyState';

import formResponseSvg from '../../../../assets/images/formResponse.svg';

interface IFormsResponse {
  FormsResponseListPagination: {
    count: number;
    pageInfo: {
      currentPage: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      itemCount: number;
      pageCount: number;
      perPage: number;
    };
    items: Array<{
      category: string;
      createdAt: string;
      desceription: string;
      eid: string;
      entityId: string;
      lastUpdated: string;
      lastUpdatedBy: {
        eid: string;
        name: string;
      };
      responseCount: string;
      thumbnail: string;
      title: string;
    }>;
  };
}

interface IProps {
  activeTabIndex: number;
  searchValue: string;
  categoryFilter: string[];
}

const FormResponseTab: FC<IProps> = ({
  activeTabIndex,
  searchValue,
  categoryFilter,
}) => {
  const { t } = useTranslation('form');
  const history = useHistory();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortBy, setSortBy] = useState<string>('_ID_DESC');

  const [getFormsResponseData, { loading }] = useLazyQuery<IFormsResponse>(
    FORMS_RESPONSE_PAGINATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => setListData(data),
      onError: () => {
        toast({
          title: 'Error',
          description: 'Something went wrong',
          status: 'error',
        });
      },
    }
  );

  useEffect(() => {
    if (activeTabIndex === 1) {
      const filter: Record<string, unknown> = {
        status: 'active',
      };

      if (searchValue) {
        filter.query = searchValue;
      }

      if (categoryFilter?.length) {
        filter.categoryId = categoryFilter;
      }

      getFormsResponseData({
        variables: {
          page: selectedPage,
          perPage: 10,
          sort: sortBy,
          filter: filter,
        },
      });
    } else {
      setSelectedPage(1);
      setSortBy('_ID_DESC');
    }
  }, [activeTabIndex, searchValue, sortBy, selectedPage, categoryFilter]);

  const [listData, setListData] = useState<IFormsResponse>({
    FormsResponseListPagination: {
      count: 0,
      items: [],
      pageInfo: {
        currentPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        itemCount: 0,
        pageCount: 0,
        perPage: 0,
      },
    },
  });

  const onPageChangeHandler = (page: number) => {
    setSelectedPage(page);
  };

  const getColumns = (isTablet: boolean) => {
    let tableColumns: any = [
      {
        Header: t('form_name_header'),
        accessor: 'formName' as const,
        width: 'calc(60% - 80px)',
        Cell: ({ cell: { value } }: any) => {
          return (
            <Box
              onClick={() => {
                deployEvent(CommonAmplitudeEvents.VIEW_FORM_RESPONSE);
                history.push(`/forms/response/${value?.eid}`);
              }}
              cursor='pointer'
            >
              <FormName formData={value} />
            </Box>
          );
        },
        sortType: NullShorting,
      },
      {
        Header: t('responses'),
        accessor: 'responses' as const,
        width: '20%',
        Cell: ({ cell: { value } }: any) => <span>{value}</span>,
        sortType: SortByNumber,
      },
      {
        Header: t('form_type'),
        accessor: 'formType' as const,
        width: '20%',
        Cell: ({ cell: { value, row } }: any) => {
          return <FormType text={value} index={row?.index} />;
        },
        sortType: NullShorting,
      },
      {
        Header: '',
        accessor: 'action' as const,
        width: '80px',
        disableSortBy: true,
      },
    ];
    return tableColumns;
  };

  const clickedItemsHandler = (
    clickedItem: string,
    form: IFormsResponse['FormsResponseListPagination']['items'][number]
  ) => {
    if (clickedItem === 'view-form-response') {
      deployEvent(AmplitudeEvent.FORMS_RESPONSE_VIEW_TAB_2);
      history.push(`/forms/response/${form?.eid}`);
    }
  };

  const getTableData = (isTablet: boolean) => {
    const tableData = listData?.FormsResponseListPagination?.items?.map(
      (item, index: number) => {
        return {
          item: item,
          formName: item,
          responses: item.responseCount,
          formType: item.category,
          action: (
            <Center>
              <ActionMenu
                menuData={[
                  {
                    name: t('view_form_response'),
                    value: 'view-form-response',
                    icon: formResponseSvg,
                  },
                ]}
                arrowSize={10}
                closeOnBlur={true}
                offset={[0, 0]}
                clickedItem={(_, clickedItem) =>
                  clickedItemsHandler(clickedItem, item)
                }
              >
                <Box cursor='pointer'>
                  <FontAwesomeIcon
                    icon={faEllipsis as IconProp}
                    size='2x'
                    color='#33383F'
                  />
                </Box>
              </ActionMenu>
            </Center>
          ),
        };
      }
    );
    return [...tableData];
  };

  const updateSortBy = (values: TSortBy) => {
    const newValue = match(values)
      .with({ id: 'formName' }, (sel) =>
        sel.desc ? 'TITLE_ASC' : 'TITLE_DESC'
      )
      // .with({ id: 'responses' }, (sel) =>
      //   sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
      // )
      .with({ id: 'formType' }, (sel) =>
        sel.desc ? 'CATEGORY_DESC' : 'CATEGORY_ASC'
      )
      .otherwise(() => '_ID_ASC');
    setSortBy(newValue);
  };

  const stateReducer = <T extends unknown>(newState: T, action: ActionType) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy(toArray<TSortBy>(newState?.sortBy)[0]);
        break;
    }

    return newState;
  };

  return (
    <Box>
      <SortingTable
        colorScheme='blue'
        customWrapperStyles={{ marginTop: '1rem' }}
        emptyData={{
          content: (
            <>
              <EmptyState image='Form' title='No forms found' />
            </>
          ),
        }}
        page={listData?.FormsResponseListPagination?.pageInfo?.currentPage}
        onPageChange={onPageChangeHandler}
        columns={getColumns(false)}
        data={getTableData(false)}
        isLoading={loading}
        totalRegisters={
          listData?.FormsResponseListPagination?.pageInfo?.itemCount
        }
        stateReducer={stateReducer}
        options={{
          autoResetSortBy: false,
        }}
        tableLayout='auto'
        isResponsive
        isHidePagination={
          (listData?.FormsResponseListPagination?.pageInfo?.currentPage === 1 &&
            listData?.FormsResponseListPagination?.pageInfo?.hasNextPage) ||
          listData?.FormsResponseListPagination?.pageInfo?.currentPage > 1
            ? false
            : true
        }
      />
    </Box>
  );
};

export default FormResponseTab;
