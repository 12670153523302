import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SelectOption } from 'atoms';
import React, { FC, useEffect, useState } from 'react';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import LocationOwnerDropdown from './LocationOwnerDropdown';
import { ProcessedEntity } from '../types';
import { UserEntityData } from 'shared/graphql/shared-types';
import { cloneDeep } from '@apollo/client/utilities';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { IFormInput } from 'pages/Locations/AddLocation/add-location.types';

interface IProps {
  data: ProcessedEntity;
  index: number;
  locationOwnersList: UserEntityData[];
  processedData: ProcessedEntity[];
  verify: (data: ProcessedEntity) => void;
  setProcessedData: React.Dispatch<React.SetStateAction<ProcessedEntity[]>>;
  updateUserNameHandler: (userName: string) => void;
  updatePasswordHandler: (password: string) => void;
  updateConfirmPasswordHandler: (confirmPassword: string) => void;
  togglePasswordVisibilityHandler: () => void;
  toggleConfirmPasswordVisibilityHandler: () => void;
}

const BaseCard: FC<IProps> = ({
  data,
  index,
  locationOwnersList,
  processedData,
  setProcessedData,
  verify,
  updateUserNameHandler,
  updatePasswordHandler,
  updateConfirmPasswordHandler,
  togglePasswordVisibilityHandler,
  toggleConfirmPasswordVisibilityHandler,
}) => {
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const methods = useForm<IFormInput>({
    defaultValues: {
      locationOwners: [
        {
          owner: '' as never,
        },
      ],
    },
  });

  let locationOwnersWatch: IFormInput['locationOwners'] = useWatch<IFormInput>({
    control: methods.control,
    name: 'locationOwners',
  });

  useEffect(() => {
    if (hasAttemptedSubmit) {
      if (locationOwnersWatch?.some((loc) => !loc?.owner?.eid)) {
        methods.setError('locationOwners', {
          type: 'manual',
          message: 'Location owner must be selected.',
        });
        let _processedData = cloneDeep(processedData);
        _processedData[index].ownersError = 'Location owner must be selected';
        setProcessedData(_processedData);
        return;
      }
    }
    methods.clearErrors('locationOwners');
    let locationOwnerEids = locationOwnersWatch
      ?.filter((lo) => lo?.owner?.value)
      ?.map((lo) => lo?.owner?.value);
    let _processedData = cloneDeep(processedData);
    _processedData[index].owners = locationOwnerEids;
    _processedData[index].ownersError = '';
    setProcessedData(_processedData);
    if (hasAttemptedSubmit) {
      setHasAttemptedSubmit(false);
    }
  }, [locationOwnersWatch, hasAttemptedSubmit]);

  const handleSubmit = () => {
    setHasAttemptedSubmit(true);
    let flag = locationOwnersWatch.every((owner) => owner?.owner?.eid);
    if (flag) {
      methods.clearErrors('locationOwners');
      let _processedData = cloneDeep(processedData);
      _processedData[index].ownersError = '';
      setProcessedData(_processedData);
      verify(data);
    } else {
      methods.setError('locationOwners', {
        type: 'manual',
        message: 'Location owner must be selected.',
      });
      let _processedData = cloneDeep(processedData);
      _processedData[index].ownersError = 'Location owner must be selected';
      setProcessedData(_processedData);
    }
  };

  return (
    <Flex
      key={data?.id}
      bg='rgba(247, 247, 247, 1)'
      borderRadius='20px'
      p={4}
      w='full'
      flexDir='column'
      gap={4}
    >
      <Flex justify='space-between' align='center' w='full'>
        <Flex gap={4}>
          <Center
            boxSize='48px'
            borderRadius='7.2px'
            bg={
              !data.readyToProceed
                ? 'rgba(255, 216, 141, 1)'
                : 'rgba(181, 228, 202, 0.4)'
            }
          >
            <FontAwesomeIcon
              icon={
                (!data.readyToProceed
                  ? faLocationDot
                  : faCircleCheck) as IconProp
              }
              fontSize={!data.readyToProceed ? '24px' : '18px'}
              color={!data.readyToProceed ? 'white' : 'rgba(131, 191, 110, 1)'}
            />
          </Center>
          <Flex flexDir='column'>
            <Text fontWeight={600} fontSize='16px'>
              {data?.data?.data?.['Location name']?.value}
            </Text>
            <Text
              fontSize='12px'
              color='rgba(51, 56, 63, 1)'
            >{`${data?.data?.data?.['Address']?.value}, ${data?.data?.data?.['City']?.value}`}</Text>
          </Flex>
        </Flex>
        {!data.readyToProceed ? (
          <Button
            variant='outline'
            colorScheme='blue'
            borderColor='rgba(204, 204, 204, 1)'
            _hover={{ bg: 'white' }}
            bg='white'
            rightIcon={<ChevronRightIcon />}
            onClick={() => {
              let _processedData = cloneDeep(processedData);
              _processedData[index].accordionState =
                !_processedData[index].accordionState;
              setProcessedData(_processedData);
            }}
          >
            Setup
          </Button>
        ) : (
          <FontAwesomeIcon
            icon={
              (processedData[index].accordionState
                ? faChevronUp
                : faChevronDown) as IconProp
            }
            color='rgba(111, 118, 126, 1)'
            cursor='pointer'
            onClick={() => {
              let _processedData = cloneDeep(processedData);
              _processedData[index].accordionState =
                !_processedData[index].accordionState;
              setProcessedData(_processedData);
            }}
          />
        )}
      </Flex>
      {data.accordionState && (
        <Flex flexDir='column' gap={4}>
          <Flex>
            <Flex gap={2}>
              <Flex flexDir='column' w='40%'>
                <FormControl isInvalid={!!data?.locationUsernameError}>
                  <TitleHeader isRequired title='Location username' />
                  <Input
                    bg='white'
                    placeholder='Enter username'
                    value={data.locationUserName}
                    onChange={(e) => updateUserNameHandler(e?.target?.value)}
                  />
                  <FormErrorMessage>
                    {data?.locationUsernameError}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex flexDir='column' w='30%'>
                <FormControl isInvalid={!!data?.passwordError}>
                  <TitleHeader isRequired title='Password' />
                  <InputGroup size='md'>
                    <Input
                      pr='4.5rem'
                      bg='white'
                      type={data?.passwordVisibleToggle ? 'text' : 'password'}
                      placeholder='**********'
                      value={data.password}
                      onChange={(e) => updatePasswordHandler(e?.target?.value)}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button
                        h='1.75rem'
                        size='sm'
                        bg='white'
                        onClick={() => {
                          togglePasswordVisibilityHandler();
                        }}
                      >
                        {data?.passwordVisibleToggle ? (
                          <FontAwesomeIcon icon={faEyeSlash as IconProp} />
                        ) : (
                          <FontAwesomeIcon icon={faEye as IconProp} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{data?.passwordError}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex flexDir='column' w='30%'>
                <FormControl isInvalid={!!data?.confirmPasswordError}>
                  <TitleHeader isRequired title='Confirm password' />
                  <InputGroup size='md'>
                    <Input
                      pr='4.5rem'
                      bg='white'
                      type={
                        data?.confirmPasswordVisibleToggle ? 'text' : 'password'
                      }
                      value={data.confirmPassword}
                      placeholder='**********'
                      onChange={(e) =>
                        updateConfirmPasswordHandler(e?.target?.value)
                      }
                    />
                    <InputRightElement width='4.5rem'>
                      <Button
                        h='1.75rem'
                        size='sm'
                        bg='white'
                        onClick={() => {
                          toggleConfirmPasswordVisibilityHandler();
                        }}
                      >
                        {data?.confirmPasswordVisibleToggle ? (
                          <FontAwesomeIcon icon={faEyeSlash as IconProp} />
                        ) : (
                          <FontAwesomeIcon icon={faEye as IconProp} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {data?.confirmPasswordError}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir='column'>
            <FormProvider {...methods}>
              <LocationOwnerDropdown />
            </FormProvider>
          </Flex>
          <Flex w='full' justify='flex-end'>
            <Button
              colorScheme='blue'
              isLoading={data.loading}
              disabled={data.loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              {!data.readyToProceed ? 'Save & next' : 'Update'}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default BaseCard;
