import { useMutation } from '@apollo/client';
import { Center, Text, useToast } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import {
  DeleteLocationResponse,
  DeleteLocationVariable,
  DELETE_LOCATION_QUERY,
} from 'pages/Locations/LocationDelete/delete-location.graphql';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from 'sub-components/Locations/locationTypes/location-types';

interface IProps {
  locationDetails: ILocation;
  onActionSuccess: () => void;
  onActionError: () => void;
}

const DeleteLocationContent: FC<IProps> = ({
  locationDetails,
  onActionError,
  onActionSuccess,
}) => {
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const [deleteCategory, { loading }] = useMutation<
    DeleteLocationResponse,
    DeleteLocationVariable
  >(DELETE_LOCATION_QUERY, {
    onCompleted: (response) => {
      if (response?.deleteLocationWithUsers) {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('location:locationDeletedSuccess'),
        });
        onActionSuccess();
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: t('location:locationNotDeletedSuccess'),
      });
      onActionError();
    },
  });
  const deleteCategoryHandler = () => {
    deleteCategory({
      variables: {
        locationId: locationDetails?.eid,
      },
    });
  };
  return (
    <Center flexDir='column' gap={4}>
      <Center boxSize='56px' borderRadius='50%' bg='rgba(255, 106, 85, 0.2)'>
        <FontAwesomeIcon
          icon={faTrashCan as IconProp}
          color='rgba(255, 106, 85, 1)'
        />
      </Center>
      <Text>Do you really want to delete {locationDetails?.name}?</Text>
      <Button
        size='lg'
        variant='solid'
        colorScheme='red'
        w='full'
        onClick={deleteCategoryHandler}
        isLoading={loading}
        isDisabled={loading}
      >
        Yes, delete
      </Button>
    </Center>
  );
};

export default DeleteLocationContent;
