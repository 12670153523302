import { SelectOption } from 'atoms';
import { toArray } from 'utils/utils';

export const formatData = (
  locationDetails: Record<string, any> | undefined
) => {
  if (!locationDetails) {
    return undefined;
  }
  return {
    address: {
      address: locationDetails?.address,
      city: locationDetails?.city,
      state: locationDetails?.state,
      zipCode: locationDetails?.zipCode,
    },
    countryOfFormation: locationDetails?.countryOfFormation?.value,
    eid: locationDetails?.eid,
    entityType: locationDetails?.entityType?.value,
    locationEmail: toArray(
      locationDetails.locationEmail?.map((v: { email: string }) => v.email)
    ).filter(Boolean),
    locationOwners: toArray(
      locationDetails.locationOwners?.map(
        (v: { owner: SelectOption<string> }) => v.owner?.value
      )
    ).filter(Boolean),
    locationPhone: toArray(
      locationDetails.locationPhone?.map((v: { phone: string }) => v.phone)
    ).filter(Boolean),
    locationStatus: locationDetails?.locationStatus?.value,
    locationType: locationDetails?.locationType,
    name: locationDetails?.locationName,
    otherFields: toArray(locationDetails.otherFields).map((field) => {
      return {
        fieldId: field.fieldId,
        value: field.value,
        files: field.files || [],
        options: toArray(field.options)
          .map((value) => {
            return value?.value;
          })
          .filter(Boolean),
      };
    }),
    stateOfFormation: locationDetails?.stateOfFormation?.value,
    taxPayerId: locationDetails?.taxPayerId,
  };
};
