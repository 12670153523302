import React, { FC } from 'react';
import { Box, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment/moment';
import { IconImage } from '../../../../../../../ui-components';
import { TaskItemEntity } from '../../../../../TaskDetails/components/TaskOverview/task-item.types';

import StatusBadge from './StatusBadge';
import { deployEvent } from 'shared/amplitudeEvents';

interface AProps {
  itemType: string;
  isAccessible?: boolean;
}

const NotAccessibleTag: FC<AProps> = ({ itemType, isAccessible }) => {
  if (itemType === 'form' && !isAccessible) {
    return (
      <Tooltip
        shouldWrapChildren
        hasArrow
        label='Form not accessible, contact your manager'
      >
        <StatusBadge
          id='my-task-details-step-listing-form-accessible'
          color='#FF6A55'
          bg='#FF6A5533'
        >
          Not accessible
        </StatusBadge>
      </Tooltip>
    );
  }

  return null;
};

export interface ICompletedTask {
  contentId: string;
  isCompleted: boolean;
  completedAt: string;
  response?: null;
}

interface IProps {
  item: TaskItemEntity;
  onClick: (type: 'navigate' | 'update', isTaskCompleted?: boolean) => void;
  createdByName?: string;
  completedTasks: ICompletedTask[];
  event?: any;
}

const StepListingItem: FC<IProps> = ({
  item,
  createdByName,
  completedTasks = [],
  onClick,
  event,
}) => {
  const isCompleted = completedTasks.some(
    (task) => task?.isCompleted && item?.eid === task?.contentId
  );

  return (
    <Flex
      align='center'
      gap={4}
      cursor='pointer'
      py={3}
      onClick={() => {
        event && deployEvent(event);
      }}
    >
      <Checkbox
        colorScheme='green'
        isChecked={isCompleted}
        onChange={() => onClick('update', isCompleted)}
      />

      <Flex
        flex={1}
        flexDir='column'
        overflow='hidden'
        onClick={() => onClick('navigate')}
      >
        <Text
          m={0}
          color='#1A1D1F'
          fontWeight={500}
          fontSize='14px'
          isTruncated={item?.type !== 'todo'}
        >
          {item?.title}
        </Text>
        <Flex
          align='center'
          gap='5px'
          color='#6F767E'
          fontWeight={400}
          fontSize='12px'
          overflow='hidden'
        >
          <Text m={0} whiteSpace='nowrap'>
            Added on {moment(item?.dateAdded)?.format('DD MMM')}
          </Text>
          <Text m={0} color='#6F767E'>
            &bull;
          </Text>
          <Text m={0} textTransform='capitalize' isTruncated>
            Created by {createdByName}
          </Text>
        </Flex>
      </Flex>

      <NotAccessibleTag
        itemType={item?.type}
        isAccessible={item?.isAccessible}
      />

      {item?.type === 'todo' ? (
        <Box boxSize='48px' minW='48px' />
      ) : (
        <IconImage
          icon={item?.icon}
          thumbnail={item.thumbnail}
          name={item.title}
          files={item.files}
          boxSize={48}
          borderRadius='6px'
        />
      )}
    </Flex>
  );
};

export default StepListingItem;
