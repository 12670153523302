export type CommonEventType = {
  value: string;
  additional_info: {
    page: string;
    eventType:
      | "navigate"
      | "view"
      | "filter"
      | "remind"
      | "retake"
      | "submit"
      | "create";
  };
};

const CommonAmplitudeEvent = [
  "ACTIVE_LOCATION_CARD",
  "TASK_COMPLETED_CARD",
  "TRAINING_FINISHED_CARD",
  "FORMS_FILLED_CARD",
  "SUPERVISED_BY_ME_TAB_HOME",
  "VIEW_SUPERVISED_BY_ME_TASK_HOME",
  "MY_TASKS_TAB_HOME",
  "VIEW_MY_TASK_HOME",
  "TRAINING_TRACK_TAB",
  "MEMBER_HOME",
  "TRAINING_RETAKE_HOME",
  "TRAINING_REMIND_HOME",
  "TRAINING_ASSIGNED_TO_YOU_TAB",
  "TRACKING_TAB",
  "TRACKING_PATH_TAB",
  "TRACKING_MEMBERS_TAB",
  "VIEW_MEMBER",
  "TRAINING_RETAKE",
  "TRAINING_REMIND",
  "MY_TRAINING_TAB",
  "ALL_FORMS_TAB",
  "VIEW_FORM",
  "FORM_RESPONSES_TAB",
  "VIEW_FORM_RESPONSE",
  "MY_TASKS_TAB",
  "VIEW_MY_TASK",
  "VIEW_MY_TASK_STEP_FORM",
  "SUBMIT_FORM_RESPONSE",
  "SUPERVISED_BY_ME_TAB",
  "VIEW_SUPERVISED_BY_ME_TASK",
  "VIEW_SUPERVISED_BY_ME_TASK_STEP",
  "FILTERS",
  "CLEAR_ALL_FILTERS",
  "JOBS_FILTER",
  "CLEAR_JOBS_FILTER",
  "LOCATIONS_FILTER",
  "CLEAR_LOCATIONS_FILTER",
  "MEMBERS_FILTER",
  "CLEAR_MEMBERS_FILTER",
  "CREATED_BY_FILTER",
  "CLEAR_CREATED_BY_FILTER",
  "CHAPTER_FOLDER",
  "CHAPTER",
  "CHAT_TAB",
  "CHAT_USER_TAB",
  "CREATE_NEW_CHANNEL_BUTTON",
] as const;

export const CommonAmplitudeEvents: Record<
  typeof CommonAmplitudeEvent[number],
  CommonEventType
> = {
  //Home
  ACTIVE_LOCATION_CARD: {
    value: "active_location_card",
    additional_info: { page: "home", eventType: "navigate" },
  },
  TASK_COMPLETED_CARD: {
    value: "task_completed_card",
    additional_info: { page: "home", eventType: "navigate" },
  },
  TRAINING_FINISHED_CARD: {
    value: "training_finished_card",
    additional_info: { page: "home", eventType: "navigate" },
  },
  FORMS_FILLED_CARD: {
    value: "forms_filled_card",
    additional_info: { page: "home", eventType: "navigate" },
  },
  SUPERVISED_BY_ME_TAB_HOME: {
    value: "supervised_by_me_tab_home",
    additional_info: { page: "home", eventType: "navigate" },
  },
  VIEW_SUPERVISED_BY_ME_TASK_HOME: {
    value: "view_supervised_by_me_task_home",
    additional_info: { page: "home", eventType: "view" },
  },
  MY_TASKS_TAB_HOME: {
    value: "my_tasks_tab_home",
    additional_info: { page: "home", eventType: "navigate" },
  },
  VIEW_MY_TASK_HOME: {
    value: "view_my_task_home",
    additional_info: { page: "home", eventType: "view" },
  },
  TRAINING_TRACK_TAB: {
    value: "training_track_tab",
    additional_info: { page: "home", eventType: "navigate" },
  },
  MEMBER_HOME: {
    value: "member_home",
    additional_info: { page: "home", eventType: "view" },
  },
  TRAINING_RETAKE_HOME: {
    value: "training_retake_home",
    additional_info: { page: "home", eventType: "retake" },
  },
  TRAINING_REMIND_HOME: {
    value: "training_remind_home",
    additional_info: { page: "home", eventType: "remind" },
  },
  TRAINING_ASSIGNED_TO_YOU_TAB: {
    value: "training_assigned_to_you_tab",
    additional_info: { page: "home", eventType: "navigate" },
  },

  //Training
  TRACKING_TAB: {
    value: "tracking_tab",
    additional_info: { page: "training", eventType: "navigate" },
  },
  TRACKING_PATH_TAB: {
    value: "tracking_path_tab",
    additional_info: { page: "training", eventType: "navigate" },
  },
  TRACKING_MEMBERS_TAB: {
    value: "tracking_members_tab",
    additional_info: { page: "training", eventType: "navigate" },
  },
  VIEW_MEMBER: {
    value: "view_member",
    additional_info: { page: "training", eventType: "view" },
  },
  TRAINING_RETAKE: {
    value: "training_retake",
    additional_info: { page: "training", eventType: "view" },
  },
  TRAINING_REMIND: {
    value: "training_remind",
    additional_info: { page: "training", eventType: "view" },
  },
  MY_TRAINING_TAB: {
    value: "my_training_tab",
    additional_info: { page: "training", eventType: "navigate" },
  },

  //Forms
  ALL_FORMS_TAB: {
    value: "all_forms_tab",
    additional_info: { page: "forms", eventType: "navigate" },
  },
  VIEW_FORM: {
    value: "view_form",
    additional_info: { page: "forms", eventType: "view" },
  },
  FORM_RESPONSES_TAB: {
    value: "form_responses_tab",
    additional_info: { page: "forms", eventType: "navigate" },
  },
  VIEW_FORM_RESPONSE: {
    value: "view_form_response",
    additional_info: { page: "forms", eventType: "view" },
  },

  //Tasks
  MY_TASKS_TAB: {
    value: "my_tasks_tab",
    additional_info: { page: "tasks", eventType: "navigate" },
  },
  VIEW_MY_TASK: {
    value: "view_my_task",
    additional_info: { page: "tasks", eventType: "view" },
  },
  VIEW_MY_TASK_STEP_FORM: {
    value: "view_my_task_step_form",
    additional_info: { page: "tasks", eventType: "view" },
  },
  SUBMIT_FORM_RESPONSE: {
    value: "submit_form_response",
    additional_info: { page: "tasks", eventType: "submit" },
  },
  SUPERVISED_BY_ME_TAB: {
    value: "supervised_by_me_tab",
    additional_info: { page: "tasks", eventType: "navigate" },
  },
  VIEW_SUPERVISED_BY_ME_TASK: {
    value: "view_supervised_by_me_task",
    additional_info: { page: "tasks", eventType: "view" },
  },
  VIEW_SUPERVISED_BY_ME_TASK_STEP: {
    value: "view_supervised_by_me_task_step",
    additional_info: { page: "tasks", eventType: "view" },
  },
  FILTERS: {
    value: "filters",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  CLEAR_ALL_FILTERS: {
    value: "clear_all_filters",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  JOBS_FILTER: {
    value: "jobs_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  CLEAR_JOBS_FILTER: {
    value: "clear_jobs_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  LOCATIONS_FILTER: {
    value: "locations_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  CLEAR_LOCATIONS_FILTER: {
    value: "clear_locations_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  MEMBERS_FILTER: {
    value: "members_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  CLEAR_MEMBERS_FILTER: {
    value: "clear_members_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  CREATED_BY_FILTER: {
    value: "created_by_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },
  CLEAR_CREATED_BY_FILTER: {
    value: "clear_created_by_filter",
    additional_info: { page: "tasks", eventType: "filter" },
  },

  //Chapter
  CHAPTER_FOLDER: {
    value: "chapter_folder",
    additional_info: { page: "knowledge_base", eventType: "view" },
  },
  CHAPTER: {
    value: "chapter",
    additional_info: { page: "knowledge_base", eventType: "view" },
  },

  //Chat
  CHAT_TAB: {
    value: "chat_tab",
    additional_info: { page: "chat", eventType: "navigate" },
  },
  CHAT_USER_TAB: {
    value: "chat_user_tab",
    additional_info: { page: "chat", eventType: "navigate" },
  },
  CREATE_NEW_CHANNEL_BUTTON: {
    value: "create_new_channel_button",
    additional_info: { page: "chat", eventType: "create" },
  },
};
