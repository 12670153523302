import { useLazyQuery, useMutation } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { LocationMember } from 'types';
import {
  ICustomEntityUser,
  IGetLocationOwnersResponse,
  IGetLocationOwnersVariables,
  IUpdateLocationOwnersResponse,
  IUpdateLocationOwnersVariables,
} from '../../types/location-owner-list-menu.types';
import LocationOwnerListMenu from '../LocationOwnerListMenu';
import {
  GET_ALL_LOCATION_OWNERS,
  UPDATE_LOCATION_OWNERS_FOR_LOCATION,
} from '../LocationOwnerListMenu/location-owner-list-menu.graphql';
import { AmplitudeEvent, deployEvent } from '../../../../../../../../shared';

interface IProps {
  eid: string | undefined;
  title?: string;
  owners: LocationMember[];
  menuBtnProps?: {
    size?: string;
    variant?: string;
    colorScheme?: string;
    style?: React.CSSProperties;
  };
  onAddLOClick: () => void;
  refetch?: () => void;
}

const LocationOwnerMenu: FC<IProps> = ({
  eid,
  title = 'Add',
  owners,
  menuBtnProps,
  onAddLOClick,
  refetch,
}) => {
  const entityId = useUserDataSelector((state) => state.entityId);
  const menuRef = useRef(null);
  const toast = useToast();
  const [items, setItems] = useState<ICustomEntityUser[]>([]);
  const [initialItems, setInitialItems] = useState<ICustomEntityUser[]>([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [updateLocationOwners, { loading: updatingLocationOwners }] =
    useMutation<IUpdateLocationOwnersResponse, IUpdateLocationOwnersVariables>(
      UPDATE_LOCATION_OWNERS_FOR_LOCATION,
      {
        onCompleted: () => {
          deployEvent(AmplitudeEvent.LOCATION_ADD_OWNER_SUCCESS);
          setMenuOpen(false);
          refetch?.();
        },
        onError: () => {
          setMenuOpen(false);
          toast({
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
            title: 'Selected location owners could not be updated',
          });
        },
      }
    );

  useEffect(() => {
    setSearchQuery('');
  }, [isMenuOpen]);

  const [getLocationOwners, { data, loading }] = useLazyQuery<
    IGetLocationOwnersResponse,
    IGetLocationOwnersVariables
  >(GET_ALL_LOCATION_OWNERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (entityId && isMenuOpen) {
      getLocationOwners({
        variables: {
          authRole: 'locationOwner',
          entityId: entityId,
        },
      });
    }
  }, [entityId, isMenuOpen]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data?.EntityUser) &&
      data?.EntityUser?.length > 0
    ) {
      let _tempUsers: ICustomEntityUser[] = JSON.parse(
        JSON.stringify(data?.EntityUser || [])
      );
      let _users = _tempUsers?.map((user) => {
        if (owners.some((owner) => owner.eid === user.eid)) {
          user.selected = true;
        } else {
          user.selected = false;
        }
        return user;
      });
      console.log('USERS : ', _users);
      setInitialItems(_users);
      setItems(_users);
    }
  }, [data]);

  // useEffect(() => {
  //   if (!searchQuery) {
  //     setItems(initialItems);
  //   } else {
  //     let filteredItems = initialItems?.filter((initial) =>
  //       initial?.name
  //         ?.trim()
  //         ?.toLowerCase()
  //         ?.includes(searchQuery?.trim()?.toLowerCase())
  //     );
  //     setItems(filteredItems);
  //   }
  // }, [searchQuery]);

  const locationOwners = useMemo(() => {
    if (!searchQuery) {
      return initialItems;
    } else {
      return initialItems?.filter((initial) =>
        initial?.name
          ?.trim()
          ?.toLowerCase()
          ?.includes(searchQuery?.trim()?.toLowerCase())
      );
    }
  }, [data, searchQuery, items, initialItems]);

  const selectedOwnersHandler = (eid: string, selectedLOEids: string[]) => {
    // if (!areSelectionAndInitialDifferent(selectedLOEids, owners)) {
    //   return;
    // }
    deployEvent(AmplitudeEvent.LOCATION_ADD_OWNER_INIT);
    updateLocationOwners({
      variables: {
        input: {
          eid: eid,
          locationOwners: selectedLOEids,
        },
      },
    });
  };

  const handleOutsideClick = () => {
    setMenuOpen(false);
    setSearchQuery('');
  };

  useOutsideClick({
    ref: menuRef,
    handler: handleOutsideClick,
  });

  const selectionHandler = (user: ICustomEntityUser) => {
    let _tempUsers: ICustomEntityUser[] = JSON.parse(JSON.stringify(items));
    _tempUsers?.forEach((_tUser) => {
      if (_tUser?.eid === user?.eid) {
        _tUser.selected = !_tUser?.selected;
      }
    });
    setInitialItems(_tempUsers);
    setItems(_tempUsers);
  };

  return (
    <Menu isOpen={isMenuOpen}>
      <div ref={menuRef}>
        <MenuButton
          as={Button}
          leftIcon={<AddIcon />}
          size={menuBtnProps?.size || 'sm'}
          width='fit-content'
          variant={menuBtnProps?.variant || 'outline'}
          colorScheme={menuBtnProps?.colorScheme || 'blue'}
          style={menuBtnProps?.style || undefined}
          onClick={() => {
            deployEvent(AmplitudeEvent.LOCATION_ADD_OWNER_MENU);
            setMenuOpen(!isMenuOpen);
          }}
        >
          {title}
        </MenuButton>
        <MenuList p={0}>
          <LocationOwnerListMenu
            eid={eid}
            loading={loading}
            items={locationOwners}
            allLocationOwners={items}
            owners={owners}
            searchQuery={searchQuery}
            updatingLocationOwners={updatingLocationOwners}
            setSearchQuery={setSearchQuery}
            selectionHandler={selectionHandler}
            setMenuOpen={setMenuOpen}
            onAddLOClick={onAddLOClick}
            selectedOwnersHandler={selectedOwnersHandler}
          />
        </MenuList>
      </div>
    </Menu>
  );
};

export default LocationOwnerMenu;
