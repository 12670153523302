import React, { FC } from 'react';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ISubSection {
  isLive?: boolean;
  locationName?: string;
  setupLocation?: () => void;
}

const SubSection: FC<ISubSection> = ({
  isLive,
  locationName,
  setupLocation,
}) => {
  // if (isLive) {
  //   return (
  //     <PrimaryButton
  //       // title={`Go to ${locationName}`}
  //       title={`Setup ${locationName}`}
  //       variant='solid'
  //       colorScheme='blue'
  //       width='fit-content'
  //       // onClick={() => goToLocation?.()}
  //       onClick={() => setupLocation?.()}
  //     />
  //   );
  // }

  return (
    <Flex flexDir='column' gap={4}>
      <Text
        fontWeight={400}
        fontSize='14px'
        color='#00000080'
        textAlign='center'
      >
        Once you make your location live, you will gain access to a range of
        powerful features. You will be able to add more members to your team,
        assign tasks to them, track their progress, assign trainings to enhance
        their skills, and much more.
      </Text>
      <Flex w='full' justify='center'>
        <Center
          gap='5px'
          bg='rgba(252, 241, 229, 1)'
          borderRadius='7px'
          p='11px 12px'
          border='1px solid rgba(255, 174, 82, 1)'
        >
          <FontAwesomeIcon
            icon={faCircleExclamation as IconProp}
            color='rgba(255, 164, 61, 1)'
          />
          <Box as='span' color='rgba(255, 164, 61, 1)'>
            All live locations are chargeable.
          </Box>
        </Center>
      </Flex>
    </Flex>
  );
};

export default SubSection;
