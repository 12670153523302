import { ChakraProvider, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import { FormProvider, useForm } from 'react-hook-form';
import VisibilityModal from 'pages/Handbook/setVisibilityModal';
import { IFolderVisibilityType, IModalStep } from '../FolderVisibility';
import { deployEvent } from 'shared';
import { useMutation } from '@apollo/client';
import { UPDATE_CHAPTER } from 'pages/Chapters/CreateChapterModal/create-chapter.graphql';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import useCombinedStore from 'zustandStore/store';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';

interface IProps {
  modalType: IFolderVisibilityType;
  getChaptersList?: () => void;
  onClose: () => void;
  updateClick: (step: IModalStep) => void;
}

const VisibilityOperation: FC<IProps> = ({
  modalType,
  getChaptersList,
  onClose,
  updateClick,
}) => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const methods = useForm<IFormInput>({
    defaultValues: {
      title: 'Set visibility',
    },
  });

  const loggedInUserAuthRole = useUserDataSelector((state) => state?.authRole);

  const { hierarchyData, visibilityScope, visibilitySelection } =
    useCombinedStore();

  const [updateChapter, { loading: updatingChapter }] = useMutation(
    UPDATE_CHAPTER,
    {
      onCompleted: (response) => {
        toast({
          title: 'Chapter updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchFoldersList();
      },
      onError: (error) => {
        toast({
          title: 'Chapter could not be updated',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const onFetchFoldersListSuccess = () => {
    getChaptersList?.();
    onClose();
  };

  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);

  const updateChapterVisibilityHandler = () => {
    deployEvent(AmplitudeEventNames.UPDATE_CHAPTER_VISIBILITY);
    updateChapter({
      variables: {
        input: {
          eid: hierarchyData?.chapter?.details?.eid,
          languageCode: hierarchyData?.chapter?.details?.language || 'en',
          visibility: visibilitySelection?.visibility || 'public',
          visibleTo: {
            condition: visibilitySelection?.condition || null,
            locations: visibilitySelection?.locations || [],
            roles: visibilitySelection?.roles || [],
            users: visibilitySelection?.users || [],
          },
        },
      },
    });
  };

  const folderVisibilityUpdateHandler = () => {
    if (
      loggedInUserAuthRole === AuthRole?.SUPER_ADMIN &&
      visibilityScope === 'chapter'
    ) {
      updateChapterVisibilityHandler?.();
    } else {
      updateClick(3);
    }
  };
  return (
    <FormProvider {...methods}>
      <ChakraProvider portalZIndex={3001}>
        <VisibilityModal
          isLoading={updatingChapter || gettingFoldersList}
          isOpen={true}
          handleClose={onClose}
          modalType='write'
          folderVisibilityUpdateHandler={folderVisibilityUpdateHandler}
        />
      </ChakraProvider>
    </FormProvider>
  );
};

export default VisibilityOperation;
