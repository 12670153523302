import React, { CSSProperties, FC } from 'react';
import { UserAnalyticsOverviewType } from 'sub-components/AnalyticsBoard/AnalyticsBoard';
import { wrapTextWithSpan } from '../NumericStats';
import ParentWrapper from './ParentWrapper';
import { Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';

const ActiveMembersSection: FC<{
  style?: CSSProperties;
  analyticsData: UserAnalyticsOverviewType['userAnalyticsOverview'] | undefined;
}> = ({ style, analyticsData }) => {
  return (
    <>
      <ParentWrapper
        title='Active locations'
        totalCount={(analyticsData?.locationCount || 0) + ''}
        style={style}
        redirectPath={'/locations'}
        event={CommonAmplitudeEvents.ACTIVE_LOCATION_CARD}
      >
        <Text>There {analyticsData?.userCount === 1 ? 'is' : 'are'}</Text>
        <Text fontWeight={700} color='#2A85FF'>
          {analyticsData?.userCount}
        </Text>
        {wrapTextWithSpan(
          `active ${
            analyticsData?.userCount === 1 ? 'member' : 'members'
          } in the above locations`
        )}
        {/* {wrapTextWithSpan('active locations')} */}
      </ParentWrapper>
    </>
  );
};

export default ActiveMembersSection;
