import { Box, Center, Grid, GridItem, Image } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'sub-components/Loader';
import { IIconList } from '../types';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  galleryIconsLoading: boolean;
  iconsList: IIconList[];
  onClose: () => void;
  selectedIcon: (imgObj: IIconList) => void;
}

const Gallery: FC<IProps> = ({
  galleryIconsLoading,
  iconsList,
  onClose,
  selectedIcon,
}) => {
  const { t } = useTranslation('training');
  const selectImageHandler = (imgObj: IIconList, onClose: Function) => {
    selectedIcon(imgObj);
    onClose();
  };

  const getIconsList = (onClose: () => void) => {
    return (
      <Box h='430px'>
        <Box h='420px' overflowY='scroll'>
          {iconsList?.length > 0 && (
            <Grid
              templateColumns='repeat(5, 1fr)'
              justifyItems='center'
              rowGap={8}
            >
              {iconsList?.map((list) => {
                return (
                  <GridItem w='100%' key={list.eid}>
                    <Center>
                      <Image
                        src={list?.url}
                        boxSize='77px'
                        objectFit='cover'
                        borderRadius='8px'
                        cursor='pointer'
                        onClick={() => selectImageHandler(list, onClose)}
                      />
                    </Center>
                  </GridItem>
                );
              })}
            </Grid>
          )}
        </Box>
      </Box>
    );
  };
  if (galleryIconsLoading) {
    return (
      <Center h='40vh'>
        <Loader />
      </Center>
    );
  }
  if (iconsList?.length === 0) {
    return (
      <EmptyState
        image='NoConversationLogs'
        title='No icons found'
        height='40vh'
      />
    );
  }
  return <>{getIconsList(onClose)}</>;
};

export default Gallery;
