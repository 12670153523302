import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import { IJsonEntity, JsonEntityWithId } from '../types';
import { cleanHeader, toCamelCase } from './utils';

const convertSheetToJson = (sheet: XLSX.WorkSheet): IJsonEntity[] => {
  const range = XLSX.utils.decode_range(sheet['!ref']!);
  const headers: string[] = [];
  const headerCounts: { [key: string]: number } = {};
  const data: any[] = [];

  // Populate headers from the first row and handle duplicates with unique delimiter
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const headerCell = sheet[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
    if (headerCell && headerCell.v) {
      let headerValue = headerCell.v;
      if (headerCounts[headerValue] !== undefined) {
        headerCounts[headerValue]++;
        headerValue = `<<${headerCounts[headerValue]}-TO_BE_TRIMMED_>>${headerValue}`;
      } else {
        headerCounts[headerValue] = 0;
      }
      headers[C] = headerValue;
    } else {
      headers[C] = '';
    }
  }

  // Process each row
  for (let R = range.s.r + 1; R <= range.e.r; ++R) {
    let rowObject: any = {};
    let empty = true;
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = sheet[XLSX.utils.encode_cell({ r: R, c: C })];
      const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
      const cellValue = cell && cell.v !== undefined ? cell.v : '';
      if (headers[C]) {
        rowObject[cleanHeader(headers[C])] = {
          value: cellValue,
          cellRef: cellRef,
          id: uuidv4(),
        };
        if (cellValue !== '') empty = false;
      }
    }
    if (!empty) {
      data.push(rowObject);
    }
  }
  return data;
};

export const fileToJsonParse = (
  result: string | ArrayBuffer | null | undefined
) => {
  const data = result;
  const workbook = XLSX.read(data, { type: 'binary' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const json: IJsonEntity[] = convertSheetToJson(worksheet);
  console.log('json : ', json);
  let processedJson: JsonEntityWithId[] = json?.map((_json) => {
    return {
      id: uuidv4(),
      data: _json,
    };
  });
  return { json, processedJson };
};
