import { gql } from '@apollo/client';

export const ASSET_PAGINATION = gql`
  query EntityAssetPagination($page: Int, $perPage: Int) {
    EntityAssetPagination(page: $page, perPage: $perPage) {
      count
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
      items {
        assetType
        createdAt
        createdBy
        color
        eid
        entityId
        fileSize
        mimetype
        resource
        resourceSubtype
        tags
        title
        updatedAt
        url
      }
    }
  }
`;

export const CREATE_ASSET = gql`
  mutation CreateEntityAsset($input: EntityAssetAddInputInput) {
    CreateEntityAsset(input: $input) {
      eid
      createdAt
      title
      tags
      updatedAt
      url
    }
  }
`;
