import {
  AlertDialogCloseButton,
  Box,
  Flex,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import DragDropComponent from 'pages/Chapters/CreateChapterModal/common/DragDropComponent';
import React, { FC, useState } from 'react';
import FileList from './FileList';
import FileErrors from './FileErrors';
import ActionButtons from './ActionButtons';
import {
  faArrowDownToLine,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { useBulkFileOperation } from './useBulkFileOperation';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// @ts-ignore
import EXISTING_SAMPLE_LOCATION_FILE from '../../assets/files/Existing Locations Sample File.xlsx';
// @ts-ignore
import PRE_LAUNCH_SAMPLE_LOCATION_FILE from '../../assets/files/Pre Launch Locations Sample File.xlsx';
import FixErrors from './FixErrors';
import {
  GroupedErrors,
  JsonEntityWithId,
  LocationUploadVariables,
  PreLaunchLocationUploadResponse,
} from './types';
import { cellErrors as cellErrorsFn } from './helper/cellErrors';
import SetupLocations from './SetupLocations/SetupLocations';
import { useMutation } from '@apollo/client';
import { UPLOAD_BULK_PRE_LAUNCH_LOCATIONS } from './bulk-locations.graphql';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';
import {
  preLaunchProcessData,
  preProcessData,
} from './SetupLocations/locations-upload-pre-process';

interface IStateEntity {
  init: boolean;
  setup: boolean;
  fixErrors: boolean;
}

interface IProps {
  type: 'existing' | 'new';
  onActionError: () => void;
  onActionSuccess: () => void;
}

const ModalContent: FC<IProps> = ({ type, onActionError, onActionSuccess }) => {
  const bulkOperations = useBulkFileOperation(type);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [states, setStates] = useState<IStateEntity>({
    init: true,
    setup: false,
    fixErrors: false,
  });

  const [
    bulkUploadPreLaunchLocations,
    { loading: uploadingPreLaunchLocations },
  ] = useMutation<PreLaunchLocationUploadResponse, LocationUploadVariables>(
    UPLOAD_BULK_PRE_LAUNCH_LOCATIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Pre Launch locations uploaded successfully!',
        });
        execute();
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Pre Launch locations could not be uploaded',
        });
        onActionError();
      },
    }
  );

  const onLoggedInUserFetchSuccess = () => {
    onActionSuccess();
  };

  const onLoggedInuserFetchError = () => {
    onActionError();
  };

  const { execute, loading } = getLoggedInUserDataHandler(
    onLoggedInUserFetchSuccess,
    onLoggedInuserFetchError
  );

  const setupLocationsHandler = () => {
    if (type === 'existing') {
      setStates({
        fixErrors: false,
        init: false,
        setup: true,
      });
    } else {
      let inputData = preLaunchProcessData(bulkOperations?.readyToProcessJson);
      bulkUploadPreLaunchLocations({
        variables: {
          input: inputData,
        },
      });
    }
  };

  const fixErrorsHandler = () => {
    setStates({ init: false, setup: false, fixErrors: true });
  };

  const removeFileHandler = () => {
    bulkOperations.setFile(undefined);
  };

  const initRender = () => {
    if (!bulkOperations?.file) {
      return (
        <DragDropComponent
          accept={undefined}
          subTitle='CSV, XLS, XLSX &bull; Max size 10 MB'
          // accept={{
          //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          //     ['.xlsx', '.xls'],
          // }}
          onFileDrop={bulkOperations?.onFileDrop}
        />
      );
    }
    return (
      <Flex flexDir='column' gap={4}>
        <FileList
          bulkOperations={bulkOperations}
          removeFile={removeFileHandler}
        />
        <FileErrors bulkOperations={bulkOperations} />
        <ActionButtons
          type={type}
          bulkOperations={bulkOperations}
          uploadingPreLaunchLocations={uploadingPreLaunchLocations || loading}
          setupLocationsHandler={setupLocationsHandler}
          fixErrorsHandler={fixErrorsHandler}
          onFileDrop={bulkOperations?.onFileDrop}
        />
      </Flex>
    );
  };

  const onSaveFixErrorsHandler = (processedJson: JsonEntityWithId[]) => {
    let { groupedErrorsArray, readyToProcessData } = cellErrorsFn(
      [...bulkOperations?.readyToProcessJson, ...processedJson],
      type
    );
    setStates({
      setup: false,
      fixErrors: false,
      init: true,
    });
    console.log({ groupedErrorsArray, readyToProcessData });
    bulkOperations.setCellErrors(groupedErrorsArray);
    bulkOperations.setReadyToProcessJson(readyToProcessData);
  };

  const onDiscardHandler = () => {
    setStates({
      setup: false,
      fixErrors: false,
      init: true,
    });
  };

  const fixErrorsRender = () => {
    return (
      <FixErrors
        bulkOperations={bulkOperations}
        onSaveFixErrorsHandler={onSaveFixErrorsHandler}
        onDiscardHandler={onDiscardHandler}
      />
    );
  };

  const setupRender = () => {
    return (
      <SetupLocations
        fileJsonData={bulkOperations?.readyToProcessJson}
        type={type}
        onActionError={onActionError}
        onActionSuccess={onActionSuccess}
      />
    );
  };

  const titleRender = () => {
    if (states?.init) {
      if (type === 'existing') {
        return (
          <BoxHeader
            title={'Import open locations'}
            color={HeaderColors.Green}
          />
        );
      } else if (type === 'new') {
        return (
          <BoxHeader title={'Import locations'} color={HeaderColors.Green} />
        );
      } else {
        return null;
      }
    } else if (states?.setup) {
      return (
        <Flex align='center'>
          <Flex align='center'>
            <Box
              mr={4}
              h={8}
              w={4}
              minW={4}
              borderRadius={4}
              bg={HeaderColors.Green}
            />
            <Text fontSize='18px' fontWeight={600}>
              Setup location(s)
            </Text>
          </Flex>
          <Text fontSize='18px' fontWeight={400}>
            &nbsp;-&nbsp;{bulkOperations?.readyToProcessJson?.length} locations
            are ready to be imported
          </Text>
        </Flex>
      );
    } else if (states?.fixErrors) {
      return <BoxHeader title={'Fix errors'} color={HeaderColors.Green} />;
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Flex gap='12px'>
        <Box flex={1}>{titleRender()}</Box>
        {states?.init && (
          <Flex
            color='rgba(42, 133, 255, 1)'
            fontSize='15px'
            fontWeight={600}
            align='center'
            gap='5px'
            cursor='pointer'
            onClick={() =>
              window.open(
                type === 'existing'
                  ? EXISTING_SAMPLE_LOCATION_FILE
                  : PRE_LAUNCH_SAMPLE_LOCATION_FILE
              )
            }
          >
            <FontAwesomeIcon icon={faArrowDownToLine as IconProp} />
            <Text>Download sample</Text>
          </Flex>
        )}
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
      {type === 'existing' && (
        <Flex
          border='1px solid rgba(255, 174, 82, 1)'
          borderRadius='7px'
          p='11px 12px'
          align='center'
          gap='5px'
          bg='rgba(252, 241, 229, 1)'
        >
          <FontAwesomeIcon
            icon={faCircleExclamation as IconProp}
            color='rgba(255, 164, 61, 1)'
          />
          <Box as='span' fontWeight={500} color='rgba(255, 164, 61, 1)'>
            All locations being uploaded are chargeable.
          </Box>
        </Flex>
      )}
      {states?.init && initRender()}
      {states?.fixErrors && fixErrorsRender()}
      {states?.setup && setupRender()}
    </Flex>
  );
};

export default ModalContent;
