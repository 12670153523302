import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Flex, useBreakpointValue, useToast } from '@chakra-ui/react';
import { ChapterWrapper } from '../chpter.styles';
import ChapterHeader from '../ChapterHeader/ChapterHeader';
import ChapterListView from '../ChapterListView';
import ChapterGridView from '../ChapterGridView';
import VideoModal from '../VideoModal';
import FolderList from '../FolderList';
import { DeleteSubFolderContainer } from '../CreateEditSubFolder/components';
import { useTranslation } from 'react-i18next';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ListPropsProps } from '../ListGridToggle';
import { SelectOption } from 'atoms';
import {
  ChapterListResponse,
  ChapterSort,
  DELETE_SOP,
} from '../chapters.graphql';
import { useFolderSubFolder, useUserData } from 'hooks';
import { match } from 'ts-pattern';
import { ChapterModal } from '../modal';
import { ChapterItem, ISopMedia } from '../chapter.types';
import { makeVar, useMutation, useReactiveVar } from '@apollo/client';
import { useEditChapter } from '../EditChapterModal';
import { useChapterDelete } from '../useChapterDelete';
import {
  useMoveSubFolder,
  useSubFolderEdit,
} from '../CreateEditSubFolder/hooks';
import {
  AmplitudeEventNames,
  deployEvent,
  CommonAmplitudeEvents,
} from 'shared/amplitudeEvents';
import { IMenuCallback } from './hooks/types';
import { IActionMenuData } from 'ui-components';
import OpenIcon from '../../../assets/images/open.png';
import DarkEditIcon from '../../../assets/images/edit-dark.svg';
import DeleteIcon from '../../../assets/images/trash-red.svg';
import {
  faArrowUpRight,
  faCopy,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';
import { AuthRole, CategoryEntity, userObj } from 'sop-commons/src/client';
import { ICustomCategoryEntity } from '../FolderList/components/List/List';
import EditFolder from '../EditFolder';
import { EditFolderRef } from '../EditFolder/EditFolder';
import FolderVisibility from '../FolderVisibility';
import { IFolderVisibilityType } from '../FolderVisibility/FolderVisibility';
import {
  IConditionType,
  IType,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { useChapterMove } from '../ChapterMove';
import useCombinedStore from 'zustandStore/store';
import {
  getChaptersList,
  getFolderById,
  getFoldersList,
} from 'shared/graphql/SharedGraphql';
import { parseFolderChapter, visibilityOperation } from './helper';
import { eventBus } from 'shared/eventEmit/eventBus';
import { CustomCategoryEntity } from '../ChapterHeader/components/BulkMoveContainer';
import { useFolderSubFolderRestriction } from 'ui-components/FolderSubFolderRestriction';
import { EventBusEmits } from 'shared/eventEmit/defaults/defaults';
import { useCustomRouteMatch } from 'sub-components/Sidebar/category/hook';
import { getQueryParamValue } from 'utils/queryParams';
import { getAllFolderSubFolderList as getAllFoldersListUtility } from '../../../pages/Handbook/setVisibilityModal/SetSeeModal/utility';
import { cloneDeep } from '@apollo/client/utilities';
import { loginUserBranchRootObj, usersEntityObj } from 'sub-components/Header';
import { IRoleData } from 'sub-components/SettingDataCenter/RolesList/roles-list.types';

export interface ChapterContainerRef {
  refetch: () => void;
}

interface IProps {
  onAddChapterClick?: () => void;
}

export const foldersListVar = makeVar([] as CategoryEntity[]);
export const chaptersListVar = makeVar({} as ChapterListResponse);

const ChapterContainer = forwardRef<ChapterContainerRef, IProps>(
  ({ onAddChapterClick }, ref) => {
    const {
      deleteSubFolderData,
      filterBy,
      hierarchyData,
      sortBy,
      statusType,
      reset,
      updateDeleteSubFolderData,
      updateHierarchyData,
      updateOperationType,
      updateSortBy,
      updateStatusType,
      updateVisibilityScope,
      updateVisibilitySelection,
    } = useCombinedStore();
    const { getAllFolderSubFolderList } = useFolderSubFolder();
    const routeMatch = useCustomRouteMatch<{ folderId: string }>();
    const foldersList = useReactiveVar(foldersListVar);
    const chapterItems = useReactiveVar(chaptersListVar);
    const userData = useReactiveVar(userObj);
    const userBranchRoot: any = useReactiveVar(loginUserBranchRootObj);
    const membersData = useReactiveVar(usersEntityObj);
    const { t } = useTranslation(['common', 'chapter']);
    const editFolderRef = useRef<EditFolderRef>(null);
    const dashboardCtx = useContext(DashboardDataContext);
    const viewType =
      dashboardCtx?.navigationPersistData?.knowledgeBase?.selectedViewType;
    const selectedPage =
      dashboardCtx?.navigationPersistData?.knowledgeBase?.selectedPage;
    const selectedFolder =
      dashboardCtx?.navigationPersistData?.knowledgeBase?.selectedFolder;
    const { folderId, subFolderId } = useRouteMatch<{
      folderId?: string;
      subFolderId?: string;
    }>().params;
    const history = useHistory();
    const userObject = useUserData();
    const { folderName } = useFolderSubFolder(folderId);
    const editChapter = useEditChapter();
    const chapterDelete = useChapterDelete();
    const editSubFolder = useSubFolderEdit();
    const moveSingleChapter = useChapterMove();
    const moveSubFolder = useMoveSubFolder();
    const folderSubFolderRestriction = useFolderSubFolderRestriction();
    const [videoModalDetails, setVideoModalDetails] = useState<{
      show: boolean;
      mediaData: ISopMedia[];
    }>({
      show: false,
      mediaData: [],
    });
    const [openNewCategory, setOpenNewCategory] = useState(false);
    const [typeFilter, setTypeFilter] = useState<SelectOption | null>(null);
    useState<ListPropsProps['viewType']>('grid');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUsersCount, setSelectedUsersCount] = useState(0);
    const [showVisibilityModal, setShowVisibilityModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState<
      { type: string; eid: string }[]
    >([]);
    const [height, setHeight] = useState<number>(0);
    const [
      selectedItemVisibilityModalType,
      setSelectedItemVisibilityModalType,
    ] = useState<IFolderVisibilityType>('folder');

    const locationsList = useMemo(() => {
      return userBranchRoot?.descendantBranches || [];
    }, [userBranchRoot]);

    const rolesList = useMemo(() => {
      const roles = userData?.entity?.roles || [];
      let updatedRolesData: IRoleData[] = roles?.map<IRoleData>((role: any) => {
        return {
          ...role,
          label: role?.name,
          value: role?.name,
        };
      });
      return updatedRolesData || [];
    }, [userData?.entity?.roles]);

    const membersList = useMemo(() => {
      return membersData || [];
    }, [membersData]);

    const { execute: fetchFoldersList, loading: gettingFoldersList } =
      getFoldersList();

    useEffect(() => {
      fetchFoldersList();
    }, []);

    useEffect(() => {
      const listItemFolderCopyPress = (folder: ICustomCategoryEntity) => {
        onFolderCopyPress(folder);
      };

      const listItemFolderEditPress = (folder: ICustomCategoryEntity) => {
        onFolderEditPress(folder);
      };

      const listItemFolderVisibilityPress = (data: {
        folder: ICustomCategoryEntity;
        allFoldersList: CustomCategoryEntity[];
      }) => {
        onFolderVisibilityPress(data.folder, data.allFoldersList);
      };

      const chaptersFolderVisibilityCloseModal = () => {
        setShowVisibilityModal(false);
      };

      eventBus.on(
        EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_COPY_PRESS,
        listItemFolderCopyPress
      );
      eventBus.on(
        EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_EDIT_PRESS,
        listItemFolderEditPress
      );
      eventBus.on(
        EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_VISIBILITY_PRESS,
        listItemFolderVisibilityPress
      );
      // eventBus.on(
      //   EventBusEmits.chapterContainer.LIST_ITEM_SUB_FOLDER_CREATED,
      //   onSubFolderCreatedHandler
      // );
      eventBus.on(
        EventBusEmits.chapterContainer.CHAPTER_LIST_GRID_TOGGLE,
        onViewChangeHandler
      );
      eventBus.on(
        EventBusEmits.chapterContainer.CHAPTERS_FOLDER_VISIBILITY_CLOSE_MODAL,
        chaptersFolderVisibilityCloseModal
      );
      // eventBus.on(
      //   EventBusEmits.sidebar.MOVE_TO_CATEGORY_GET_CHAPTERS_LIST,
      //   onGetChaptersListHandler
      // );
      eventBus.on(
        EventBusEmits.sidebar.MOVE_TO_CATEGORY_HISTORY_PUSH,
        onHistoryPush
      );
      // eventBus.on(
      //   EventBusEmits.knowledgeBase.BULK_DELETE_HEADER_GET_CHAPTERS_LIST,
      //   onBulkDeleteFetchChapters
      // );

      return () => {
        eventBus.off(
          EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_COPY_PRESS,
          listItemFolderCopyPress
        );
        eventBus.off(
          EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_EDIT_PRESS,
          listItemFolderEditPress
        );
        eventBus.off(
          EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_VISIBILITY_PRESS,
          listItemFolderVisibilityPress
        );
        // eventBus.off(
        //   EventBusEmits.chapterContainer.LIST_ITEM_SUB_FOLDER_CREATED,
        //   onSubFolderCreatedHandler
        // );
        eventBus.off(
          EventBusEmits.chapterContainer.CHAPTER_LIST_GRID_TOGGLE,
          onViewChangeHandler
        );
        eventBus.off(
          EventBusEmits.chapterContainer.CHAPTERS_FOLDER_VISIBILITY_CLOSE_MODAL,
          chaptersFolderVisibilityCloseModal
        );
        // eventBus.off(
        //   EventBusEmits.sidebar.MOVE_TO_CATEGORY_GET_CHAPTERS_LIST,
        //   onGetChaptersListHandler
        // );
        eventBus.off(
          EventBusEmits.sidebar.MOVE_TO_CATEGORY_HISTORY_PUSH,
          onHistoryPush
        );
        // eventBus.off(
        //   EventBusEmits.knowledgeBase.BULK_DELETE_HEADER_GET_CHAPTERS_LIST,
        //   onBulkDeleteFetchChapters
        // );
      };
    }, []);

    const nameValue = getQueryParamValue('name');
    const statusValue = getQueryParamValue('status');
    const typeValue = getQueryParamValue('type');

    const onHistoryPush = (data: { selectedFolderId: string }) => {
      if (data?.selectedFolderId && data?.selectedFolderId !== folderId) {
        history.push(`/folders/${data?.selectedFolderId}`);
        fetchChaptersList({
          variables: getVariables(),
        });
      } else {
        fetchChaptersList({
          variables: getVariables(),
        });
      }
    };

    const allFoldersList = useMemo(() => {
      return getAllFolderSubFolderList();
    }, [getAllFolderSubFolderList]);

    const {
      execute: fetchParentFolderById,
      data: parentFolderData,
      loading: parentFolderByIdLoading,
    } = getFolderById();

    const onFetchFolderByIdSuccess = (data: CategoryEntity) => {
      updateStatusType('FINAL');
      if (data?.parent) {
        fetchParentFolderById({
          variables: {
            eid: data?.parent,
          },
        });
      }
    };

    const {
      execute: fetchFolderById,
      data: folderData,
      loading: folderByIdLoading,
    } = getFolderById(onFetchFolderByIdSuccess);

    useEffect(() => {
      if (
        folderData?.EntityCategoryById?.eid &&
        parentFolderData?.EntityCategoryById?.eid
      ) {
        const searchParams = new URLSearchParams(history.location.search);
        // Add the new query parameter
        searchParams.set('name', folderData?.EntityCategoryById?.name);
        searchParams.set('type', 'subFolder');
        searchParams.set('status', 'RESTRICT');

        // Append the query parameter without navigating away or re-rendering the entire page
        history.replace({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        });
        updateHierarchyData({
          ...hierarchyData,
          parentFolder: {
            details: {
              ...parentFolderData?.EntityCategoryById,
              type: 'folder',
            },
            visibilityData: {
              locations:
                parentFolderData?.EntityCategoryById?.visibleTo?.locations,
              roles: parentFolderData?.EntityCategoryById?.visibleTo?.roles,
              users: parentFolderData?.EntityCategoryById?.visibleTo?.users,
              ...(parentFolderData?.EntityCategoryById?.visibleTo?.condition
                ? {
                    condition: parentFolderData?.EntityCategoryById?.visibleTo
                      ?.condition as IConditionType,
                  }
                : {}),
              visibility: parentFolderData?.EntityCategoryById?.visibility,
            },
          },
          subFolder: {
            ...hierarchyData,
            details: { ...folderData?.EntityCategoryById, type: 'subFolder' },
            visibilityData: {
              locations: folderData?.EntityCategoryById?.visibleTo?.locations,
              roles: folderData?.EntityCategoryById?.visibleTo?.roles,
              users: folderData?.EntityCategoryById?.visibleTo?.users,
              ...(folderData?.EntityCategoryById?.visibleTo?.condition
                ? {
                    condition: folderData?.EntityCategoryById?.visibleTo
                      ?.condition as IConditionType,
                  }
                : {}),
              visibility: folderData?.EntityCategoryById?.visibility,
            },
          },
        });
      } else if (folderData?.EntityCategoryById?.eid) {
        const searchParams = new URLSearchParams(history.location.search);
        // Add the new query parameter
        searchParams.set('name', folderData?.EntityCategoryById?.name);
        searchParams.set('type', 'folder');
        searchParams.set('status', 'RESTRICT');

        // Append the query parameter without navigating away or re-rendering the entire page
        history.replace({
          pathname: history.location.pathname,
          search: searchParams.toString(),
        });
        updateHierarchyData({
          ...hierarchyData,
          parentFolder: {
            details: {
              ...folderData?.EntityCategoryById,
              type: 'folder',
            },
            visibilityData: {
              locations: folderData?.EntityCategoryById?.visibleTo?.locations,
              roles: folderData?.EntityCategoryById?.visibleTo?.roles,
              users: folderData?.EntityCategoryById?.visibleTo?.users,
              ...(folderData?.EntityCategoryById?.visibleTo?.condition
                ? {
                    condition: folderData?.EntityCategoryById?.visibleTo
                      ?.condition as IConditionType,
                  }
                : {}),
              visibility: folderData?.EntityCategoryById?.visibility,
            },
          },
        });
      }
    }, [folderData, parentFolderData]);

    useEffect(() => {
      if (!statusValue || statusValue !== 'RESTRICT') {
        updateStatusType('INITIAL');
      }
      if (statusType === 'FINAL') {
        return;
      }
      if (statusType === 'MID') {
        if (folderId) {
          fetchFolderById({
            variables: {
              eid: folderId,
            },
          });
        }
        return;
      }
      if (statusType === 'INITIAL') {
        if ((folderId || subFolderId) && foldersList?.length > 0) {
          let flag = false;
          if (folderId && subFolderId) {
            let allFoldersList = getAllFoldersListUtility(foldersList);
            let foundParentFolder = allFoldersList?.some(
              (list) => list?.eid === folderId
            );
            let foundSubFolder = allFoldersList?.some(
              (list) => list?.eid === subFolderId
            );
            flag = foundParentFolder && foundSubFolder;
          } else if (folderId) {
            flag = foldersList?.some((list) => list?.eid === folderId);
          }
          if (!flag) {
            history.push('/folders');
            folderSubFolderRestriction({});
          }
        }
      }
    }, [statusType, folderId, subFolderId, foldersList, statusValue]);

    const perPage = useBreakpointValue({
      base: 12,
      '2xl': 15,
    })!;

    const getVariables = useCallback(() => {
      const filter: Record<string, any> = {
        // status: 'active',
        query: searchQuery,
      };

      let sortValue = match<string, ChapterSort>(filterBy?.value!)
        .with('Alphabetically', () => 'TITLE_ASC')
        .with('Last Created', () => 'CREATEDAT_DESC')
        .with('Last Updated', () => 'LASTUPDATED_DESC')
        .with('Select Filter', () => '_ID_DESC')
        .otherwise(() => '_ID_DESC');

      if (filterBy?.value && filterBy?.value !== 'Select Filter') {
        if (filterBy.value === 'Active') {
          filter.status = 'active';
        } else if (filterBy.value === 'Inactive') {
          filter.status = 'inactive';
        }
      }

      if (subFolderId) {
        filter.categoryId = subFolderId;
      } else if (folderId) {
        filter.categoryId = folderId;
      }

      return {
        page: selectedPage,
        perPage: perPage,
        filter: filter,
        sort: sortBy || sortValue,
      };
    }, [
      searchQuery,
      selectedPage,
      folderId,
      subFolderId,
      sortBy,
      filterBy?.value,
      perPage,
    ]);

    const toast = useToast({
      position: 'top-right',
      duration: 3000,
    });
    const [dataList, setDataList] = useState<ChapterModal[]>([]);
    const [initialDataList, setInitialDataList] = useState<ChapterItem[]>([]);

    useEffect(() => {
      if (
        (chapterItems && chapterItems?.SopPagination?.count > 0) ||
        (foldersList && foldersList?.length > 0)
      ) {
        setSelectedItems([]);
        let translatedDataList = parseFolderChapter(
          foldersList,
          chapterItems,
          locationsList,
          membersList,
          rolesList,
          folderId,
          subFolderId,
          pageInfo,
          typeFilter,
          searchQuery
        );
        setDataList(translatedDataList);
      }
    }, [
      chapterItems,
      foldersList,
      typeFilter,
      searchQuery,
      locationsList,
      membersList,
      rolesList,
    ]);
    const pageInfo = chapterItems?.SopPagination?.pageInfo;

    const [deleteSop] = useMutation(DELETE_SOP, {
      onCompleted: () => {
        toast({
          title: t('chapter:singleChapterDeleteSuccess'),
          status: 'success',
        });
        fetchChaptersList({
          variables: getVariables(),
        });
      },
      onError: () => {
        toast({
          title: t('chapter:singleChapterDeleteError'),
          status: 'error',
        });
        fetchChaptersList({
          variables: getVariables(),
        });
      },
    });

    useEffect(() => {
      fetchChaptersList({
        variables: getVariables(),
      });
    }, [getVariables]);

    useImperativeHandle(
      ref,
      () => {
        return {
          refetch: () => {
            fetchChaptersList({
              variables: getVariables(),
            });
          },
        };
      },
      [getVariables]
    );

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
      deployEvent(AmplitudeEventNames.SEARCH_QUERY);
    };

    const { execute: fetchChaptersList, loading } = getChaptersList();

    const clickedHandler = async (
      clickedItem: string,
      chapter: ChapterModal
    ) => {
      switch (clickedItem) {
        case 'open':
          if (chapter?.type === 'folder') {
            if (statusValue === 'RESTRICT') {
              history.push(
                `/folders/${chapter?.parentFolderId}/${chapter?.eid}?status=RESTRICT`
              );
              return;
            } else {
              deployEvent(CommonAmplitudeEvents.CHAPTER_FOLDER);
              history.push(
                `/folders/${chapter?.parentFolderId}/${chapter?.eid}`
              );
              return;
            }
          }
          deployEvent(CommonAmplitudeEvents.CHAPTER);
          switch (chapter.sopType) {
            // case 'document':
            //   history.push(
            //     `/chapters/view/${chapter.eid}?fileUrl=${
            //       chapter?.fileType?.[0]?.url
            //     }&fileType=${generateExtenstion(chapter?.fileType?.[0]?.url)}`
            //   );
            //   // window.open(
            //   //   `https://docs.google.com/viewer?url=${chapter?.fileType?.[0].url}`,
            //   //   '_blank'
            //   // );
            //   return;
            case 'video':
              deployEvent(AmplitudeEventNames.OPEN_VIDEO_FILE_ON_CLICK);
              setVideoModalDetails({
                show: true,
                mediaData: chapter?.mediaData,
              });
              return;
            default:
              if (statusValue === 'RESTRICT') {
                return history.push(
                  `/chapters/view/${chapter.eid}?status=RESTRICT`
                );
              } else {
                deployEvent(CommonAmplitudeEvents.CHAPTER);
                return history.push(`/chapters/view/${chapter.eid}`);
              }
          }
        case 'edit':
          if (chapter.type === 'folder') {
            deployEvent(AmplitudeEventNames.EDIT_SUB_FOLDER);
            editFolderRef.current?.initialise({
              category: chapter.title,
              categoryId: chapter.eid,
            });
            editFolderRef.current?.folderIconDetails({
              icon: chapter.icon as unknown as string,
              color: chapter.color,
            });
            setOpenNewCategory(true);
            if (chapter?.parentFolderId) {
              editFolderRef?.current?.updateSubFolderData?.({
                isSubFolder: true,
                parentFolderName: chapter?.category,
              });
            } else {
              editFolderRef?.current?.updateSubFolderData?.({
                isSubFolder: false,
                parentFolderName: '',
              });
            }
            return;
          }
          if (chapter.sopType === 'text') {
            updateVisibilityScope('chapter');
            updateOperationType('edit');
            deployEvent(AmplitudeEventNames.EDIT_CHAPTER);
            if (statusValue === 'RESTRICT') {
              return history.push(
                `/edit-chapter/${chapter.eid}?status=RESTRICT`,
                {
                  // @ts-ignore
                  backToTitle: t('common:backToTitle', {
                    value: folderName || t('common:chapters'),
                  }),
                }
              );
            }
            return history.push(`/edit-chapter/${chapter.eid}`, {
              // @ts-ignore
              backToTitle: t('common:backToTitle', {
                value: folderName || t('common:chapters'),
              }),
            });
          }
          updateVisibilityScope('chapter');
          updateOperationType('edit');
          editChapter({
            chapterId: chapter.eid,
            onChapterUpdated: () => {
              fetchChaptersList({
                variables: getVariables(),
              });
            },
          });
          return undefined;
        case 'delete':
          if (chapter.type === 'folder') {
            deployEvent(AmplitudeEventNames.DELETE_SUB_FOLDER);
            updateDeleteSubFolderData({
              ...deleteSubFolderData,
              showConfirmModal: true,
              selectedSubFolderDetails: {
                label: chapter.title,
                value: chapter.eid,
                ...chapter,
              },
            });
            return;
          }
          deployEvent(AmplitudeEventNames.DELETE_CHAPTER);
          chapterDelete({
            trainings: chapter.trainings,
            onDeletePress: () => {
              return deleteSop({
                variables: {
                  eid: chapter.eid,
                },
              });
            },
          });
          return;
        case 'visibility': {
          deployEvent(AmplitudeEventNames.ITEM_VISIBILITY);
          visibilityOperation(
            statusValue,
            reset,
            setShowVisibilityModal,
            updateHierarchyData,
            updateVisibilityScope,
            updateVisibilitySelection,
            getAllFolderSubFolderList(),
            hierarchyData,
            chapter?.type === 'folder' ? 'subFolder' : 'chapter',
            chapter?.parentFolderId,
            chapter?.type === 'folder' ? chapter?.eid : chapter?.categoryId,
            chapter
          );
          updateOperationType('view');
          setSelectedItemVisibilityModalType(
            chapter?.type === 'folder' ? 'subFolder' : 'chapter'
          );
          setShowVisibilityModal(true);
          return;
        }
        case 'copy': {
          deployEvent(AmplitudeEventNames.ITEM_LINK_COPY);
          if (chapter?.type === 'folder' || chapter?.type === 'subFolder') {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_FRONTEND_URL}/folders/${chapter?.parentFolderId}/${chapter?.eid}`
            );
          } else {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_FRONTEND_URL}/chapters/view/${chapter?.eid}`
            );
          }
          toast({
            status: 'success',
            position: 'top-right',
            isClosable: true,
            description: 'Folder link copied to clipboard',
          });
          return;
        }
        case 'move':
          if (chapter?.type === 'folder') {
            deployEvent(AmplitudeEventNames.SUB_FOLDER_MOVE);
            moveSubFolder({
              folderDetails: {
                eid: chapter?.eid,
                name: chapter?.title,
                visibility: chapter?.visibility as IType,
                visibleTo: {
                  locations: chapter?.visibleTo?.locations,
                  roles: chapter?.visibleTo?.roles,
                  users: chapter?.visibleTo?.users,
                  condition: chapter?.visibleTo?.condition,
                },
                getVariables: getVariables,
              },
            });
            return;
          }
          deployEvent(AmplitudeEventNames.CHAPTER_MOVE);
          moveSingleChapter({
            chapterDetails: chapter,
          });
          return;
      }
    };
    const getMenuData = useCallback<IMenuCallback>(
      (menuData) => {
        const menuList: Array<IActionMenuData> = [
          {
            name: t('common:open'),
            value: 'open',
            icon: OpenIcon,
          },
        ];

        if (userObject?.type === 'branch') {
          return menuList;
        }

        if (
          menuData.createdBy?.eid === userObject?.eid ||
          userObject?.authRole === AuthRole.SUPER_ADMIN ||
          userObject?.authRole === AuthRole.ADMIN
        ) {
          menuList.push(
            {
              name: t('common:edit'),
              value: 'edit',
              icon: DarkEditIcon,
            },
            {
              name: 'Move to another folder',
              value: 'move',
              icon: faArrowUpRight,
              normalIcon: 'fontAwesome',
            }
          );
          menuList.push(
            ...[
              {
                name: 'Visibility',
                value: 'visibility',
                icon: faEye,
                normalIcon: 'fontAwesome',
              },
              {
                name: 'Copy Link',
                value: 'copy',
                icon: faCopy,
                normalIcon: 'fontAwesome',
              },
            ]
          );
          // if (menuData.type === 'folder') {
          // }

          menuList.push({
            name: t('common:delete'),
            value: 'delete',
            textColor: '#FF6A55',
            icon: DeleteIcon,
          });
        }

        return menuList;
      },
      [userObject]
    );
    const onViewChangeHandler = (viewType: ListPropsProps['viewType']) => {
      let navigationData = dashboardCtx?.navigationPersistData;
      let knowledgeBaseData =
        dashboardCtx?.navigationPersistData?.knowledgeBase;
      dashboardCtx?.navigationPersistDataHandler({
        ...navigationData,
        knowledgeBase: { ...knowledgeBaseData, selectedViewType: viewType },
      });
      if (viewType === 'grid') {
        updateSortBy(undefined);
      }
    };
    const onPageChangeHandler = (page: number) => {
      let navigationData = dashboardCtx?.navigationPersistData;
      let knowledgeBaseData =
        dashboardCtx?.navigationPersistData?.knowledgeBase;
      dashboardCtx?.navigationPersistDataHandler({
        ...navigationData,
        knowledgeBase: { ...knowledgeBaseData, selectedPage: page },
      });
      //setSelectedPage(page);
    };

    // const onSubFolderCreatedHandler = () => {
    //   fetchChaptersList({
    //     variables: getVariables(),
    //   });
    // };

    const onFolderCopyPress = (folder: ICustomCategoryEntity) => {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_FRONTEND_URL}/folders/${folder?.eid}`
      );
      toast({
        status: 'success',
        position: 'top-right',
        isClosable: true,
        description: 'Folder link copied to clipboard',
      });
    };

    const onFolderEditPress = (folder: ICustomCategoryEntity) => {
      editFolderRef.current?.initialise({
        category: folder.name,
        categoryId: folder.eid,
      });
      editFolderRef.current?.folderIconDetails({
        icon: folder.icon,
        color: folder.color,
      });
      editFolderRef?.current?.updateSubFolderData?.({
        isSubFolder: false,
        parentFolderName: '',
      });
      setOpenNewCategory(true);
    };

    const onFolderVisibilityPress = (
      folder: ICustomCategoryEntity,
      allFoldersList: CustomCategoryEntity[]
    ) => {
      visibilityOperation(
        statusValue,
        reset,
        setShowVisibilityModal,
        updateHierarchyData,
        updateVisibilityScope,
        updateVisibilitySelection,
        allFoldersList,
        hierarchyData,
        'folder',
        folder?.eid,
        undefined,
        undefined
      );
      setSelectedItemVisibilityModalType('folder');
    };

    const checkboxSelectHandler = (
      type: 'single' | 'all',
      selectedValue: boolean,
      selectedId?: string,
      selectedType?: string
    ) => {
      if (type === 'all') {
        if (selectedValue) {
          let addSelectedData = dataList
            ?.filter(
              (list) =>
                userObject?.eid === list?.createdBy?.eid ||
                userObject?.authRole === AuthRole.SUPER_ADMIN
            )
            ?.map((list) => {
              return {
                eid: list?.eid,
                type: list?.type || '',
              };
            });
          // let allIds = allCheckboxSelect(dataList, userObject);
          setSelectedItems(addSelectedData || []);
        } else {
          setSelectedItems([]);
        }
      } else {
        if (!selectedId) return;
        if (selectedValue) {
          let _selectedItems = cloneDeep(selectedItems);
          // add the id to array
          _selectedItems?.push({
            eid: selectedId!,
            type: selectedType!,
          });
          let uniqueData: any[] = [
            ...new Map(
              _selectedItems.map((item) => [item['eid'], item])
            ).values(),
          ];
          setSelectedItems(uniqueData);
        } else {
          // remove selected if from array
          let filteredIds = selectedItems?.filter(
            (item) => item?.eid !== selectedId
          );
          setSelectedItems(filteredIds);
        }
      }
    };

    const getChaptersListHandler = () => {
      fetchChaptersList({
        variables: getVariables(),
      });
    };

    return (
      <ChapterWrapper>
        <ChapterHeader
          viewType={
            dashboardCtx?.navigationPersistData?.knowledgeBase?.selectedViewType
          }
          searchQuery={searchQuery}
          onSearch={onSearchChange}
          sortBy={filterBy}
          typeFilter={typeFilter}
          onTypeFilterChange={(value) => {
            deployEvent(AmplitudeEventNames.SOP_TYPE_DROPDOWN_CLICK);
            setTypeFilter(value);
          }}
          selectedData={selectedItems}
          shouldHideBulkMove={
            gettingFoldersList ||
            folderByIdLoading ||
            parentFolderByIdLoading ||
            loading
          }
          selectedPageNumber={pageInfo?.currentPage}
          getChaptersListHandler={getChaptersListHandler}
          checkboxSelectHandler={checkboxSelectHandler}
        />
        <Flex gap={4} w='full'>
          <Flex w='20%'>
            <FolderList
              height={height}
              loading={
                gettingFoldersList ||
                folderByIdLoading ||
                parentFolderByIdLoading
              }
              foldersList={foldersList || []}
              viewType={viewType}
              getChaptersList={getChaptersListHandler}
            />
          </Flex>
          {viewType === 'list' && (
            <Flex w='80%'>
              <ChapterListView
                // dataList={dataList}
                dataList={dataList}
                loading={loading}
                perPage={perPage}
                currentPage={selectedPage}
                itemCount={pageInfo?.itemCount}
                typeFilter={typeFilter}
                onPageChange={onPageChangeHandler}
                onClickedHandler={clickedHandler}
                setDataList={setDataList}
                initialDataList={initialDataList}
                setInitialDataList={setInitialDataList}
                setSelectedUsersCount={setSelectedUsersCount}
                getMenuData={getMenuData}
                onAddChapterClick={onAddChapterClick}
                checkboxSelectHandler={checkboxSelectHandler}
                selectedItems={selectedItems}
                setHeight={setHeight}
              />
            </Flex>
          )}
          {viewType === 'grid' && (
            <Flex w='80%'>
              <ChapterGridView
                // dataList={dataList}
                dataList={dataList}
                loading={loading}
                perPage={perPage}
                currentPage={selectedPage}
                itemCount={pageInfo?.itemCount}
                typeFilter={typeFilter}
                onPageChange={onPageChangeHandler}
                onClickedHandler={clickedHandler}
                getMenuData={getMenuData}
                onAddChapterClick={onAddChapterClick}
                setHeight={setHeight}
                selectedItems={selectedItems}
                checkboxSelectHandler={checkboxSelectHandler}
              />
            </Flex>
          )}
        </Flex>
        {videoModalDetails?.show && (
          <VideoModal
            media={videoModalDetails?.mediaData}
            onClose={() => setVideoModalDetails({ show: false, mediaData: [] })}
          />
        )}
        {deleteSubFolderData.showConfirmModal && (
          <DeleteSubFolderContainer
            selectedFolder={selectedFolder}
            fetchChapterList={fetchChaptersList}
            getChaptetVariables={getVariables}
          />
        )}
        {/* <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <AddCategory
            ref={addCategoryRef}
            open={openNewCategory}
            onClose={closeHandler}
            type='folder'
          />
        </Authorize> */}
        <EditFolder
          ref={editFolderRef}
          isOpen={openNewCategory}
          onClose={() => setOpenNewCategory(false)}
        />
        {showVisibilityModal && (
          <FolderVisibility getChaptersList={getChaptersListHandler} />
        )}
      </ChapterWrapper>
    );
  }
);

ChapterContainer.displayName = 'Pages/ChapterContainer';

export default ChapterContainer;
