import { cloneDeep } from '@apollo/client/utilities';
import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Button } from 'atoms';
import { ICropImgFilesData } from 'pages/LocationDetails/components/LocationAssetsCard/components/Documents/components/DocumentsUploadComponent';
import React, { FC, useEffect, useState } from 'react';
import Cropper, { Point } from 'react-easy-crop';
import { fileToBase64 } from 'utils';
import { getCroppedImg } from 'utils/image-crop';
import ImageResizeSkeleton from './ImageResizeSkeleton';

const Wrapper = styled.div`
  .cropContainer {
    position: relative;
    width: 100%;
    height: 320px;
    background: #333;
  }

  .cropButton {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .controls {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .sliderContainer {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
    margin-left: 16px;
  }
`;

interface IProps {
  cropImgFilesData: ICropImgFilesData[];
  files: File[];
  loading: boolean;
  onClose: () => void;
  setCropImgFilesData: React.Dispatch<
    React.SetStateAction<ICropImgFilesData[]>
  >;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadImageHandler: () => void;
}

const ImageResizer: FC<IProps> = ({
  cropImgFilesData,
  files,
  loading,
  onClose,
  setCropImgFilesData,
  setFiles,
  uploadImageHandler,
}) => {
  const [isFinished, setIsFinished] = useState(false);
  const [zoomValue, setZoomValue] = useState(1);

  useEffect(() => {
    console.log(cropImgFilesData?.[0]?.zoom);
    setZoomValue(cropImgFilesData?.[0]?.zoom || 1);
  }, []);

  useEffect(() => {
    if (isFinished) {
      let _cropData: ICropImgFilesData[] = JSON.parse(
        JSON.stringify(cropImgFilesData)
      );
      _cropData[0].zoom = zoomValue;
      setCropImgFilesData(_cropData);
    }
  }, [isFinished]);

  const setZoomHandler = (zoom: number) => {
    setZoomValue(zoom);
  };

  const handleChangeStart = () => {
    setIsFinished(false);
  };

  const handleChangeEnd = (value: number) => {
    setIsFinished(true);
  };

  const cropChangeHandler = (location: Point) => {
    let _cropData: ICropImgFilesData[] = cloneDeep(cropImgFilesData);
    _cropData[0].crop = location;
    setCropImgFilesData(_cropData);
  };

  const onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      let _cropData: ICropImgFilesData[] = cloneDeep(cropImgFilesData);
      let _base64File = await fileToBase64(files?.[0]);
      const croppedImage = await getCroppedImg(
        _base64File,
        croppedAreaPixels,
        0,
        undefined,
        files?.[0]?.name,
        files?.[0]?.type
      );
      if (!croppedImage) return;
      _cropData?.forEach((_data, index) => {
        if (index === 0) {
          _data.croppedImageFile = croppedImage;
          _data.croppedAreaPixels = croppedAreaPixels;
        }
      });
      setCropImgFilesData(_cropData);
    } catch (e) {
      console.error(e);
    }
  };

  const onCloseHandler = () => {
    onClose();
    setCropImgFilesData([]);
    setFiles([]);
    setIsFinished(false);
    setZoomValue(1);
  };

  return (
    <Flex w='full'>
      {cropImgFilesData?.length === 0 ? (
        <ImageResizeSkeleton
          setFiles={setFiles}
          setCropImgFilesData={setCropImgFilesData}
        />
      ) : (
        <Flex
          id='image-cropper-1'
          flexDir='column'
          justify='space-between'
          h='full'
          w='full'
        >
          <Flex
            id='image-cropper-2'
            flexDir='column'
            gap='10px'
            w='full'
            h='40vh'
            justify='space-between'
          >
            <Wrapper id='image-cropper-wrapper'>
              <Box className='cropContainer'>
                <Cropper
                  image={cropImgFilesData?.[0]?.imageBase64Url}
                  crop={cropImgFilesData?.[0]?.crop}
                  zoom={cropImgFilesData?.[0]?.zoom}
                  aspect={1}
                  onCropChange={(location: Point) =>
                    cropChangeHandler(location)
                  }
                  onCropComplete={onCropComplete}
                  onZoomChange={(zoom: number) => setZoomHandler(zoom)}
                />
              </Box>
            </Wrapper>
            <Flex flexDir='column'>
              <Text>Image scale</Text>
              <Slider
                aria-label='image-slider'
                value={zoomValue}
                min={1}
                max={5}
                step={0.1}
                onChange={setZoomHandler}
                onChangeStart={handleChangeStart}
                onChangeEnd={handleChangeEnd}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb outline='#686868' border='1px solid black' />
              </Slider>
            </Flex>
            <Flex w='full' justify='space-between' gap={4}>
              <Button
                size='lg'
                variant='outline'
                w='50%'
                onClick={onCloseHandler}
              >
                Cancel
              </Button>
              <Button
                size='lg'
                variant='solid'
                colorScheme='blue'
                bg='rgb(42, 133, 255)'
                w='50%'
                isLoading={loading}
                disabled={loading}
                onClick={uploadImageHandler}
              >
                Upload
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default ImageResizer;
