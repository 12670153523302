import moment from 'moment';
import { LauncherLocationDetailTask } from 'pages/launcher/details/types/location-launch-details.types';

export const getRefactoredLiveDate = (
  liveDate: string,
  tasks: LauncherLocationDetailTask[]
) => {
  if (!liveDate) {
    if (Array.isArray(tasks) && tasks.length > 0) {
      const validDates = tasks
        .map((task) => task?.dueDate)
        .filter(Boolean)
        .map((date) => moment(date))
        .filter((dateMoment) => dateMoment.isValid());

      if (validDates.length > 0) {
        const largestDueDate = moment.max(validDates);
        return largestDueDate.toISOString();
      }
    }
    return moment().toISOString();
  }
  return liveDate;
};

export const commonDurationHandler = (
  refDate: moment.Moment,
  now: moment.Moment
) => {
  const duration = moment.duration(refDate.diff(now));
  let result = '';
  const years = Math.abs(duration.years());
  const months = Math.abs(duration.months());
  const days = Math.abs(duration.days());
  const hours = Math.abs(duration.hours());
  const minutes = Math.abs(duration.minutes());
  const seconds = Math.abs(duration.seconds());

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''} `;
  } else if (months > 0) {
    result += `${months} month${months > 1 ? 's' : ''} `;
  } else if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''} `;
  } else if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''} `;
  } else if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
  } else if (seconds > 0) {
    result += `${seconds} second${minutes > 1 ? 's' : ''} `;
  } else {
    result = '';
  }
  return result;
};

export const durationString = (
  type: 'isFuture' | 'isPast',
  result: string,
  isFuture?: boolean,
  isPast?: boolean
) => {
  if (!result) return '';
  if (type === 'isFuture') {
    return result.trim() + ` ${isFuture ? 'left' : 'passed'}`;
  } else {
    return `${isPast ? 'since' : 'in'} ` + result.trim();
  }
};
