import React, { useEffect, useState } from 'react';
import { Checkbox, Flex, Text, Box } from '@chakra-ui/react';
import { Row } from 'antd';
import { ReactComponent as BackButton } from '../../../assets/images/back.svg';
import { PopoverCloseButton } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import EmptyState from './EmptyState';
import { userObj } from 'sop-commons/src/client';
import { useReactiveVar } from '@apollo/client';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

import './TasksFilter.scss';

type LocationFilterComponentProps = {
  extraFilterData: any;
  sortedLocationArray: any;
  setSortedLocationArray: any;
  setCheckedOptionsData: any;
  checkedOptionsData: any;
};

const LocationFilterComponent = ({
  extraFilterData,
  sortedLocationArray,
  setSortedLocationArray,
  setCheckedOptionsData,
  checkedOptionsData,
}: LocationFilterComponentProps) => {
  const { entity } = useReactiveVar(userObj);
  const [locationList, setLocationList] = useState(entity?.locations);
  const [searchLocationQuery, setSearchLocationQuery] = useState('');

  const { setPageName, loading } = extraFilterData;

  const { checkedLocationList } = checkedOptionsData;

  const searchLocationQueryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchLocationQuery(e?.target?.value);
  };

  const handleLocationSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newCheckedLocationList = [...checkedLocationList];
      newCheckedLocationList.push(e.target.value);
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkedLocationList: newCheckedLocationList,
      });
    } else {
      const newCheckedLocationList = checkedLocationList.filter(
        (item: any) => item !== e.target.value
      );
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkedLocationList: newCheckedLocationList,
      });
    }
  };

  let finalLocationList =
    sortedLocationArray?.length > 0 ? sortedLocationArray : locationList;

  const filteredLocations = finalLocationList.filter((location) =>
    location?.name?.toLowerCase().includes(searchLocationQuery.toLowerCase())
  );

  useEffect(() => {
    const tempArray: any[] = [];
    if (checkedLocationList?.length > 0) {
      locationList.map((location: any) => {
        if (checkedLocationList.includes(location?.eid)) {
          const obj = { ...location, isChecked: true };
          tempArray.push(obj);
        }
      });

      locationList.map((location: any) => {
        if (!checkedLocationList.includes(location?.eid)) {
          const obj = { ...location, isChecked: false };
          tempArray.push(obj);
        }
      });
    }

    setSortedLocationArray(tempArray);
  }, [checkedLocationList]);

  return (
    <>
      <Flex>
        <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
          <Row className='task-filter-tag-container' />
          <Row className='task-filter-title-container'>Filters</Row>
        </Row>
        <Flex gap={'20px'} height={'fit-content'}>
          <Text
            style={{ position: 'relative', top: 5 }}
            fontSize={'15px'}
            color={checkedLocationList?.length > 0 ? '#2A85FF' : '#DDDDDD'}
            cursor={'pointer'}
            onClick={() => {
              deployEvent(CommonAmplitudeEvents.CLEAR_LOCATIONS_FILTER);
              setCheckedOptionsData({
                ...checkedOptionsData,
                checkedLocationList: [],
              });
            }}
          >
            Clear all
          </Text>
        </Flex>
        {/* <PopoverCloseButton
          style={{
            backgroundColor: '#EFEFEF',
            borderRadius: '50%',
            position: 'relative',
          }}
        /> */}
      </Flex>
      <Flex gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          {`Assigned to Locations (${locationList?.length} locations)`}
        </Text>
      </Flex>
      <SearchInput
        className='location-search-field'
        value={searchLocationQuery}
        onChange={searchLocationQueryHandler}
        placeholder='Search by location name'
        hideShortcuts
      />
      {filteredLocations?.length > 0 ? (
        <Box maxHeight={'300px'} overflow={'scroll'}>
          {filteredLocations.map((location: any) => {
            return (
              <Flex
                key={location?.eid}
                gap={3}
                alignItems={'center'}
                padding={'0.5rem 0px'}
              >
                <Checkbox
                  // disabled={loading ? true : false}
                  value={location?.eid}
                  onChange={handleLocationSelection}
                  isChecked={
                    checkedLocationList.includes(location?.eid) ? true : false
                  }
                >
                  <Text>{`${location?.name}`}</Text>
                </Checkbox>
              </Flex>
            );
          })}
        </Box>
      ) : (
        <EmptyState title={'location'} />
      )}
    </>
  );
};

export default LocationFilterComponent;
