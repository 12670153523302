import { gql } from '@apollo/client';

export const ATTACH_LOCATION_CREDENTIALS = gql`
  mutation AttachLocationCredentials(
    $locationId: String!
    $username: String!
    $password: String!
  ) {
    AttachLocationCredentials(
      locationId: $locationId
      username: $username
      password: $password
    ) {
      eid
    }
  }
`;

export const MAKE_PRE_LAUNCH_LOCATION_LIVE = gql`
  mutation MakePreLauncherLocationLive($locationId: String!) {
    MakePreLauncherLocationLive(locationId: $locationId) {
      succeed
    }
  }
`;


export const magicLinkQuery = gql`
  query Query($eid: String!) {
    getMagicLink(eid: $eid)
  }
`;

export const SEND_LOCATION_DETAILS_TO_LOCATION_OWNERS_QUERY = gql`
  mutation SendLocationDetailsToLocationOwners(
    $senderId: String!
    $message: String!
    $receiverIds: [String]
  ) {
    sendDirectMessage(
      senderId: $senderId
      message: $message
      receiverIds: $receiverIds
    ) {
      succeed
    }
  }
`;

export const LOCATION_MEMBERS = gql`
  query LocationMembers($locationIds: [String!], $authRoles: [String]) {
    locationMembers(locationIds: $locationIds, authRoles: $authRoles) {
      members {
        eid
      }
    }
  }
`;