import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
} from '../LocationListContainer';
import React, { FC } from 'react';
import { isDeepEqual } from 'utils/isDeepEqualObjects';

interface IProps {
  data: ICityStateListEntity[];
  finalData: ICityStateListEntity[];
  filterType: IDropdownType;
  label: string;
  fetchData: () => void;
  listRender: (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => React.ReactNode;
}

const BasePopover: FC<IProps> = ({
  data,
  finalData,
  filterType,
  label,
  fetchData,
  listRender,
}) => {
  const onCloseHandler = () => {
    let isEqual = isDeepEqual(data, finalData);
    if (!isEqual) {
      fetchData?.();
    }
  };
  return (
    <Popover onClose={onCloseHandler} placement='bottom'>
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Flex gap={2} align='center' cursor='pointer'>
          <Text as='span'>{label}</Text>
          {data && data?.length > 0 && (
            <FontAwesomeIcon
              icon={faSortDown as IconProp}
              cursor='pointer'
              style={{ marginBottom: '5px' }}
            />
          )}
        </Flex>
      </PopoverTrigger>
      {data && data?.length > 0 && (
        <PopoverContent w='fit-content'>
          <PopoverBody>
            <Flex flexDir='column' gap={2} overflowY='auto' maxH='20vh'>
              {data?.map((type, index) => listRender(type, index, filterType))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default BasePopover;
