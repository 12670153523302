import { existingJsonSchema, preLaunchJsonSchema } from '../schema';
import {
  CellErrorsEntity,
  GroupedErrors,
  JsonEntityWithId,
  Schema,
} from '../types';

export const cellErrors = (
  jsonData: JsonEntityWithId[],
  type: 'existing' | 'new'
) => {
  console.log('json data : ', jsonData);
  let errors: CellErrorsEntity[] = [];
  const readyToProcessData: any[] = [];
  const schema: Schema =
    type === 'existing' ? existingJsonSchema : preLaunchJsonSchema;
  const uniqueValues: Map<string, Set<any>> = new Map();

  jsonData?.map((_data) => {
    console.log('_data : ', _data);
    let doesRowHaveError = false;
    Object.keys(schema).forEach((key) => {
      const field = schema[key].value;
      const value = _data?.data?.[key]?.value;
      console.log({ field, value });
      let errorFound = false;

      // Check for required fields
      if (field.required.flag && (value === undefined || value === '')) {
        errors.push({
          errorMsg: field.required.message,
          processData: { ..._data?.data?.[key], key, _data, schema: field },
        });
        errorFound = true;
        doesRowHaveError = true;
      }

      // Initialize tracking for unique fields
      if (!errorFound && field.unique?.flag) {
        if (!uniqueValues.has(key)) {
          uniqueValues.set(key, new Set());
        }
        if (uniqueValues.get(key)?.has(value)) {
          errors.push({
            errorMsg: field.unique.message,
            processData: { ..._data?.data?.[key], key, _data, schema: field },
          });
          errorFound = true;
          doesRowHaveError = true;
        } else {
          uniqueValues.get(key)?.add(value);
        }
      }

      // Check for pattern validation
      if (!errorFound && field.validate && value) {
        const regex = new RegExp(field.validate.pattern);
        if (!regex.test(value)) {
          errors.push({
            errorMsg: field.validate.message || '',
            processData: { ..._data?.data?.[key], key, _data, schema: field },
          });
          errorFound = true;
          doesRowHaveError = true;
        }
      }

      // Check against predefined values
      if (!errorFound && field.values && value) {
        let preDefinedData = field.values.data.map((_data) => _data.label);
        if (!preDefinedData.includes(value)) {
          errors.push({
            errorMsg: field.values.message,
            processData: { ..._data?.data?.[key], key, _data, schema: field },
          });
          doesRowHaveError = true;
        }
      }
    });
    // if (errors?.length === 0) {
    //   readyToProcessData?.push(_data);
    // }
    if (!doesRowHaveError) {
      readyToProcessData?.push(_data);
    }
  });
  console.log('ERRORS : ', errors);

  const groupedErrors = new Map<string, CellErrorsEntity[]>();

  errors.forEach((error) => {
    const _dataId = error.processData._data.id;
    if (groupedErrors.has(_dataId)) {
      groupedErrors.get(_dataId)?.push(error);
    } else {
      groupedErrors.set(_dataId, [error]);
    }
  });

  // Convert Map to an array of objects
  const groupedErrorsArray: GroupedErrors[] = Array.from(
    groupedErrors,
    ([id, errors]) => ({ id, errors })
  );

  return { groupedErrorsArray, readyToProcessData };
};
