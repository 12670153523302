import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import { PrimaryButton } from 'atoms';
import {
  ADD_EXISTING_LOCATION,
  ADD_LOCATION,
  ADD_PRE_LAUNCH_LOCATION,
  LAUNCHER_ADD_LOCATION,
} from 'appRoutes';
import DashboardContainer from 'sub-components/DashboardContainer';

import { LocationListContainer } from '../Teams/locations';
// import { LocationListContainer } from '../Teams/new-locations';

import { LocationProvider } from '../Teams/new-locations/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocketLaunch } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileImport,
  faListOl,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import { useBulkLocationsUpload } from 'sub-components/BulkLocationsUpload/useBulkLocationsUpload';
import useCombinedStore from 'zustandStore/store';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { Tooltip } from 'antd';
import { useOpenLocationConfirm } from './OpenLocationConfirm/useOpenLocationConfirm';

const Wrapper = styled.div`
  background: white;
  border-radius: 12px;
  padding-block: 24px;
  padding-inline: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .sop-list-loader {
    flex: 1;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

const ListRender: FC<{
  title: string | React.ReactNode;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
}> = ({ title, description, icon, onClick }) => {
  return (
    <Flex
      _hover={{ bg: '#F9F9F9' }}
      cursor='pointer'
      w='full'
      _first={{
        _hover: {
          bg: '#F9F9F9',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        },
      }}
      _last={{
        _hover: {
          bg: '#F9F9F9',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        },
      }}
    >
      <Flex align='center' gap={4} px={6} py={6} onClick={onClick}>
        <Center boxSize='40px' borderRadius='50%' bg='rgba(244, 244, 244, 1)'>
          {icon}
        </Center>
        <Flex flexDir='column'>
          {typeof title === 'string' ? (
            <Text as='span' fontWeight={500} fontSize='15px'>
              {title}
            </Text>
          ) : (
            title
          )}
          <Text as='span' fontSize='12px' color='rgba(111, 118, 126, 1)'>
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Header: FC = () => {
  const history = useHistory();
  const openLocationConfirm = useOpenLocationConfirm();
  const loggedInUserEntity = useUserDataSelector((state) => state.entity);
  const launcherPublished = loggedInUserEntity?.launcher?.published; // Launcher is published
  const isLauncherEnabled = loggedInUserEntity?.features?.launcher; // Launcher is enabled from admin panel

  const { t } = useTranslation(['sidebar', 'team']);

  const bulkLocationsUpload = useBulkLocationsUpload();

  const { emitEvent } = useCombinedStore();

  const onBulkUploadSuccess = () => {
    emitEvent('bulkUploadLocationsModal', 'onBulkUploadSuccess', {
      msg: 'Emitted successfully',
    });
  };

  const onBulkUploadError = () => {
    emitEvent('bulkUploadLocationsModal', 'onBulkUploadError', {
      msg: 'Emitted successfully',
    });
  };

  return (
    <Flex align='center' justify='space-between' mb='20px'>
      <Box fontSize='28px' fontWeight='700' color='#272B30'>
        {t('sidebar:locations')}
      </Box>
      <Flex gap={4}>
        <Authorize permittedRoles={[AuthRole.SUPER_ADMIN]} permittedFor='user'>
          <Menu>
            <Box>
              <Tooltip
                title='Get your current locations open in no time'
                placement='top'
                overlayInnerStyle={{
                  backgroundColor: 'black',
                  borderRadius: '5px',
                  width: '300px',
                }}
              >
                <MenuButton
                  as={Button}
                  leftIcon={<AddIcon color='rgba(42, 133, 255, 1)' />}
                  color='rgba(42, 133, 255, 1)'
                  variant='outline'
                  style={{ backgroundColor: 'white' }}
                  h='48px'
                  _hover={{ backgroundColor: 'white' }}
                >
                  Open location
                </MenuButton>
              </Tooltip>
            </Box>
            <MenuList
              zIndex={'modal'}
              borderRadius='16px'
              boxShadow='0px 5px 14px -12px rgba(0, 0, 0, 0.5);'
              p={0}
            >
              <Flex id='l-p-v-s' flexDir='column' align='flex-start' w='full'>
                <ListRender
                  title='Add an open location'
                  description='Add existing open location seamlessly'
                  icon={<FontAwesomeIcon icon={faLocationDot as IconProp} />}
                  onClick={() => {
                    openLocationConfirm({
                      onOkPress: () => {
                        history.push(ADD_EXISTING_LOCATION);
                      },
                    });
                  }}
                />
                <ListRender
                  title='Import open locations'
                  description='Import your current locations quickly'
                  icon={<FontAwesomeIcon icon={faFileImport as IconProp} />}
                  onClick={() => {
                    openLocationConfirm({
                      title: 'You are uploading chargeable locations',
                      desc: 'Please note that uploading open locations would be chargeable.',
                      onOkPress: () => {
                        bulkLocationsUpload({
                          type: 'existing',
                          onBulkUploadError: onBulkUploadError,
                          onBulkUploadSuccess: onBulkUploadSuccess,
                        });
                      },
                    });
                  }}
                />
              </Flex>
            </MenuList>
          </Menu>
          {/* <PrimaryButton
            leftIcon={<AddIcon />}
            title='New location'
            width={'fit-content'}
            variant='outline'
            style={{ backgroundColor: 'white', border: '1px solid rgba(221, 221, 221, 1)' }}
            colorScheme='blue'
            onClick={() => {
              deployEvent(AmplitudeEventNames.LOCATION_ADD_NEW_LOCATION);
              history.push(ADD_LOCATION);
            }}
          /> */}
        </Authorize>
        <Authorize permittedRoles={[AuthRole.SUPER_ADMIN]} permittedFor='user'>
          <Menu>
            <Box>
              <Tooltip
                placement='top'
                title='Launch or list new locations'
                overlayInnerStyle={{
                  backgroundColor: 'black',
                  borderRadius: '5px',
                }}
              >
                <MenuButton
                  as={Button}
                  leftIcon={<AddIcon color='rgba(42, 133, 255, 1)' />}
                  color='rgba(42, 133, 255, 1)'
                  variant='outline'
                  style={{ backgroundColor: 'white' }}
                  h='48px'
                  _hover={{ backgroundColor: 'white' }}
                >
                  New location
                </MenuButton>
              </Tooltip>
            </Box>
            <MenuList
              zIndex={'modal'}
              borderRadius='16px'
              boxShadow='0px 5px 14px -12px rgba(0, 0, 0, 0.5);'
              p={0}
            >
              <Flex id='l-p-v-s' flexDir='column' align='flex-start' w='full'>
                {isLauncherEnabled && launcherPublished && (
                  <ListRender
                    title='Launch with launcher'
                    description='Launch locations with predefined tasks'
                    icon={<FontAwesomeIcon icon={faRocketLaunch as IconProp} />}
                    onClick={() => {
                      openLocationConfirm({
                        onOkPress: () => {
                          history.push(LAUNCHER_ADD_LOCATION);
                        },
                      });
                    }}
                  />
                )}
                <ListRender
                  title={
                    <Flex align='center' gap='5px'>
                      <Box as='span' fontWeight={500} fontSize='15px'>
                        Add a pre-launch location
                      </Box>
                      <Box
                        fontSize='12px'
                        bg='rgba(181, 228, 202, 1)'
                        p='2px 8px'
                        borderRadius='6px'
                      >
                        Free
                      </Box>
                    </Flex>
                  }
                  description='Locations that are sold but not open'
                  icon={<FontAwesomeIcon icon={faListOl as IconProp} />}
                  onClick={() => history.push(ADD_PRE_LAUNCH_LOCATION)}
                />
                <ListRender
                  title='Import pre-launch locations'
                  description='Easily add multiple pre-launch locations'
                  icon={<FontAwesomeIcon icon={faFileImport as IconProp} />}
                  onClick={() =>
                    bulkLocationsUpload({
                      type: 'new',
                      onBulkUploadError: onBulkUploadError,
                      onBulkUploadSuccess: onBulkUploadSuccess,
                    })
                  }
                />
              </Flex>
            </MenuList>
          </Menu>
          {/* <PrimaryButton
            leftIcon={<AddIcon />}
            title='New location'
            width={'fit-content'}
            variant='outline'
            style={{ backgroundColor: 'white', border: '1px solid rgba(221, 221, 221, 1)' }}
            colorScheme='blue'
            onClick={() => {
              deployEvent(AmplitudeEventNames.LOCATION_ADD_NEW_LOCATION);
              history.push(ADD_LOCATION);
            }}
          /> */}
        </Authorize>
      </Flex>
    </Flex>
  );
};

const LocationPage: FC = () => {
  return (
    <DashboardContainer>
      <Header />
      <Wrapper>
        <LocationProvider>
          <LocationListContainer />
        </LocationProvider>
      </Wrapper>
    </DashboardContainer>
  );
};

export default LocationPage;
