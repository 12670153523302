import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
} from '../LocationListContainer';
import BasePopover from './BasePopover';

interface IProps {
  cityStateList: ICityStateList;
  finalCityStateList: ICityStateList;
  fetchData: () => void;
  listRender: (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => React.ReactNode;
}

const LocationZipCodeHeader: FC<IProps> = ({
  cityStateList,
  finalCityStateList,
  fetchData,
  listRender,
}) => {
  const { t } = useTranslation(['setting']);
  const selectedType = cityStateList?.zipCode?.filter((t) => t.selected);

  const labelRender = () => {
    if (selectedType?.length > 0) {
      // @ts-ignore
      return t('setting:locations_section.zipCode_selected', {
        value: selectedType?.length,
      });
    }
    return t('setting:locations_section.zipCode');
  };

  return (
    <Flex gap='10px' align='center'>
      <BasePopover
        label={labelRender()}
        data={cityStateList?.zipCode}
        finalData={finalCityStateList?.zipCode}
        filterType='zipcode'
        fetchData={fetchData}
        listRender={listRender}
      />
    </Flex>
  );
};

export default LocationZipCodeHeader;
