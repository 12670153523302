export const EXISTING_LOCATION_FILE_HEADERS = [
  { name: 'Location name', required: true },
  { name: 'Location type', required: true },
  { name: 'Address', required: true },
  { name: 'City', required: true },
  { name: 'State', required: true },
  { name: 'Zipcode', required: true },
  { name: 'Email address', required: true },
  { name: 'Phone number', required: true },
  { name: 'Entity name', required: true },
  { name: 'Entity type', required: true },
  { name: 'Country of formation', required: true },
  { name: 'State of formation', required: true },
  { name: 'Tax Payer Id', required: false },
];

export const PRE_LAUNCH_LOCATION_FILE_HEADERS = [
  { name: 'Location name', required: true },
  { name: 'Location type', required: true },
  { name: 'Address', required: true },
  { name: 'City', required: true },
  { name: 'State', required: true },
  { name: 'Zipcode', required: true },
  { name: 'Email address', required: false },
  { name: 'Phone number', required: false },
  { name: 'Entity name', required: false },
  { name: 'Entity type', required: false },
  { name: 'Country of formation', required: false },
  { name: 'State of formation', required: false },
  { name: 'Tax Payer Id', required: false },
];
