import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';

interface IProps {
  status: string;
}

const LocationStatus: FC<IProps> = ({ status }) => {
  const { t } = useTranslation('common');
  return (
    <Flex whiteSpace='pre-wrap'>
      <Box
        as={'span'}
        fontSize='12px'
        fontWeight='600'
        p='5px 16px'
        borderRadius='6px'
        bg={
          status === 'open'
            ? 'rgba(181, 228, 202, 0.3)'
            : status === 'development'
            ? 'rgba(255, 216, 141, 0.4)'
            : 'rgba(244, 244, 244, 1)'
        }
        color={
          status === 'open'
            ? '#83BF6E'
            : status === 'development'
            ? 'rgba(227, 152, 6, 1)'
            : 'rgba(51, 56, 63, 1)'
        }
      >
        {/*// @ts-ignore */}
        {/* {t(status)} */}
        {status === 'open'
          ? 'Open'
          : status === 'development'
          ? 'Launching'
          : 'Pre-launch'}
      </Box>
    </Flex>
  );
};

export default LocationStatus;
