import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
} from '../LocationListContainer';
import BasePopover from './BasePopover';

interface IProps {
  cityStateList: ICityStateList;
  finalCityStateList: ICityStateList;
  fetchData: () => void;
  listRender: (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => React.ReactNode;
}

const LocationState: FC<IProps> = ({
  cityStateList,
  finalCityStateList,
  fetchData,
  listRender,
}) => {
  const { t } = useTranslation(['location', 'setting']);
  const selectedType = cityStateList?.state?.filter((t) => t.selected);

  const labelRender = () => {
    if (selectedType?.length > 0) {
      // @ts-ignore
      return t('setting:locations_section.state_selected', {
        value: selectedType?.length,
      });
    }
    return t('setting:locations_section.state');
  };

  return (
    <Flex gap='10px' align='center'>
      <BasePopover
        data={cityStateList?.state}
        finalData={finalCityStateList?.state}
        filterType='state'
        label={labelRender()}
        fetchData={fetchData}
        listRender={listRender}
      />
    </Flex>
  );
};

export default LocationState;
