import { Flex, List, ListItem } from '@chakra-ui/react';
import MyTaskItem from 'pages/TasksDashboard/Components/MyTaskItem';
import { taskTypeCount } from 'pages/TasksDashboard/MyTasks/functions/taskTypeCount';
import {
  IMyTask,
  ITaskListData,
  ITaskOverview,
} from 'pages/TasksDashboard/tasks.types';
import React, { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

const TodayTasksComponent: FC<{
  data: IMyTask | undefined;
  taskOverviewData: ITaskOverview[];
  taskData: ITaskListData[];
}> = ({ data, taskData, taskOverviewData }) => {
  return taskTypeCount(taskOverviewData, 'dueToday') > 0 ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title="Today's tasks" color='#CABDFF' />
      <Flex flexDir='column'>
        <List spacing={2}>
          {taskData?.map((task, index) => {
            return (
              task?.type === 'dueToday' &&
              task?.data?.map((_data, index) => (
                <ListItem
                  onClick={() => {
                    deployEvent(CommonAmplitudeEvents.VIEW_MY_TASK);
                  }}
                  key={index}
                  bg='white'
                  p={2}
                  borderRadius='8px'
                  _hover={{ bg: '#F9F9F9' }}
                >
                  <MyTaskItem task={_data} />
                </ListItem>
              ))
            );
          })}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

export default TodayTasksComponent;
