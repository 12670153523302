import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ILocation } from 'sub-components/Locations/locationTypes/location-types';
import ActionButton from './ActionButton';
import BannerIcon from './BannerIcon';
import Description from './Description';
import FooterMessage from './FooterMessage';
import Stepper from './Stepper';

interface IProps {
  locationDetails: ILocation;
  launchHandler: () => void;
  openHandler: () => void;
}

const PreLaunchContent: FC<IProps> = ({
  locationDetails,
  launchHandler,
  openHandler,
}) => {
  return (
    <Flex gap={8} flexDir='column' px={6} py={4}>
      <Stepper />
      <Description />
      <BannerIcon />
      <ActionButton launchHandler={launchHandler} />
      <FooterMessage openHandler={openHandler} />
    </Flex>
  );
};

export default PreLaunchContent;
