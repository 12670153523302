import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Flex, Box, AlertDialogCloseButton } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { ILocation } from 'sub-components/Locations/locationTypes/location-types';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import useCombinedStore from 'zustandStore/store';
import PreLaunchContent from '.';

interface IProps {
  locationDetails: ILocation;
  launchHandler: () => void;
  openHandler: () => void;
}

type PreLaunchLocation = (props: IProps) => void;

export const usePreLaunchLocation = (): PreLaunchLocation => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const launchViaLauncher = useCombinedStore(
    (state) => state.events['preLaunchLocationModal']['goToLauncher']
  );

  useEffect(() => {
    if (launchViaLauncher) {
      confirmRef.current?.destroy();
    }
  }, [launchViaLauncher]);

  return useCallback(({ locationDetails, launchHandler, openHandler }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader
              title={`Launch ${locationDetails?.name}`}
              color={HeaderColors.Green}
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <PreLaunchContent
          locationDetails={locationDetails}
          launchHandler={() => {
            confirmRef.current?.destroy();
            launchHandler();
          }}
          openHandler={() => {
            confirmRef.current?.destroy();
            openHandler();
          }}
        />
      ),
      size: '3xl',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
      okText: null,
      onOK: undefined,
      cancelText: null,
    });
  }, []);
};
