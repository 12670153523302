import React, { CSSProperties, FC } from 'react';
import { UserAnalyticsOverviewType } from 'sub-components/AnalyticsBoard/AnalyticsBoard';
import { Text } from '@chakra-ui/react';
import ParentWrapper from './ParentWrapper';
import { wrapTextWithSpan } from '../NumericStats';
import { useUserData } from 'hooks';
import { AuthRole } from 'authorization';
import { shouldRender } from 'sub-components/AnalyticsBoard/helper';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';

interface IProps {
  style?: CSSProperties;
  analyticsData: UserAnalyticsOverviewType['userAnalyticsOverview'] | undefined;
}

const TasksCompletedSection: FC<IProps> = ({ style, analyticsData }) => {
  const userData = useUserData();

  const renderText = () => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER]?.includes(
        userData?.authRole
      ) &&
      userData?.type === 'user'
    ) {
      return analyticsData?.tasks?.complete! > 0 ? (
        <>
          {wrapTextWithSpan('Members from all your locations have completed')}
          <Text fontWeight={700} color='#2A85FF'>
            {analyticsData?.tasks?.complete}
          </Text>
          <Text>{analyticsData?.tasks?.complete === 1 ? 'task' : 'tasks'}</Text>
        </>
      ) : (
        <>
          {wrapTextWithSpan('Click here to')}
          <Text fontWeight={700} color='#2A85FF'>
            create
          </Text>
          <Text>tasks</Text>
        </>
      );
    } else {
      return (
        <>
          {wrapTextWithSpan('You have completed')}
          <Text fontWeight={700} color='#2A85FF'>
            {analyticsData?.tasks?.complete}
          </Text>
          {wrapTextWithSpan(
            `${
              analyticsData?.tasks?.complete === 1 ? 'task' : 'tasks'
            } since you joined.`
          )}
        </>
      );
    }
  };

  const redirectText = () => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER]?.includes(
        userData?.authRole
      )
    ) {
      if (analyticsData?.tasks?.complete! > 0) {
        return '/tasks/supervised';
      } else {
        return '/tasks/create-task';
      }
    } else {
      return '/tasks/my-tasks';
    }
  };

  const compRender = () => {
    let flag = shouldRender(analyticsData?.tasks?.complete || 0);
    if (flag) {
      return (
        <ParentWrapper
          title='Tasks completed'
          totalCount={(analyticsData?.tasks?.complete || 0) + ''}
          style={style}
          redirectPath={redirectText()}
          backToTitle='Back to Home'
          event={CommonAmplitudeEvents.TASK_COMPLETED_CARD}
        >
          {renderText()}
        </ParentWrapper>
      );
    } else {
      return null;
    }
  };

  return compRender();
};

export default TasksCompletedSection;
