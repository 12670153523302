import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompareVisibilityType } from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { faCircleCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { IDetailedVisibility } from '../helper/useVisibilityStatusInfoLogic';

interface IProps {
  status: ICompareVisibilityType;
  childDetails: IDetailedVisibility | undefined;
  parentDetails: IDetailedVisibility | undefined;
  chapterName?: string;
  parentFolderName?: string;
}

const TypeChapter: FC<IProps> = ({
  status,
  childDetails,
  chapterName,
  parentDetails,
  parentFolderName,
}) => {
  const renderHandler = () => {
    if (parentDetails) {
      if (
        parentDetails?.locations?.length > 0 &&
        parentDetails?.roles?.length > 0 &&
        parentDetails?.users?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Text>1.</Text>
              <Flex flexWrap='wrap' align='center'>
                People who have role{' '}
                <Text fontWeight={700} mx='5px'>
                  {parentDetails?.roles?.join(' ')}
                </Text>{' '}
                and are in location{' '}
                <Text fontWeight={700} mx='5px'>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>
              </Flex>
            </Flex>
            <Flex gap='5px'>
              <Text>2.</Text>
              <Text fontWeight={700}>
                {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
              </Text>
            </Flex>
          </Flex>
        );
      } else if (
        parentDetails?.locations?.length > 0 &&
        parentDetails?.roles?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Flex flexWrap='wrap' align='center'>
                People who have role{' '}
                <Text fontWeight={700} mx='5px'>
                  {parentDetails?.roles?.join(' ')}
                </Text>{' '}
                and are in location{' '}
                <Text fontWeight={700} mx='5px'>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      } else if (
        parentDetails?.locations?.length > 0 &&
        parentDetails?.users?.length > 0
      ) {
        <Flex flexDir='column'>
          <Flex gap='5px'>
            <Text>1.</Text>
            <Flex flexWrap='wrap' align='center'>
              People who are in locations&nbsp;
              <Text fontWeight={700} mx='5px'>
                {parentDetails?.locations?.join(' ')}
              </Text>{' '}
            </Flex>
          </Flex>
          <Flex gap='5px'>
            <Text>2.</Text>
            <Text fontWeight={700}>
              {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
            </Text>
          </Flex>
        </Flex>;
      } else if (parentDetails?.locations?.length > 0) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Flex flexWrap='wrap' align='center'>
                People who are in locations&nbsp;
                <Text fontWeight={700}>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>{' '}
              </Flex>
            </Flex>
          </Flex>
        );
      } else if (
        parentDetails?.roles?.length > 0 &&
        parentDetails?.users?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Text>1.</Text>
              <Flex flexWrap='wrap' align='center'>
                People who have role&nbsp;
                {parentDetails?.roles?.join(' ')}
              </Flex>
            </Flex>
            <Flex gap='5px'>
              <Text>2.</Text>
              <Text fontWeight={700}>
                {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
              </Text>
            </Flex>
          </Flex>
        );
      } else if (parentDetails?.roles?.length > 0) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Flex flexWrap='wrap' align='center'>
                People who have role&nbsp;
                {parentDetails?.roles?.join(' ')}
              </Flex>
            </Flex>
          </Flex>
        );
      } else if (parentDetails?.users?.length > 0) {
        <Flex gap='5px'>
          <Text fontWeight={700}>
            {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
          </Text>
        </Flex>;
      }
    }
  };

  switch (status) {
    case 'parentChildSameLengthDifferentVisibility':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          w='full'
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          This chapter {chapterName ? `(${chapterName})` : null} is visible to
          more members than those mentioned above, as defined in the parent
          folder. Since the parent folder{' '}
          {parentFolderName ? `(${parentFolderName})` : null}
          is not visible to all those members, they will find the chapter via
          Search.
        </Flex>
      );
    case 'parentChildSameVisibleSameLength':
    case 'parentPublicChildPublic':
      return (
        <Flex
          bg='#B5E4CA99'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          w='full'
        >
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            style={{ marginTop: '5px' }}
          />
          <Text>
            The parent folder{' '}
            {parentFolderName ? `(${parentFolderName})` : null} of this chapter{' '}
            {chapterName ? `(${chapterName})` : null} shares the same visibility
            as mentioned above.
          </Text>
        </Flex>
      );
    case 'parentLessVisibleThanChild':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          w='full'
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Flex flexDir='column'>
            <Flex flexWrap='wrap' align='center'>
              <Text m={0} p={0} mr='5px'>
                The parent folder{' '}
                {parentFolderName ? `(${parentFolderName})` : null} is visible
                to less members than those mentioned above. Therefore
              </Text>
              <Text fontWeight={700} mr='5px' p={0}>
                {chapterName ? chapterName : 'Chapter'}
              </Text>
              <Text m={0} p={0}>
                will only be visible in the knowledge base to -
              </Text>
            </Flex>
            <Flex flexDir='column'>{renderHandler()}</Flex>
            <Flex>
              <Text>Everyone else will find it via search.</Text>
            </Flex>
          </Flex>
        </Flex>
      );
    case 'parentMoreVisibleThanChild':
      return (
        <Flex
          bg='#B5E4CA99'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          w='full'
        >
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            style={{ marginTop: '5px' }}
          />
          <Flex flexDir='column'>
            <Text>
              The parent folder{' '}
              {parentFolderName ? `(${parentFolderName})` : null} is visible to
              more members than those mentioned above.
            </Text>
            <div style={{ width: '100%' }}>
              <span>Therefore,</span>
              <span style={{ fontWeight: '700', margin: '0px 5px' }}>
                {chapterName ? chapterName : 'Chapter'}
              </span>
              will be visible to everyone mentioned above.
            </div>
          </Flex>
        </Flex>
      );
    case 'parentChildNoIntersection':
      return (
        <Flex
          bg='#FF6A5533'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          w='full'
        >
          <FontAwesomeIcon icon={faXmark as IconProp} color='#FF6A55' />
          Visibility of parent folder of this chapter is limited. Thus folder{' '}
          {parentFolderName ? `(${parentFolderName})` : null} will be not be
          visible to anyone for above visibility settings
        </Flex>
      );
    default:
      return (
        <Flex
          bg='#FF6A5533'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          w='full'
        >
          <FontAwesomeIcon icon={faXmark as IconProp} color='#FF6A55' />
          Visibility of parent folder of this chapter is limited. Thus folder{' '}
          {parentFolderName ? `(${parentFolderName})` : null} will be not be
          visible to anyone for above visibility settings
        </Flex>
      );
  }
};

export default TypeChapter;
