import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { Button } from 'atoms';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { ErrorsObj, IBulkOperations } from './types';

interface IProps {
  bulkOperations: IBulkOperations;
  type: 'existing' | 'new';
  uploadingPreLaunchLocations?: boolean;
  fixErrorsHandler: () => void;
  setupLocationsHandler: () => void;
  onFileDrop: (fileLists: File[]) => void;
}

const ActionButtons: FC<IProps> = ({
  bulkOperations,
  type,
  uploadingPreLaunchLocations,
  fixErrorsHandler,
  onFileDrop,
  setupLocationsHandler,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: undefined,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });
  const { errors } = bulkOperations;
  if (
    errors?.cellErrors?.length === 0 &&
    errors?.fileUploadErrors?.length === 0 &&
    errors?.headerErrors?.length === 0
  ) {
    return (
      <Flex w='full'>
        <Button
          variant='solid'
          colorScheme='blue'
          size='lg'
          w='full'
          onClick={setupLocationsHandler}
          isLoading={uploadingPreLaunchLocations}
          disabled={uploadingPreLaunchLocations}
        >
          {type === 'existing'
            ? 'Add open locations'
            : 'Import pre-launch locations'}
        </Button>
      </Flex>
    );
  }

  if (
    errors?.headerErrors?.length > 0 ||
    errors?.fileUploadErrors?.length > 0
  ) {
    return (
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Center
          bg='rgba(42, 133, 255, 1)'
          borderRadius='7px'
          color='white'
          p='10px 12px'
          fontWeight={600}
          cursor='pointer'
          onClick={open}
        >
          <Text>Replace file</Text>
        </Center>
      </Box>
    );
  }

  if (
    errors?.cellErrors?.length > 0 &&
    bulkOperations?.readyToProcessJson?.length > 0
  ) {
    return (
      <Flex justify='space-between' gap={4}>
        <Button
          variant='outline'
          size='lg'
          w='50%'
          disabled={uploadingPreLaunchLocations}
          isLoading={uploadingPreLaunchLocations}
          onClick={setupLocationsHandler}
        >
          Import {bulkOperations?.readyToProcessJson?.length} location
          {bulkOperations?.readyToProcessJson?.length === 1 ? '' : 's'}
        </Button>
        <Button
          variant='solid'
          colorScheme='blue'
          size='lg'
          w='50%'
          onClick={fixErrorsHandler}
        >
          Fix errors
        </Button>
      </Flex>
    );
  }

  if (errors?.cellErrors?.length > 0) {
    return (
      <Button
        variant='solid'
        colorScheme='blue'
        size='lg'
        w='full'
        onClick={fixErrorsHandler}
      >
        Fix errors
      </Button>
    );
  }

  return (
    <Flex w='full'>
      <Button
        variant='solid'
        colorScheme='blue'
        size='lg'
        w='full'
        disabled={uploadingPreLaunchLocations}
        isLoading={uploadingPreLaunchLocations}
        onClick={setupLocationsHandler}
      >
        Add open locations
      </Button>
    </Flex>
  );
};

export default ActionButtons;
