import React, { FC, useState } from 'react';

import {
  AmplitudeEventNames,
  deployEvent,
  CommonAmplitudeEvents,
} from 'shared/amplitudeEvents';

import TrackingContainer from './TrackingContainer';
import MyTrainingContainer from './MyTrainingContainer';
import { TrainingWrapper } from './training.styles';
import TrainingTabs, { TrainingTabValue } from './TrainingTabs';
import { AuthRole, useAuthorization } from '../../../authorization';

interface IProps {}

const TrainingContainer: FC<IProps> = () => {
  const authorization = useAuthorization({
    permittedFor: ['user'],
  });
  const [value, setValue] = useState<TrainingTabValue>(() => {
    return authorization(
      {
        permittedRoles: [
          AuthRole.LOCATION_OWNER,
          AuthRole.ADMIN,
          AuthRole.SUPER_ADMIN,
        ],
      },
      'tracking',
      'myTrainings'
    ) as TrainingTabValue;
  });

  const onTabClick = (newValue: TrainingTabValue) => {
    setValue(newValue);
    switch (newValue) {
      case 'myTrainings':
        deployEvent(CommonAmplitudeEvents.MY_TRAINING_TAB);
        break;
      case 'tracking':
        deployEvent(CommonAmplitudeEvents.TRACKING_PATH_TAB);
        break;
    }
  };

  return (
    <TrainingWrapper>
      <TrainingTabs value={value} onClick={onTabClick} />
      {value === 'tracking' && <TrackingContainer />}
      {value === 'myTrainings' && <MyTrainingContainer />}
    </TrainingWrapper>
  );
};

export default TrainingContainer;
