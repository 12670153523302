import { ApolloProvider, useApolloClient } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import { useConfirm, UseConfirm } from 'ui-components';
import useCombinedStore from 'zustandStore/store';
import ModalContent from '.';

interface IProps {
  type: 'existing' | 'new';
  onBulkUploadSuccess: () => void;
  onBulkUploadError: () => void;
}

type BulkLocationsUpload = (props: IProps) => void;

export const useBulkLocationsUpload = (): BulkLocationsUpload => {
  const initRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const launchViaLauncher = useCombinedStore(
    (state) => state.events['preLaunchLocationModal']['goToLauncher']
  );

  useEffect(() => {
    if (launchViaLauncher) {
      initRef.current?.destroy();
    }
  }, [launchViaLauncher]);

  const init = useCallback(
    ({ type, onBulkUploadError, onBulkUploadSuccess }) => {
      if (initRef.current) {
        initRef.current.destroy();
      }

      const onActionErrorHandler = () => {
        initRef?.current?.destroy();
        onBulkUploadError();
      };

      const onActionSuccessHandler = () => {
        initRef?.current?.destroy();
        onBulkUploadSuccess();
      };

      initRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        content: (
          <ModalContent
            type={type}
            onActionError={onActionErrorHandler}
            onActionSuccess={onActionSuccessHandler}
          />
        ),
        size: '3xl',
        isCentered: false,
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '16px',
        },
        footer: null,
        okText: null,
        onOK: undefined,
        cancelText: null,
      });
    },
    []
  );
  return init;
};
