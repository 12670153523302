import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Center, Flex, Text, useOutsideClick } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useReactiveVar } from '@apollo/client';
import { AuthRole, LocationsEntity, userObj } from 'sop-commons/src/client';
import { faAngleDown, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import useCombinedStore from 'zustandStore/store';

const LocationsSelect: FC = () => {
  const { updateSelectedInProgressLocation } = useCombinedStore();
  const userData = useReactiveVar(userObj);
  const dashboardCtx = useContext(DashboardDataContext);
  const ref = React.useRef<HTMLDivElement>(null);
  const assignedLocationsToUser = userData?.locations;
  const [selectedLocation, setSelectedLocation] = useState<
    LocationsEntity | undefined
  >(undefined);
  const [showList, setShowList] = useState(false);

  useOutsideClick({
    ref: ref,
    handler: () => setShowList(false),
  });

  const checkFlag = (location: LocationsEntity | undefined) => {
    let flag = false;
    let launcherFeatureFlagEnabled = userData?.entity?.features?.launcher;
    let launchId = location?.launchId;
    let launcher = userData?.entity?.launcher;
    // let locationStatus = location?.locationStatus;
    let locationOnboarded = location?.onboarded;
    if (launcherFeatureFlagEnabled) {
      // That means Business has access to Launcher
      if (launchId) {
        // Selected location is launched from launcher
        if (locationOnboarded) {
          // Location is onboarded, means it is live, so will be seeing everything
          flag = false;
        } else {
          // Location is not onboarded, means it is not live and is still in review, so will see restricted view
          flag = true;
        }
      } else {
        // Location is not launched from launcher, but normal process to add location
        flag = false;
      }
    } else {
      // That means Business has no access to Launcher, will see normal view
      flag = false;
    }
    return flag;
  };

  useEffect(() => {
    setSelectedLocation(assignedLocationsToUser?.[0]);
    updateSelectedInProgressLocation(assignedLocationsToUser?.[0]);
    let flag = checkFlag(assignedLocationsToUser?.[0]);
    dashboardCtx?.setIsLauncherInProgress(flag);
    // if (userData?.entity?.features?.launcher) {
    //   if (!assignedLocationsToUser?.[0]?.onboarded) {
    //     dashboardCtx?.setIsLauncherInProgress(true);
    //   } else {
    //     dashboardCtx?.setIsLauncherInProgress(false);
    //   }
    // } else {
    //   dashboardCtx?.setIsLauncherInProgress(true);
    // }
  }, [assignedLocationsToUser, userData?.entity?.features?.launcher]);

  const onChangeHandler = (loc: LocationsEntity) => {
    console.log('loc : ', loc);
    setSelectedLocation(loc);
    let flag = checkFlag(loc);
    dashboardCtx?.setIsLauncherInProgress(flag);
    updateSelectedInProgressLocation(loc);
    // if (!loc?.onboarded) {
    //   dashboardCtx?.setIsLauncherInProgress(true);
    // } else {
    //   dashboardCtx?.setIsLauncherInProgress(false);
    // }
  };

  if (userData?.authRole === AuthRole.LOCATION_OWNER) {
    return (
      <Flex w='500px' justify='flex-end'>
        <Flex position='relative' ref={ref}>
          <Flex
            align='center'
            gap={1}
            w='fit-content'
            onClick={() => setShowList(true)}
            justify='flex-end'
          >
            <FontAwesomeIcon
              icon={faLocationDot as IconProp}
              color='#6F767E'
              fontSize='16px'
            />
            {/* <Text color='#6F767E'>Current location:</Text> */}
            <Text color='#272B30' fontWeight={600} isTruncated maxW='150px'>
              {selectedLocation?.name}
            </Text>
            <FontAwesomeIcon icon={faAngleDown as IconProp} color='#272B30' />
          </Flex>
          {showList && (
            <Flex
              position='absolute'
              borderRadius='16px'
              top='40px'
              right='-90px'
              w='250px'
              bg='white'
              flexDir='column'
              zIndex={2}
              overflowY='auto'
              maxH='300px'
              boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'
            >
              {assignedLocationsToUser?.map((loc) => (
                <Flex
                  key={loc?.eid}
                  align='center'
                  _hover={{ bg: '#2A85FF1A' }}
                  w='full'
                  cursor='pointer'
                  p='10px 12px'
                  borderRadius='16px'
                  justify='space-between'
                  onClick={() => onChangeHandler(loc)}
                >
                  <Flex align='center' gap='10px'>
                    <Center
                      boxSize='40px'
                      bg='#F4F4F4'
                      borderRadius='50%'
                      p='8px'
                    >
                      <FontAwesomeIcon
                        icon={faLocationDot as IconProp}
                        color='#6F767E'
                        fontSize='18px'
                      />
                    </Center>
                    <Flex flexDir='column'>
                      <Text isTruncated maxW='170px'>
                        {loc?.name}
                      </Text>
                      {loc?.onboarded ? (
                        <Flex gap='5px' align='center'>
                          <Box borderRadius='50%' bg='#83BF6E' boxSize='6px' />
                          <Text color='#83BF6E' fontSize='12px'>
                            Live now
                          </Text>
                        </Flex>
                      ) : (
                        <Text color='#999999' fontSize='12px'>
                          In Progress
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                  {selectedLocation?.eid === loc?.eid && (
                    <FontAwesomeIcon
                      icon={faCircleCheck as IconProp}
                      color='#2A85FF'
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  } else {
    return null;
  }
};

export default LocationsSelect;
