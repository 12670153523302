import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { BoxHeader } from 'ui-components';

import { FormInput, PrimaryButton } from 'atoms';

import { IFormInput } from '../AddLocation/add-location.types';
import { usePasswordChange } from '../../Profile/TabComponents/InfoComponent/password-change';
import { useLocation } from 'react-router-dom';

interface IProps {
  locationId?: string;
}

const UpdateLoginDetails: FC<IProps> = ({ locationId }) => {
  const { t } = useTranslation(['common', 'auth', 'location', 'profile']);
  const { control, getValues } = useFormContext<IFormInput>();

  const passwordChange = usePasswordChange();

  const isHQLocation = useWatch<IFormInput, 'isRoot'>({
    name: 'isRoot',
  });

  const _onUpdatePassword = () => {
    passwordChange({
      userId: locationId!,
      name: getValues('locationName'),
    });
  };

  if (
    isHQLocation ||
    ['development', 'preLaunch']?.includes(getValues('locationStatus.value'))
  ) {
    return null;
  }

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='18px'
        color='#B1E5FC'
        title={t('location:login_details')}
      />

      <Flex gap='20px'>
        <Controller
          control={control}
          name='username'
          rules={{
            required: t('location:validation.name_required'),
            pattern: {
              value: /^[a-zA-Z_0-9]+$/,
              message: t('location:validation.name_invalid'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl flex={1} isInvalid={!!fieldState.error}>
                <TitleHeader title={t('location:locationUserName')} />
                <FormInput mt={2} size='lg' {...field} />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Box flex={2} pt='32px'>
          <PrimaryButton
            width='auto'
            size='lg'
            title={t('profile:updatePassword')}
            variant='outline'
            onClick={_onUpdatePassword}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default UpdateLoginDetails;
