import React, { useMemo, useState } from 'react';
import {
  ErrorsObj,
  GroupedErrors,
  IBulkOperations,
  JsonEntityWithId,
} from './types';
import addFormats from 'ajv-formats';
import addErrors from 'ajv-errors';
import Ajv from 'ajv';
import { cellErrors as cellErrorsFn } from './helper/cellErrors';
import { headerErrors as headerErrorsFn } from './helper/headerErrors';
import { fileToJsonParse } from './helper/fileToJsonParse';

export const useBulkFileOperation = (
  type: 'existing' | 'new'
): IBulkOperations => {
  const [fileJsonData, setFileJsonData] = useState<JsonEntityWithId[]>([]);
  const [cellErrors, setCellErrors] = useState<GroupedErrors[]>([]);
  const [readyToProcessJson, setReadyToProcessJson] = useState<any[]>([]);
  const [fileUploadErrors, setFileUploadErrors] = useState<string[]>([]);
  const [headerErrors, setHeaderErrors] = useState<string[]>([]);
  const [file, setFile] = useState<File | undefined>(undefined);
  const ajv = new Ajv({
    allErrors: true,
    ownProperties: true,
    $data: true,
    verbose: true,
  });
  addFormats(ajv, {
    mode: 'full',
  });
  addErrors(ajv, {
    keepErrors: true,
    singleError: true,
  });

  const resetErrors = () => {
    setCellErrors([]);
    setHeaderErrors([]);
    setFileUploadErrors([]);
    setFileJsonData([]);
    setReadyToProcessJson([]);
    setFile(undefined);
  };

  const onFileDrop = (fileLists: File[]) => {
    resetErrors();
    const newFile = fileLists?.[0];
    const extension = newFile?.name?.split?.('.')?.pop?.()?.toLowerCase?.();
    setFile(newFile);
    let _errors: string[] = [];
    if (
      ![
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'xlsx',
        'xls',
        'csv',
        'text/csv',
      ]?.includes(newFile?.type)
    ) {
      _errors.push(
        extension
          ? `Unsupported file format. Trying to import an Excel file in .${extension} format when the tool only supports .csv, .xlsx, .xls`
          : 'Unsupported file format. Tool only supports .xlsx'
      );
    } else if (newFile && newFile.size > 10 * 1024 * 1024) {
      _errors.push(
        'File size too large. The file exceeds the maximum allowed size of 10MB'
      );
    }
    if (_errors?.length > 0) {
      setFileUploadErrors(_errors);
    } else {
      handleConvert(newFile);
    }
  };

  const fileSize = useMemo(() => {
    const units = ['bytes', 'KB', 'MB', 'GB'];

    if (!file?.size) {
      return 0;
    }

    let l = 0;
    let n = parseInt(file.size + '', 10) || 0;
    while (n >= 1000 && ++l) {
      n = n / 1000;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }, [file]);

  const handleConvert = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let { json, processedJson } = fileToJsonParse(e?.target?.result);
        setFileJsonData(processedJson);
        let _errors: string[] = headerErrorsFn(json, type);
        setHeaderErrors(_errors);
        if (_errors?.length > 0) {
          return;
        }
        console.log('Json : ', json);
        console.log('Processed Json : ', processedJson);
        let { groupedErrorsArray, readyToProcessData } = cellErrorsFn(
          processedJson,
          type
        );
        console.log({ groupedErrorsArray, readyToProcessData });
        setCellErrors(groupedErrorsArray);
        setReadyToProcessJson(readyToProcessData);
      };
      reader.readAsBinaryString(file);
    }
  };
  return {
    errors: {
      cellErrors,
      fileUploadErrors,
      headerErrors,
    } as ErrorsObj,
    readyToProcessJson,
    file,
    fileJsonData,
    fileSize,
    setFile,
    onFileDrop,
    setCellErrors,
    setReadyToProcessJson,
  };
};
