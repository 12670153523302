import {
  ChakraProvider,
  extendTheme,
  Flex,
  Portal,
  Text,
} from '@chakra-ui/react';
import VisibilityModal from 'pages/Handbook/setVisibilityModal';
import GenerateConditionsText from 'pages/Handbook/setVisibilityModal/SetSeeModal/GenerateConditionsText';
import { areObjectsSimilar } from 'pages/Handbook/setVisibilityModal/SetSeeModal/helper';
import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import useCombinedStore from 'zustandStore/store';
import { IFolderVisibilityType } from '../FolderVisibility/FolderVisibility';
import { IVisibility } from './UploadFile/UploadFile';
export type IViewDirection = 'left-right' | 'top-bottom';

interface IProps {
  isEditMode: boolean;
  /** `descTitle` is used to show custom heading if parent component is Folder or Sub Folder related modal */
  descTitle?: string;
  /** `bgColor` is used to show custom background color if parent component is Folder or Sub Folder related modal */
  bgColor?: string;
  /** `customIcon` is used to show custom icon if parent component is Folder or Sub Folder related modal */
  customIcon?: React.ReactChild;
  /** `conditionOperatorColor` is used to show custom text color for the condition operators 'and', 'or' if parent component is Folder or Sub Folder related modal */
  conditionOperatorColor?: CSSProperties['color'];
  hideChangeVisibilityBtn?: boolean;
  parentDataVisibility?: IVisibility;
  parentFolderName?: string;
  customElement?: React.ReactChild;
  /**
   Direction of text and button. If left-right (default), then text will be on left side and 'Change Visibility' button will be on right side or else text will be on top and 'Change Visibility' button will be at bottom
   */
  viewDirection?: IViewDirection;
  type: 'folder' | 'subFolder' | 'chapter';
}

interface IFileVisibilityProps {
  open: boolean;
  isEditMode: boolean;
  showState: { showVisibility: boolean };
  setShowState: React.Dispatch<
    React.SetStateAction<{
      showVisibility: boolean;
    }>
  >;
}

export const FileVisibilityModal: FC<IFileVisibilityProps> = ({
  open,
  isEditMode,
  showState,
  setShowState,
}) => {
  const { visibilitySelection, updateSelectedData } = useCombinedStore();
  const methods = useForm<IFormInput>({
    defaultValues: {
      title: 'Set visibility',
    },
  });
  const onApply = () => {
    return;
  };

  return (
    <FormProvider {...methods}>
      <ChakraProvider portalZIndex={3001}>
        <VisibilityModal
          isOpen={true}
          zIndex={'tooltip'}
          showStateData={showState}
          handleClose={(type: string, _, selectedData) => {
            if (type === 'close') {
              setShowState({
                showVisibility: false,
              });
            } else {
              setShowState({
                showVisibility: false,
              });
              updateSelectedData({
                locations: selectedData?.selectedLocations || [],
                members: selectedData?.selectedMembers || [],
                roles: selectedData?.selectedRoles || [],
              });
            }
          }}
          setVisiblityData={!isEditMode ? onApply : null}
          isCreate={!isEditMode}
          visibilityData={visibilitySelection}
          deckTitle={''}
          cardCount={0}
          deckThumbnail={''}
          modalType='write'
          isLoading={false}
        />
      </ChakraProvider>
    </FormProvider>
  );
};

const FileVisibility: FC<IProps> = ({
  isEditMode,
  descTitle,
  bgColor,
  customIcon,
  conditionOperatorColor,
  hideChangeVisibilityBtn = false,
  viewDirection = 'left-right',
  type,
  parentDataVisibility,
  parentFolderName,
  customElement,
}) => {
  const { selectedData, visibilitySelection } = useCombinedStore();
  const [showState, setShowState] = useState({
    showVisibility: false,
  });

  let isSameAsParent = useMemo(() => {
    if (!parentDataVisibility) return;
    return areObjectsSimilar(parentDataVisibility, visibilitySelection!);
  }, [visibilitySelection, parentDataVisibility, selectedData]);

  return (
    <>
      <Flex
        w='full'
        align='center'
        bg={bgColor ? bgColor : 'rgba(177, 229, 252, 0.4)'}
        borderRadius='8px'
        flexDir={viewDirection === 'top-bottom' ? 'column' : 'row'}
      >
        <Flex w={viewDirection === 'top-bottom' ? '100%' : '80%'}>
          <GenerateConditionsText
            marginTop={undefined}
            descTitle={descTitle}
            customIcon={customIcon}
            conditionOperatorColor={conditionOperatorColor}
            defaultSubFolderMsg={isSameAsParent && type === 'subFolder'}
            parentFolderName={parentFolderName}
            customElement={customElement}
          />
        </Flex>
        {!hideChangeVisibilityBtn && (
          <Flex w={viewDirection === 'top-bottom' ? '100%' : '20%'}>
            <Text
              cursor='pointer'
              ml={7}
              w='full'
              color='#2A85FF'
              fontWeight={700}
              textDecoration='underline'
              onClick={() => {
                setShowState({ showVisibility: true });
              }}
              p={viewDirection === 'top-bottom' ? '0px 0px 16px 16px' : '0px'}
            >
              CHANGE VISIBILITY
            </Text>
          </Flex>
        )}
      </Flex>
      {showState?.showVisibility && (
        <FileVisibilityModal
          isEditMode={isEditMode}
          open={showState?.showVisibility}
          showState={showState}
          setShowState={setShowState}
        />
      )}
    </>
  );
};

export default FileVisibility;
