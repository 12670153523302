import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
  split,
  from,
  defaultDataIdFromObject,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwt_decode from "jwt-decode";
import { UserData } from "./user-data.types";
import { match, P } from "ts-pattern";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

export const isJwtValid = (token?: string): boolean => {
  if (token) {
    const decodedToken = jwt_decode<Record<string, never>>(token);
    return decodedToken?.exp >= (new Date().getTime() + 1) / 1000;
  }
  return false;
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("authToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
  };
});

let wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_SOCKET_ENDPOINT}`,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem("authToken");
      return {
        Authorization: token ? `Bearer ${token}` : "",
      };
    },
    lazy: true,
  },
});

export const userObj = makeVar<UserData>({} as UserData);
export const chatStatus = makeVar<boolean>(false);
export type { UserData };

export const authTokenVar = makeVar(localStorage.getItem("authToken"));

const isTokenExpired = (token: string): Boolean => {
  try {
    if (token) {
      const decodedToken = jwt_decode<Record<string, never>>(token);
      if (decodedToken?.exp < (new Date().getTime() + 1) / 1000) {
        return true;
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    return true;
  }
  return false;
};

const refreshTokenQuery = `
  query GetToken($refreshToken: String!) {
    getToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`;

const fetchAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshAuthToken");

  const graphqlUrl: string = process.env.REACT_APP_API_ENDPOINT!;

  const response = await fetch(graphqlUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: refreshTokenQuery,
      variables: {
        refreshToken: refreshToken,
      },
    }),
  });
  return await response.json();
};

const refreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    const token = localStorage.getItem("authToken");
    return !token || !isTokenExpired(token);
  },
  fetchAccessToken: fetchAccessToken,
  handleFetch: (accessToken) => {
    authTokenVar(accessToken);
    localStorage.setItem("authToken", accessToken);
  },
  handleResponse: () => (response: any) => {
    if (response?.data?.getToken) {
      return response?.data?.getToken;
    }
  },
  handleError: (err) => {
    // Sentry.captureException(err);
    console.log(err);
    userObj({} as UserData);
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshAuthToken");
    window.location.href = "/login";
    // showErrorMessage("Session expired. Please log in again.");
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const stringify = (data: object) => JSON.stringify(data, null, 2);

/** Error Interceptor to handle various errors coming from graphql backend */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log(
    JSON.stringify({ errors: { GE: graphQLErrors, NE: networkError } })
  );
  if (graphQLErrors)
    graphQLErrors?.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      Sentry.captureMessage(
        stringify({
          type: "[GraphQL error]",
          message: message,
          locations: locations,
          path: path?.toString(),
          operationName: operation.operationName,
          pathname: window?.location?.toString(),
        })
      );
      // Add your error handling logic here
      // For example, redirect on a specific error
      // if (message.includes('some specific error')) {
      //   window.location.href = '/error-page';
      // }
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError, {
      data: {
        operationName: operation.operationName,
        // query: operation.query?.loc?.source?.body,
        pathname: window?.location?.toString(),
      },
    });
    // Redirect on network errors
    // window.location.href = '/network-error';
  }
});

export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  /** Uncomment to add `errorLink` to the array */
  // link: from([errorLink, refreshLink, authLink, splitLink]),
  link: from([errorLink, refreshLink, authLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        merge: true,
      },
      ChatGroup: {
        merge: true,
      },
    },
    dataIdFromObject: (responseObject, context) => {
      return match(responseObject)
        .with(
          {
            __typename: "User",
            eid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.eid}`;
          }
        )
        .with(
          {
            __typename: "ChatGroup",
            guid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.guid}`;
          }
        )
        .with(
          {
            __typename: "FormsCategory",
            eid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.eid}`;
          }
        )
        .otherwise(() => defaultDataIdFromObject(responseObject, context));
    },
    // dataIdFromObject: (responseObject) => {
    //   switch (responseObject.__typename) {
    //     case "LocationLaunchContentsTask":
    //       if (responseObject.eid && responseObject.launchId) {
    //         return `${responseObject.__typename}:${responseObject.launchId}:${responseObject.eid}`;
    //       }
    //       return defaultDataIdFromObject(responseObject);
    //     case "LauncherLocations":
    //       return `${responseObject.__typename}:${responseObject.launchId}`;
    //   }
    //
    //   if (responseObject.__typename && responseObject.eid) {
    //     return `${responseObject.__typename}:${responseObject.eid}`;
    //   }
    //   return defaultDataIdFromObject(responseObject);
    // },
  }),
});
