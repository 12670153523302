import { Flex, Text } from '@chakra-ui/react';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GenericColorHeader from 'sub-components/GenericColorHeader';

interface IProps {
  details: ILocationDetails | undefined;
}

const ColorText: FC<{ text: string; color: string; bg: string }> = ({
  text,
  bg,
  color,
}) => {
  return (
    <Text
      fontWeight={600}
      fontSize='14px'
      color={color}
      bg={bg}
      borderRadius='6px'
      align='center'
      p='3px 15px'
      w='fit-content'
    >
      {text}
    </Text>
  );
};

const LocationDetailsComponent: FC<IProps> = ({ details }) => {
  const { t } = useTranslation(['common', 'location', 'setting']);
  const locationStatusRender = () => {
    if (details?.locationStatus === 'open') {
      return 'Open';
    } else if (details?.locationStatus === 'development') {
      return 'Launching';
    } else if (details?.locationStatus === 'preLaunch') {
      return 'Pre-launch';
    } else {
      return '-';
    }
  };
  const locationTypeRender = () => {
    if (details?.locationType === 'corporate') {
      return 'Corporate';
    } else if (details?.locationType === 'franchise') {
      return 'Franchise';
    } else {
      return '-';
    }
  };
  return (
    <Flex flexDir='column' gap='20px'>
      <GenericColorHeader title='Location details' color='#CABDFF' />
      <Flex flexDir='column' gap='20px'>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('common:status')}:</Text>
          <ColorText
            text={locationStatusRender()}
            bg={
              details?.locationStatus === 'open'
                ? 'rgba(181, 228, 202, 0.3)'
                : details?.locationStatus === 'development'
                ? 'rgba(255, 216, 141, 0.4)'
                : 'rgba(244, 244, 244, 1)'
            }
            color={
              details?.locationStatus === 'open'
                ? '#83BF6E'
                : details?.locationStatus === 'development'
                ? 'rgba(227, 152, 6, 1)'
                : 'rgba(51, 56, 63, 1)'
            }
          />
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:address')}:</Text>
          <Text>{details?.address?.address || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:city')}:</Text>
          <Text>{details?.address?.city || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:state')}:</Text>
          <Text>{details?.address?.state || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:userName')}:</Text>
          <Text>{details?.username || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:locationType')}:</Text>
          <ColorText
            text={locationTypeRender()}
            bg='#B1E5FC4D'
            color='#2A85FF'
          />
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('setting:email')}:</Text>
          {details?.locationEmail?.map((email, index) => (
            <Text key={index} color='#2A85FF'>
              {email}
            </Text>
          ))}
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('common:phone')}:</Text>
          {details?.locationPhone?.map((phone, index) => (
            <Text key={index} color='#2A85FF'>
              {phone}
            </Text>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LocationDetailsComponent;
