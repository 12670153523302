import React, { FC } from 'react';
import { AllOpeningFilterBadgeList } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/List';
import { IFilterBadgeProps } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/Singleton/FilterBadge';

interface IProps {
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  filterOptions: IFilterBadgeProps[];
}

const Filters: FC<IProps> = (props) => {
  const { filterOptions, selectedFilter, setSelectedFilter } = props;
  const handleFilterSelect = (value: string) => {
    setSelectedFilter(value);
  };
  const filterBadges = filterOptions.map((filter) => ({
    ...filter,
    selected: filter.value === selectedFilter,
    onSelected: handleFilterSelect,
  }));
  return <AllOpeningFilterBadgeList list={filterBadges} />;
};

export default Filters;
