import React, { FC, useMemo } from 'react';
import {
  Box,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faRocketLaunch,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { MessageIconButton } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';

import { ReactComponent as EditIcon } from '../../../../assets/images/editIcon.svg';
import { ReactComponent as ShareLight } from '../../../../assets/images/shareLight.svg';
import useCombinedStore from 'zustandStore/store';
import { useHasChatAccess } from 'hooks/useChatRestriction';

export const SuperAdminAdmin = [
  AuthRole.SUPER_ADMIN,
  AuthRole.ADMIN,
  AuthRole.LOCATION_OWNER,
];

interface IProps {
  locationId?: string;
  shouldHideEdit?: boolean;
  locationOwnerShare?: boolean;
  locationDetails: any;
  preLaunch?: boolean;
  onEditClick?: () => void;
  onEditPreLaunchClick?: () => void;
  onShareClick?: () => void;
  onMessageClick?: () => void;
  onLaunchLocationClick?: () => void;
  onDeleteLocationClick?: () => void;
}

const LocationActions: FC<IProps> = ({
  locationId,
  locationOwnerShare,
  preLaunch,
  locationDetails,
  shouldHideEdit,
  onEditClick,
  onEditPreLaunchClick,
  onShareClick,
  onMessageClick,
  onLaunchLocationClick,
  onDeleteLocationClick,
}) => {
  const { userType, authRole } = useUserDataSelector(
    (state) => ({
      userType: state.type,
      authRole: state.authRole,
    }),
    shallowEqual
  );

  const hasChatAccess = useHasChatAccess();

  // const emitEvent = useCombinedStore((state) => state.emitEvent);

  const iconRender = () => {
    if (preLaunch) {
      return (
        <Tooltip label='Launch this location' hasArrow>
          <Center boxSize='32px'>
            <FontAwesomeIcon
              icon={faRocketLaunch as IconProp}
              cursor='pointer'
              color='#2a85ff'
              onClick={() => {
                onLaunchLocationClick?.();
                // emitEvent('LocationListPage', 'preLaunchLocationLaunchClick', {
                //   data: 'Emitted successfully',
                // });
              }}
            />
          </Center>
        </Tooltip>
      );
    } else if (locationDetails?.locationStatus === 'open') {
      return (
        <MessageIconButton
          size='sm'
          hasChatAccess={hasChatAccess({
            eid: locationId!,
            role: 'location',
            authRole: AuthRole.LOCATION_OWNER,
            type: 'branch',
          })}
          onClick={onMessageClick}
        />
      );
    } else {
      return <Box w='30px' />;
    }
  };

  const menuList = useMemo(() => {
    if (preLaunch) {
      return (
        <MenuList>
          <MenuItem onClick={() => onEditPreLaunchClick?.()} h='40px'>
            <Flex p='10px' cursor='pointer' align='center' gap='20px'>
              <EditIcon />
              <Text m={0}>Edit</Text>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => onLaunchLocationClick?.()} h='40px'>
            <Flex p='10px' cursor='pointer' align='center' gap='20px'>
              <FontAwesomeIcon icon={faRocketLaunch as IconProp} />
              <Text m={0}>Launch this location</Text>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => onDeleteLocationClick?.()} h='40px'>
            <Flex
              p='10px'
              cursor='pointer'
              align='center'
              gap='20px'
              color='rgba(255, 106, 85, 1)'
            >
              <FontAwesomeIcon icon={faTrashCan as IconProp} />
              <Text m={0}>Delete</Text>
            </Flex>
          </MenuItem>
        </MenuList>
      );
    }
    return (
      <MenuList p={0}>
        {!shouldHideEdit && (
          <Authorize
            permittedFor={'user'}
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
            canAccess={(authUser) =>
              authUser.authRole === AuthRole.LOCATION_OWNER &&
              !!authUser.locations?.some((it) => it.eid === locationId)
            }
          >
            <MenuItem onClick={() => onEditClick?.()} h='40px'>
              <Flex p='10px' cursor='pointer' align='center' gap='20px'>
                <EditIcon />
                <Text m={0}>Edit</Text>
              </Flex>
            </MenuItem>
          </Authorize>
        )}
        {locationDetails?.locationStatus === 'open' && (
          <MenuItem
            h='40px'
            onClick={() => {
              if (
                (SuperAdminAdmin.includes(authRole) && userType === 'user') ||
                locationOwnerShare
              ) {
                return onShareClick?.();
              }
            }}
          >
            <Flex
              align='center'
              gap='20px'
              style={{
                cursor:
                  (SuperAdminAdmin.includes(authRole) && userType === 'user') ||
                  locationOwnerShare
                    ? 'pointer'
                    : 'no-drop',
                padding: '10px',
              }}
            >
              <ShareLight />
              <Text m={0}>Share</Text>
            </Flex>
          </MenuItem>
        )}
      </MenuList>
    );
  }, [preLaunch]);

  return (
    <Flex align='center' justify='space-between' gap='12px'>
      {iconRender()}
      <Menu>
        <MenuButton>
          <Center boxSize='24px'>
            <FontAwesomeIcon
              icon={faEllipsis as IconProp}
              fontSize='20px'
              color='#6F767E'
            />
          </Center>
        </MenuButton>
        <Portal>{menuList}</Portal>
      </Menu>
    </Flex>
  );
};

export default LocationActions;
