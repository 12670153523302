import React from 'react';
import {
  ILocationData,
  IUserData,
  IRoleData,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { AuthRole, UserData } from 'sop-commons/src/client';
import { IVisibility } from '../CreateChapterModal/UploadFile/UploadFile';
import { ISelectedData } from 'zustandStore/slices/useVisibility';

export const locationUserRoleHelper = (
  _data: any,
  userData: UserData,
  setMembersList: React.Dispatch<React.SetStateAction<any[]>>,
  setLocationsList: React.Dispatch<React.SetStateAction<any[]>>,
  selectedData: ISelectedData,
  setSelectedData: any
) => {
  let _usersData = _data?.branch?.descendantUsers?.map((user: IUserData) => {
    return {
      ...user,
      label: user?.name,
      value: user?.name,
    };
  });
  let _locationsData = [];
  if (userData?.authRole === AuthRole.LOCATION_OWNER) {
    let filteredLocationsArray = _data?.branch?.descendantBranches?.filter(
      (location: ILocationData) => {
        return (userData?.locations || []).find((userLocation) => {
          return userLocation?.eid === location?.eid;
        });
      }
    );
    _locationsData = filteredLocationsArray;
  } else {
    _locationsData = _data?.branch?.descendantBranches?.map(
      (user: ILocationData) => {
        return {
          ...user,
          label: user?.name,
          value: user?.name,
        };
      }
    );
  }
  let _user = _usersData?.filter((user: any) => user?.eid !== userData?.eid);
  if (_user && Array.isArray(_user) && _user?.length > 0) {
    _user = _user?.sort((a, b) => a?.name?.localeCompare(b?.name));
  }
  setMembersList(_user);
  setLocationsList(_locationsData);
  if (
    userData?.authRole === AuthRole.LOCATION_OWNER &&
    _locationsData?.length === 1
  ) {
    setSelectedData({ ...selectedData, locations: _locationsData });
  }
};

export const rolesListSetHandler = (
  userData: UserData,
  setRolesList: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const roles = userData?.entity?.roles || [];
  let updatedRolesData: IRoleData[] = roles?.map<IRoleData>((role: any) => {
    return {
      ...role,
      label: role?.name,
      value: role?.name,
    };
  });
  setRolesList(updatedRolesData);
};

export const selectedDataSetHandler = (
  membersList: any[],
  locationsList: any[],
  newSetVisibility: IVisibility,
  rolesList: any[],
  setSelectedData: any
) => {
  if (
    membersList?.length === 0 ||
    locationsList?.length === 0 ||
    (newSetVisibility?.locations?.length === 0 &&
      newSetVisibility?.roles?.length === 0 &&
      newSetVisibility?.users?.length === 0)
  )
    return;
  let passedData = newSetVisibility;
  let selectedDataMembers: any[] = [];
  let selectedDataLocations: any[] = [];
  let selectedDataRoles: any[] = [];
  if (passedData?.users && passedData?.users?.length > 0) {
    let obj: { value: string }[] = [];
    for (let i in passedData?.users) {
      obj[+i] = {
        value: passedData?.users[i],
      };
    }
    const res = membersList?.filter((user) =>
      obj?.some((y) => {
        return y.value === user.eid;
      })
    );
    selectedDataMembers = res;
  }

  if (passedData?.locations && passedData?.locations?.length > 0) {
    let objLocation: { value: string }[] = [];
    for (let i in passedData?.locations) {
      objLocation[+i] = {
        value: passedData?.locations[i],
      };
    }
    const resLocation = locationsList?.filter((location) =>
      objLocation?.some((y) => {
        return y.value === location.eid;
      })
    );
    selectedDataLocations = resLocation;
  }

  if (passedData?.roles && passedData?.roles?.length > 0) {
    let objRole: { value: string }[] = [];
    for (let i in passedData?.roles) {
      objRole[+i] = {
        value: passedData?.roles[i],
      };
    }
    const resRole = rolesList?.filter((role) =>
      objRole?.some((y) => {
        return y.value === role.name;
      })
    );
    selectedDataRoles = resRole;
  }
  setSelectedData({
    locations: selectedDataLocations,
    members: selectedDataMembers,
    roles: selectedDataRoles,
  });
};
