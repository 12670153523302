import React, { FC } from 'react';
import PreLaunch from 'assets/images/preLaunch.svg';
import { Center, Image } from '@chakra-ui/react';

const BannerIcon: FC = () => {
  return (
    <Center>
      <Image src={PreLaunch} boxSize='150px' />
    </Center>
  );
};

export default BannerIcon;
