import { cloneDeep } from '@apollo/client/utilities';
import { Flex, Input, Select, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Button } from 'atoms';
import Dropdown from 'atoms/Dropdown';
import React, { FC, useEffect, useState } from 'react';
import {
  CellErrorsEntity,
  GroupedErrors,
  IBulkOperations,
  JsonEntityWithId,
} from './types';

interface IProps {
  bulkOperations: IBulkOperations;
  onSaveFixErrorsHandler: (processedJson: JsonEntityWithId[]) => void;
  onDiscardHandler: () => void;
}

const Wrapper = styled.div`
  table {
    border-collapse: separate;
    border-spacing: 0;
    // border: .5px solid rgba(238, 238, 238, 1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
  }
  thead {
    background-color: rgba(243, 243, 243, 1);
    tr > td {
      padding: 10px;
      font-size: 15px;
      font-weight: 500;
      border: 1px solid rgba(238, 238, 238, 1);
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 35%;
      }
      &:nth-child(3) {
        width: 35%;
      }
    }
  }
  tbody {
    background-color: white;
    tr > td {
      padding: 10px;
      border: 1px solid rgba(238, 238, 238, 1);
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 35%;
      }
      &:nth-child(3) {
        width: 35%;
      }
    }
  }
`;

const FixErrors: FC<IProps> = ({
  bulkOperations,
  onSaveFixErrorsHandler,
  onDiscardHandler,
}) => {
  const [cellErrors, setCellErrors] = useState<GroupedErrors[]>([]);

  useEffect(() => {
    setCellErrors(bulkOperations?.errors?.cellErrors);
  }, [bulkOperations?.errors?.cellErrors]);

  const findIndex = (error: GroupedErrors) => {
    let foundIndex = 0;
    bulkOperations?.fileJsonData?.map((data, index) => {
      if (error?.id === data?.id) {
        foundIndex = index + 2;
      }
    });
    return foundIndex;
  };

  const updateFieldHandler = (value: string, errorCol: CellErrorsEntity) => {
    let _cellErrors = cloneDeep(cellErrors);
    _cellErrors?.map((_cellError) => {
      if (_cellError?.id === errorCol?.processData?._data?.id) {
        _cellError?.errors?.map((error) => {
          if (error?.processData?.id === errorCol?.processData?.id) {
            error.processData.value = value;
          }
        });
      }
    });
    setCellErrors(_cellErrors);
  };

  const saveHandler = () => {
    let _cellErrors = cloneDeep(cellErrors);
    let processedJson: JsonEntityWithId[] = [];
    _cellErrors?.map((cellError) => {
      cellError?.errors?.map((error) => {
        error.processData._data.data[error.processData.key].value =
          error.processData.value;
      });
      processedJson?.push({
        id: cellError?.id,
        data: cellError?.errors?.[0]?.processData?._data?.data,
      });
    });
    onSaveFixErrorsHandler(processedJson);
  };

  const discardHandler = () => {
    onDiscardHandler();
  };

  return (
    <Flex flexDir='column' gap={4}>
      {cellErrors?.map((error) => {
        return (
          <Flex flexDir='column' key={error?.id}>
            <Text fontWeight={700} fontSize='15px'>
              Row {findIndex(error)}
            </Text>
            <Wrapper>
              <table>
                <thead>
                  <tr>
                    <td>Column name</td>
                    <td>Error</td>
                    <td>Add fix here</td>
                  </tr>
                </thead>
                <tbody>
                  {error?.errors?.map((_error, index) => (
                    <tr key={index}>
                      <td>{_error?.processData?.key}</td>
                      <td style={{ color: 'rgba(255, 106, 85, 1)' }}>
                        {_error?.errorMsg}
                      </td>
                      <td>
                        {_error?.processData?.schema?.values?.data?.length >
                        0 ? (
                          <Select
                            onChange={(e) =>
                              updateFieldHandler(e?.target?.value, _error)
                            }
                          >
                            <option selected disabled value=''>
                              Select option
                            </option>
                            {_error?.processData?.schema?.values?.data?.map(
                              (value: any, index: number) => (
                                <option key={index} value={value?.label}>
                                  {value?.label}
                                </option>
                              )
                            )}
                          </Select>
                        ) : (
                          <Input
                            border='none'
                            placeholder='Enter correct data'
                            onChange={(e) =>
                              updateFieldHandler(e?.target?.value, _error)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Wrapper>
          </Flex>
        );
      })}
      <Flex w='full' gap={4}>
        <Button w='50%' variant='outline' onClick={discardHandler}>
          Discard & Go back
        </Button>
        <Button
          w='50%'
          variant='solid'
          colorScheme='blue'
          onClick={saveHandler}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default FixErrors;
