import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faPlay,
  faRocketLaunch,
} from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import { getUserTimezoneConvertedNow } from 'pages/launcher/details/helper';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import {
  commonDurationHandler,
  durationString,
  getRefactoredLiveDate,
} from '../util/helper';
import { LauncherLocationDetailTask } from 'pages/launcher/details/types/location-launch-details.types';

interface IProps {
  liveDate: string;
  tasks: LauncherLocationDetailTask[];
}

const GoLiveRocket: FC<IProps> = ({ liveDate, tasks }) => {
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);
  const getDurationDifference = () => {
    const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
    const refDate = moment(getRefactoredLiveDate(liveDate, tasks));
    const isFuture = refDate.diff(now) > 0;

    const formattedDiff = getFormattedDifference(now, refDate, isFuture);
    return formattedDiff;
  };

  const getFormattedDifference = (
    now: moment.Moment,
    refDate: moment.Moment,
    isFuture: boolean
  ): string => {
    let result = commonDurationHandler(refDate, now);
    return durationString('isFuture', result, isFuture, undefined);
  };

  return (
    <Flex gap='10px' align='center' boxSize='full'>
      <Center
        boxSize='60px'
        borderRadius='50%'
        border='1px solid rgba(221, 228, 236, 1)'
      >
        <FontAwesomeIcon
          icon={faRocketLaunch as IconProp}
          color='rgba(42, 133, 255, 1)'
          size='2x'
        />
      </Center>
      <Flex flexDir='column'>
        <Flex gap='5px'>
          <Box as='span' fontWeight={500} color='rgba(111, 118, 126, 1)'>
            Go-live date
          </Box>
          <Box as='span' color='rgba(217, 217, 217, 1)' fontSize='18px'>
            &bull;
          </Box>
          <Box as='span' fontWeight={500} color='rgba(151, 158, 166, 1)'>
            {/* {moment(getRefactoredLiveDate())?.fromNow(true)} left */}
            {getDurationDifference()}
          </Box>
        </Flex>
        <Box as='span' fontSize='18px' fontWeight={600}>
          {moment(getRefactoredLiveDate(liveDate, tasks))?.format(
            'DD MMM YYYY'
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default GoLiveRocket;
