import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { matchPath, useHistory, useParams } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TASK_PLAY_URL, TRAINING_PLAY_URL } from 'appRoutes';
import { AccessibilityType } from 'types';
import {
  SOP_ITEM_DETAILS,
  SopDetailResponse,
  UPDATE_VIEW,
} from './chapter.graphql';
import {
  UserDetailsComponent,
  UserDetailsRef,
} from '../../../sub-components/UserLocationDetails';
import { useSubmitForm } from 'sub-components/forms';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import { defaultContent } from 'sub-components/ChapterEditor/defaultData';
import PreviewModal from 'sub-components/ChapterEditor/PreviewModal';
import ShareChapterDrawer from './ShareChapterDrawer';
import { useEditChapter } from '../EditChapterModal';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import ChapterTextView from './ChapterTextView';
import { ChapterViewWrapper } from './chapter-view.styles';
import { useChapterDelete } from '../useChapterDelete';
import { toArray } from '../../../utils/utils';
import { DELETE_SOP } from '../chapters.graphql';
import { useReadOnlyEditor } from 'delightree-editor';
import { useReactToPrint } from 'react-to-print';
import useCombinedStore from 'zustandStore/store';
import { IType } from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { getQueryParamValue } from 'utils/queryParams';
import { useVisibilityView } from 'ui-components/VisibilityView';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { useUpdateChapterVisibility } from './useUpdateChapterVisibility';

interface IProps {}

export type ICustomFormInput = IFormInput & {
  iconType: string;
};

const ChapterView: FC<IProps> = () => {
  const params = useParams<{ chapterId: string }>();
  const statusValue = getQueryParamValue('status');
  const visibilityView = useVisibilityView();
  const { updateVisibilitySelection } = useCombinedStore();
  const readOnlyEditor = useReadOnlyEditor();
  // @ts-ignore
  const { t } = useTranslation(['common', 'chapter']);
  const editorContainerRef = useRef<HTMLDivElement | null>(null);
  const userData = useUserDataSelector((state) => ({
    eid: state?.eid,
    authRole: state?.authRole,
  }));
  const [searchQuery, setSearchQuery] = useState<string>();
  const [showPreview, setShowPreview] = useState(false);
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const [unAuthorised, setUnauthorised] = useState<AccessibilityType>();
  const methods = useForm<ICustomFormInput>({
    defaultValues: {
      title: '',
      content: defaultContent,
      smartPage: false,
      chapterIcon: '',
    },
  });

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const history = useHistory();
  const client = useApolloClient();
  const chapterDelete = useChapterDelete();

  const refetchHandler = () => {
    refetch?.();
  };

  const { renderVisibilityModal, updateVisibility } =
    useUpdateChapterVisibility(refetchHandler);

  const useDetailRef = useRef<UserDetailsRef>(null);
  let dashboardCtx = useContext(DashboardDataContext);
  // let selectedCategory =
  //   dashboardCtx?.navigationPersistData?.knowledgeBase?.selectedCategory;

  const submitForm = useSubmitForm();
  const editChapter = useEditChapter();

  const canOverride = useMemo(() => {
    return (
      matchPath(window.opener?.location?.pathname, {
        path: [TASK_PLAY_URL, TRAINING_PLAY_URL],
        exact: true,
        strict: true,
      }) !== null
    );
  }, []);

  const { data, loading, refetch } = useQuery<SopDetailResponse>(
    SOP_ITEM_DETAILS,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: params.chapterId,
        override: canOverride,
      },
      onCompleted: (response) => {
        let _response: SopDetailResponse['SopById'] = response?.SopById;
        if (_response?.eid) {
          setUnauthorised(undefined);
          updateVisibilitySelection({
            locations: _response?.visibleTo?.locations || [],
            roles: _response?.visibleTo?.roles || [],
            users: _response?.visibleTo?.users || [],
            condition: _response?.visibleTo?.condition || undefined,
            visibility: _response?.visibility as IType,
          });
          let editorData = _response;
          methods?.setValue('title', editorData?.title, {
            shouldDirty: false,
          });
          methods?.setValue('content', editorData?.content?.[0]?.tiptap, {
            shouldDirty: false,
          });
          methods?.setValue('smartPage', editorData?.smartPageEnabled, {
            shouldDirty: false,
          });
          methods?.setValue('chapterIcon', editorData?.icon?.native);
          methods?.setValue('iconType', editorData?.icon?.id);

          if (editorData?.createdBy !== userData?.eid) {
            client
              .mutate({
                mutation: UPDATE_VIEW,
                variables: {
                  sopId: response.SopById.eid,
                },
              })
              .catch((err) =>
                // eslint-disable-next-line no-console
                console.log('view count update error ==> ', err?.message)
              );
          }
        }
      },
      onError: (error) => {
        for (let err of error.graphQLErrors) {
          if (err.extensions.code === 'FORBIDDEN') {
            setUnauthorised('UNAUTHORISED');
            break;
          }
          if (err.extensions.code === 'BAD_USER_INPUT') {
            setUnauthorised('NOT_FOUND');
            break;
          }
        }
      },
    }
  );

  useEffect(() => {
    let visibleData = data?.SopById?.visibleTo;
    let visibility = data?.SopById?.visibility;
    updateVisibilitySelection({
      locations: visibleData?.locations || [],
      roles: visibleData?.roles || [],
      users: visibleData?.users || [],
      condition: visibleData?.condition || undefined,
      visibility: visibility as IType,
    });
  }, [data?.SopById]);

  const sopData = data?.SopById;

  const handlePrint = useReactToPrint({
    content: () => editorContainerRef.current,
    documentTitle: sopData?.title,
  });

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);

  const shareChapterHandler = () => {
    setShowShareDrawer(true);
  };

  const onEditClick = () => {
    if (sopData?.sopType === 'text') {
      if (statusValue === 'RESTRICT') {
        return history.push(
          `/edit-chapter/${params.chapterId}?status=RESTRICT`,
          {
            backToTitle: 'Back to Chapter View',
          }
        );
      }
      return history.push(`/edit-chapter/${params.chapterId}`, {
        backToTitle: 'Back to Chapter View',
      });
    }
    editChapter({
      chapterId: params.chapterId,
      onChapterUpdated: refetch,
    });
  };

  const [deleteSop] = useMutation(DELETE_SOP, {
    onCompleted: () => {
      toast({
        title: t('chapter:singleChapterDeleteSuccess'),
        status: 'success',
      });
      history.goBack();
    },
    onError: () => {
      toast({
        title: t('chapter:singleChapterDeleteError'),
        status: 'error',
      });
    },
  });

  const onDeleteClick = () => {
    if (!sopData?.eid) {
      return null;
    }
    chapterDelete({
      trainings: toArray(sopData?.trainings),
      onDeletePress: () => {
        return deleteSop({
          variables: {
            eid: sopData?.eid!,
          },
        });
      },
    });
  };

  const exportPDFHandler = () => {
    handlePrint();
  };

  const showVisibility = () => {
    if (userData?.authRole === AuthRole.SUPER_ADMIN) {
      if (data?.SopById) {
        updateVisibility(data?.SopById);
      }
    } else {
      visibilityView({ heading: sopData?.title });
    }
  };

  return (
    <ChapterViewWrapper>
      <FormProvider {...methods}>
        <ChapterTextView
          searchQuery={searchQuery}
          onSearch={onSearch}
          sopData={sopData}
          showPreview={setShowPreview}
          showVisibility={showVisibility}
          shareChapterHandler={shareChapterHandler}
          exportPDFHandler={exportPDFHandler}
          onEditClick={onEditClick}
          loading={loading}
          onItemClick={onItemClick}
          onDeleteClick={onDeleteClick}
          unAuthorised={unAuthorised}
          ref={editorContainerRef}
        />

        <UserDetailsComponent ref={useDetailRef} />
        <PreviewModal
          open={showPreview}
          onClose={() => setShowPreview(false)}
        />
        {/* {showVisibility && (
          <VisibilityModal
            isOpen={showVisibility}
            handleClose={() => setShowVisibility(false)}
            setVisiblityData={null}
            isCreate={true}
            modalType='read'
            setCreateVisibility={() => {}}
            isLoading={false}
          />
        )} */}
        {showShareDrawer && (
          <ShareChapterDrawer
            chapterId={params.chapterId}
            isOpen={showShareDrawer}
            onClose={() => setShowShareDrawer(false)}
            chapterData={sopData}
          />
        )}
      </FormProvider>
      {/* <ReactToPrint content={<ChapterFileView sopData={sopData} />} trigger={exportPDFHandler} /> */}
      {/* <PDFDownloadLink document={<ChapterFileView sopData={sopData} />} fileName="somename.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink> */}
      {renderVisibilityModal()}
    </ChapterViewWrapper>
  );
};

export default ChapterView;
