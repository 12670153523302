import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XlsImg from 'assets/images/xls-img.png';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorsObj, IBulkOperations } from './types';

interface IProps {
  bulkOperations: IBulkOperations;
  removeFile: () => void;
}

const FileList: FC<IProps> = ({ bulkOperations, removeFile }) => {
  let { errors, file, fileSize } = bulkOperations;
  const { t } = useTranslation(['chapter']);
  const loggedInUserName = useUserDataSelector((state) => state.name);
  return (
    <Flex
      gap={3}
      p='16px'
      align='center'
      border={`1px solid ${
        errors?.cellErrors?.length === 0 &&
        errors?.fileUploadErrors?.length === 0 &&
        errors?.headerErrors?.length === 0
          ? 'rgba(131, 191, 110, 1)'
          : errors?.cellErrors?.length > 0 ||
            errors?.fileUploadErrors?.length > 0 ||
            errors?.headerErrors?.length > 0
          ? 'rgba(255, 106, 85, 1)'
          : '#DDDDDD'
      }`}
      borderRadius='8px'
    >
      <Center
        boxSize='60px'
        borderRadius='6px'
        border='1px solid rgba(230, 244, 225, 1)'
        bg='rgba(230, 244, 225, 1)'
      >
        <Image boxSize='34px' borderRadius='6px' src={XlsImg} />
      </Center>
      <Flex
        flex={1}
        flexDir='column'
        color='#333B4F'
        fontWeight={400}
        fontSize='14px'
      >
        <Text fontWeight={600} fontSize='16px'>
          {file!.name}
        </Text>

        <Flex gap={1}>
          <Text>{fileSize},</Text>
          <Text>
            <Trans
              t={t}
              i18nKey='chapter:uploadedBy'
              values={{
                name: loggedInUserName,
              }}
              components={{
                span: (
                  <Box
                    as='span'
                    bg='#CABDFF66'
                    w='fit-content'
                    px={1}
                    borderRadius='2px'
                  />
                ),
              }}
            />
          </Text>
          <Text>{moment()?.format('DD MMM YYYY')}</Text>
        </Flex>
      </Flex>
      <FontAwesomeIcon
        icon={faXmark as IconProp}
        size='lg'
        cursor='pointer'
        onClick={removeFile}
      />
    </Flex>
  );
};

export default FileList;
