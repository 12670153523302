import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

const Stepper: FC = () => {
  return (
    <Flex flexDir='column' align='center'>
      <Flex position='relative' w='80%'>
        <Flex justify='space-between' w='full'>
          <Center boxSize='40px' bg='rgba(131, 191, 110, 1)' borderRadius='50%'>
            <FontAwesomeIcon
              icon={faCheck as IconProp}
              color='white'
              fontSize='20px'
            />
          </Center>
          <Center
            borderRadius='50%'
            boxSize='40px'
            bg='rgba(42, 133, 255, 1)'
            color='white'
          >
            <Text>2</Text>
          </Center>
          <Center
            borderRadius='50%'
            bg='white'
            boxSize='40px'
            border='1px solid rgba(126, 172, 252, 1)'
            color='rgba(126, 172, 252, 1)'
          >
            <Text>3</Text>
          </Center>
        </Flex>
        <Box
          position='absolute'
          top='19px'
          zIndex='-1'
          w='full'
          h='2px'
          bg='rgba(126, 172, 252, 1)'
        />
      </Flex>
      <Flex justify='space-between' w='90%'>
        <Text w='100px' fontWeight={500} textAlign='center'>
          Pre launch location
        </Text>
        <Text w='100px' fontWeight={500} textAlign='center'>
          Launch via launcher
        </Text>
        <Text w='100px' fontWeight={500} textAlign='center'>
          Your location is “open”
        </Text>
      </Flex>
    </Flex>
  );
};

export default Stepper;
