import { Box, Image, Input } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import TrainingPathIconsModal, {
  IIconList,
} from '../../../../../sub-components/TrainingPathIconsModal';
// import TrainingPathIcon from '../../../../../assets/images/training-path-icon.png';
import TrainingPathIcon from '../../../../../assets/images/training-path-icon.png';
import TrainingPathContext from '../TrainingPathStore/training-path-context';
import { deployEvent } from '../../../../../shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from '../../../../../shared/amplitudeEvents/amplitude-events-types';
import { useLazyQuery } from '@apollo/client';
import { getTrainingPathIcons } from '../../../training.graphql';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TrainingCardNameIcon = () => {
  const { t } = useTranslation('training');
  const trainingPathCtx = useContext(TrainingPathContext);
  const params = useRouteMatch<{ id?: string }>()?.params;
  const editDraftId = params?.id && params?.id !== 'draft';
  const [searchIcon, setSearchIcon] = useState<string>();

  useEffect(() => {
    getTrainingPathIconsHandler();
  }, []);

  const iconsList = useMemo(() => {
    if (searchIcon) {
      return trainingPathCtx?.trainingPathIconsList?.filter((value) =>
        value.tags?.some((tag: any) => tag.includes(searchIcon))
      );
    }
    return trainingPathCtx?.trainingPathIconsList;
  }, [trainingPathCtx?.trainingPathIconsList, searchIcon]);

  const onTitleBlurHandler = (e: any) => {
    deployEvent(
      AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CLICK_ON_PATH_NAME
    );
  };

  const [getTrainingPathIconsHandler, { loading: trainingIconsLoading }] =
    useLazyQuery(getTrainingPathIcons, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const items: IIconList[] = data?.iconsList?.items;
        trainingPathCtx.setTrainingPathIconsListHandler(items);
        const icon = items[Math.floor(Math.random() * items.length)];
        if (!editDraftId && trainingPathCtx?.isCounterMovedFromFirstStep) {
          if (!trainingPathCtx?.trainingPathIcon?.url) {
            trainingPathCtx?.setTrainingPathIconHandler(icon);
          }
        }
      },
    });

  console.log('trainign card name icon : ', trainingPathCtx?.trainingPathIcon);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <div>
        <div>
          <span style={{ fontWeight: '600', fontSize: '14px' }}>
            {t('tp_name')}&nbsp;
          </span>
          <span style={{ color: 'red' }}>*</span>
        </div>
        {/* <div style={{ fontWeight: '400', fontSize: '11px' }}>
          {t('sub_text')}
        </div> */}
        <Box display='flex' position='relative' mt='20px'>
          <Box
            height='50px'
            minWidth='50px'
            borderRadius='6.5px'
            backgroundColor='rgb(244, 244, 244)'
            marginRight='20px'
          >
            <TrainingPathIconsModal
              iconsList={iconsList}
              galleryIconsLoading={trainingIconsLoading}
              selectedIcon={(imgObj) => {
                trainingPathCtx?.setTrainingPathIconHandler(imgObj);
              }}
              searchedString={(search) => setSearchIcon(search)}
            >
              <img
                src={TrainingPathIcon}
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  left: '35px',
                  top: '-12px',
                  cursor: 'pointer',
                  width: 'max-content',
                }}
              />
            </TrainingPathIconsModal>
            {trainingPathCtx?.trainingPathIcon?.url && (
              <Image
                boxSize='50px'
                borderRadius='8px'
                // style={{ height: '100%', width: '100%' }}
                src={trainingPathCtx?.trainingPathIcon?.url}
              />
            )}
          </Box>
          <Input
            placeholder={t('tp_name_placeholder')}
            size='lg'
            style={{ width: '100%' }}
            value={trainingPathCtx?.trainingPathName}
            onBlur={onTitleBlurHandler}
            onChange={(e) => {
              trainingPathCtx?.setTrainingPathNameHandler(e?.target?.value);
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default TrainingCardNameIcon;
