import React, { FC, useCallback, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { toArray } from '../../../utils/utils';

import DashboardContainer from 'sub-components/DashboardContainer';
import LauncherDashHeader from './LauncherDashHeader';
import LocationLaunchGraph from './LocationLaunchGraph';
import ReadyToLiveLocations from './ReadyToLiveLocations';
import { ProgressListContainer } from './ProgressList';
import { isLauncherDue } from './launcher.helpers';
import { useUserDataSelector } from '../../../hooks';
import { AuthRole } from 'sop-commons/src/client';
import moment from 'moment-timezone';
import {
  LAUNCHER_LOCATION_DETAILS,
  LAUNCHER_SUPERVISORS,
} from '../details/graphql/location-launch-details.graphql';
import { LauncherLocationDetail } from '../details/types/location-launch-details.types';

interface Response {
  LocationLaunchList: LauncherLocationDetail[];
  LocationLaunchSupervisorTasks: LauncherLocationDetail[];
}

/**
 * The `LauncherDashboard` component represents the main dashboard of the location launcher.
 * This component also handles the modal functionality and filters the in-progress list based on selected criteria.
 *
 * @returns The rendered Launcher Dashboard component.
 */
const LauncherDashboard: FC = () => {
  const isAdmin = useUserDataSelector(
    (state) => state.authRole === AuthRole.ADMIN
  );
  const loggedInUserTimezone = useUserDataSelector((state) => state?.timezone);
  const loggedInUserEId = useUserDataSelector((state) => state?.eid);
  const { data, loading, refetch } = useQuery<Response>(
    isAdmin ? LAUNCHER_SUPERVISORS : LAUNCHER_LOCATION_DETAILS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const refetchHandler = () => {
    refetch?.();
  };

  const filterAdminTasks = useCallback(
    (details: LauncherLocationDetail[] | undefined) => {
      if (!details) return undefined;
      return details?.filter((listData) =>
        isAdmin
          ? listData?.tasks?.some((task) =>
              task?.assignedUsers?.includes?.(loggedInUserEId)
            )
          : true
      );
    },
    [isAdmin, loggedInUserEId]
  );

  const response = useMemo(
    () =>
      isAdmin
        ? filterAdminTasks(data?.LocationLaunchSupervisorTasks)
        : data?.LocationLaunchList,
    [data, filterAdminTasks, isAdmin]
  );

  const locationList = useMemo(() => {
    let _locationLaunchListData = cloneDeep(response);
    _locationLaunchListData?.forEach((listData) => {
      listData?.tasks?.forEach((task) => {
        let taskDueDate = moment.utc(task?.dueDate);
        let taskStartDate = moment.utc(task?.startDate);
        // let initTaskStartDate = moment.utc(task?.startDate);
        // let bufferApplied = false;
        // if (task.buffer > 0) {
        //   if (!bufferApplied) {
        //     // Apply buffer only to taskDueDate for the first occurrence
        //     taskDueDate = moment(task.dueDate).add(task.buffer, 'days');
        //     bufferApplied = true;
        //   } else {
        //     // Apply buffer to both taskStartDate and taskDueDate for subsequent tasks
        //     initTaskStartDate = moment(task.startDate).add(task.buffer, 'days');
        //     taskDueDate = moment(task.dueDate).add(task.buffer, 'days');
        //   }
        // }
        // let _taskStartDate = moment(
        //   moment.utc(initTaskStartDate),
        //   'DD-MMM-YYYY hh:mm a'
        // )?.tz(loggedInUserTimezone);
        // let taskStartDate = moment.utc(
        //   _taskStartDate?.format('DD-MMM-YYYY hh:mm a'),
        //   'DD-MMM-YYYY hh:mm a'
        // );
        task.dueDate = taskDueDate?.toISOString();
        task.startDate = taskStartDate?.toISOString();
      });
    });
    return toArray(_locationLaunchListData).reduce<LauncherLocationDetail[]>(
      (acc, currentValue) => {
        if (!currentValue.isLive) {
          acc.push({
            ...currentValue,
            isLauncherDue: isLauncherDue(currentValue.tasks),
          });
        }

        return acc;
      },
      []
    );
  }, [response]);

  return (
    <DashboardContainer>
      {/* @ts-ignore */}
      <Flex flexDir='column' gap={8}>
        <LauncherDashHeader />

        <ReadyToLiveLocations
          locations={locationList}
          refetchHandler={refetchHandler}
        />

        <LocationLaunchGraph locations={response} isLoading={loading} />

        {/*<Flex>*/}
        {/*  <PhaseCardList list={launcherPhases} />*/}
        {/*</Flex>*/}

        <ProgressListContainer locations={locationList} isLoading={loading} />
      </Flex>
    </DashboardContainer>
  );
};

export default React.memo(LauncherDashboard);
