import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useWatch } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';

interface LocationState {
  backToTitle?: string;
}

const HeadingName: FC = () => {
  const locState = useLocation<LocationState>().state;
  const history = useHistory();
  const chapterHeading = useWatch<IFormInput>({
    name: 'title',
  });
  const redirectHandler = () => {
    locState?.backToTitle ? history.goBack() : history.push('/folders');
  };
  return (
    <Box display='flex' gap={4}>
      <Box alignItems='baseline'>
        <ArrowBackIcon
          fontSize='4xl'
          cursor='pointer'
          onClick={redirectHandler}
        />
      </Box>
      <Box>
        <Text fontSize='2xl' fontWeight='bold'>
          {chapterHeading || 'Untitled'}
        </Text>
        <Text
          cursor='pointer'
          fontWeight={400}
          fontSize={'15px'}
          color={'rgba(111, 118, 126, 1)'}
          onClick={redirectHandler}
        >
          {locState?.backToTitle || 'Back to Chapters'}
        </Text>
      </Box>
    </Box>
  );
};

export default HeadingName;
