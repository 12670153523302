import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { authTokenVar, isJwtValid } from 'sop-commons/src/client';

interface AuthProps {
  allow?: '$unauthenticated' | '$authenticated';
  redirectTo: string;
}
const Auth: FC<AuthProps> = ({ children, allow, redirectTo }) => {
  const authToken = useReactiveVar(authTokenVar);

  if (allow === '$unauthenticated') {
    if (!isJwtValid(authToken)) return <>{children}</>;
    else {
      return <Redirect to={'/'} />;
    }
  } else if (allow === '$authenticated' && isJwtValid(authToken)) {
    // if (
    //   (pathname === '/dashboard' || pathname === '/') &&
    //   !updatedUserObj?.onboarded
    // ) {
    //   if (
    //     updatedUserObj?.name &&
    //     updatedUserObj?.role &&
    //     updatedUserObj?.authRole === ADMIN
    //   ) {
    //     return <Redirect to={'/onboarding/add-sop'} />;
    //   } else {
    //     return <Redirect to={'/onboarding/company-info'} />;
    //   }
    // } else if (
    //   (pathname === '/onboarding/add-sop' ||
    //     pathname === '/onboarding/company-info' ||
    //     pathname === '/onboarding/sop-list') &&
    //   updatedUserObj?.onboarded
    // )
    // return <Redirect to={'/'} />;
    return <>{children}</>;
  } else {
    return <Redirect to={redirectTo} />;
  }
};

export default Auth;
