export function arraysEqual(a: any, b: any) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function cleanHeader(header: string): string {
  // Regex to remove any optional/mandatory suffixes, considering different casing and spacing
  const regex = /\s*\((mandatory|optional)\)$/i;
  return header.replace(regex, '').trim();
}

export function toCamelCase(header: string): string {
  // Remove any special characters except spaces, underscores, and hyphens
  let cleanedHeader = header.replace(/[^a-zA-Z0-9 _-]/g, '').trim();

  // Split the string into words using space, underscore, or hyphen as the delimiter
  let words = cleanedHeader.split(/[\s_-]+/);

  // Convert to camel case: lowercase the first word, capitalize subsequent words
  let camelCaseHeader = words
    .map((word, index) => {
      // Convert the word to lowercase
      word = word.toLowerCase();
      if (index > 0) {
        // Capitalize the first letter of each subsequent word
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join('');

  return camelCaseHeader;
}
