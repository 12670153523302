import React, { FC, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  List,
  ListItem,
  usePopoverContext,
} from '@chakra-ui/react';
import { AuthRole } from '../../../../../../authorization';
import { PrimaryButton, SelectOption } from '../../../../../../atoms';

import { useJobLocationData } from '../../select-group/useJobLocationData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  value?: string[];
  onChange: (value: string[]) => void;
}

const SelectRoleContent: FC<IProps> = ({ value: _value, onChange }) => {
  const { onClose } = usePopoverContext();

  const [value, setValue] = useState<string[]>(
    _value || [AuthRole.SUPER_ADMIN]
  );

  const { roles } = useJobLocationData();

  const onCheckClick = (item: SelectOption) => {
    if (!value.includes(item.value)) {
      setValue?.([...value, item.value]);
    } else {
      setValue?.(value.filter((v) => v !== item.value));
    }
  };

  return (
    <div>
      <List maxH='60vh' overflow='auto' mb={2}>
        {roles.map((item) => (
          <ListItem
            key={item.value}
            _notLast={{
              borderBottom: '1px solid #EAEBED',
            }}
          >
            <Flex
              gap={3}
              py={3}
              cursor={'pointer'}
              onClick={() => onCheckClick(item)}
            >
              <Flex flexDir='column' flex={1} overflow='hidden'>
                <Box color='#33383F' textTransform='capitalize' isTruncated>
                  {item.label}
                </Box>
              </Flex>

              {item.value === AuthRole.SUPER_ADMIN ? (
                <FontAwesomeIcon icon={faSquareCheck} color={'#2A85FF'} />
              ) : value.includes(item.value) ? (
                <FontAwesomeIcon icon={faSquareCheck} color={'#2A85FF'} />
              ) : (
                <FontAwesomeIcon icon={faSquare} color={'#2A85FF'} />
              )}

              {/* <Checkbox
                isReadOnly={item.value === AuthRole.SUPER_ADMIN}
                isChecked={value.includes(item.value)}
                onChange={(e) => {
                  onCheckClick(item);
                }}
              /> */}
            </Flex>
          </ListItem>
        ))}
      </List>

      <PrimaryButton
        title='Add selected roles'
        colorScheme='blue'
        onClick={() => {
          onChange?.(value);
          onClose();
        }}
      />
    </div>
  );
};

export default SelectRoleContent;
