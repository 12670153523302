import React, { FC, useEffect, useRef, useState } from 'react';
import { CheckIcon, LockIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEllipsis,
  faEye,
  faMobile,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import PrimaryButton from 'atoms/PrimaryButton';
import useCombinedStore from 'zustandStore/store';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { faShare } from '@fortawesome/sharp-regular-svg-icons';
import { useChapterDelete } from 'pages/Chapters/useChapterDelete';
import { useMutation } from '@apollo/client';
import { DELETE_SOP } from 'pages/Chapters/chapters.graphql';
import Loader from 'sub-components/Loader';
import ShareChapterDrawer from 'pages/Chapters/ChapterView/ShareChapterDrawer';
import { SopDetailData } from 'pages/Chapters/ChapterView/chapter.graphql';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import { ISaveChapterData } from 'sub-components/ChapterEditor/hooks/types';
import { Authorize, AuthRole } from 'authorization';
import { FileVisibilityModal } from 'pages/Chapters/CreateChapterModal/FileVisibility';
import FolderVisibility from 'pages/Chapters/FolderVisibility';
import { useUpdateChapterVisibility } from 'pages/Chapters/ChapterView/useUpdateChapterVisibility';
import { IChapterInterface } from 'sub-components/ChapterEditor/chapter.types';
import { useUserDataSelector } from 'hooks';

interface IProps {
  chapterId: string;
  chapterSaveData: ISaveChapterData;
  chapterStatus: string | undefined;
  editorData: IChapterInterface;
  isAnyChangeMade: boolean;
  chapterSaveLoading: boolean;
  chapterUpdateLoading: boolean;
  initialLoad: boolean;
  isEditMode: boolean;
  showTrainingPathFlowModal: boolean;
  getChapterDetails: () => void;
  previewHandler: () => void;
  publishHandler: () => void;
  saveTemporaryHandler: () => void;
  setShowShareDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionButtons: FC<IProps> = ({
  chapterId,
  chapterSaveData,
  chapterStatus,
  editorData,
  isAnyChangeMade,
  chapterSaveLoading,
  chapterUpdateLoading,
  initialLoad,
  isEditMode,
  showTrainingPathFlowModal,
  getChapterDetails,
  previewHandler,
  publishHandler,
  saveTemporaryHandler,
  setShowShareDrawer,
}) => {
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const chapterDelete = useChapterDelete();
  const { updateVisibilityScope } = useCombinedStore();
  const { renderVisibilityModal, updateVisibility } =
    useUpdateChapterVisibility(getChapterDetails);
  const { t } = useTranslation(['common']);
  const [showAIField, setShowAIField] = useState(false);
  const [showState, setShowState] = useState({
    showVisibility: false,
  });
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [deleteChapter, { loading: deletingChapter }] = useMutation(
    DELETE_SOP,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Chapter deleted successfully',
        });
        history.push('/folders');
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Chapter could not be deleted',
        });
      },
    }
  );

  const chapterHeading = useWatch<IFormInput>({
    name: 'title',
  });
  const onDeleteChapterClick = () => {
    chapterDelete({
      onDeletePress: () => {
        if (chapterId) {
          deleteChapter({
            variables: {
              eid: chapterId,
            },
          });
        }
      },
      trainings: [],
    });
  };
  const saveBtnStatusHandler = () => {
    if (
      (chapterSaveData?.savedOnce && !isAnyChangeMade) ||
      chapterSaveLoading ||
      chapterUpdateLoading ||
      (initialLoad && isEditMode && !isAnyChangeMade)
    ) {
      return true;
    }
    return false;
  };
  const onPreviewClick = () => {
    // if (windowRef.current && !windowRef.current?.closed) {
    //   windowRef.current.postMessage(
    //     {
    //       message: 'initChapterPreview',
    //       data: getValues(),
    //     },
    //     '*'
    //   );
    //   windowRef.current?.focus();
    //   return;
    // }
    // window.name = Math.random().toString(36).slice(2);

    // const url = history.createHref({
    //   pathname: '/chapter-preview',
    //   search: `parent=${window.name}`,
    // });

    // windowRef.current = window.open(url, 'chapter-preview');

    // if (windowRef.current) {
    //   const initInt = setInterval(() => {
    //     if (windowRef.current?.closed) {
    //       clearInterval(initInt);
    //     }
    //     windowRef.current.postMessage(
    //       { message: 'isChapterPreviewReady' },
    //       '*'
    //     );
    //   }, 1000);

    //   window.addEventListener('message', (evt) => {
    //     if (evt.data.message === 'chapterPreviewIsReady') {
    //       clearInterval(initInt);
    //       windowRef.current.postMessage(
    //         {
    //           message: 'initChapterPreview',
    //           data: getValues(),
    //         },
    //         '*'
    //       );
    //     }
    //   });
    // }
    previewHandler();
  };
  return (
    <Flex gap={4}>
      {deletingChapter && (
        <Center>
          <Loader size='md' />
        </Center>
      )}
      {isEditMode && loggedInUserAuthRole === AuthRole.SUPER_ADMIN ? (
        <Menu>
          <MenuButton>
            <Center bg='white' boxSize='40px' borderRadius='7px'>
              <FontAwesomeIcon icon={faEllipsis as IconProp} fontSize='20px' />
            </Center>
          </MenuButton>
          <MenuList borderRadius='16px' p={0}>
            <MenuItem
              _hover={{
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
              }}
              onClick={() => {
                // setShowState({ showVisibility: true })
                // setShowVisibilityModal(true);
                updateVisibilityScope('chapter');
                if (editorData?.SopById) {
                  updateVisibility(
                    editorData?.SopById as unknown as SopDetailData
                  );
                }
              }}
            >
              <Flex px={4} pt={2} gap={3} align='center' justify='flex-start'>
                <FontAwesomeIcon icon={faEye as IconProp} />
                <Text my='5px' fontWeight={400} fontSize='14px'>
                  Set visibility
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem onClick={onPreviewClick} isDisabled={!chapterHeading}>
              <Flex px={4} gap={3} align='center' justify='flex-start'>
                <FontAwesomeIcon icon={faUpRightFromSquare as IconProp} />
                <Text my='5px' fontWeight={400} fontSize='14px'>
                  Preview
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem onClick={() => setShowShareDrawer(true)}>
              <Flex px={4} gap={3} align='center' justify='flex-start'>
                <FontAwesomeIcon icon={faShare as IconProp} />
                <Text my='5px' fontWeight={400} fontSize='14px'>
                  Share
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              _hover={{
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
              }}
              onClick={onDeleteChapterClick}
            >
              <Flex px={4} gap={3} pb={2} align='center' justify='flex-start'>
                <FontAwesomeIcon
                  icon={faTrashCan as IconProp}
                  color='rgba(255, 106, 85, 1)'
                />
                <Text my='5px' fontWeight={400} fontSize='14px'>
                  Delete chapter
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <PrimaryButton
          variant='solid'
          title={t('common:preview')}
          disabled={!chapterHeading}
          leftIcon={
            <FontAwesomeIcon icon={faMobile as IconProp} color='#000000' />
          }
          style={{
            color: 'black',
            backgroundColor: 'white',
            paddingLeft: 24,
            paddingRight: 24,
          }}
          onClick={onPreviewClick}
        />
      )}
      {!showTrainingPathFlowModal &&
        (chapterStatus === 'draft' || !chapterStatus) && (
          <Tooltip
            label='This chapter will only be visible to you unless you publish'
            hasArrow
            placement='bottom-end'
          >
            <Box>
              <PrimaryButton
                title={saveBtnStatusHandler() ? 'Saved' : 'Save'}
                leftIcon={saveBtnStatusHandler() ? <CheckIcon /> : <LockIcon />}
                disabled={saveBtnStatusHandler()}
                isLoading={chapterSaveLoading || chapterUpdateLoading}
                colorScheme='blue'
                style={{
                  backgroundColor: saveBtnStatusHandler()
                    ? '#c7c7c7'
                    : '#2a85ff',
                }}
                variant='solid'
                onClick={saveTemporaryHandler}
              />
            </Box>
          </Tooltip>
        )}
      <Authorize
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor='user'
      >
        <PrimaryButton
          variant='solid'
          title={isEditMode ? t('common:saveAndPublish') : t('common:publish')}
          disabled={!chapterHeading}
          // disabled={
          //   !chapterHeading ||
          //   (!isEditMode &&
          //     !chapterSaveData?.savedOnce &&
          //     !showTrainingPathFlowModal)
          // }
          style={{
            color: 'white',
            backgroundColor: 'rgba(131, 191, 110, 1)',
          }}
          onClick={() => {
            publishHandler();
            updateVisibilityScope('chapter');
          }}
        />
      </Authorize>
      {/* {showState?.showVisibility && (
        <FileVisibilityModal
          isEditMode={isEditMode}
          open={showState?.showVisibility}
          showState={showState}
          setShowState={setShowState}
        />
      )} */}
      {/* {showVisibilityModal && (
        <FolderVisibility
          getChaptersList={() => {
            setShowVisibilityModal(false);
            // refetchHandler();
          }}
        />
      )} */}
      {renderVisibilityModal()}
    </Flex>
  );
};

export default ActionButtons;
