import { gql } from '@apollo/client';

export const LAUNCHER_LOCATION_USERS = gql`
  query LocationUser($locationId: String!, $authRoles: [String]) {
    locationUser(locationId: $locationId, authRoles: $authRoles) {
      name
      eid
      type
      role
      authRole
      email
      username
      status
      isExternal
      profilePic
    }
  }
`;

export const LauncherListFragment = gql`
  fragment LauncherLocationsFragment on LauncherLocations {
    locationId
    startDate
    liveDate
    locationId
    launchId
    location {
      eid
      name
      username
    }
    isLive
    tasks {
      eid
      assignedUsers
      title
      startDate
      completedAt
      dueDate
      isCompleted
    }
  }
`;

export const LAUNCHER_LOCATION_DETAILS = gql`
  ${LauncherListFragment}
  query LocationLaunchList {
    LocationLaunchList {
      ...LauncherLocationsFragment
    }
  }
`;

export const LOCATION_LAUNCH_BY_ID = gql`
  query LocationLaunchById($eid: String!) {
    LocationLaunchById(eid: $eid) {
      startDate
      liveDate
      createdAt
      launcherId
      createdBy
      eid
      entityId
      isLive
      locationId
      password
      tasks
      updatedAt
      username
      supervisors
      location {
        eid
        name
      }
      details {
        timezone
        address {
          address
          city
          zipCode
          state
          _id
        }
        locationType
        locationStatus
        locationName
        locationPhone
        locationEmail
        entityName
        entityType
        countryOfFormation
        stateOfFormation
        taxPayerId
        _id
      }
      contents {
        category
        selected
        assignedUser
        tasks {
          description
          eid
          title
        }
        eid
      }
    }
  }
`;

export const LAUNCHER_SUPERVISORS = gql`
  ${LauncherListFragment}
  query LocationLaunchSupervisorTasks {
    LocationLaunchSupervisorTasks {
      ...LauncherLocationsFragment
    }
  }
`;

export const UPDATE_LOCATION_LAUNCH = gql`
  mutation UpdateLocationLaunch($input: LocationLaunchUpdateInputInput) {
    UpdateLocationLaunch(input: $input) {
      succeed
    }
  }
`;
