import { FC, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import './Home.css';
import DashboardContainer from '../../sub-components/DashboardContainer';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import { Authorize, AuthRole } from 'authorization';
import ContinueTraining from 'sub-components/ContinueTraining';
import CardPreviewCarousel from 'sub-components/CardPreviewCarousel';
import HomeFormResponse from 'sub-components/HomeFormResponse';
import { MobileExperienceContainer } from '../../sub-components/MobileExperience';
import { AnalyticsBoard, TasksBoard, TrainingBoard } from 'sub-components';
import { HomeActivityFeed, HomeHeading } from './components';
import { useRenderFlag } from 'sub-components/Sidebar/sections/helper';
import LOInProgress from './components/LOInProgress';
import { userObj } from 'sop-commons/src/client';
import { CommonAmplitudeEvents } from 'shared/amplitudeEvents';

export interface ITasksForToday {
  total: number;
  completed: number;
}

const Home: FC = () => {
  const [showSingleUserChatModal, setShowSingleUserChatModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tasksForTodayData, setTasksForTodayData] = useState<ITasksForToday>({
    total: 0,
    completed: 0,
  });
  const userData = useReactiveVar(userObj);

  const setShowSingleUserChatModalHandler = (value: any) => {
    setShowSingleUserChatModal(value);
  };

  const setSelectedUserHandler = (user: any) => {
    setSelectedUser(user?.eid);
  };

  let flag = useRenderFlag();

  return (
    <DashboardContainer>
      <Box w='full'>
        <HomeHeading userData={userData} />
        {flag ? (
          <VStack align='flex-start' w='full'>
            <Authorize
              permittedFor={['user']}
              permittedRoles={[
                AuthRole.SUPER_ADMIN,
                AuthRole.ADMIN,
                AuthRole.LOCATION_OWNER,
              ]}
            >
              <Flex mb={4} h='auto' w='full'>
                <AnalyticsBoard tasksForTodayData={tasksForTodayData} />
              </Flex>
            </Authorize>
            <Flex justify='space-between' w='full'>
              <Box className='recommend-train-container' mr='1rem'>
                <Flex gap={4} direction={'column'}>
                  <TasksBoard
                    tasksForTodayData={tasksForTodayData}
                    setTasksForTodayData={setTasksForTodayData}
                    event={CommonAmplitudeEvents}
                  />
                  <TrainingBoard event={CommonAmplitudeEvents} />
                  <Authorize
                    permittedFor={['user']}
                    permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
                  >
                    <HomeFormResponse />
                  </Authorize>
                  <ContinueTraining />
                </Flex>
              </Box>
              <Authorize permittedRoles={[AuthRole.WORKER]}>
                <AnalyticsBoard tasksForTodayData={tasksForTodayData} />
              </Authorize>
              <Authorize permittedFor={'branch'}>
                <AnalyticsBoard tasksForTodayData={tasksForTodayData} />
              </Authorize>
              <HomeActivityFeed
                setShowSingleUserChatModalHandler={
                  setShowSingleUserChatModalHandler
                }
                setSelectedUserHandler={setSelectedUserHandler}
              />
            </Flex>
          </VStack>
        ) : (
          <HStack>
            <LOInProgress />
          </HStack>
        )}
        {showSingleUserChatModal && (
          <SingleUserChatModal
            onClose={() => setShowSingleUserChatModal(false)}
            selectedUser={selectedUser}
          />
        )}
      </Box>
      {selectedItem && (
        <CardPreviewCarousel
          deckSelected={selectedItem}
          onCloseClick={() => setSelectedItem(null)}
        />
      )}
      <Authorize canAccess={(authUser) => authUser?.entity?.isDemo}>
        <MobileExperienceContainer />
      </Authorize>
    </DashboardContainer>
  );
};

export default Home;
