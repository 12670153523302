import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Flex, Box, AlertDialogCloseButton } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { ILocation } from 'sub-components/Locations/locationTypes/location-types';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import useCombinedStore from 'zustandStore/store';
import DeleteLocationContent from '.';

interface IProps {
  locationDetails: ILocation;
  onActionError: () => void;
  onActionSuccess: () => void;
}

type DeleteLocation = (props: IProps) => void;

export const useDeleteLocation = (): DeleteLocation => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const launchViaLauncher = useCombinedStore(
    (state) => state.events['preLaunchLocationModal']['goToLauncher']
  );

  useEffect(() => {
    if (launchViaLauncher) {
      confirmRef.current?.destroy();
    }
  }, [launchViaLauncher]);

  return useCallback(({ locationDetails, onActionError, onActionSuccess }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const onActionErrorHandler = () => {
      confirmRef?.current?.destroy();
      onActionError();
    };

    const onActionSuccessHandler = () => {
      confirmRef?.current?.destroy();
      onActionError();
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader
              title='Delete this location?'
              color='rgba(255, 188, 153, 1)'
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <DeleteLocationContent
          locationDetails={locationDetails}
          onActionError={onActionErrorHandler}
          onActionSuccess={onActionSuccessHandler}
        />
      ),
      size: 'md',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
      okText: null,
      onOK: undefined,
      cancelText: null,
    });
  }, []);
};
