import { Flex, Text } from '@chakra-ui/react';
import { REVIEW_ADD_LOCATION } from 'appRoutes';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  openHandler: () => void;
}

const FooterMessage: FC<IProps> = ({ openHandler }) => {
  const history = useHistory();
  return (
    <Flex align='center' w='full' justify='center'>
      <Text as='span' fontWeight={400}>
        Don&apos;t want to launch via launcher?
      </Text>
      &nbsp;
      <Text
        as='span'
        fontWeight={600}
        color='rgba(42, 133, 255, 1)'
        cursor='pointer'
        onClick={() => openHandler()}
      >
        Click here to change status to open
      </Text>
    </Flex>
  );
};

export default FooterMessage;
