import { SelectOption } from 'atoms';
import { LocationStatus, LocationType } from 'types';

export const EntityTypes = [
  'SOLE_PROPRIETORSHIP',
  'GENERAL_PARTNERSHIP',
  'LIMITED_PARTNERSHIP',
  'C_CORPORATION',
  'S_CORPORATION',
  'LLC',
  'CORPORATION',
  'PARTNERSHIP',
  'NON_PROFIT_ORGANIZATION',
  'COOPERATIVE',
  'PROFESSIONAL_CORPORATION',
  'BENEFIT_CORPORATION',
  'SERIES_LLC',
  'L3C',
  'TRUST',
  'ESTATE',
] as const;

export type IEntityTypes = typeof EntityTypes[number];

export const EntityLabel: Record<IEntityTypes, string> = {
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  GENERAL_PARTNERSHIP: 'General Partnership',
  LIMITED_PARTNERSHIP: 'Limited Partnership',
  C_CORPORATION: 'C Corporation',
  S_CORPORATION: 'S Corporation',
  LLC: 'LLC (Limited Liability Company)',
  CORPORATION: 'Corporation',
  PARTNERSHIP: 'Partnership',
  NON_PROFIT_ORGANIZATION: 'Non-Profit Organization',
  COOPERATIVE: 'Cooperative',
  PROFESSIONAL_CORPORATION: 'Professional Corporation',
  BENEFIT_CORPORATION: 'Benefit Corporation',
  SERIES_LLC: 'Series LLC',
  L3C: 'L3C (Low-Profit Limited Liability Company)',
  TRUST: 'Trust',
  ESTATE: 'Estate',
};

export const LocationStatusOption: SelectOption<LocationStatus>[] = [
  { label: 'Launching', value: 'development' },
  { label: 'Open', value: 'open' },
];

export const LocationTypeOption: SelectOption<LocationType>[] = [
  { label: 'Corporate', value: 'corporate' },
  { label: 'Franchise', value: 'franchise' },
];
