import {
  Box,
  Center,
  Flex,
  Input,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
// import { ReactComponent as SearchIcon } from '../../assets/images/searchIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/searchIcon.svg';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import SearchInput from 'atoms/SearchInput';
import styled from '@emotion/styled';
import {
  getSupervisedByMeTasks,
  getUsers,
} from '../services/SupervisedTasks.service';
import Loader from 'ui-components/Loader';
import TaskDataContext from 'pages/TasksDashboard/store/task-context';
import { processSupervisedData } from '../functions/processSupervisedData';
import {
  ISupervisedAPIArgs,
  ITaskOverview,
} from 'pages/TasksDashboard/tasks.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faBarsFilter,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { taskTypeCount } from 'pages/TasksDashboard/MyTasks/functions/taskTypeCount';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import SupervisedTaskItem from './SupervisedTaskItem';
import { useReactiveVar } from '@apollo/client';
import { AuthRole, userObj } from 'sop-commons/src/client';
import debounce from 'lodash.debounce';
import EmptyState from 'sub-components/EmptyState';
import { Authorize } from 'authorization';
import { PrimaryButton } from 'atoms';
import { AddIcon } from '@chakra-ui/icons';
import {
  AmplitudeEventNames,
  deployEvent,
  CommonAmplitudeEvents,
} from 'shared/amplitudeEvents';
import { TASKS_CREATE_URL } from 'appRoutes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserDataSelector } from 'hooks';
import { defaultCSTTimezone } from 'sub-components/tasks/create-task/validation/helper';
import { IEntityUser } from 'shared/global-types';
import { usersEntityObj } from 'sub-components/Header';
import { shallowEqual } from '../../../../utils';

import '../../../../ui-components/Tasks/TasksFilter/TasksFilter.scss';
import MainFilterContainer from '../../../../ui-components/Tasks/TasksFilter/MainFilterContainer';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';

const Wrapper = styled.div`
  .input-search-field {
    background-color: white !important;
    margin-bottom: 20px;
  }
`;

const CurrentRunningComponent: FC<{ data: any | undefined }> = ({ data }) => {
  const taskCtx = useContext(TaskDataContext);
  let overdueData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'overdue')
      ?.map((detail) => detail?.data)?.[0] || [];

  let partiallyDoneData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'partiallyDone')
      ?.map((detail) => detail?.data)?.[0] || [];

  let upcomingData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'upcoming')
      ?.map((detail) => detail?.data)?.[0] || [];

  let thisWeekData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'thisWeek')
      ?.map((detail) => detail?.data)?.[0] || [];

  let dueTodayData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'dueToday')
      ?.map((detail) => detail?.data)?.[0] || [];

  let combinedData = [
    ...overdueData,
    ...partiallyDoneData,
    ...thisWeekData,
    ...dueTodayData,
    ...upcomingData,
  ];

  let overdueFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'overdue')
    ?.map((overview) => overview.selected)?.[0];
  let dueTodayFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'dueToday')
    ?.map((overview) => overview.selected)?.[0];
  let partiallyDoneFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'partiallyDone')
    ?.map((overview) => overview.selected)?.[0];
  let upcomingFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'upcoming')
    ?.map((overview) => overview.selected)?.[0];
  let thisWeek = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'thisWeek')
    ?.map((overview) => overview.selected)?.[0];

  if (
    !overdueFlag &&
    !dueTodayFlag &&
    !partiallyDoneFlag &&
    !upcomingFlag &&
    !thisWeek &&
    combinedData?.length > 0
  ) {
    return (
      <Flex flexDirection='column' gap='10px'>
        <Flex flexDir='column'>
          <List id='current-running-supervised-task-list' spacing={2} p={0}>
            {combinedData?.map((task, index) => {
              return (
                <ListItem
                  key={index}
                  bg='white'
                  padding='8px 12px'
                  borderRadius='8px'
                  _hover={{ bg: '#F9F9F9' }}
                >
                  <SupervisedTaskItem task={task} />
                </ListItem>
              );
            })}
          </List>
        </Flex>
      </Flex>
    );
  }
  return null;
};

const OverdueTasksComponent: FC = () => {
  const taskCtx = useContext(TaskDataContext);
  let overdueFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'overdue')
    ?.map((overview) => overview.selected)?.[0];
  return taskTypeCount(
    taskCtx?.supervisedByMe?.landing?.taskOverviewData,
    'overdue'
  ) > 0 && overdueFlag ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title='Overdue tasks' color='#FF1F0033' />
      <Flex flexDir='column'>
        <List id='overdue-supervised-task-list' p={0} spacing={2}>
          {taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails?.map(
            (task, index) => {
              return (
                task?.type === 'overdue' &&
                task?.data?.map((_data, index) => (
                  <ListItem
                    key={index}
                    bg='white'
                    padding='8px 12px'
                    borderRadius='8px'
                    _hover={{ bg: '#F9F9F9' }}
                  >
                    <SupervisedTaskItem task={_data} />
                  </ListItem>
                ))
              );
            }
          )}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

const TodayTasksComponent: FC = () => {
  const taskCtx = useContext(TaskDataContext);
  let dueTodayFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'dueToday')
    ?.map((overview) => overview.selected)?.[0];
  return taskTypeCount(
    taskCtx?.supervisedByMe?.landing?.taskOverviewData,
    'dueToday'
  ) > 0 && dueTodayFlag ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title="Today's tasks" color='#CABDFF' />
      <Flex flexDir='column'>
        <List id='today-supervised-task-list' p={0} spacing={2}>
          {taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails?.map(
            (task, index) => {
              return (
                task?.type === 'dueToday' &&
                task?.data?.map((_data, index) => (
                  <ListItem
                    key={index}
                    bg='white'
                    padding='8px 12px'
                    borderRadius='8px'
                    _hover={{ bg: '#F9F9F9' }}
                  >
                    <SupervisedTaskItem task={_data} />
                  </ListItem>
                ))
              );
            }
          )}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

const ThisWeekTasksComponent: FC = () => {
  const taskCtx = useContext(TaskDataContext);
  let thisWeekFlag = taskCtx?.supervisedByMe?.landing?.taskOverviewData
    ?.filter((overview) => overview?.id === 'thisWeek')
    ?.map((overview) => overview.selected)?.[0];
  return taskTypeCount(
    taskCtx?.supervisedByMe?.landing?.taskOverviewData,
    'thisWeek'
  ) > 0 && thisWeekFlag ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title="This Week's tasks" color='#B1E5FC' />
      <Flex flexDir='column'>
        <List id='this-week-supervised-task-list' p={0} spacing={2}>
          {taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails?.map(
            (task, index) => {
              return (
                task?.type === 'thisWeek' &&
                task?.data?.map((_data, index) => (
                  <ListItem
                    key={index}
                    bg='white'
                    padding='8px 12px'
                    borderRadius='8px'
                    _hover={{ bg: '#F9F9F9' }}
                  >
                    <SupervisedTaskItem task={_data} />
                  </ListItem>
                ))
              );
            }
          )}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

const UpcomingComponent: FC = () => {
  return null;
};

const ScheduledComponent: FC = () => {
  const taskCtx = useContext(TaskDataContext);
  let scheduledData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'scheduled')
      ?.map((detail) => detail?.data)?.[0] || [];
  let _sortedData = scheduledData.sort(
    (a, b) => a?.dateMoment?.valueOf() - b?.dateMoment?.valueOf()
  );
  return taskTypeCount(
    taskCtx?.supervisedByMe?.landing?.taskOverviewData,
    'scheduled'
  ) > 0 ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title='Scheduled tasks' color='#B1E5FC' />
      <Flex flexDir='column'>
        <List id='scheduled-supervised-task-list' p={0} spacing={2}>
          {_sortedData?.map((task, index) => {
            return (
              <ListItem
                key={index}
                bg='white'
                padding='8px 12px'
                borderRadius='8px'
                _hover={{ bg: '#F9F9F9' }}
              >
                <SupervisedTaskItem task={task} />
              </ListItem>
            );
          })}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

const CompletedTasksComponent: FC<{ data: any | undefined }> = ({ data }) => {
  const taskCtx = useContext(TaskDataContext);
  let count =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'completed')
      ?.map((detail) => detail?.data?.length)?.[0] || 0;
  let completedData =
    taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
      ?.filter((detail) => detail?.type === 'completed')
      ?.map((detail) => detail?.data)?.[0] || [];
  return count > 0 ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title='Completed tasks' color='#b6e4ca' />
      <Flex flexDir='column'>
        <List id='completed-supervised-task-list' p={0} spacing={2}>
          {completedData?.map((task, index) => {
            return (
              <ListItem
                key={index}
                bg='white'
                padding='8px 12px'
                borderRadius='8px'
                _hover={{ bg: '#F9F9F9' }}
              >
                <SupervisedTaskItem task={task} />
              </ListItem>
            );
          })}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

const PartiallyCompletedComponent: FC = () => {
  return null;
};

const TotalTasksComponent: FC<{ data: any | undefined }> = ({ data }) => {
  return null;
  // let isSelected;
  // const taskCtx = useContext(TaskDataContext);
  // let count =
  //   taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
  //     ?.filter((detail) => detail?.type === 'totalTasks')
  //     ?.map((detail) => detail?.data?.length)?.[0] || 0;
  // let totalData =
  //   taskCtx?.supervisedByMe?.landing?.supervisedTaskDetails
  //     ?.filter((detail) => detail?.type === 'totalTasks')
  //     ?.map((detail) => detail?.data)?.[0] || [];
  // return count > 0 ? (
  //   <Flex flexDirection='column' gap='10px'>
  //     <GenericColorHeader title='Total tasks' color='#b6e4ca' />
  //     <Flex flexDir='column'>
  //       <List spacing={2}>
  //         {totalData?.map((task, index) => {
  //           return (
  //             <ListItem key={index} bg='white' p={2} borderRadius='8px'>
  //               <SupervisedTaskItem task={task} />
  //             </ListItem>
  //           );
  //         })}
  //       </List>
  //     </Flex>
  //   </Flex>
  // ) : null;
};

const SupervisedTasks: FC = () => {
  const taskCtx = useContext(TaskDataContext);
  const firstRender = useRef(true); // Using ref to track the first render
  const { t } = useTranslation(['sidebar', 'task']);
  const history = useHistory();
  const usersEntityData: IEntityUser[] = useReactiveVar(usersEntityObj);
  // const { usersEntity } = useUsersEntity();
  // const usersEntityData = usersEntity as IEntityUser[];
  // const userData = useReactiveVar(userObj);

  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      locations: state.locations,
      timezone: state.timezone,
    }),
    shallowEqual
  );

  const filterButtonRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState('');
  const { execute, loading, error, data } = getSupervisedByMeTasks();

  const [totalFilters, setTotalFilters] = useState(0);
  const [isPopoverOpen, setIsPopOverOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');

  const [checkedOptionsData, setCheckedOptionsData] = useState({
    checkedJobList: [],
    checkedLocationList: [],
    checkedMemberList: [],
    checkCreatedByList: [],
  });

  // const {
  //   execute: executeGetUsers,
  //   loading: getUsersLoading,
  //   error: getUsersError,
  //   data: getUsersData,
  // } = getUsers();

  useEffect(() => {
    const timer = setTimeout(() => {
      execute({
        variables: getSupervisorQuery(),
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [totalFilters]);

  const getSupervisorQuery = () => {
    let filter: {
      supervisor: boolean;
      query?: string;
      assignedLocations?: string[];
      assignedRoles?: string[];
      assignedTo?: string[];
      createdByUser?: string[];
    } = {
      supervisor: true,
    };
    if (searchQuery) {
      filter.query = searchQuery;
    }

    if (checkedOptionsData?.checkedJobList?.length > 0) {
      filter.assignedRoles = checkedOptionsData?.checkedJobList;
    }

    if (checkedOptionsData?.checkedLocationList?.length > 0) {
      filter.assignedLocations = checkedOptionsData?.checkedLocationList;
    }

    if (checkedOptionsData?.checkedMemberList?.length > 0) {
      filter.assignedTo = checkedOptionsData?.checkedMemberList;
    }

    if (checkedOptionsData?.checkCreatedByList?.length > 0) {
      filter.createdByUser = checkedOptionsData?.checkCreatedByList;
    }

    return {
      filter,
      sort: '_ID_DESC',
      page: 1,
      perPage: 50,
    } as ISupervisedAPIArgs;
  };

  const triggerPopupCloseOnClearFilter = () => {
    filterButtonRef.current.click();
  };

  // useEffect(() => {
  //   executeGetUsers({
  //     variables: {
  //       entityId: userData?.entityId,
  //     },
  //   });
  // }, [userData?.entityId]);

  useEffect(() => {
    const delayDebounceFn = debounce(() => {
      execute({
        variables: getSupervisorQuery(),
      });
    }, 1000);

    if (firstRender.current) {
      firstRender.current = false; // Set it to false after the first render
    } else {
      // Only call debounce function if it's not the first render
      delayDebounceFn();
    }

    // Cleanup function to cancel debounce timer when component is unmounted or searchQuery changes
    return () => delayDebounceFn.cancel();
  }, [searchQuery]);

  useEffect(() => {
    if (
      data?.TaskPagination &&
      data?.TaskPagination?.count > 0 &&
      usersEntityData &&
      usersEntityData?.length > 0
    ) {
      let { taskDetails, taskOverview } = processSupervisedData(
        data?.TaskPagination?.items,
        userData?.timezone || defaultCSTTimezone,
        usersEntityData,
        userData
      );
      taskCtx?.supervisedTaskDetailsHandler(taskDetails);
      taskCtx?.supervisedTaskOverviewDataHandler(taskOverview);
    } else {
      taskCtx?.supervisedTaskDetailsHandler([]);
      taskCtx?.supervisedTaskOverviewDataHandler([]);
    }
  }, [data, usersEntityData]);

  const taskComponents = {
    currentRunning: CurrentRunningComponent,
    overdue: OverdueTasksComponent,
    dueToday: TodayTasksComponent,
    totalTasks: TotalTasksComponent,
    completed: CompletedTasksComponent,
    partiallyDone: PartiallyCompletedComponent,
    thisWeek: ThisWeekTasksComponent,
    scheduled: ScheduledComponent,
    upcoming: UpcomingComponent,
  };

  const getTaskOverviewToShow = () => {
    // console.log(
    //   'taskCtx.supervisedByMe.landing.taskOverviewData : ',
    //   taskCtx.supervisedByMe.landing.taskOverviewData
    // );
    const selectedTaskOverview =
      taskCtx?.supervisedByMe?.landing?.taskOverviewData?.find(
        (task) =>
          task?.selected &&
          task?.id !== 'completed' &&
          task?.id !== 'totalTasks'
      );
    if (selectedTaskOverview) {
      return [selectedTaskOverview];
    }
    return taskCtx?.supervisedByMe?.landing?.taskOverviewData;
  };

  const taskOverviewSelectionHandler = (task: ITaskOverview) => {
    let _taskOverview: ITaskOverview[] = JSON.parse(
      JSON.stringify(taskCtx?.supervisedByMe?.landing?.taskOverviewData)
    );
    if (task?.id === 'totalTasks') {
      _taskOverview?.map((_task) => {
        if (_task?.id === 'totalTasks') {
          _task.selected = !_task.selected;
        } else {
          _task.selected = false;
        }
      });
    } else {
      _taskOverview?.map((_task) => {
        if (_task?.id === task?.id) {
          _task.selected = !_task?.selected;
        } else {
          _task.selected = false;
        }
      });
    }
    taskCtx?.supervisedTaskOverviewDataHandler(_taskOverview);
  };

  const searchQueryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e?.target?.value);
  };

  const componentRender = () => {
    let overviewStepData = taskCtx?.supervisedByMe?.landing?.taskOverviewData;
    const anySelectedAndCountPositive =
      taskCtx?.supervisedByMe?.landing?.taskOverviewData.some(
        (item) => item.selected && item.count > 0
      );
    const allSelectedFalse = !overviewStepData.some((item) => item.selected);
    const anyCountGreaterThanZero = overviewStepData.some(
      (item) => item.count > 0
    );
    const allCountsZero = overviewStepData.every((item) => item.count === 0);
    const selectedWithZeroCount =
      !allSelectedFalse &&
      overviewStepData.some((item) => item.selected && item.count === 0);

    if (
      anySelectedAndCountPositive ||
      (allSelectedFalse && anyCountGreaterThanZero)
    ) {
      return getTaskOverviewToShow().map((task, index) => {
        const TaskComponent = taskComponents[task?.id];
        return <TaskComponent key={index} data={data} />;
      });
    } else if (allSelectedFalse && allCountsZero) {
      return (
        <EmptyState
          image='MyTask'
          title='No tasks found'
          description='Adjust your filters or create a new task to get started.'
        />
      );
    } else if (selectedWithZeroCount) {
      return (
        <EmptyState image='MyTask' title='Selected category has no task' />
      );
    }
  };

  const extraFilterData = {
    //loading status after every filter action
    loading,
    //Other Props
    setTotalFilters,
    isPopoverOpen,
    totalFilters,
    triggerPopupCloseOnClearFilter,
  };

  return (
    <Flex flexDirection='column' gap='20px'>
      <Flex align='center' justify='space-between'>
        <Text fontWeight={700} fontSize='28px'>
          Tasks
        </Text>
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor='user'
        >
          <PrimaryButton
            leftIcon={<AddIcon />}
            title={t('task:addNewTask')}
            width='fit-content'
            variant='solid'
            style={{ backgroundColor: 'white', color: '#2A85FF' }}
            onClick={() => {
              deployEvent(AmplitudeEventNames.TASK_CREATE);
              history.push(TASKS_CREATE_URL);
            }}
          />
        </Authorize>
      </Flex>
      <Wrapper>
        <Flex className='search-filter-container' gap={'12px'}>
          <SearchInput
            className='input-search-field'
            value={searchQuery}
            onChange={searchQueryHandler}
            placeholder='Search'
            hideShortcuts
          />

          <Popover
            placement='bottom-start'
            onOpen={() => {
              deployEvent(CommonAmplitudeEvents.FILTERS);
              setIsPopOverOpen(true);
            }}
            onClose={() => setIsPopOverOpen(false)}
          >
            {/* @ts-ignore */}
            <PopoverTrigger>
              <Box ref={filterButtonRef} className='filter-button'>
                <PrimaryButton
                  leftIcon={<FontAwesomeIcon icon={faBarsFilter as IconProp} />}
                  title={`Filter ${
                    totalFilters ? '(' + totalFilters + ')' : ''
                  }`}
                  width='8vw'
                  variant='solid'
                  style={{
                    backgroundColor: 'white',
                    color: '#2A85FF',
                    border: isPopoverOpen ? '1px solid #2A85FF' : undefined,
                    minWidth: '151px',
                  }}
                  rightIcon={
                    totalFilters ? (
                      <FontAwesomeIcon
                        icon={faTimes as IconProp}
                        style={{ marginLeft: '1rem', fontSize: '1.2rem' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCheckedOptionsData({
                            ...checkedOptionsData,
                            checkedJobList: [],
                            checkedLocationList: [],
                            checkedMemberList: [],
                            checkCreatedByList: [],
                          });
                        }}
                      />
                    ) : null
                  }
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent p='12px' maxW='400px' w='350px'>
              <PopoverArrow />
              <PopoverBody>
                {/* 
                  > Create a single object for the filter props. No need to pass all the data in props individually.
                  > On click of 'Clear all', close the popover as well
                  > Confirm that on each checkbox click backend api has to be called or not
                  > While fetching the filtered data from backend, if any filter list is opened, disable the selection of checkboxes during that time
                  > Add empty state for each of the filter list. Check for <EmptyState /> component that is being used across the project
                 */}
                <MainFilterContainer
                  extraFilterData={extraFilterData}
                  setCheckedOptionsData={setCheckedOptionsData}
                  checkedOptionsData={checkedOptionsData}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>

        {!loading ? (
          <Flex flexDir='column' gap={4}>
            <Flex flexDir='column' gap='20px'>
              <Flex
                gap={'20px'}
                align='center'
                justifyContent={'space-between'}
              >
                {taskCtx?.supervisedByMe?.landing?.taskOverviewData
                  ?.filter((overview) => overview.show)
                  ?.map((task, index) => {
                    //persisting the selected task status
                    if (task.id === selectedTaskId) {
                      task.selected = true;
                    }

                    return (
                      <Flex
                        key={index}
                        cursor='pointer'
                        p={6}
                        borderRadius='8px'
                        border={task?.selected ? '1px solid #2b85ff' : 'none'}
                        bg='white'
                        flexDir='column'
                        w='20vw'
                        gap='10px'
                        onClick={() => {
                          setSelectedTaskId(task?.id);
                          taskOverviewSelectionHandler(task);
                        }}
                      >
                        <Flex align='center' justify='space-between'>
                          <Flex gap='10px' align='center'>
                            <Box
                              h='20px'
                              w='12px'
                              bg={task?.color}
                              borderRadius='2px'
                            />
                            <Text>{task?.name}</Text>
                          </Flex>
                          {task?.selected && (
                            <FontAwesomeIcon
                              icon={faCircleCheck as IconProp}
                              color='#2b85ff'
                            />
                          )}
                        </Flex>
                        <Text fontWeight={700} fontSize='20px'>
                          {task?.count} {task?.count === 1 ? 'task' : 'tasks'}
                        </Text>
                      </Flex>
                    );
                  })}
              </Flex>
              {componentRender()}
              {/* {getTaskOverviewToShow().map((task, index) => {
                const TaskComponent = taskComponents[task?.id];
                return <TaskComponent key={index} data={data} />;
              })} */}
            </Flex>
          </Flex>
        ) : (
          <Center h='80vh'>
            <Loader />
          </Center>
        )}
      </Wrapper>
    </Flex>
  );
};

export default SupervisedTasks;
