import React, { FC, useEffect, useState } from 'react';
import DashboardContainer from '../../sub-components/DashboardContainer';
import './EditTraining.scss';
import '../CreateDeck/CreateDeck.scss';
import { useHistory } from 'react-router-dom';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useForm } from 'react-hook-form';
import { useRouter } from '../../utils';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { getTrainingPathIcons } from 'sop-commons/Query/Training';
import {
  TRAINING_BY_ID_COMPLETE_NEW,
  GET_ALL_MEMBER_LOCATION_DATA,
} from './edit-training.graphql';
import { UPDATE_TRAINING } from './edit-training.graphql';
import {
  ICreateChangeLog,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import { IPublishChangeDiffCheckTable } from 'pages/Training/PublishChangeDiffCheckModal/PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { CREATE_CHANGE_LOG } from 'pages/TrainingDraft/training-draft-queries-mutations/training-draft-graphql';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { IMultiSelectListData } from 'sub-components/MultiSelectChipDropdown/MultiSelectChipDropdown';
import { useConfirm } from 'ui-components/Confirm/Confirm';
import PreviewHeader from 'sub-components/forms/component/PreviewHeader';
import Loader from 'ui-components/Loader';
import { CardModal, HandbookModal } from 'modal';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import {
  publishChangesHandler,
  sameSequenceCheck,
  getTrainingDataHandler,
} from './helper';
import EditTrainingHeading from './EditTrainingHeading';
import EditTrainingModals from './EditTrainingModals';
import EditTrainingBody from './EditTrainingBody';
export interface ISelectedIcon {
  thumbnail: string;
  thumbnailColor: string;
}

export type IMilestoneModalClose = 'close' | 'save';

const EditTraining: FC = () => {
  const { t } = useTranslation(['training', 'common']);
  const history = useHistory();
  const [isMilestoneModalOpen, setIsMilestoneModalOpen] = useState(false);
  const [actionCardModalOpened, setActionCardModalOpened] = useState(false);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isAnyChange, setIsAnyChange] = useState(false);
  const [milestoneType, setMilestoneType] = useState('');
  const [selectedMilestone, setSelectedMilestone] = useState<any>(null);
  const [editMilestoneData, setEditMilestoneData] = useState([]);
  const [trainingIcons, setTrainingIcons] = useState<any>([]);
  const [initialTrainingIcons, setInitialTrainingIcons] = useState<any>([]);
  const [showKnowledgeBaseDrawer, setShowKnowledgeBaseDrawer] = useState(false);
  const [openPowerupPreviewModal, setOpenPowerupPreviewModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedCardType, setSelectedCardType] =
    useState<IPowerUpModalTypes['types']>('');
  const [isPublishChangeDiffCheckModalOpen, setIsPublishChangeDiffCheckModal] =
    useState(false);
  const [changedData, setChangedData] = useState<
    IPublishChangeDiffCheckTable[]
  >([]);
  const [updatedTrainingInput, setUpdatedTrainingInput] = useState<any>({});
  const [flagCount, setFlagCount] = useState(0);
  const [thirdStepSelections, setThirdStepSelections] =
    useState<IThirdStepData>({
      roles: [],
      supervisors: [],
      locations: [],
      members: [],
    });
  const [thirdStepFinalSelections, setThirdStepFinalSelections] =
    useState<IThirdStepData>({
      roles: [],
      supervisors: [],
      locations: [],
      members: [],
    });
  const [thirdStepData, setThirdStepData] = useState<IThirdStepData>({
    roles: [],
    supervisors: [],
    locations: [],
    members: [],
  });
  const [changeLogResponse, setChangeLogResponse] = useState<
    ICreateChangeLogChangesEntity[]
  >([]);
  const [selectedIcon, setSelectedIcon] = useState<ISelectedIcon>({
    thumbnail: '',
    thumbnailColor: '',
  });
  const [trainingProgressModal, setTrainingProgressModal] = useState(false);
  const toast = useToast();
  const confirm = useConfirm();
  const [checkEditCardOrMilestone, setCheckEditCardOrMilestone] =
    useState(false);
  const [editCardData, setEditCardData] = useState<any>([]);
  const [getTrainingPathIconsHandler, { loading: trainingIconsLoading }] =
    useLazyQuery(getTrainingPathIcons, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setInitialTrainingIcons(data?.iconsList?.items);
        setTrainingIcons(data?.iconsList?.items);
      },
    });
  const [updateTraining, { loading: isPublishingChanges }] = useMutation(
    /////
    UPDATE_TRAINING,
    {
      onCompleted: (data) => {
        setUpdatedTrainingInput({});
        if (data?.UpdateTraining?.eid) {
          setTrainingData(data?.UpdateTraining);
          let contentById: any = {};
          data?.UpdateTraining?.contentDetails?.forEach((content: any) => {
            contentById[content.eid] = content;
          });
          setContentById({ ...contentById });
          updateChangeLogHandler();
        }
      },
      onError: () => {
        toast({
          title: t('training:training_path_update_error_toast'),
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setUpdatedTrainingInput({});
      },
    }
  );

  const [createChangeLog, { loading: createChangeLogLoading }] = useMutation(
    CREATE_CHANGE_LOG,
    {
      onCompleted: (data) => {
        toast({
          title: t('training:training_path_update_success_toast'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        history.push('/training');
      },
      onError: (error) => {
        toast({
          title: t('training:training_path_update_error_toast'),
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        history.push('/training');
      },
    }
  );
  useEffect(() => {
    if (
      thirdStepData?.members?.length > 0 &&
      thirdStepFinalSelections?.members?.length > 0 &&
      flagCount === 0
    ) {
      setFlagCount(1);
      let _thirdStepFinalSelections: IThirdStepData = JSON.parse(
        JSON.stringify(thirdStepFinalSelections)
      );
      _thirdStepFinalSelections?.members?.map((member) => {
        let foundMember = thirdStepData?.members?.find(
          (_member) => _member?.id === member?.id
        );
        if (foundMember) {
          member.location = foundMember?.location;
        }
      });
      setThirdStepFinalSelections(_thirdStepFinalSelections);
    }
  }, [thirdStepData?.members, thirdStepFinalSelections?.members]);

  const updateChangeLogHandler = () => {
    let trainingChangeLogInput: ICreateChangeLog = {
      // changes: dataUpdation,
      changes: changeLogResponse,
      status: 'active',
      trainingId: router.query.id,
    };
    let _trainingChangeLogInput: ICreateChangeLog = JSON.parse(
      JSON.stringify(trainingChangeLogInput)
    );
    _trainingChangeLogInput.changes.map((change) => {
      if (
        change.changeEvent === 'cardAdded' ||
        change.changeEvent === 'cardRemoved'
      ) {
        delete change.title;
        delete change.milestone;
        delete change.icon;
      }
    });
    createChangeLog({
      variables: {
        input: _trainingChangeLogInput,
      },
    });
  };

  const [trainingData, setTrainingData] = useState<any>(null);
  const [initialTrainingData, setInitialTrainingData] = useState<any>(null);
  const [contentById, setContentById] = useState<any>({});
  const userData = useReactiveVar(userObj);

  const router: any = useRouter();

  const [trainingByIdData, { loading: loadingTraining }] = useLazyQuery(
    TRAINING_BY_ID_COMPLETE_NEW,
    {
      fetchPolicy: 'network-only',
      onCompleted: (_data) => {
        let data = cloneDeep(_data);
        if (data?.trainingById?.eid) {
          getTrainingDataHandler(
            data,
            setTrainingData,
            setSelectedIcon,
            setInitialTrainingData,
            setContentById,
            setThirdStepSelections,
            setThirdStepFinalSelections
          );
        }
      },
    }
  );

  const getRolesData = () => {
    let roleData: any = [];
    userData?.entity?.roles
      ? userData?.entity?.roles?.forEach(
          (role: { name: string; eid: string }) => {
            // roleData.push(key);
            roleData.push({
              id: role?.eid,
              label: role?.name,
              value: role?.name,
              selected: false,
              url: '',
            });
          }
        )
      : [];
    setThirdStepData({ ...thirdStepData, roles: roleData });
  };

  useEffect(() => {
    getAllUsersHandler();
  }, [userData?.entityId]);

  const getAllUsersHandler = () => {
    getAllUsers({
      variables: {
        entityId: userData?.entityId,
      },
    });
  };

  const [getAllUsers] = useLazyQuery(GET_ALL_MEMBER_LOCATION_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let users = data?.EntityUser?.filter(
        (entity: any) => entity?.type === 'user'
      );
      let locations = data?.EntityUser?.filter(
        (entity: any) => entity?.type === 'branch'
      );
      let _members: IMultiSelectListData[] = [];
      let _supervisors: IMultiSelectListData[] = [];
      let _locations: IMultiSelectListData[] = [];
      users?.map((user: any) => {
        if (userData?.eid !== user?.eid) {
          _members?.push({
            id: user?.eid,
            label: user?.name,
            value: user?.name,
            selected: false,
            url: user?.profilePic,
            role: user?.role,
            authRole: user?.authRole,
            location: user?.locations?.[0]?.name || 'N/A',
            locations: user?.locations,
          });
          _supervisors?.push({
            id: user?.eid,
            label: user?.name,
            value: user?.name,
            selected: false,
            url: user?.profilePic,
            authRole: user?.authRole,
            role: user?.role,
            locations: user?.locations,
            location: user?.locations?.[0]?.name || 'N/A',
          });
          // if (user?.authRole !== WORKER) {
          // }
        }
      });
      locations?.map((branch: any) => {
        _locations?.push({
          id: branch?.eid,
          label: branch?.name,
          value: branch?.name,
          selected: false,
          url: '',
        });
      });
      setThirdStepData({
        ...thirdStepData,
        members: _members,
        supervisors: _supervisors,
        locations: _locations,
      });
    },
  });

  useEffect(() => {
    getRolesData();
  }, [userData?.entity?.roles]);

  useEffect(() => {
    if (router.query.id) {
      trainingByIdData({
        variables: {
          eid: router.query.id,
        },
      });
      getTrainingPathIconsHandler();
    }
  }, [router?.query?.id]);

  useEffect(() => {
    if (trainingData?.title) {
      setValue('name', trainingData?.title);
    }
  }, [trainingData?.eid, trainingData?.title, trainingData?.status]);

  useEffect(() => {
    getTrainingPathIconsHandler();
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  const onFinish = (values: any) => {};

  useEffect(() => {
    checkChangesHandler();
  }, [
    trainingData?.title,
    trainingData?.status,
    trainingData?.trainingItems,
    trainingData?.supervisors,
    trainingData?.assignedToUsers,
    checkEditCardOrMilestone,
    selectedIcon?.thumbnail,
    trainingData?.visibility,
    thirdStepFinalSelections?.locations,
    thirdStepFinalSelections?.members,
    thirdStepFinalSelections?.roles,
    thirdStepFinalSelections?.supervisors,
  ]);

  const sameSequenceCheckHelperFn = () => {
    return sameSequenceCheck(
      trainingData,
      initialTrainingData,
      thirdStepFinalSelections
    );
  };

  const toggleChangeCheck = () => {
    function hasMandatoryFieldChanged() {
      for (let item of trainingData.trainingItems) {
        let initialItem = initialTrainingData.trainingItems.find(
          (i: any) => i?.eid === item?.eid
        );
        if (initialItem && initialItem.isMandatory !== item.isMandatory) {
          return true;
        }
      }

      for (let initialItem of initialTrainingData.trainingItems) {
        let item = trainingData.trainingItems.find(
          (i: any) => i?.eid === initialItem?.eid
        );
        if (!item || initialItem.isMandatory !== item.isMandatory) {
          return true;
        }
      }
      return false;
    }
    return hasMandatoryFieldChanged();
  };

  const checkChangesHandler = () => {
    if (
      (trainingData?.title !== initialTrainingData?.title &&
        trainingData?.title.trim().length !== 0) ||
      trainingData?.status !== initialTrainingData?.status ||
      trainingData?.trainingItems?.length !==
        initialTrainingData?.trainingItems?.length ||
      sameSequenceCheckHelperFn() ||
      checkEditCardOrMilestone ||
      selectedIcon?.thumbnail !== trainingData?.thumbnail ||
      trainingData?.visibility !== initialTrainingData?.visibility ||
      toggleChangeCheck()
    ) {
      setIsAnyChange(true);
    } else {
      setIsAnyChange(false);
    }
  };

  const createdUser = trainingData?.createdBy;

  const handleCardEdit = (cardId: any) => {
    setIsCardModalOpened(true);
    let filterdData = trainingData.trainingItems.filter(
      (data: any) => data.eid === cardId && data.type === 'card'
    );
    setEditCardData(filterdData);
  };

  const onCardEditSuccess = async (_data: any) => {
    let data = { ..._data, type: 'card', added: true };
    let tempArr = cloneDeep(trainingData);
    let translatedData = new CardModal(data);
    let _tempTrainingItemsArr: any[] = [];
    tempArr.trainingItems.map((item: any) => {
      if (item.eid === translatedData.eid) {
        _tempTrainingItemsArr.push(translatedData);
      } else {
        _tempTrainingItemsArr.push(item);
      }
    });
    tempArr.trainingItems = _tempTrainingItemsArr;
    // let tmpAry = await Promise.all(promise);
    // tempArr.trainingItems = tempArr.trainingItems;
    // contentById[data.eid] = tmpAry[0];
    setTrainingData(tempArr);
    setCheckEditCardOrMilestone(true);
  };

  const handleRemove = (id: string) => {
    let removeCardIndex = -1;

    for (let i = 0; i < trainingData?.trainingItems?.length; i++) {
      const card = trainingData?.trainingItems[i];
      if (card?.eid === id) {
        removeCardIndex = i;
        break;
      }
    }
    // trainingData?.contents?.splice(removeCardIndex, 1);
    let updatedTrainingData = cloneDeep(trainingData);
    let tempArr: any = [];
    updatedTrainingData?.trainingItems?.map((data: any, index: number) => {
      if (index !== removeCardIndex) {
        tempArr.push(data);
      }
    });
    let tempArrDetail: any = [];
    updatedTrainingData?.trainingItems?.map((data: any, index: number) => {
      if (index !== removeCardIndex) {
        tempArrDetail.push(data);
      }
    });
    updatedTrainingData.contents = tempArr;
    updatedTrainingData.trainingItems = tempArrDetail;
    // setSortingCardList([...selected]);
    setTrainingData({ ...updatedTrainingData });
  };

  const onCardDelete = () => {
    handleRemove(editCardData?.[0]?.eid);
    setTimeout(() => {
      setEditCardData([]);
      setIsCardModalOpened(false);
    });
  };

  const closeActionCardModal = () => {
    setActionCardModalOpened(false);
  };

  const returnedCardDataHandler = (data: any) => {
    let _trainingData = cloneDeep(trainingData);
    let extractedReturnedData = data?.data?.createCard?.[0] || data;
    let tempArr = cloneDeep(trainingData?.trainingItems);
    let _returnedData = cloneDeep(extractedReturnedData);
    _returnedData.type = ['action', 'video', 'form', 'quiz'].includes(
      selectedCardType
    )
      ? 'card'
      : 'milestone';
    if (
      _returnedData?.type === 'milestone' &&
      (!_returnedData?.eid || !_returnedData?.tempId || !_returnedData?.tempEid)
    ) {
      _returnedData.tempId = Date.now();
    }
    tempArr.push(new CardModal(_returnedData));
    _trainingData.trainingItems = tempArr;
    setTrainingData(_trainingData);
  };

  const closeCardModal = () => {
    setEditCardData([]);
    setIsCardModalOpened(false);
  };

  const closeUpdateDeckAddModalHandler = () => {
    setShowSearchModal(false);
  };

  const cardsToBeAddedHandler = (cards: any) => {
    let contentsArr: any = cloneDeep(trainingData?.contents);
    let contentDetails: any = cloneDeep(trainingData?.contentDetails);
    let contentByIdObject: any = JSON.parse(JSON.stringify(contentById));
    let tempArr = cloneDeep(trainingData);
    cards.forEach((card: any) => {
      let _card = cloneDeep(card);
      // _card.type = _card.type || 'card';
      let _data = {
        eid: _card?.eid,
        title: null,
        type: _card?.type || 'card',
      };
      contentsArr.push(_data);
      // contentDetails.push(_card);
      contentDetails.push(card);

      contentByIdObject[_card?.eid] = _card;
      setContentById(contentByIdObject);
    });
    tempArr.contents = contentsArr;
    tempArr.contentDetails = new HandbookModal(contentDetails)?.data;
    setTrainingData(tempArr);
  };

  const publishChangesHelperFn = () => {
    publishChangesHandler(
      trainingData,
      router,
      getValues,
      selectedIcon,
      thirdStepSelections,
      initialTrainingData,
      setUpdatedTrainingInput,
      publishHandler
    );
  };

  const selectedIconHandler = (icon: any) => {
    setSelectedIcon({
      thumbnail: icon?.url,
      thumbnailColor: icon?.backgroundColor,
    });
  };

  const searchedStringHandler = (search: string) => {
    if (!search) {
      setTrainingIcons(initialTrainingIcons);
    } else {
      let filteredIcons = initialTrainingIcons?.filter((icon: any) =>
        icon?.tags?.some((tag: any) => tag?.includes(search))
      );
      setTrainingIcons(filteredIcons);
    }
  };

  const closeModalHandler = () => {
    setTrainingProgressModal(false);
  };

  const previewHandler = (_data: any) => {};

  const publishHandler = (inputData: any) => {
    updateTraining({
      variables: {
        input: inputData,
      },
    });
  };

  const publishChangeDiffCheckModalCloseHandler = (
    type: 'cancel' | 'publish' | 'continue'
  ) => {
    if (type === 'cancel') {
      confirm({
        title: (
          <PreviewHeader
            title={t('training:discard_change_heading')}
            color='red.500'
          />
        ),
        content: (
          <Flex>
            <Box w={8} />
            <Box>{t('training:discard_change_description')}</Box>
          </Flex>
        ),
        okText: t('common:yes'),
        isCentered: true,
        contentProps: {
          paddingTop: '12px',
          paddingBottom: '12px',
        },
        onOK: discardChangesHandler,
      });
    } else {
      setIsPublishChangeDiffCheckModal(false);
      if (type === 'publish') {
        updateTraining({
          variables: {
            input: updatedTrainingInput,
          },
        });
      }
    }
  };

  const discardChangesHandler = () => {
    history.push('/training');
  };

  const getCustomTrainingDataHandler = () => {
    let _trainingData = cloneDeep(trainingData);
    let _contents: any[] = [];
    let _fullContentDetails: any[] = [];
    _trainingData?.contentDetails?.forEach((content: any) => {
      if (content?.type !== 'deck') {
        _fullContentDetails?.push(content);
      } else {
        content?.cards?.forEach((card: any) => {
          _fullContentDetails?.push(card);
        });
      }
    });
    _fullContentDetails?.filter((content: any) => {
      let found = _trainingData?.trainingItems?.find(
        (expand: any) => expand?.eid === content?.eid
      );
      if (found) {
        _contents?.push(found);
      } else {
        _contents?.push(content);
      }
    });
    _trainingData.contentDetails = _contents;
    _trainingData.contentDetails = new HandbookModal(_contents)?.data;
    return _trainingData;
  };

  return (
    <DashboardContainer>
      <div className='create-deck-container'>
        <EditTrainingHeading
          loadingTraining={loadingTraining}
          trainingData={trainingData}
          isPublishingChanges={isPublishingChanges}
          createChangeLogLoading={createChangeLogLoading}
          isAnyChange={isAnyChange}
          thirdStepFinalSelections={thirdStepFinalSelections}
          publishChangesHelperFn={publishChangesHelperFn}
        />

        <div className='edit-training-container'>
          {loadingTraining && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              h='60vh'
              w='full'
            >
              <Loader />
            </Box>
          )}
          {!loadingTraining && (
            <EditTrainingBody
              handleSubmit={handleSubmit}
              onFinish={onFinish}
              setEditCardData={setEditCardData}
              setSelectedCardType={setSelectedCardType}
              setIsMilestoneModalOpen={setIsMilestoneModalOpen}
              setMilestoneType={setMilestoneType}
              setEditMilestoneData={setEditMilestoneData}
              setActionCardModalOpened={setActionCardModalOpened}
              setIsCardModalOpened={setIsCardModalOpened}
              setShowKnowledgeBaseDrawer={setShowKnowledgeBaseDrawer}
              trainingIcons={trainingIcons}
              trainingIconsLoading={trainingIconsLoading}
              selectedIconHandler={selectedIconHandler}
              searchedStringHandler={searchedStringHandler}
              selectedIcon={selectedIcon}
              errors={errors}
              trainingData={trainingData}
              checkChangesHandler={checkChangesHandler}
              register={register}
              setTrainingData={setTrainingData}
              handleRemove={handleRemove}
              setSelectedMilestone={setSelectedMilestone}
              handleCardEdit={handleCardEdit}
              setShowPreview={setShowPreview}
              setOpenPowerupPreviewModal={setOpenPowerupPreviewModal}
              createdUser={createdUser}
              thirdStepFinalSelections={thirdStepFinalSelections}
              setThirdStepSelections={setThirdStepSelections}
              thirdStepSelections={thirdStepSelections}
              setThirdStepFinalSelections={setThirdStepFinalSelections}
              setThirdStepData={setThirdStepData}
              thirdStepData={thirdStepData}
            />
          )}
        </div>
      </div>
      <EditTrainingModals
        isMilestoneModalOpen={isMilestoneModalOpen}
        setIsMilestoneModalOpen={setIsMilestoneModalOpen}
        milestoneType={milestoneType}
        userData={userData}
        trainingData={trainingData}
        contentById={contentById}
        setTrainingData={setTrainingData}
        setContentById={setContentById}
        setCheckEditCardOrMilestone={setCheckEditCardOrMilestone}
        selectedMilestone={selectedMilestone}
        editMilestoneData={editMilestoneData}
        editCardData={editCardData}
        isCardModalOpened={isCardModalOpened}
        closeCardModal={closeCardModal}
        returnedCardDataHandler={returnedCardDataHandler}
        actionCardModalOpened={actionCardModalOpened}
        closeActionCardModal={closeActionCardModal}
        selectedCardType={selectedCardType}
        showSearchModal={showSearchModal}
        closeUpdateDeckAddModalHandler={closeUpdateDeckAddModalHandler}
        cardsToBeAddedHandler={cardsToBeAddedHandler}
        previewHandler={previewHandler}
        onCardEditSuccess={onCardEditSuccess}
        onCardDelete={onCardDelete}
        trainingProgressModal={trainingProgressModal}
        closeModalHandler={closeModalHandler}
        getCustomTrainingDataHandler={getCustomTrainingDataHandler}
        isPublishChangeDiffCheckModalOpen={isPublishChangeDiffCheckModalOpen}
        showKnowledgeBaseDrawer={showKnowledgeBaseDrawer}
        setShowKnowledgeBaseDrawer={setShowKnowledgeBaseDrawer}
        openPowerupPreviewModal={openPowerupPreviewModal}
        setOpenPowerupPreviewModal={setOpenPowerupPreviewModal}
      />
    </DashboardContainer>
  );
};

export default EditTraining;
