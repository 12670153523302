import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faMessage,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

import { CometChatUserList } from '../../Users';
import { CometChatGroupList } from '../../Groups';
import { CometChatConversationList } from '../../Chats';
import { CometChatUserProfile } from '../../UserProfile';

import * as enums from '../../../util/enums.js';
import { CometChatContext } from '../../../util/CometChatContext';

import Translator from '../../../resources/localization/translator';
import { theme } from '../../../resources/theme';
import tabs from '../../../resources/tabs.json';
import { ReactComponent as ChatMessageIcon } from './resources/chat-message-icon.svg';

import { ReactComponent as ProfileCircledIcon } from './resources/profile-circled-icon.svg';

import { checkAccess } from 'utils/checkChatAccess';

import {
  footerStyle,
  navbarStyle,
  itemStyle,
  itemLinkStyle,
  itemLinkTextStyle,
  itemIconStyle,
} from './style';

import chatGreyIcon from './resources/cometChatIcon.svg';
import contactGreyIcon from './resources/cometChatUserIcon.svg';
import groupGreyIcon from './resources/groups.svg';
import moreGreyIcon from './resources/more.svg';

import { UserListManager } from '../../Users/CometChatUserList/controller';
import { ConversationListManager } from '../../Chats/CometChatConversationList/controller';
import ArchivedChats from './Archived';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

function compare(x, y) {
  return x.name.localeCompare(y.name);
}

export class CometChatNavBar extends React.Component {
  static contextType = CometChatContext;
  timeout;
  tabListKeys = [];
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
      tabList: [],
      userlist: [],
      decoratorMessage: Translator.translate('LOADING', props.lang),
      conversationlist: [],
      decoratorMessageConversation: Translator.translate('LOADING', props.lang),
    };
  }

  componentDidMount() {
    let tabList = this.context.UIKitSettings.tabs;

    tabList.forEach((tabName) => {
      for (const t in tabs) {
        if (tabName === tabs[t]) {
          this.tabListKeys.push(t);
        }
      }
    });

    this.getFilteredTabs().then((filteredTabs) => {
      this.setState({ tabList: filteredTabs, activeTab: filteredTabs[0] });
    });

    const storedUserList = this.context.userList;
    if (storedUserList && storedUserList?.length) {
      this.setState({
        userlist: [...storedUserList],
        decoratorMessage: '',
      });
    }
    this.UserListManager = new UserListManager(this.context);
    this.UserListManager.initializeUsersRequest()
      .then((response) => {
        const isUsersAlreadyPresent = !!storedUserList?.length;

        this.getUsers(isUsersAlreadyPresent);

        this.UserListManager.attachListeners(this.userUpdated);
      })
      .catch((error) =>
        this.setState({
          decoratorMessage: Translator.translate(
            'SOMETHING_WRONG',
            this.props.lang
          ),
        })
      );
    this.ConversationListManager = new ConversationListManager(this.context);

    // fetch conversations...
    // this.setState({ conversationlist: [] }, () => {
    //   this.ConversationListManager = new ConversationListManager(this.context);
    //   this.getConversations();
    //   // this.ConversationListManager.attachListeners(this.conversationCallback);
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.activeTab &&
      prevState.activeTab !== this.state.activeTab &&
      this.state.activeTab === 'SIDEBAR_CHATS'
    ) {
      console.log('Active tab', this.state.activeTab);
      this.ConversationListManager = new ConversationListManager(this.context);
      this.getConversations(true);
      // this.setState({ conversationlist: [] }, () => {
      //   // this.ConversationListManager.attachListeners(this.conversationCallback);
      // });
    }
    // if()
  }

  componentWillUnmount() {
    this.UserListManager.removeListeners();
    this.UserListManager = null;
    this.ConversationListManager.removeListeners();
    this.ConversationListManager = null;
  }

  getConversations = (callOnUpdate) => {
    this.ConversationListManager.fetchNextConversation()
      .then((conversationData) => {
        let conversationList = [...conversationData];
        if (conversationList.length === 0) {
          if (this.state.conversationlist.length === 0) {
            this.setState({
              decoratorMessageConversation: Translator.translate(
                'NO_CHATS_FOUND',
                this.props.lang
              ),
            });
          }
        } else {
          this.setState({ decoratorMessageConversation: '' });
        }

        let conversations = [...this.state.conversationlist];

        //if conversation exists already in the state, remove it from this list
        conversationList.forEach((eachConversation) => {
          let conversationKey = conversations.findIndex(
            (c) => c.conversationId === eachConversation.conversationId
          );
          if (conversationKey > -1) {
            const newUnreadMessageCount =
              conversations[conversationKey]['unreadMessageCount'] +
              eachConversation['unreadMessageCount'];
            const updatedConversation = {
              ...conversations[conversationKey],
              unreadMessageCount: newUnreadMessageCount,
            };
            conversations.splice(conversationKey, 1, updatedConversation);

            conversationList.splice(conversationKey, 1);
          }
        });

        if (!callOnUpdate) {
          this.setState({
            conversationlist: [...conversations, ...conversationList],
          });
        } else {
          conversationData.forEach((eachConversation) => {
            let conversationKey = conversations.findIndex(
              (c) => c.conversationId === eachConversation.conversationId
            );

            if (conversationKey > -1) {
              const newUnreadMessageCount =
                // conversations[conversationKey]['unreadMessageCount'] +
                eachConversation['unreadMessageCount'];
              const updatedConversation = {
                ...conversations[conversationKey],
                unreadMessageCount: newUnreadMessageCount,
              };
              conversations.splice(conversationKey, 1, updatedConversation);
              let conversationKeyNewList = conversationData.findIndex(
                (c) => c.conversationId === eachConversation.conversationId
              );
              if (conversationData[conversationKeyNewList]?.lastMessage) {
                updatedConversation.lastMessage =
                  conversationData[conversationKeyNewList].lastMessage;
              }

              conversationData.splice(
                conversationKeyNewList,
                1,
                updatedConversation
              );
            }
          });

          this.setState({
            conversationlist: [...conversationData],
          });
        }
      })
      .catch((error) => {
        console.log('Errtor', error);
        this.setState({
          decoratorMessageConversation: Translator.translate(
            'SOMETHING_WRONG',
            this.props.lang
          ),
        });
      });
  };

  userUpdated = (user) => {
    let userlist = [...this.state.userlist];

    //search for user
    let userKey = userlist.findIndex((u) => u.uid === user.uid);

    //if found in the list, update user object
    if (userKey > -1) {
      let userObj = { ...userlist[userKey] };
      let newUserObj = { ...userObj, ...user };
      userlist.splice(userKey, 1, newUserObj);
      let userlist = userlist.sort(compare);
      this.setState({ userlist: userlist });
    }
  };

  searchUsers = (e) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    let val = e.target.value;
    this.UserListManager = new UserListManager(this.context, val);

    this.UserListManager.initializeUsersRequest()
      .then((response) => {
        this.timeout = setTimeout(() => {
          this.setState(
            {
              userlist: [],
              decoratorMessage: Translator.translate(
                'LOADING',
                this.props.lang
              ),
            },
            () => this.getUsers()
          );
        }, 500);
      })
      .catch((error) =>
        this.setState({
          decoratorMessage: Translator.translate(
            'SOMETHING_WRONG',
            this.props.lang
          ),
        })
      );
  };

  getUsers = (isUsersAlreadyPresent) => {
    this.UserListManager.fetchNextUsers()
      .then((userList) => {
        if (userList.length === 0) {
          if (this.state.userlist.length === 0) {
            this.setState({
              decoratorMessage: Translator.translate(
                'NO_USERS_FOUND',
                this.props.lang
              ),
            });
          }
        } else {
          if (!isUsersAlreadyPresent) {
            let userData = [...this.state.userlist, ...userList];
            userData.sort(compare);

            this.context.setUserList(userData);
            this.setState({
              userlist: [...userData],
              decoratorMessage: '',
            });
          }
        }
      })
      .catch((error) =>
        this.setState({
          decoratorMessage: Translator.translate(
            'SOMETHING_WRONG',
            this.props.lang
          ),
        })
      );
  };

  getFilteredTabs = () => {
    return new Promise((resolve) => {
      const filteredTabs = [];
      const promises = [
        this.enableChats(),
        this.enableUsers(),
        this.enableGroups(),
        this.enableSettings(),
      ];
      Promise.allSettled(promises).then((results) => {
        this.tabListKeys.forEach((eachTabKey) => {
          results.forEach((result) => {
            const tabKey = result.value[0];
            const tabEnabled = result.value[1];

            if (eachTabKey === tabKey && tabEnabled === true) {
              filteredTabs.push(eachTabKey);
            }
          });
        });
        resolve(filteredTabs);
      });
    });
  };

  enableChats = () => {
    return new Promise((resolve) => {
      this.context.FeatureRestriction.isRecentChatListEnabled()
        .then((response) => resolve(['SIDEBAR_CHATS', response]))
        .catch((error) => resolve(['SIDEBAR_CHATS', false]));
    });
  };

  enableUsers = () => {
    return new Promise((resolve) => {
      this.context.FeatureRestriction.isUserListEnabled()
        .then((response) => resolve(['SIDEBAR_USERS', response]))
        .catch((error) => resolve(['SIDEBAR_USERS', false]));
    });
  };

  enableGroups = () => {
    return new Promise((resolve) => {
      this.context.FeatureRestriction.isGroupListEnabled()
        .then((response) => resolve(['SIDEBAR_GROUPS', response]))
        .catch((error) => resolve(['SIDEBAR_GROUPS', false]));
    });
  };

  enableSettings = () => {
    return new Promise((resolve) => {
      this.context.FeatureRestriction.isUserSettingsEnabled()
        .then((response) => resolve(['SIDEBAR_MOREINFO', response]))
        .catch((error) => resolve(['SIDEBAR_MOREINFO', false]));
    });
  };

  tabChanged = (tab) => {
    if (tab === 'SIDEBAR_USERS') {
      deployEvent(CommonAmplitudeEvents.CHAT_USER_TAB);
    } else if (tab === 'SIDEBAR_CHATS') {
      deployEvent(CommonAmplitudeEvents.CHAT_TAB);
    }
    this.setState({ activeTab: tab });
  };

  updateUserList = (userData, decoratorMessage) => {
    this.setState({
      userlist: [...userData],
      decoratorMessage: decoratorMessage,
    });
  };

  updateConversationList = (conversationData, decoratorMessage) => {
    this.setState({
      conversationlist: [...conversationData],
      decoratorMessageConversation: decoratorMessage,
    });
  };

  toggleArchive = () => {
    this.setState({
      archive: !this.state.archive,
    });
  };

  getActiveTab = () => {
    switch (this.state.activeTab) {
      case 'SIDEBAR_USERS':
        return (
          <CometChatUserList
            theme={this.props.theme}
            lang={this.context.language}
            userlist={this.state.userlist}
            updateUserList={this.updateUserList}
            decoratorMessage={this.state.decoratorMessage}
            getUsers={this.getUsers}
            searchUsers={this.searchUsers}
            _parent='unified'
            actionGenerated={this.props.actionGenerated}
            // businessId={this.props.businessId}
            onItemClick={(item, type) =>
              this.props.actionGenerated(
                enums.ACTIONS['ITEM_CLICKED'],
                type,
                item
              )
            }
          />
        );
      case 'SIDEBAR_CALLS':
        return null;
      case 'SIDEBAR_CHATS':
        return (
          <CometChatConversationList
            theme={this.props.theme}
            lang={this.context.language}
            // businessId={this.props.businessId}
            _parent='unified'
            actionGenerated={this.props.actionGenerated}
            conversationlist={this.state.conversationlist}
            decoratorMessage={this.state.decoratorMessageConversation}
            updateConversationList={this.updateConversationList}
            getConversations={this.getConversations}
            conversationListManager={this.ConversationListManager}
            onItemClick={(item, type) =>
              this.props.actionGenerated(
                enums.ACTIONS['ITEM_CLICKED'],
                type,
                item
              )
            }
            onCreateGroupEvent={this.props.onCreateGroupEvent}
            showArchive={this.toggleArchive}
          />
        );
      case 'SIDEBAR_GROUPS':
        return (
          <CometChatGroupList
            theme={this.props.theme}
            lang={this.context.language}
            _parent='unified'
            actionGenerated={this.props.actionGenerated}
            // businessId={this.props.businessId}
            onItemClick={(item, type) =>
              this.props.actionGenerated(
                enums.ACTIONS['ITEM_CLICKED'],
                type,
                item
              )
            }
          />
        );
      case 'SIDEBAR_MOREINFO':
        return (
          <CometChatUserProfile
            theme={this.props.theme}
            lang={this.context.language}
            onItemClick={(item, type) =>
              this.props.actionGenerated(
                enums.ACTIONS['ITEM_CLICKED'],
                type,
                item
              )
            }
          />
        );
      default:
        return null;
    }
  };

  getTabList = () => {
    const chatsTabActive =
      this.state.activeTab === 'SIDEBAR_CHATS' ? true : false;
    const userTabActive =
      this.state.activeTab === 'SIDEBAR_USERS' ? true : false;
    const groupsTabActive =
      this.state.activeTab === 'SIDEBAR_GROUPS' ? true : false;
    const moreTabActive =
      this.state.activeTab === 'SIDEBAR_MOREINFO' ? true : false;

    const tabList = [...this.state.tabList];

    return tabList.map((tab) => {
      switch (tab) {
        case 'SIDEBAR_CHATS':
          return (
            <div
              key={tab}
              css={itemStyle(this.props, chatsTabActive)}
              className='navbar__item'
              onClick={() => this.tabChanged('SIDEBAR_CHATS')}
            >
              <div
                css={itemLinkStyle(chatGreyIcon, chatsTabActive, this.context)}
                className='item__link item__link__chats'
                title={Translator.translate('CHATS', this.context.language)}
              >
                <ChatMessageIcon
                  css={itemIconStyle(
                    chatGreyIcon,
                    chatsTabActive,
                    this.context
                  )}
                />
              </div>

              <div
                css={itemLinkTextStyle(chatsTabActive, this.context)}
                className='item__label'
              >
                {Translator.translate('CHATS', this.context.language)}
              </div>
            </div>
          );
        case 'SIDEBAR_USERS':
          if (
            checkAccess(
              this.context.chatEntityConfig?.startDM?.restrictType,
              this.context.chatEntityConfig,
              this.context.loggedInUserDetails
            ) ||
            this.context.chatEntityConfig.restrictions
          ) {
            return (
              <div
                key={tab}
                css={itemStyle(this.props, userTabActive)}
                className='navbar__item'
                onClick={() => this.tabChanged('SIDEBAR_USERS')}
              >
                <div
                  css={itemLinkStyle(
                    contactGreyIcon,
                    userTabActive,
                    this.context
                  )}
                  className='item__link item__link__contacts'
                  title={Translator.translate('USERS', this.context.language)}
                >
                  <ProfileCircledIcon
                    css={itemIconStyle(
                      chatGreyIcon,
                      userTabActive,
                      this.context
                    )}
                  />
                </div>

                <div
                  css={itemLinkTextStyle(userTabActive, this.context)}
                  className='item__label'
                >
                  {Translator.translate('USERS', this.context.language)}
                </div>
              </div>
            );
          }
          return null;
        case 'SIDEBAR_GROUPS':
          return (
            <div
              key={tab}
              css={itemStyle(this.props)}
              className='navbar__item'
              onClick={() => this.tabChanged('SIDEBAR_GROUPS')}
            >
              {/* <div
                css={itemLinkStyle(
                  groupGreyIcon,
                  groupsTabActive,
                  this.context
                )}
                className='item__link item__link__groups'
                title={Translator.translate('GROUPS', this.context.language)}
              ></div> */}
              <FontAwesomeIcon
                style={{
                  ...itemLinkStyle(null, groupsTabActive, this.context),
                }}
                icon={faUserFriends}
              />
              <div
                css={itemLinkTextStyle(groupsTabActive, this.context)}
                className='item__label'
              >
                {Translator.translate('GROUPS', this.context.language)}
              </div>
            </div>
          );
        case 'SIDEBAR_MOREINFO':
          return (
            <div
              key={tab}
              css={itemStyle(this.props)}
              className='navbar__item'
              onClick={() => this.tabChanged('SIDEBAR_MOREINFO')}
            >
              <div
                css={itemLinkStyle(moreGreyIcon, moreTabActive, this.context)}
                className='item__link item__link__info'
                title={Translator.translate('MORE', this.context.language)}
              ></div>
              <div
                css={itemLinkTextStyle(moreTabActive, this.context)}
                className='item__label'
              >
                {Translator.translate('MORE', this.context.language)}
              </div>
            </div>
          );
        default:
          return null;
      }
    });
  };

  render() {
    if (this.state.archive) {
      return (
        <ArchivedChats
          archivedIds={this.context.archivedChats}
          conversationlist={this.state.conversationlist}
          onClose={this.toggleArchive}
          onItemClick={(item, type) =>
            this.props.actionGenerated(
              enums.ACTIONS['ITEM_CLICKED'],
              type,
              item
            )
          }
        />
      );
    }

    return (
      <React.Fragment>
        <div css={footerStyle()} className='sidebar__footer'>
          <div css={navbarStyle()} className='footer__navbar'>
            {this.getTabList()}
          </div>
        </div>
        {this.getActiveTab()}
      </React.Fragment>
    );
  }
}

// Specifies the default values for props:
CometChatNavBar.defaultProps = {
  theme: theme,
};

CometChatNavBar.propTypes = {
  theme: PropTypes.object,
  onCreateGroupEvent: PropTypes.func,
};
