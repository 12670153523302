import { Checkbox, Divider, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import React, { FC } from 'react';
import useCombinedStore from 'zustandStore/store';

const SameAsParent: FC = () => {
  const loggedInUserAuthRole = useUserDataSelector((state) => state?.authRole);
  const {
    hierarchyData,
    sameAsParent,
    visibilityScope,
    updateCustomSectionExpand,
    updateSameAsParent,
    updateVisibilitySelection,
  } = useCombinedStore();

  console.log({
    SameAsParent: { hierarchyData, sameAsParent, visibilityScope },
  });

  if (visibilityScope === 'folder') {
    return null;
  }

  const checkboxChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (loggedInUserAuthRole === AuthRole?.LOCATION_OWNER) {
      return;
    }
    if (event.target.checked && hierarchyData && hierarchyData?.parentFolder) {
      if (visibilityScope === 'chapter') {
        updateSameAsParent(true);
        updateCustomSectionExpand(false);
        updateVisibilitySelection(
          hierarchyData?.subFolder
            ? hierarchyData?.subFolder?.visibilityData
            : hierarchyData?.parentFolder?.visibilityData
        );
      } else {
        updateSameAsParent(true);
        updateCustomSectionExpand(false);
        updateVisibilitySelection(hierarchyData?.parentFolder?.visibilityData);
      }
    } else {
      updateSameAsParent(false);
      updateVisibilitySelection({
        locations: [],
        roles: [],
        users: [],
        condition: undefined,
        visibility: 'private',
      });
    }
  };

  return (
    <Flex flexDir='column' gap={4} mt={4}>
      <Flex
        align='center'
        justify='space-between'
        cursor={
          loggedInUserAuthRole === AuthRole.LOCATION_OWNER
            ? 'no-drop'
            : 'default'
        }
      >
        <Flex
          align='center'
          gap={2}
          opacity={`${
            loggedInUserAuthRole !== AuthRole.LOCATION_OWNER ? 1 : 0.6
          }`}
        >
          <FontAwesomeIcon icon={faFolder as IconProp} fontSize='17px' />
          <Text fontSize='20px' fontWeight='600'>
            Same as parent
          </Text>
        </Flex>
        <Checkbox
          size='lg'
          isChecked={sameAsParent}
          disabled={loggedInUserAuthRole === AuthRole?.LOCATION_OWNER}
          onChange={checkboxChangeHandler}
        />
      </Flex>
      <Divider />
    </Flex>
  );
};

export default SameAsParent;
