import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import { Flex, useToast } from '@chakra-ui/react';
import { Button } from 'atoms';
import { AuthRole } from 'authorization';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';
import { usersEntityObj } from 'sub-components/Header';
import { v4 as uuidv4 } from 'uuid';
import {
  CHECK_USERNAME_EXISTS,
  UPLOAD_BULK_EXISTING_LOCATIONS,
  UPLOAD_BULK_PRE_LAUNCH_LOCATIONS,
} from '../bulk-locations.graphql';
import {
  ExistingLocationUploadResponse,
  LocationUploadVariables,
  PreLaunchLocationUploadResponse,
  ProcessedEntity,
} from '../types';
import BaseCard from './BaseCard';
import { preProcessData } from './locations-upload-pre-process';

interface IProps {
  fileJsonData: any[];
  type: 'existing' | 'new';
  onActionSuccess: () => void;
  onActionError: () => void;
}

interface Update {
  [key: number]: Partial<ProcessedEntity>;
}

const SetupLocations: FC<IProps> = ({
  fileJsonData,
  type,
  onActionError,
  onActionSuccess,
}) => {
  const [processedData, setProcessedData] = useState<ProcessedEntity[]>([]);
  const client = useApolloClient();
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const usersEntityData = useReactiveVar(usersEntityObj);

  const locationOwners = useMemo(() => {
    return usersEntityData?.filter(
      (entity) =>
        entity.type === 'user' && entity.authRole === AuthRole.LOCATION_OWNER
    );
  }, [usersEntityData]);

  useEffect(() => {
    const jsonData: ProcessedEntity[] = fileJsonData.map((data) => ({
      data,
      id: uuidv4(),
      accordionState: false,
      locationUserName: '',
      readyToProceed: false,
      password: '',
      confirmPassword: '',
      passwordVisibleToggle: false,
      confirmPasswordVisibleToggle: false,
      owners: [],
      ownersError: '',
      locationUsernameError: '',
      passwordError: '',
      confirmPasswordError: '',
      loading: false,
      showList: false,
    }));
    setProcessedData(jsonData);
  }, [fileJsonData]);

  const [bulkUploadExistingLocation, { loading: uploadingExistingLocations }] =
    useMutation<ExistingLocationUploadResponse, LocationUploadVariables>(
      UPLOAD_BULK_EXISTING_LOCATIONS,
      {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          toast({
            status: 'success',
            title: 'success',
            description: 'Existing locations uploaded successfully!',
          });
          execute();
        },
        onError: () => {
          toast({
            status: 'error',
            title: 'error',
            description: 'Existing locations could not be uploaded',
          });
          onActionError();
        },
      }
    );

  const onLoggedInUserFetchSuccess = () => {
    onActionSuccess();
  };

  const onLoggedInUserFetchError = () => {
    onActionError();
  };

  const { execute, loading } = getLoggedInUserDataHandler(
    onLoggedInUserFetchSuccess,
    onLoggedInUserFetchError
  );

  const importLocations = () => {
    let inputData = preProcessData(processedData, type);
    if (type === 'existing') {
      bulkUploadExistingLocation({
        variables: {
          input: inputData,
        },
      });
    }
  };

  const validateAndSetProcessedData = (updates: Update) => {
    console.log('updates : ', updates);
    const newProcessedData = processedData.map((item, index) => ({
      ...item,
      ...(updates[index] || {}),
    }));
    console.log('New Processed data : ', newProcessedData);
    setProcessedData(newProcessedData);
  };

  const togglePasswordVisibility = (index: number) => {
    const update = {
      passwordVisibleToggle: !processedData[index].passwordVisibleToggle,
    };
    validateAndSetProcessedData({ [index]: update });
  };

  const toggleConfirmPasswordVisibility = (index: number) => {
    const update = {
      confirmPasswordVisibleToggle:
        !processedData[index].confirmPasswordVisibleToggle,
    };
    validateAndSetProcessedData({ [index]: update });
  };

  const updateFieldsHandler = (
    type: 'username' | 'password' | 'confirmPassword',
    index: number,
    value: string
  ) => {
    const update: Partial<ProcessedEntity> = {};
    if (type === 'username') {
      update.locationUserName = value;
    } else if (type === 'password') {
      update.password = value;
    } else if (type === 'confirmPassword') {
      update.confirmPassword = value;
    }
    validateAndSetProcessedData({ [index]: update });
  };

  const validations = (data: ProcessedEntity, index: number): boolean => {
    const errors: Partial<ProcessedEntity> = {
      locationUsernameError: '',
      passwordError: '',
      confirmPasswordError: '',
      ownersError: '',
    };
    if (!data.locationUserName) {
      errors.locationUsernameError = 'Location username is required';
    }
    if (!data.password || data.password.length < 6) {
      errors.passwordError = data.password
        ? 'Min. 6 characters are required'
        : 'Password is required';
    }
    if (!data.confirmPassword || data.confirmPassword.length < 6) {
      errors.confirmPasswordError = data.confirmPassword
        ? 'Min. 6 characters are required'
        : 'Confirm Password is required';
    }
    if (data.password !== data.confirmPassword) {
      errors.passwordError = 'Passwords do not match';
      errors.confirmPasswordError = 'Passwords do not match';
    }
    if (data?.owners?.length === 0) {
      errors.ownersError = 'Location owner(s) is required';
    }

    let locationNames: string[] = [];
    processedData?.map((process) => {
      if (
        process?.locationUserName &&
        process?.id !== data?.id &&
        process.readyToProceed
      ) {
        locationNames.push(process?.locationUserName);
      }
    });
    if (locationNames?.includes(data?.locationUserName)) {
      errors.locationUsernameError = 'Location username already exists';
    }

    console.log('errors : ', errors);
    validateAndSetProcessedData({ [index]: errors });

    // Return false if any errors were set, indicating validation failed
    return (
      !errors.locationUsernameError &&
      !errors.passwordError &&
      !errors.confirmPasswordError &&
      !errors.ownersError
    );
  };

  const verify = async (data: ProcessedEntity, index: number) => {
    if (!validations(data, index)) return;
    const updates: Update = {
      [index]: {
        loading: true,
        locationUsernameError: '',
        confirmPasswordError: '',
        passwordError: '',
      },
    };
    validateAndSetProcessedData(updates);

    try {
      const response = await client.query({
        query: CHECK_USERNAME_EXISTS,
        fetchPolicy: 'network-only',
        variables: { usernames: [data.locationUserName] },
      });
      if (!response?.data?.CheckUsernameExists?.found?.length) {
        updates[index].readyToProceed = true;
        updates[index].accordionState = false;
      } else {
        updates[index].locationUsernameError = 'Username already exists';
      }
    } catch (err) {
      console.error('Error verifying username:', err);
    } finally {
      updates[index].loading = false;
      validateAndSetProcessedData(updates);
    }
  };

  return (
    <Flex gap={2} flexDir='column'>
      {processedData.map((data, index) => (
        <BaseCard
          key={data.id}
          data={data}
          index={index}
          processedData={processedData}
          setProcessedData={setProcessedData}
          locationOwnersList={locationOwners}
          toggleConfirmPasswordVisibilityHandler={() =>
            toggleConfirmPasswordVisibility(index)
          }
          togglePasswordVisibilityHandler={() =>
            togglePasswordVisibility(index)
          }
          updateConfirmPasswordHandler={(confirmPassword: string) =>
            updateFieldsHandler('confirmPassword', index, confirmPassword)
          }
          updatePasswordHandler={(password: string) =>
            updateFieldsHandler('password', index, password)
          }
          updateUserNameHandler={(username: string) =>
            updateFieldsHandler('username', index, username)
          }
          verify={() => verify(data, index)}
        />
      ))}
      <Button
        colorScheme='blue'
        variant='solid'
        size='lg'
        w='full'
        isDisabled={
          processedData.some((process) => !process.readyToProceed) ||
          processedData?.some(
            (process) =>
              process?.locationUsernameError ||
              process?.passwordError ||
              process?.confirmPasswordError ||
              process?.ownersError
          ) ||
          uploadingExistingLocations ||
          loading
        }
        isLoading={uploadingExistingLocations || loading}
        onClick={importLocations}
      >
        Import Locations
      </Button>
    </Flex>
  );
};

export default SetupLocations;
