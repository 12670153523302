import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { BoxHeader, useConfirm } from '../../../ui-components';
import { AlertBox } from '../../../atoms';
import { HeaderColors } from '../../../shared/headerColors/header-colors';

interface IProps {
  title?: string;
  desc?: string;
  onOkPress?: (...args: any[]) => any | PromiseLike<any>;
  onCancelPress?: (...args: any[]) => any | PromiseLike<any>;
}

type IOpenLocationConfirm = (props: IProps) => void;

export const useOpenLocationConfirm = (): IOpenLocationConfirm => {
  const confirm = useConfirm();
  const readMoreUrl = 'https://8332146.hs-sites.com/en/knowledgebase/locations';

  return useCallback(
    ({
      title = 'You are adding a chargeable location',
      desc = 'Please note that adding an open location or a location through the launcher would be chargeable.',
      onOkPress,
      onCancelPress,
    }) => {
      confirm({
        title: (
          <Flex gap='12px' justify='space-between'>
            <BoxHeader title={title} color={HeaderColors.Green} />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={() => onCancelPress?.()}
            />
          </Flex>
        ),
        content: (
          <Box>
            <Box as='span' color='rgba(51, 56, 63, 1)'>
              {desc}
            </Box>
            &nbsp;
            <Box
              as='span'
              color='rgba(42, 133, 255, 1)'
              cursor='pointer'
              fontWeight={600}
              onClick={() => window.open(readMoreUrl, '_blank')}
            >
              Read more about billing
            </Box>
          </Box>
        ),
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '10px',
          minW: '550px',
        },
        okButtonProps: {
          colorScheme: 'blue',
          fontSize: '15px',
          size: 'lg',
          borderRadius: '12px',
          minW: '50%',
        },
        cancelButtonProps: {
          size: 'lg',
          variant: 'outline',
          fontSize: '15px',
          borderRadius: '12px',
          minW: '50%',
        },
        okText: 'Continue',
        cancelText: 'Cancel',
        onOK: onOkPress,
        onCancel: onCancelPress,
      });
    },
    [confirm]
  );
};
