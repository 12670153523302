import { Button } from 'atoms';
import { useUserDataSelector } from 'hooks';
import React, { FC } from 'react';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  launchHandler: () => void;
}

const ActionButton: FC<IProps> = ({ launchHandler }) => {
  const { emitEvent } = useCombinedStore();
  const loggedInUserEntity = useUserDataSelector((state) => state?.entity);
  const launcherPublished = loggedInUserEntity?.launcher?.published; // Launcher is published
  const isLauncherEnabled = loggedInUserEntity?.features?.launcher; // Launcher is enabled from admin panel

  return (
    <Button
      variant='solid'
      colorScheme='blue'
      size='lg'
      w='full'
      isDisabled={!launcherPublished || !isLauncherEnabled}
      onClick={() =>
        // emitEvent('preLaunchLocationModal', 'goToLauncher', { msg: 'redirect' })
        launchHandler()
      }
    >
      Launch via launcher
    </Button>
  );
};

export default ActionButton;
