import React, { ChangeEventHandler, FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import SearchInput from '../../../atoms/SearchInput';
import Dropdown, { SelectOption } from '../../../atoms/Dropdown';
import {
  ICityStateList,
  ILocationStatusEntity,
  ISelectedFilterTab,
} from '../locations/LocationListContainer';
import { TeamTabType } from '../useActiveTeamTab';
import LocationStatusTabs from './LocationStatusTabs';
import LocationFilter from './LocationFilter';

interface IProps {
  activeTab?: TeamTabType;
  searchQuery?: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  sortBy?: SelectOption | null;
  onSortChange?: (value: SelectOption | null) => void;
  selectedFilterTab?: ISelectedFilterTab;
  showLocationFilter?: boolean;
  setSelectedFilterTab?: React.Dispatch<
    React.SetStateAction<ISelectedFilterTab>
  >;
  setInitialCityStateList: React.Dispatch<React.SetStateAction<ICityStateList>>;
  setCityStateList: React.Dispatch<React.SetStateAction<ICityStateList>>;
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
  initialCityStateList: ICityStateList;
  cityStateList: ICityStateList;
  filterByCategory: SelectOption<string>[];
  locationStatus?: ILocationStatusEntity;
  locationFilterChangeHandler: (option: SelectOption<string>[]) => void;
}

const SearchFilterHeader: FC<IProps> = ({
  activeTab,
  searchQuery,
  onSearch,
  sortBy,
  onSortChange,
  selectedFilterTab,
  showLocationFilter = false,
  setSelectedFilterTab,
  setSelectedPage,
  initialCityStateList,
  cityStateList,
  setInitialCityStateList,
  setCityStateList,
  locationStatus,
  filterByCategory,
  locationFilterChangeHandler,
}) => {
  const { t } = useTranslation(['common', 'handbook']);

  const _onSortChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === sortBy?.value) {
        onSortChange?.(null);
      } else {
        onSortChange?.(option);
      }
    },
    [sortBy, onSortChange]
  );

  return (
    <Flex
      justify={activeTab === 'locations' ? 'space-between' : 'flex-end'}
      gap='12px'
      mb='8px'
      flexDir='column'
    >
      {activeTab === 'locations' && (
        <LocationStatusTabs
          initialCityStateList={initialCityStateList}
          cityStateList={cityStateList}
          selectedFilterTab={selectedFilterTab}
          setSelectedFilterTab={setSelectedFilterTab}
          setInitialCityStateList={setInitialCityStateList}
          setCityStateList={setCityStateList}
          setSelectedPage={setSelectedPage}
          locationStatus={locationStatus}
        />
      )}
      <Flex
        align='center'
        gap='10px'
        justify={activeTab === 'locations' ? 'space-between' : 'flex-end'}
      >
        <SearchInput
          disabled={true}
          size='md'
          width='400px'
          hideShortcuts={true}
          placeholder={t('common:search')}
          onChange={onSearch}
          value={searchQuery}
        />
        <Flex gap={4}>
          <LocationFilter
            filterByCategory={filterByCategory}
            showFilter={showLocationFilter}
            locationFilterChangeHandler={locationFilterChangeHandler}
          />
          <Flex gap='12px'>
            <Box width='210px' zIndex='100'>
              <Dropdown
                placeholder={t('handbook:sort')}
                value={sortBy}
                onChange={_onSortChange}
                options={[
                  // {
                  //   label: t('handbook:filter.last_updated'),
                  //   value: 'Last Updated',
                  // },
                  {
                    label: t('handbook:filter.last_created'),
                    value: 'Last Created',
                  },
                  {
                    label: t('handbook:filter.alphabetically'),
                    value: 'Alphabetically',
                  },
                  // { label: t('handbook:filter.active'), value: 'Active' },
                  // { label: t('handbook:filter.inactive'), value: 'Inactive' },
                ]}
                selectStyles={{
                  singleValue: {
                    color: '#6f767e',
                    fontWeight: '600',
                  },
                }}
                components={{
                  SingleValue: (valProps) => {
                    return (
                      <chakraComponents.SingleValue {...valProps}>
                        <Trans
                          t={t}
                          i18nKey='handbook:sort_by'
                          values={{
                            value: valProps.children,
                          }}
                        />
                      </chakraComponents.SingleValue>
                    );
                  },
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchFilterHeader;
