import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, SearchInput } from 'atoms';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from 'sub-components/EmptyState';
import { LocationMember } from 'types';
import Loader from 'ui-components/Loader';
import { getImage } from 'utils';
import { ICustomEntityUser } from '../../types/location-owner-list-menu.types';
import { AmplitudeEvent, deployEvent } from '../../../../../../../../shared';

interface IProps {
  eid?: string;
  loading: boolean;
  items: ICustomEntityUser[];
  allLocationOwners: ICustomEntityUser[];
  owners: LocationMember[];
  searchQuery: string;
  updatingLocationOwners: boolean;
  onAddLOClick: () => void;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectionHandler: (user: ICustomEntityUser) => void;
  selectedOwnersHandler: (eid: string, selectedLOEids: string[]) => void;
}

const LocationOwnerListMenu: FC<IProps> = ({
  eid,
  loading,
  items,
  allLocationOwners,
  owners,
  searchQuery,
  updatingLocationOwners,
  onAddLOClick,
  setMenuOpen,
  setSearchQuery,
  selectionHandler,
  selectedOwnersHandler,
}) => {
  const areSelectionAndInitialDifferent = (
    selectedLOEids: string[],
    owners: LocationMember[]
  ): boolean => {
    if (selectedLOEids?.length !== owners?.length) {
      return true; // Arrays are of different lengths, so data is definitely different
    }
    const ownersEidsSet = new Set(owners?.map((owner) => owner?.eid));
    // Check if every selectedLOEid is in the ownersEidsSet
    return !selectedLOEids?.every((eid) => ownersEidsSet?.has(eid));
  };

  const getSelectedLOEids = () => {
    return allLocationOwners
      ?.filter((item) => item?.selected)
      ?.map((item) => item?.eid);
  };

  const onClickHandler = () => {
    if (!eid) return;
    let selectedLOEids = getSelectedLOEids();
    if (!areSelectionAndInitialDifferent(selectedLOEids, owners)) {
      return;
    }
    selectedOwnersHandler(eid, selectedLOEids);
  };

  const { t } = useTranslation(['common', 'location']);
  const compRender = () => {
    if (loading) {
      return (
        <Center w='400px' h='30vh'>
          <Loader />
        </Center>
      );
    }
    return (
      <Flex flexDir='column' px={4} w='400px'>
        {(items?.length > 0 || searchQuery) && (
          <Box mt={4}>
            <SearchInput
              placeholder='Search'
              value={searchQuery}
              hideShortcuts
              onChange={(e) => setSearchQuery(e?.target?.value)}
            />
          </Box>
        )}
        {!loading && (
          <Flex
            gap='10px'
            py={4}
            cursor='pointer'
            align='center'
            onClick={() => {
              deployEvent(AmplitudeEvent.LOCATION_INVITE_NEW_OWNER);
              setMenuOpen(false);
              onAddLOClick();
            }}
          >
            <AddIcon
              color='#6F767E'
              bg='#EFEFEF'
              borderRadius='50%'
              boxSize='40px'
              p='11px'
            />
            <Text fontWeight={600} fontSize='15px' color='#2A85FF'>
              Invite new owner
            </Text>
          </Flex>
        )}
        {items?.length > 0 ? (
          <Flex pt={4} gap={4} flexDir='column'>
            <List overflow='scroll' maxH='30vh' spacing={4} w='full'>
              {items?.map((user) => (
                <ListItem key={user?.eid}>
                  <Flex
                    align='center'
                    justify='space-between'
                    cursor='pointer'
                    onClick={() => selectionHandler(user)}
                  >
                    <Flex align='flex-start' gap='10px'>
                      <Image
                        src={getImage(user?.profilePic, user?.name)}
                        boxSize='40px'
                        borderRadius='50%'
                      />
                      <Flex flexDir='column'>
                        <Text fontWeight={600} m={0} p={0}>
                          {user?.name}
                        </Text>
                        <Flex
                          color='#92929D'
                          fontWeight={500}
                          fontSize='12px'
                          flexDir='column'
                        >
                          <Text m={0} p={0}>
                            {user?.role}
                          </Text>
                          <Flex>
                            <Text
                              m={0}
                              p={0}
                              maxW='210px'
                              whiteSpace='pre-wrap'
                            >
                              {user?.locations?.[0]?.name || '-'}
                            </Text>{' '}
                            {user?.locations?.length > 1 && (
                              <Text m={0} p={0}>
                                &nbsp;(+{user?.locations?.length - 1} more)
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                    {user?.selected && (
                      <FontAwesomeIcon
                        icon={faCheck as IconProp}
                        color='#83BF6E'
                      />
                    )}
                  </Flex>
                  <Divider mt={4} />
                </ListItem>
              ))}
            </List>
            <Flex align='center' justify='center' mb={4}>
              <PrimaryButton
                title='Add selected owners'
                variant='solid'
                colorScheme='blue'
                disabled={
                  allLocationOwners?.every((i) => !i.selected) ||
                  !areSelectionAndInitialDifferent(
                    getSelectedLOEids(),
                    owners
                  ) ||
                  updatingLocationOwners
                }
                isLoading={updatingLocationOwners}
                onClick={onClickHandler}
              />
            </Flex>
          </Flex>
        ) : searchQuery ? (
          <Center mb={4} w='full'>
            <EmptyState
              image={'Search'}
              title={t('common:searchResultNotFound')}
            />
          </Center>
        ) : null}
      </Flex>
    );
  };

  return compRender();
};

export default LocationOwnerListMenu;
