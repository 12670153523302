import { Box, Center, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import React, { FC } from 'react';
import { EmptyState, Loader } from 'sub-components';
import { AssetEntityItem, IIconList, ServiceLayer } from '../types';

interface IProps {
  service: ServiceLayer;
  onClose: () => void;
  selectedIcon: (imgObj: IIconList) => void;
}

const RecentylUsed: FC<IProps> = ({ service, onClose, selectedIcon }) => {
  const assetService = service.assetsListing;
  const assetItems =
    assetService?.assetResponse?.data?.EntityAssetPagination?.items || [];

  if (assetService?.assetResponse?.loading) {
    return (
      <Center h='430px'>
        <Loader />
      </Center>
    );
  }

  if (assetItems?.length === 0) {
    return (
      <Center h='430px'>
        <EmptyState image='TrainingPath' title='No Gallery Items found' />
      </Center>
    );
  }

  const selectedIconHandler = (icon: AssetEntityItem) => {
    selectedIcon({
      backgroundColor: '',
      createdAt: icon?.createdAt,
      description: '',
      eid: icon?.eid,
      label: icon?.title,
      tags: icon?.tags,
      type: '',
      updatedAt: icon?.updatedAt,
      url: icon?.url,
    });
    onClose();
  };

  return (
    <Box h='430px'>
      <Box h='420px' overflow='scroll'>
        <Grid templateColumns='repeat(5, 1fr)' justifyItems='center' rowGap={8}>
          {assetItems?.map((list) => {
            return (
              <GridItem key={list.eid}>
                <Center>
                  <Image
                    src={list?.url}
                    boxSize='77px'
                    cursor='pointer'
                    objectFit='cover'
                    borderRadius='8px'
                    onClick={() => {
                      selectedIconHandler(list);
                    }}
                  />
                </Center>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default RecentylUsed;
