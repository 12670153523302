import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useModalContext, useToast } from '@chakra-ui/react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { SelectOption } from 'atoms';
import { useFolderSubFolder, useUserData, useUserDataSelector } from 'hooks';
import { PayloadInput } from 'types';

import { getFileType } from '../CreateChapterModal';
import { BaseUploadFile } from '../CreateChapterModal/UploadFile';
import {
  EDIT_CHAPTER_QUERY,
  EditableResponse,
  EditChapterInput,
  FileDataEntity,
  UPDATE_CHAPTER,
} from '../CreateChapterModal/create-chapter.graphql';

import EditChapterLoader from './EditChapterLoader';
import { IVisibility } from '../CreateChapterModal/UploadFile/UploadFile';
import {
  locationUserRoleHelper,
  rolesListSetHandler,
  selectedDataSetHandler,
} from './helper';
import { useNotifyUserModal } from '../../../sub-components/ChapterEditor/NotifyUserModal';
import { useCategoryList } from 'sub-components/ChapterEditor/CategoryModal/useCategoryList';
import { loginUserBranchRootObj } from 'sub-components/Header';
import { AuthRole, CategoryEntity } from 'sop-commons/src/client';
import { CategoryRef } from 'sub-components/ChapterEditor/CategoryModal/CategoryModal';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import {
  IConditionType,
  ILocationData,
  IRoleData,
  IUserData,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { foldersListVar } from '../ChapterContainer/ChapterContainer';
import useCombinedStore from 'zustandStore/store';
import {
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
} from 'zustandStore/slices/useVisibility';
import { getQueryParamValue } from 'utils/queryParams';
import { getAllFolderSubFolderList } from 'pages/Handbook/setVisibilityModal/SetSeeModal/utility';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const GET_USERS = gql`
  query BaseUploadFileUsers {
    userDescendants {
      authRole
      eid
      name
      profilePic
      role
      type
      username
      locations {
        eid
        name
      }
    }
  }
`;

interface IProps {
  chapterId?: string;
  onChapterUpdated?: () => void;
  defaultCategory?: string;
}

const ModalContent: FC<IProps> = ({
  onChapterUpdated,
  defaultCategory,
  chapterId,
}) => {
  const { t } = useTranslation(['chapter']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  let userData = useUserData();
  const loggedInUserData = useUserDataSelector((state) => ({
    eid: state?.eid,
    authRole: state?.authRole,
    locactions: state?.locations,
  }));
  const {
    hierarchyData,
    isInitialFetch,
    visibilitySelection,
    visibilityScope,
    updateIsInitialFetch,
    updateOperationType,
    updateVisibilityScope,
    updateHierarchyData,
    updateVisibilitySelection,
  } = useCombinedStore();
  const categoryModalRef = useRef<CategoryRef>(null);
  const userBranchRootObj = useReactiveVar(loginUserBranchRootObj);
  const foldersListData = useReactiveVar(foldersListVar);
  const methods = useForm<IFormInput>();
  const folderWatch = useWatch<IFormInput, 'category'>({
    control: methods.control,
    name: 'category',
  });
  const subFolderWatch = useWatch<IFormInput, 'subFolder'>({
    control: methods.control,
    name: 'subFolder',
  });
  const locUserTree: any = useReactiveVar(loginUserBranchRootObj);
  const [chapterTitle, setChapterTitle] = useState<string>('');
  const [file, setFile] = useState<FileDataEntity>();
  const [isUploading, setIsUploading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string>();
  const [membersList, setMembersList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const [notifyUsers, setNotifyUsers] = useState(false);
  const categoryList = useCategoryList();
  const statusValue = getQueryParamValue('status');

  const { onClose } = useModalContext();
  const notifyUserModal = useNotifyUserModal();
  const { selectedData, updateSelectedData } = useCombinedStore();

  useEffect(() => {
    // getLocationUserRoleData();
    locationUserRoleHelper(
      { branch: userBranchRootObj },
      userData,
      setMembersList,
      setLocationsList,
      selectedData,
      updateSelectedData
    );
  }, []);

  useEffect(() => {
    rolesListSetHandler(userData, setRolesList);
  }, [userData]);

  useEffect(() => {
    selectedDataSetHandler(
      membersList,
      locationsList,
      visibilitySelection!,
      rolesList,
      updateSelectedData
    );
  }, [locationsList, membersList, visibilitySelection]);

  const { data: _userData } = useQuery(GET_USERS);

  useEffect(() => {
    let _locationsData: ISelectedLocationData[] = [];
    let _usersData: ISelectedMemberData[] = [];
    let updatedRolesData: ISelectedRoleData[] = [];
    const roles = userData?.entity?.roles || [];
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      let filteredLocationsArray = (
        locUserTree?.descendantBranches || []
      ).filter((location: ILocationData) => {
        return (userData?.locations || []).find((userLocation) => {
          return userLocation.eid === location.eid;
        });
      });
      _locationsData = filteredLocationsArray;
    } else {
      _locationsData = (locUserTree?.descendantBranches || [])?.map(
        (user: ILocationData) => {
          return {
            ...user,
            label: user.name,
            value: user.name,
          };
        }
      );
      _usersData = (_userData?.userDescendants || [])?.map(
        (user: IUserData) => {
          return {
            ...user,
            label: user.name,
            value: user.name,
          };
        }
      );
      updatedRolesData = roles?.map<IRoleData>((role: any) => {
        return {
          ...role,
          label: role?.name,
          value: role?.name,
        };
      });
    }
    let selectedLocations = _locationsData?.filter((loc) => {
      return visibilitySelection?.locations?.some(
        (_loc: any) => _loc === loc?.eid
      );
    });
    let selectedMembers = _usersData?.filter((user) => {
      return visibilitySelection?.users?.some(
        (_user: any) => _user === user?.eid
      );
    });
    let selectedJobs = updatedRolesData?.filter((role) => {
      return visibilitySelection?.roles?.some(
        (_role: any) => _role === role?.label
      );
    });
    updateSelectedData({
      locations: selectedLocations,
      roles: selectedJobs,
      members: selectedMembers,
    });
  }, [_userData, visibilitySelection]);

  let allFoldersList = useMemo(() => {
    return getAllFolderSubFolderList(foldersListData);
  }, [foldersListData]);

  const { loading, data } = useQuery<EditableResponse>(EDIT_CHAPTER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !chapterId,
    variables: {
      eid: chapterId,
    },
    onCompleted: (response) => {
      const sopData = response.SopById;
      //
      if (statusValue === 'RESTRICT') {
        if (
          hierarchyData?.parentFolder?.details?.eid &&
          hierarchyData?.subFolder?.details?.eid
        ) {
          let newObj = {
            category: {
              label: hierarchyData?.parentFolder?.details?.name,
              value: hierarchyData?.parentFolder?.details?.name,
              ...hierarchyData?.parentFolder?.details,
            },
            subFolder: {
              label: hierarchyData?.subFolder?.details?.name,
              value: hierarchyData?.subFolder?.details?.name,
              ...hierarchyData?.subFolder?.details,
            },
          };
          methods.reset(newObj);
        } else if (hierarchyData?.parentFolder?.details?.eid) {
          let newObj = {
            category: {
              label: hierarchyData?.parentFolder?.details?.name,
              value: hierarchyData?.parentFolder?.details?.name,
              ...hierarchyData?.parentFolder?.details,
            },
            subFolder: undefined,
          };
          methods.reset(newObj);
        }
      } else {
        let foundFolder = allFoldersList?.filter(
          (list) => list?.eid === sopData?.categoryId
        )?.[0];
        if (!foundFolder?.parentFolderId) {
          let newObj = {
            category: {
              ...foundFolder,
              label: foundFolder?.name,
              value: foundFolder?.name,
            },
          };
          methods.reset(newObj);
          updateHierarchyData({
            parentFolder: {
              details: foundFolder,
              visibilityData: {
                locations: foundFolder?.visibleTo?.locations || [],
                roles: foundFolder?.visibleTo?.roles || [],
                users: foundFolder?.visibleTo?.users || [],
                condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
                foundFolder?.visibleTo?.roles?.length > 0
                  ? foundFolder?.visibleTo?.condition
                  : undefined) as IConditionType,
                visibility: foundFolder?.visibility,
              },
            },
            ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
              ? {
                  chapter: {
                    details: {} as any,
                    visibilityData: {
                      locations:
                        (sopData?.visibleTo?.locations || [])?.length > 0
                          ? sopData?.visibleTo?.locations || []
                          : loggedInUserData?.locactions?.map(
                              (loc) => loc?.eid
                            ) || [],
                      roles:
                        (sopData?.visibleTo?.roles || [])?.length > 0
                          ? sopData?.visibleTo?.roles || []
                          : [],
                      users:
                        (sopData?.visibleTo?.users || [])?.length > 0
                          ? sopData?.visibleTo?.users || []
                          : [],
                      condition: 'and',
                      visibility: 'private',
                    },
                  },
                }
              : {}),
          });
        } else {
          let foundParentFolder = allFoldersList?.filter(
            (list) => list?.eid === foundFolder?.parentFolderId
          )?.[0];
          let newObj = {
            category: {
              ...foundParentFolder,
              label: foundParentFolder?.name,
              value: foundParentFolder?.name,
            },
            subFolder: {
              ...foundFolder,
              label: foundFolder?.name,
              value: foundFolder?.name,
            },
          };
          methods.reset(newObj);
          updateHierarchyData({
            parentFolder: {
              details: foundParentFolder,
              visibilityData: {
                locations: foundParentFolder?.visibleTo?.locations || [],
                roles: foundParentFolder?.visibleTo?.roles || [],
                users: foundParentFolder?.visibleTo?.users || [],
                condition: (foundParentFolder?.visibleTo?.locations?.length >
                  0 && foundParentFolder?.visibleTo?.roles?.length > 0
                  ? foundParentFolder?.visibleTo?.condition
                  : undefined) as IConditionType,
                visibility: foundParentFolder?.visibility,
              },
            },
            subFolder: {
              details: foundFolder,
              visibilityData: {
                locations: foundFolder?.visibleTo?.locations || [],
                roles: foundFolder?.visibleTo?.roles || [],
                users: foundFolder?.visibleTo?.users || [],
                condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
                foundFolder?.visibleTo?.roles?.length > 0
                  ? foundFolder?.visibleTo?.condition
                  : undefined) as IConditionType,
                visibility: foundFolder?.visibility,
              },
            },
            ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
              ? {
                  chapter: {
                    details: {} as any,
                    visibilityData: {
                      locations:
                        (data?.SopById?.visibleTo?.locations || [])?.length > 0
                          ? data?.SopById?.visibleTo?.locations || []
                          : loggedInUserData?.locactions?.map(
                              (loc) => loc?.eid
                            ) || [],
                      roles:
                        (data?.SopById?.visibleTo?.roles || [])?.length > 0
                          ? data?.SopById?.visibleTo?.roles || []
                          : [],
                      users:
                        (data?.SopById?.visibleTo?.users || [])?.length > 0
                          ? data?.SopById?.visibleTo?.users || []
                          : [],
                      condition: 'and',
                      visibility: 'private',
                    },
                  },
                }
              : {}),
          });
        }
      }
      setChapterTitle(sopData.title);
      setFile(sopData.files?.[0]);
      setNotifyUsers(sopData?.notifyUsers);
      let _visibilityData = {
        locations: sopData?.visibleTo?.locations,
        roles: sopData?.visibleTo?.roles,
        users: sopData?.visibleTo?.users,
        condition: sopData?.visibleTo?.condition,
        visibility: sopData?.visibility as never,
      };
      updateVisibilitySelection(_visibilityData);
      updateIsInitialFetch(true);
    },
  });

  useEffect(() => {
    if (
      allFoldersList?.length === 0 ||
      statusValue === 'RESTRICT' ||
      isInitialFetch
    )
      return;
    if (subFolderWatch?.eid && folderWatch?.eid) {
      if (loggedInUserData?.authRole === AuthRole.LOCATION_OWNER) {
        updateVisibilitySelection({
          locations:
            (data?.SopById?.visibleTo?.locations || [])?.length > 0
              ? data?.SopById?.visibleTo?.locations || []
              : loggedInUserData?.locactions?.map((loc) => loc?.eid) || [],
          roles:
            (data?.SopById?.visibleTo?.roles || [])?.length > 0
              ? data?.SopById?.visibleTo?.roles || []
              : [],
          users:
            (data?.SopById?.visibleTo?.users || [])?.length > 0
              ? data?.SopById?.visibleTo?.users || []
              : [],
          condition: 'and',
          visibility: 'private',
        });
      } else {
        updateVisibilitySelection({
          locations: subFolderWatch?.visibleTo?.locations || [],
          roles: subFolderWatch?.visibleTo?.roles || [],
          users: subFolderWatch?.visibleTo?.users || [],
          condition:
            subFolderWatch?.visibleTo?.locations?.length > 0 &&
            subFolderWatch?.visibleTo?.roles?.length > 0
              ? subFolderWatch?.visibleTo?.condition
              : undefined,
          visibility: subFolderWatch?.visibility || 'public',
        });
      }
      let foundParentFolder = allFoldersList?.find(
        (list) => list?.eid === folderWatch?.eid
      );
      let foundFolder = allFoldersList?.find(
        (list) => list?.eid === subFolderWatch?.eid
      );
      if (foundParentFolder && foundFolder) {
        updateHierarchyData({
          parentFolder: {
            details: foundParentFolder,
            visibilityData: {
              locations: foundParentFolder?.visibleTo?.locations || [],
              roles: foundParentFolder?.visibleTo?.roles || [],
              users: foundParentFolder?.visibleTo?.users || [],
              condition: (foundParentFolder?.visibleTo?.locations?.length > 0 &&
              foundParentFolder?.visibleTo?.roles?.length > 0
                ? foundParentFolder?.visibleTo?.condition
                : undefined) as IConditionType,
              visibility: foundParentFolder?.visibility,
            },
          },
          subFolder: {
            details: foundFolder,
            visibilityData: {
              locations: foundFolder?.visibleTo?.locations || [],
              roles: foundFolder?.visibleTo?.roles || [],
              users: foundFolder?.visibleTo?.users || [],
              condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
              foundFolder?.visibleTo?.roles?.length > 0
                ? foundFolder?.visibleTo?.condition
                : undefined) as IConditionType,
              visibility: foundFolder?.visibility,
            },
          },
          ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
            ? {
                chapter: {
                  details: {} as any,
                  visibilityData: {
                    locations:
                      (data?.SopById?.visibleTo?.locations || [])?.length > 0
                        ? data?.SopById?.visibleTo?.locations || []
                        : loggedInUserData?.locactions?.map(
                            (loc) => loc?.eid
                          ) || [],
                    roles:
                      (data?.SopById?.visibleTo?.roles || [])?.length > 0
                        ? data?.SopById?.visibleTo?.roles || []
                        : [],
                    users:
                      (data?.SopById?.visibleTo?.users || [])?.length > 0
                        ? data?.SopById?.visibleTo?.users || []
                        : [],
                    condition: 'and',
                    visibility: 'private',
                  },
                },
              }
            : {}),
        });
      }
    } else if (folderWatch?.eid) {
      if (loggedInUserData?.authRole === AuthRole.LOCATION_OWNER) {
        updateVisibilitySelection({
          locations:
            (data?.SopById?.visibleTo?.locations || [])?.length > 0
              ? data?.SopById?.visibleTo?.locations || []
              : loggedInUserData?.locactions?.map((loc) => loc?.eid) || [],
          roles:
            (data?.SopById?.visibleTo?.roles || [])?.length > 0
              ? data?.SopById?.visibleTo?.roles || []
              : [],
          users:
            (data?.SopById?.visibleTo?.users || [])?.length > 0
              ? data?.SopById?.visibleTo?.users || []
              : [],
          condition: 'and',
          visibility: 'private',
        });
      } else {
        updateVisibilitySelection({
          locations: folderWatch?.visibleTo?.locations || [],
          roles: folderWatch?.visibleTo?.roles || [],
          users: folderWatch?.visibleTo?.users || [],
          condition:
            folderWatch?.visibleTo?.locations?.length > 0 &&
            folderWatch?.visibleTo?.roles?.length > 0
              ? folderWatch?.visibleTo?.condition
              : undefined,
          visibility: folderWatch?.visibility || 'public',
        });
      }
      let foundFolder = allFoldersList?.find(
        (list) => list?.eid === folderWatch?.eid
      );
      if (foundFolder) {
        updateHierarchyData({
          parentFolder: {
            details: foundFolder,
            visibilityData: {
              locations: foundFolder?.visibleTo?.locations || [],
              roles: foundFolder?.visibleTo?.roles || [],
              users: foundFolder?.visibleTo?.users || [],
              condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
              foundFolder?.visibleTo?.roles?.length > 0
                ? foundFolder?.visibleTo?.condition
                : undefined) as IConditionType,
              visibility: foundFolder?.visibility,
            },
          },
          ...(loggedInUserData?.authRole === AuthRole.LOCATION_OWNER
            ? {
                chapter: {
                  details: {} as any,
                  visibilityData: {
                    locations:
                      (data?.SopById?.visibleTo?.locations || [])?.length > 0
                        ? data?.SopById?.visibleTo?.locations || []
                        : loggedInUserData?.locactions?.map(
                            (loc) => loc?.eid
                          ) || [],
                    roles:
                      (data?.SopById?.visibleTo?.roles || [])?.length > 0
                        ? data?.SopById?.visibleTo?.roles || []
                        : [],
                    users:
                      (data?.SopById?.visibleTo?.users || [])?.length > 0
                        ? data?.SopById?.visibleTo?.users || []
                        : [],
                    condition: 'and',
                    visibility: 'private',
                  },
                },
              }
            : {}),
        });
      }
    }
  }, [
    folderWatch,
    subFolderWatch,
    allFoldersList,
    statusValue,
    isInitialFetch,
  ]);

  const [updateChapter] = useMutation<never, PayloadInput<EditChapterInput>>(
    UPDATE_CHAPTER,
    {
      onCompleted: async () => {
        toast({
          title: 'Chapter updated successfully',
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: 'Chapter could not be updated',
          status: 'error',
        });
      },
    }
  );

  const onUpdateChapter = async (_notifyUsers: boolean) => {
    try {
      deployEvent(AmplitudeEventNames.UPDATE_FILE_CHAPTER);
      setNotifyUsers(_notifyUsers);
      setIsUploading(true);

      const inputs = {
        eid: chapterId!,
        title: chapterTitle!,
        raw_content: chapterTitle!,
        // category: category?.value!,
        categoryId: subFolderWatch?.eid || folderWatch?.eid,
        languageCode: 'en',
        visibility: visibilitySelection?.visibility || 'public',
        visibleTo: {
          condition:
            (visibilitySelection?.locations || [])?.length > 0 &&
            (visibilitySelection?.roles || [])?.length > 0
              ? visibilitySelection?.condition
              : null,
          locations: visibilitySelection?.locations,
          roles: visibilitySelection?.roles,
          users: visibilitySelection?.users,
        },
        notifyUsers: _notifyUsers,
      } as EditChapterInput;

      if (file?.file && file.url) {
        inputs.sopType = file?.mimetype?.includes('video/')
          ? 'video'
          : 'document';
        const fileType = getFileType(file?.mimetype);
        inputs.thumbnail = fileType === 'pdf' ? thumbnail : null;
        inputs.files = [
          {
            mimetype: file?.mimetype!,
            name: file?.name!,
            url: file.url!,
            type: fileType,
            fileSize: file?.fileSize!,
          },
        ];
      }

      const response = await updateChapter({
        variables: {
          input: inputs,
        },
      });
      if (response.errors) {
        throw response.errors;
      } else {
        onChapterUpdated?.();
      }
      onClose();
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
    }
  };

  const _onUpdateChapter = () => {
    notifyUserModal({
      isEditMode: true,
      notifyUsers: notifyUsers,
      onPublishClick: onUpdateChapter,
    });
  };

  const onAddCategoryModalClose = () => {
    updateOperationType('edit');
    updateVisibilityScope('chapter');
  };

  return (
    <div>
      <FormProvider {...methods}>
        <EditChapterLoader isLoading={loading || !data?.SopById}>
          <BaseUploadFile
            categoryModalRef={categoryModalRef}
            file={file}
            onFileChange={setFile}
            title={chapterTitle}
            onTitleChange={setChapterTitle}
            category={subFolderWatch?.eid ? subFolderWatch : folderWatch}
            onCategoryChange={() => {}}
            thumbnail={thumbnail}
            onThumbnailChange={setThumbnail}
            isUploading={isUploading}
            buttonTitle={t('chapter:updateChapter')}
            onSubmitClick={_onUpdateChapter}
            onAddCategoryModalClose={onAddCategoryModalClose}
          />
        </EditChapterLoader>
      </FormProvider>
    </div>
  );
};

export default ModalContent;
