import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ControlLayer, IIconList, ServiceLayer } from '../types';
import { Gallery, ImageUpload, RecentlyUsed } from '../components';

export const useControlLayer = (
  galleryIconsLoading: boolean,
  iconsList: IIconList[],
  service: ServiceLayer,
  selectedIcon: (imgObj: IIconList) => void
): ControlLayer => {
  const [activeTab, setActiveTab] = useState(-1);

  useEffect(() => {
    if (activeTab === 2) {
      service.assetsListing?.getAssets({
        variables: {
          page: 1,
          perPage: 100,
        },
      });
    }
  }, [activeTab]);

  const getTabs = (onClose: () => void): React.ReactNode => {
    return (
      <Tabs onChange={setActiveTab} pt={2}>
        <TabList px={4}>
          <Tab>Gallery</Tab>
          <Tab>Upload</Tab>
          <Tab>Recently used</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Gallery
              galleryIconsLoading={galleryIconsLoading}
              iconsList={iconsList}
              onClose={onClose}
              selectedIcon={selectedIcon}
            />
          </TabPanel>
          <TabPanel>
            <ImageUpload
              service={service}
              onClose={onClose}
              selectedIcon={selectedIcon}
            />
          </TabPanel>
          <TabPanel>
            <RecentlyUsed
              service={service}
              onClose={onClose}
              selectedIcon={selectedIcon}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  };
  return {
    getTabs,
    setActiveTab,
  };
};
