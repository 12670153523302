import React from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import './TrainingPathIconsModal.scss';
import { useControlLayer, useServiceLayer } from './layers';
import { ITrainingPathIconsModal } from './types';

const TrainingPathIconsModal = ({
  galleryIconsLoading,
  iconsList,
  selectedIcon,
  children,
}: ITrainingPathIconsModal) => {
  const service = useServiceLayer();
  const { getTabs, setActiveTab } = useControlLayer(
    galleryIconsLoading,
    iconsList,
    service,
    selectedIcon
  );

  return (
    <>
      <Popover
        placement='bottom-start'
        arrowSize={10}
        closeOnBlur={true}
        direction='ltr'
        preventOverflow={true}
        offset={[-20, 45]}
        onOpen={() => setActiveTab(0)}
      >
        {({ onClose }) => (
          <>
            {/* @ts-ignore */}
            <PopoverTrigger>{children}</PopoverTrigger>
            <PopoverContent borderRadius='10' width='600px' padding={0}>
              <PopoverArrow />
              <PopoverBody borderRadius='10' boxShadow='lg' padding={0}>
                {getTabs(onClose)}
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default TrainingPathIconsModal;
