import { Container, Flex, Text } from '@chakra-ui/react';
import Dropdown from 'atoms/Dropdown';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCombinedStore from 'zustandStore/store';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';
import MultiCustomDropdown from './MultiCustomDropdown';
import ConditionalSelection from './ConditionalSelection';
import { useReactiveVar } from '@apollo/client';
import { loginUserBranchRootObj } from 'sub-components/Header';
import { AuthRole, userObj } from 'sop-commons/src/client';
import { ILocationData, ISecondDropdownData } from '../set-see-modal-types';
import { useUserDataSelector } from 'hooks';

interface IProps {
  setNewRole: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationJobsSection: FC<IProps> = ({ setNewRole }) => {
  const { t } = useTranslation('deck');
  const setSeeCtx = useContext(SetSeeDataContext);
  const userData = useReactiveVar(userObj);
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const _locationsData: any = useReactiveVar(loginUserBranchRootObj);
  const { operationType, visibilitySelection, updateVisibilitySelection } =
    useCombinedStore();

  useEffect(() => {
    if (visibilitySelection) {
      if (
        visibilitySelection?.locations?.length > 0 &&
        visibilitySelection?.roles?.length > 0
      ) {
        setSeeCtx?.updateSelectedFirstDropdown({
          label: 'Location',
          value: 'Location',
        });
      } else if (visibilitySelection?.locations?.length > 0) {
        setSeeCtx?.updateSelectedFirstDropdown({
          label: 'Location',
          value: 'Location',
        });
      } else if (visibilitySelection?.roles?.length > 0) {
        setSeeCtx?.updateSelectedFirstDropdown({
          label: 'Role',
          value: 'Role',
        });
      }
    }
  }, []);

  const locationsData = useMemo(() => {
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      let filteredLocationsArray = (
        _locationsData?.descendantBranches || []
      ).filter((location: ILocationData) => {
        return (userData?.locations || []).find((userLocation) => {
          return userLocation.eid === location.eid;
        });
      });
      return filteredLocationsArray;
    } else {
      return _locationsData?.descendantBranches?.map((location: any) => {
        return {
          eid: location?.eid,
          label: location?.name,
          name: location?.name,
          value: location?.eid,
        };
      });
    }
  }, [_locationsData]);

  useEffect(() => {
    if (
      loggedInUserAuthRole === AuthRole.LOCATION_OWNER &&
      operationType === 'add'
    ) {
      updateVisibilitySelection({
        ...visibilitySelection!,
        locations: locationsData?.map((loc: any) => loc?.eid) || [],
      });
    }
  }, [locationsData]);

  const dropdownRender = () => {
    if (setSeeCtx?.selectedFirstDropdown?.label === 'Location') {
      return (
        <MultiCustomDropdown
          options={locationsData}
          value={visibilitySelection?.locations || []}
          type='location'
          onClick={(selection: ISecondDropdownData) => {
            console.log('selection : ', selection);
            if (visibilitySelection) {
              let foundEid = visibilitySelection?.locations?.find(
                (loc) => loc === selection?.eid
              );
              if (foundEid) {
                let filteredIds = visibilitySelection?.locations?.filter(
                  (loc) => loc !== selection?.eid
                );
                updateVisibilitySelection({
                  ...visibilitySelection,
                  locations: filteredIds,
                });
              } else {
                updateVisibilitySelection({
                  ...visibilitySelection,
                  locations: [...visibilitySelection.locations, selection?.eid],
                });
              }
            }
            // setSeeCtx?.updateSelectedLocationsList(e);
            // if (visibilitySelection) {
            //   let filteredEids: string[] =
            //     e?.map((_e: any) => _e?.eid) || ([] as string[]);
            //   updateVisibilitySelection({
            //     ...visibilitySelection,
            //     locations: filteredEids,
            //   });
            // }
          }}
          createRole={() => {}}
        />
      );
    } else {
      return (
        <MultiCustomDropdown
          options={setSeeCtx?.rolesList}
          value={visibilitySelection?.roles || []}
          type='role'
          onClick={(selection: ISecondDropdownData) => {
            if (visibilitySelection) {
              let foundRoleName = visibilitySelection?.roles?.find(
                (loc) => loc === selection?.name
              );
              if (foundRoleName) {
                let filteredRoleNames = visibilitySelection?.roles?.filter(
                  (loc) => loc !== selection?.name
                );
                updateVisibilitySelection({
                  ...visibilitySelection,
                  roles: filteredRoleNames,
                });
              } else {
                updateVisibilitySelection({
                  ...visibilitySelection,
                  roles: [...visibilitySelection.roles, selection?.name],
                });
              }
            }
            // setSeeCtx?.updateSelectedRolesList(e);
            // if (visibilitySelection) {
            //   let filteredEids: string[] =
            //     e?.map((_e: any) => _e?.eid) || ([] as string[]);
            //   updateVisibilitySelection({
            //     ...visibilitySelection,
            //     roles: filteredEids,
            //   });
            // }
          }}
          createRole={() => setNewRole(true)}
        />
      );
    }
  };

  return (
    <Flex flexDir='column' w='full' bg='#f7f7f7' p={8} borderRadius='12px'>
      <Text fontSize='16px' fontWeight={600} m='0 0 1rem'>
        {t('visibility.location_jobs_heading')}
      </Text>
      <Flex w='full' flexDir='column' gap={4}>
        <Flex alignItems='center' gap={4}>
          <Dropdown
            className='single-dropdown'
            options={[
              { value: 'Location', label: 'Location' },
              { value: 'Job', label: 'Job' },
            ]}
            value={setSeeCtx?.selectedFirstDropdown}
            isDisabled={loggedInUserAuthRole === AuthRole.LOCATION_OWNER}
            placeholder={t('visibility.dropdown_placeholder')}
            onChange={(e: any) => {
              setSeeCtx?.updateSelectedFirstDropdown(e);
              if (visibilitySelection) {
                updateVisibilitySelection({
                  ...visibilitySelection,
                  locations: [],
                  roles: [],
                });
              }
            }}
          />
          <Text as='b'>{t('visibility.is_text')}</Text>
          <Container w='45%' p='0' m='0'>
            {dropdownRender()}
          </Container>
        </Flex>
        <ConditionalSelection setNewRole={setNewRole} />
      </Flex>
    </Flex>
  );
};

export default LocationJobsSection;
