import {
  EntityLabel,
  EntityTypes,
  LocationTypeOption,
} from 'pages/Locations/AddLocation/static-data';
import { LocationType } from 'types';
import { LocationUploadEntity, ProcessedEntity } from '../types';

export const preProcessData = (
  processedData: ProcessedEntity[],
  type: 'existing' | 'new'
): LocationUploadEntity[] => {
  return processedData?.map((processed) => {
    let locationType: LocationType = 'corporate';
    LocationTypeOption?.map((option) => {
      if (processed?.data?.data?.['Location type']?.value === option?.label) {
        locationType = option?.value;
      }
    });

    let entityType: string = '';
    EntityTypes?.map((value) => {
      if (
        EntityLabel[value] === processed?.data?.data?.['Entity type']?.value
      ) {
        entityType = value;
      }
    });
    return {
      address: {
        address: processed?.data?.data?.['Address']?.value || '',
        city: processed?.data?.data?.['City']?.value || '',
        state: processed?.data?.data?.['State']?.value || '',
        zipCode: processed?.data?.data?.['Zipcode']?.value + '' || '',
      },
      countryOfFormation:
        processed?.data?.data?.['Country of formation']?.value || '',
      entityName: processed?.data?.data?.['Entity name']?.value || '',
      ...(entityType ? { entityType: entityType } : {}),
      locationEmail: [processed?.data?.data?.['Email address']?.value] || [],
      locationOwners: processed?.owners || [],
      locationPhone: [processed?.data?.data?.['Phone number']?.value] || [],
      locationType: locationType || '',
      name: processed?.data?.data?.['Location name']?.value || '',
      password: processed?.password || '',
      stateOfFormation:
        processed?.data?.data?.['State of formation']?.value || '',
      taxPayerId: processed?.data?.data?.['Tax Payer Id']?.value || '',
      username: processed?.locationUserName || '',
    };
  });
};

export const preLaunchProcessData = (processedData: any[]) => {
  console.log('processed data : ', processedData);
  return processedData?.map((processed) => {
    let locationType: LocationType = 'corporate';
    LocationTypeOption?.map((option) => {
      if (processed?.data?.['Location type']?.value === option?.label) {
        locationType = option?.value;
      }
    });

    let entityType: string = '';
    EntityTypes?.map((value) => {
      if (EntityLabel[value] === processed?.data?.['Entity type']?.value) {
        entityType = value;
      }
    });
    return {
      address: {
        address: processed?.data?.['Address']?.value || '',
        city: processed?.data?.['City']?.value || '',
        state: processed?.data?.['State']?.value || '',
        zipCode: processed?.data?.['Zipcode']?.value + '' || '',
      },
      countryOfFormation:
        processed?.data?.['Country of formation']?.value || '',
      entityName: processed?.data?.['Entity name']?.value || '',
      ...(processed?.data?.['Entity type']?.value
        ? { entityType: entityType }
        : {}),
      locationEmail: [processed?.data?.['Email address']?.value] || [],
      locationPhone: [processed?.data?.['Phone number']?.value] || [],
      locationType: locationType || '',
      name: processed?.data?.['Location name']?.value || '',
      stateOfFormation: processed?.data?.['State of formation']?.value || '',
      taxPayerId: processed?.data?.['Tax Payer Id']?.value || '',
    };
  });
};
