import {
  EXISTING_LOCATION_FILE_HEADERS,
  PRE_LAUNCH_LOCATION_FILE_HEADERS,
} from '../file-headers';
import { IJsonEntity } from '../types';
import { cleanHeader, toCamelCase } from './utils';

export const headerErrors = (json: IJsonEntity[], type: 'existing' | 'new') => {
  let _errors: string[] = [];

  if (json?.length === 0) {
    _errors.push(
      'Missing headers. The imported sheet does not have column headers.'
    );
    return _errors;
  }

  let headerKeys = Object.keys(json?.[0]);
  let cleanHeaderKeys = headerKeys?.map((key) => cleanHeader(key));
  console.log('clean header keys : ', cleanHeaderKeys);
  let trimmedHeaderKeys = cleanHeaderKeys?.map((key) => {
    if (key?.includes('-TO_BE_TRIMMED_>>')) {
      let slicedKey = key?.split('-TO_BE_TRIMMED_>>')?.[1];
      return slicedKey;
    }
    return key;
  });

  // Check for duplicates
  //   const uniqueHeaders = new Set(trimmedHeaderKeys);
  //   if (uniqueHeaders.size !== trimmedHeaderKeys.length) {
  //     _errors.push(
  //       'Duplicate column names found. Please ensure all column names are unique.'
  //     );
  //   }

  // for (let i = 0; i < trimmedHeaderKeys.length; i++) {
  //     for (let j = i + 1; j < trimmedHeaderKeys.length; j++) {
  //       if (arraysEqual(trimmedHeaderKeys[i], trimmedHeaderKeys[j])) {
  //         _errors.push(
  //           `Duplicate column name "${trimmedHeaderKeys[i]}". Please ensure all column names are unique.`
  //         );
  //       }
  //     }
  //   }

  const uniqueHeaders = new Set();
  const duplicates = trimmedHeaderKeys.filter(
    (item) => uniqueHeaders.size === uniqueHeaders.add(item).size
  );
  console.log('Duplicates : ', duplicates);
  if (duplicates?.length > 0) {
    duplicates?.map((duplicate) => {
      _errors.push(
        `Duplicate column name "${duplicate}". Please ensure all column names are unique.`
      );
    });
  }

  if (_errors?.length > 0) {
    return _errors;
  }

  // Headers count and existence mismatch
  const expectedHeaders =
    type === 'existing'
      ? EXISTING_LOCATION_FILE_HEADERS
      : PRE_LAUNCH_LOCATION_FILE_HEADERS;
  const expectedHeaderNames = expectedHeaders.map((header) => header.name);

  //   const missingHeaders = expectedHeaderNames.filter(
  //     (header) => !trimmedHeaderKeys.includes(header)
  //   );

  if (trimmedHeaderKeys.length !== expectedHeaders?.length) {
    _errors.push(
      `Header count mismatch. Expected ${
        expectedHeaders?.length
      } headers {${expectedHeaderNames.join(', ')}}, but found ${
        trimmedHeaderKeys?.length
      } headers. Please ensure the uploaded file matches the expected format.`
    );
  }

  if (_errors?.length > 0) {
    return _errors;
  }

  let result = expectedHeaderNames.filter(
    (item) => !trimmedHeaderKeys.includes(item)
  );
  if (result?.length > 0) {
    result?.forEach((res) => {
      _errors?.push(
        `Column name mismatch. Expected column ${res}. Please ensure the column names match the sample file format.`
      );
    });
  }

  // Headers count mismatch
  // let initialHeaders =
  //     type === 'existing'
  //         ? EXISTING_LOCATION_FILE_HEADERS
  //         : type === 'new'
  //             ? PRE_LAUNCH_LOCATION_FILE_HEADERS
  //             : [];
  // if (initialHeaders?.length !== cleanHeaderKeys?.length) {
  //     _errors.push(
  //         `Header count mismatch. Expected ${initialHeaders?.length
  //         } headers {${initialHeaders
  //             ?.map((header) => header?.name)
  //             ?.join(', ')}}, but found ${cleanHeaderKeys?.length
  //         } headers. Please ensure the uploaded file matches the expected format.`
  //     );
  // }

  // if (_errors?.length > 0) {
  //     return _errors;
  // }

  // Header name mismatch
  // for (let i = 0; i < initialHeaders?.length; i++) {
  //     if (initialHeaders[i]?.name !== trimmedHeaderKeys[i]) {
  //         _errors.push(
  //             `Column name mismatch. Expected column '${initialHeaders[i].name}, but found '${trimmedHeaderKeys[i]}'. Please ensure the column names match the sample file format.`
  //         );
  //     }
  // }
  return _errors;
};
