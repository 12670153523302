import React, { useEffect, useState } from 'react';
import { Checkbox, Flex, Text, Box, Image } from '@chakra-ui/react';
import { Row } from 'antd';
import { ReactComponent as BackButton } from '../../../assets/images/back.svg';
import SearchInput from 'atoms/SearchInput';
import EmptyState from './EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { useReactiveVar } from '@apollo/client';
import { IEntityUser } from 'shared/global-types';
import { AuthRole } from '../../../authorization';
import { deployEvent, CommonAmplitudeEvents } from 'shared/amplitudeEvents';

import './TasksFilter.scss';
import { getImage } from 'utils';

type CreatedByFilterComponentProps = {
  extraFilterData: any;
  sortedCreatedByArray: any;
  setSortedCreatedByArray: any;
  setCheckedOptionsData: any;
  checkedOptionsData: any;
};

const CreatedByFilterComponent = ({
  extraFilterData,
  sortedCreatedByArray,
  setSortedCreatedByArray,
  setCheckedOptionsData,
  checkedOptionsData,
}: CreatedByFilterComponentProps) => {
  const usersEntityData: IEntityUser[] = useReactiveVar(usersEntityObj);

  const createdByUserRoles = [
    AuthRole.SUPER_ADMIN,
    AuthRole.LOCATION_OWNER,
    AuthRole.ADMIN,
  ];
  const membersData = usersEntityData.filter(
    (item) =>
      item?.type === 'user' && createdByUserRoles.includes(item?.authRole)
  );

  const [createdByList, setCreatedByList] = useState(membersData);
  const [searchCreatedByQuery, setSearchCreatedByQuery] = useState('');
  const { setPageName, loading } = extraFilterData;

  const { checkCreatedByList } = checkedOptionsData;

  const searchCreatedByQueryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchCreatedByQuery(e?.target?.value);
  };

  const handleCreatedBySelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newCheckedCreatedByList = [...checkCreatedByList];
      newCheckedCreatedByList.push(e.target.value);
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkCreatedByList: newCheckedCreatedByList,
      });
    } else {
      const newCheckedCreatedByList = checkCreatedByList.filter(
        (item: any) => item !== e.target.value
      );
      setCheckedOptionsData({
        ...checkedOptionsData,
        checkCreatedByList: newCheckedCreatedByList,
      });
    }
  };

  let finalCreatedByList =
    sortedCreatedByArray?.length > 0 ? sortedCreatedByArray : createdByList;

  const filteredCreatedBy = finalCreatedByList?.filter((createdBy: any) =>
    createdBy?.name?.toLowerCase().includes(searchCreatedByQuery?.toLowerCase())
  );

  useEffect(() => {
    const tempArray: string[] = [];
    if (checkCreatedByList?.length > 0) {
      createdByList.map((createdBy: any) => {
        if (checkCreatedByList.includes(createdBy?.eid)) {
          const obj = { ...createdBy, isChecked: true };
          tempArray.push(obj);
        }
      });

      createdByList.map((createdBy: any) => {
        if (!checkCreatedByList.includes(createdBy?.eid)) {
          const obj = { ...createdBy, isChecked: false };
          tempArray.push(obj);
        }
      });
    }

    setSortedCreatedByArray(tempArray);
  }, [checkCreatedByList]);

  return (
    <>
      <Flex>
        <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
          <Row className='task-filter-tag-container' />
          <Row className='task-filter-title-container'>Filters</Row>
        </Row>
        <Flex gap={'20px'} height={'fit-content'}>
          <Text
            style={{ position: 'relative', top: 5 }}
            fontSize={'15px'}
            color={checkCreatedByList?.length > 0 ? '#2A85FF' : '#DDDDDD'}
            cursor={'pointer'}
            onClick={() => {
              deployEvent(CommonAmplitudeEvents.CLEAR_CREATED_BY_FILTER);
              setCheckedOptionsData({
                ...checkedOptionsData,
                checkCreatedByList: [],
              });
            }}
          >
            Clear all
          </Text>
        </Flex>
        {/* <PopoverCloseButton
          style={{
            backgroundColor: '#EFEFEF',
            borderRadius: '50%',
            position: 'relative',
          }}
        /> */}
      </Flex>
      <Flex gap={3} alignItems={'center'} marginBottom={'1rem'}>
        <BackButton
          width={'1.2rem'}
          onClick={() => setPageName('base')}
          cursor={'pointer'}
        />
        <Text
          style={{
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Created By
        </Text>
      </Flex>
      <SearchInput
        className='member-search-field'
        value={searchCreatedByQuery}
        onChange={searchCreatedByQueryHandler}
        placeholder='Search by name'
        hideShortcuts
      />
      {filteredCreatedBy?.length > 0 ? (
        <Box maxHeight={'300px'} overflow={'scroll'}>
          {filteredCreatedBy.map((member: any) => {
            return (
              <Flex
                key={member?.eid}
                gap={4}
                alignItems={'center'}
                padding={'0.5rem 0px'}
              >
                <Checkbox
                  // disabled={loading ? true : false}
                  value={member?.eid}
                  onChange={handleCreatedBySelection}
                  isChecked={
                    checkCreatedByList.includes(member?.eid) ? true : false
                  }
                />

                <Flex gap={2} alignItems={'center'}>
                  <Box>
                    <Image
                      src={getImage(member.profilePic, member.name)}
                      alt={member?.name}
                      width={'40px'}
                      height='40px'
                      borderRadius={'50%'}
                      backgroundColor={'gray.200'}
                    />
                  </Box>
                  <Flex direction={'column'}>
                    <Text fontWeight={600}>{`${member?.name}`}</Text>
                    <Text
                      color={'#92929D'}
                      fontSize={'0.7rem'}
                      fontWeight={600}
                    >{`${member?.role} . ${member?.locations[0]?.name}`}</Text>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Box>
      ) : (
        <EmptyState title={'member'} />
      )}
    </>
  );
};

export default CreatedByFilterComponent;
