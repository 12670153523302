import { Text } from '@chakra-ui/react';
import React, { FC } from 'react';

const Description: FC = () => {
  return (
    <Text fontWeight={400} textAlign='center'>
      Launch the location via launcher to get your location up and running. This
      will enable the location owner to complete the necessary tasks to mark the
      location as &apos;Open&apos;
    </Text>
  );
};

export default Description;
