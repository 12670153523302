import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';

interface IProps {
  startDate?: string;
}

const ProjectStartSection: FC<IProps> = ({ startDate }) => {
  return (
    <Flex gap='10px' align='center' boxSize='full' p={4}>
      <Center
        boxSize='60px'
        borderRadius='50%'
        border='1px solid rgba(221, 228, 236, 1)'
      >
        <FontAwesomeIcon
          icon={faPlay as IconProp}
          color='rgba(42, 133, 255, 1)'
          size='2x'
        />
      </Center>
      <Flex flexDir='column'>
        <Flex gap='5px'>
          <Box as='span' fontWeight={500} color='rgba(111, 118, 126, 1)'>
            Project start date
          </Box>
        </Flex>
        <Box as='span' fontSize='18px' fontWeight={600}>
          {moment(startDate)?.isValid()
            ? moment(startDate)?.format('DD MMMM YYYY')
            : '-'}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProjectStartSection;
